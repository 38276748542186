import React, { useEffect, useState } from "react";
import Listing from "src/components/Common/Listing";
import moment from "moment";
import "./RenderResult.scss";

export const RenderResult = ({ result }) => {
  const [errorMap, setErrorMap] = useState([]);

  const { processCount, successCount, invalidData } = result;
  const errorCount = Object.keys(invalidData).length;
  const date = moment(new Date()).format("ll");
  const time = moment(new Date()).format("LT");

  useEffect(() => {
    if (invalidData) {
      const errorMapping = Object.entries(invalidData).map(([key, value]) => {
        return { email: key, error: value };
      });
      setErrorMap(errorMapping);
    }
  }, [invalidData]);
  if (result) {
    return (
      <div className="render-result">
        <p>
          {successCount}/{processCount} successfully uploaded • {date} • {time}
        </p>

        <div className="mt-3">
          {!!errorCount && (
            <>
              <h5 className="error-heading">Failed ({errorCount})</h5>
              <hr className="mt-0" />
              <Listing
                headerClassName="gap-3"
                rowClassName="gap-3"
                dataSource={errorMap}
                headerLayout={"1fr 2fr"}
                dataLayout={"1fr 2fr"}
                headers={["Email", "Error in Fields"]}
                dataKeys={[
                  {
                    name: "email",
                    render: (data) => {
                      return <p>{data.email}</p>;
                    },
                  },
                  {
                    name: "error",
                    render: (data) => {
                      return <p>{data.error}</p>;
                    },
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    );
  }
  return null;
};
