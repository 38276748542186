import { SyncOutlined } from '@ant-design/icons'

export const GAMEPLAY_STATUSES = [
  { name: "Created", value: "created" },
  { name: "Invited", value: "invited" },
  { name: "Playing", value: "playing" },
  { name: "Completed", value: "completed" },
  // { name: "Clear", value: "" },
];

export const HIRING_STATUSES = [
  {
    name: "Shortlisted",
    value: "shortlisted-sub",
    children: [
      { name: "Initial Stage", value: "shortlisted" },
      { name: "Offer Rolled Out", value: "offer-rolled-out" },
      { name: "Hired", value: "hired" },
    ]
  },
  {
    name: "Rejected",
    value: "rejected-sub",
    children: [
      { name: "Initial Stage", value: "rejected" },
      { name: "Post Shortlist", value: "shortlisted-reject" },
      { name: "Post Offer", value: "offer-rolled-out-reject" },
    ]
  },
  {
    name: "Dropped Out",
    value: "dropout",
    children: [
      { name: "Post Shortlist", value: "shortlisted-dropout" },
      { name: "Post Offer", value: "offer-rolled-out-dropout" },
    ]
  },
];

export const USER_STATUSES = [
  { name: "Disqualified", value: 1 },
  { name: "Qualified", value: 0 },
];

export const REPORT_OPTIONS = [
  { name: "Pending", value: "pending" },
  { name: "Generated", value: "generated" },
  { name: "Clear", value: "" },
  // { name: "Shortlisted", value: "shortlist" },
];

export const SORT_OPTIONS = [
  { name: "Recently changed", value: "-updatedAt" },
  { name: "Highest fitscore", value: "-fitScore" },
  {
    name: <div className="flex items-center">
      <SyncOutlined />
      <span className="ml-4 mb--2">Clear All</span>
    </div>,
    value: "clear"
  }
];

export const GAMEPLAY_KEY = 'gameplay';
export const HIRING_KEY = 'hiring';
export const USER_STATUS_KEY = 'user_status';
export const FIT_SCORE_KEY = "fitScoreRange"
export const CLEAR_KEY = 'clear';

export const INITIAL_VALUES = {
  gameplayStatus: "",
  hiringStatus: "",
  reportStatus: "",
  linkStatus: "",
  fitScoreRange: "",
  created: "",
  sort: "",
};