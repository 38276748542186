import React, { useEffect, useState } from "react";

import { Collapse } from "antd";

import { convertToStyle, getTraitBand } from "../oprations";
import BulbIcon from "src/assets/img/bulb.png";
import { MIXPANEL_EVENTS } from "src/constants/globalConstants";
import { pluralizeWord } from "src/utils/helperFunctions";
import { UpArrow } from "src/assets/icons";

const HOW_TO_PROBE_LINK = process.env.REACT_APP_HOW_TO_PROBE_LINK;

export const InsightsTabs = ({
  traitsMap,
  activeTrait,
  standOutTraits,
  probeTraits,
  drivers,
  derailers,
  onTraitHover,
  logMixpanelEvent,
}) => {
  const [activeKeys, setActiveKeys] = useState([]);
  useEffect(() => {
    setActiveKeys(
      standOutTraits.length > 0 ? (probeTraits.length > 0 ? [1, 2] : [1]) : []
    );
  }, [standOutTraits, probeTraits]);
  return (
    <Collapse
      className="dark-theme"
      activeKey={activeKeys}
      expandIconPosition="right"
      bordered={false}
      onChange={(keys) => setActiveKeys(keys)}
      expandIcon={({ isActive }) => (
        <span className={`collapse-arrow ${isActive ? "" : "rotate"}`}>
          <UpArrow />
        </span>
      )}
    >
      <Collapse.Panel
        header={
          <h1
            className={`collapse-heading ${
              !standOutTraits.length ? "disabled" : ""
            }`}
          >
            {standOutTraits.length
              ? "Traits that stand out"
              : "No traits that stand out found"}
          </h1>
        }
        key="1"
        showArrow={!!standOutTraits.length}
        collapsible={standOutTraits.length ? null : "disabled"}
      >
        <div className="traits-wrapper">
          {standOutTraits.map(({ traitId, traitDisplayName }) => (
            <h2
              key={traitId}
              className={`trait-box dark small${
                activeTrait && traitId !== activeTrait ? " dim" : ""
              }`}
              style={convertToStyle(getTraitBand(traitsMap.get(traitId)))}
              onMouseEnter={() => onTraitHover(traitId)}
              onMouseLeave={() => onTraitHover()}
            >
              {traitDisplayName}
            </h2>
          ))}
        </div>
      </Collapse.Panel>
      <Collapse.Panel
        header={
          <h1
            className={`collapse-heading ${
              !probeTraits.length ? "disabled" : ""
            }`}
          >
            {probeTraits.length
              ? "Traits that need attention"
              : "No traits that need attention found"}
          </h1>
        }
        key="2"
        showArrow={!!probeTraits.length}
        collapsible={probeTraits.length ? null : "disabled"}
      >
        <div className="collapse-content">
          {probeTraits.map(
            ({ traitId, traitDisplayName, statement, howToProbe, score }) =>
              score === null ? null : (
                <div
                  key={traitId}
                  className={
                    activeTrait && traitId !== activeTrait ? "dim" : ""
                  }
                >
                  <div className="heading-wrapper">
                    <h2
                      className={`trait-box dark small`}
                      style={convertToStyle(
                        getTraitBand(traitsMap.get(traitId))
                      )}
                      onMouseEnter={() => onTraitHover(traitId)}
                      onMouseLeave={() => onTraitHover()}
                    >
                      {traitDisplayName}
                    </h2>
                    {howToProbe && (
                      <a
                        href={HOW_TO_PROBE_LINK}
                        className="probe-btn"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          logMixpanelEvent(MIXPANEL_EVENTS.ViewedHowToProbe)
                        }
                      >
                        <BulbIcon />
                        See how to probe
                      </a>
                    )}
                  </div>
                  <p className="statement">{statement}</p>
                </div>
              )
          )}
        </div>
      </Collapse.Panel>
      <Collapse.Panel
        header={
          <h1
            className={`collapse-heading ${!drivers.length ? "disabled" : ""}`}
          >
            {drivers.length
              ? `${drivers.length} ${pluralizeWord(
                  "Observed Driver",
                  "Observed Drivers",
                  drivers.length
                )}`
              : "No Observed Drivers Found"}
          </h1>
        }
        key="3"
        showArrow={!!drivers.length}
        collapsible={drivers.length ? null : "disabled"}
      >
        <div className="collapse-content">
          {drivers.map(({ heading, text }) => (
            <div key={heading}>
              <h2 className={`trait-box dark small no-tag`}>{heading}</h2>
              <p className="statement">{text}</p>
            </div>
          ))}
        </div>
      </Collapse.Panel>
      <Collapse.Panel
        header={
          <h1
            className={`collapse-heading ${
              !derailers.length ? "disabled" : ""
            }`}
          >
            {derailers.length
              ? `${derailers.length} Observed Derailer${
                  derailers.length > 1 ? "s" : ""
                }`
              : "No Observed Derailers Found"}
          </h1>
        }
        key="4"
        showArrow={!!derailers.length}
        collapsible={derailers.length ? null : "disabled"}
      >
        <div className="collapse-content">
          {derailers.map(({ heading, text }) => (
            <div key={heading}>
              <h2 className={`trait-box dark small no-tag`}>{heading}</h2>
              <p className="statement">{text}</p>
            </div>
          ))}
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
