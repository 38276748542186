import React, { useState, useEffect } from 'react';
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css';
import { CHART_COLORS } from './constants';
import './SpiderChart.scss';

export default function SpiderChart(props) {
  const { paramsMap = {}, scores } = props;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const data = scores.map((score, index) => {
      return { data: { ...score }, meta: { color: CHART_COLORS[index] } };
    });
    setChartData(data);
  }, [scores]);

  return (
    <div className="spider-chart align-center">
      {Object.keys(paramsMap).length > 0 && (
        <RadarChart captions={paramsMap} data={chartData} size={280} />
      )}
    </div>
  );
}

export const Legend = (props) => {
  const { legends } = props;
  return (
    <div className="legends flex flex-col">
      {legends.map((legend, index) => {
        return (
          <div className="flex">
            <div
              className="legend-color"
              style={{ backgroundColor: CHART_COLORS[index] }}
            ></div>
            <div className="uppercase text-grey size-11 weight-500">
              {legend}
            </div>
          </div>
        );
      })}
    </div>
  );
};
