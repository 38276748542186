import React from "react";

const resetErrorBoundaryState = () => {
  window.location.reload();
};

export default function ErrorFallback({ error, resetErrorBoundary }) {
  console.log("info", error.message);
  return (
    <div className="flex main-warning-wrapper items-center justify-center">
      <div className="alert-global" role="alert">
        <img className="error-msg" src="/img/err-msg.png" alt="error" />
        <div className="warning-msg">Aaaah! Something went wrong :(</div>
        <div className="warning-desc">
          Brace yourself till we get the error fixed.
        </div>
        <div className="warning-footer">
          You may also Refresh the page of click on Try Again below
        </div>
        <button
          className="try-again-global cursor-pointer"
          onClick={() => resetErrorBoundaryState()}
        >
          Try again
        </button>
      </div>
    </div>
  );
}
