/* eslint-disable no-undef */
import Toast from "src/components/Common/Toast";
import { storage } from "src/services/config/storage";
import Config from "./apiUrl";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import { REFRESH_SESSION_EVENT, REFRESH_SESSION_FORM_VIEW } from "src/constants/globalConstants";

const EXPIRED_TOKEN = "your session has expired";
const USER_EXISTS = "user already exists";

const { REFRESH_TOKEN, OTP_LOGIN } = REFRESH_SESSION_FORM_VIEW;

const _AuthHeaders = async () => {
  const token = storage.get.authToken();
  return {
    Authorization: `${token}`,
    "Content-Type": "application/json",
  };
};

/**
 * Check the validity of the token and refresh it if necessary.
 * @param {string} path - The API URL path.
 * @returns {string|null} - The refreshed/valid auth token or null if refresh is in progress or token is expired.
 */
const tokenValidityHandler = async (path) => {
  const decodedToken = storage.get.decodedToken();
  const authToken = storage.get.authToken();
  /* Update: Not run validity checks on report and login routes. */
  const hasReportOrLoginLocation =
    window.location.pathname.includes("report") ||
    window.location.pathname.includes("login");
  if (hasReportOrLoginLocation) return (authToken ?? 'report');

  const sysTime = Math.round(Date.now() / 1000);
  //** If current time is less than expiry time for decoded token, returns current token. */ 
  if (decodedToken && (sysTime < decodedToken.exp)) return authToken;
  const validToken = await tokenValidityCall(path);
  return validToken;
};

/**
 * Made into separate function.
 * To handle also for case of two login instances -> Which triggers token-expiry on first logged in instance.
 * 
 * To avoid multiple new auth token gen. calls, keep flag in LS to check,
 * If try-catch is already in progress.
 * Flag reset in finally block.
 */
const tokenValidityCall = async (path = Config.API_URL) => {
  const refreshInProgress = storage.get.refreshTokenInProgress();
  window.dispatchEvent(new CustomEvent(REFRESH_SESSION_EVENT, { detail: REFRESH_TOKEN }));
  if (refreshInProgress) return null;
  storage.set.refreshTokenInProgress(true);
  try {
    const refreshToken = storage.get.refreshToken();
    const authToken = storage.get.authToken();
    const data = { 'refreshToken': refreshToken };
    const headers = {
      Authorization: `${authToken}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${path}/api/user/v1/generate-new-auth-token`, {
      method: "POST",
      headers,
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error('Token expired');
    }
    const responseData = await response.json();
    const { token = null } = responseData;
    /**
    * On new authToken, reset vars in LS
    * And reload window -> Keeping user on the same page.
    */
    if (token) {
      storage.set.authToken(token);
      storage.set.decodedToken(token);
      window.location.reload();
      return;
    }
    /** If not token, clear LS -> Reload brings user to "/login" route */
    storage.destroy.authToken();
    window.location.reload();
  } catch (e) {
    storage.destroy.authToken();
    window.location.reload();
    console.error('e', e);
  } finally {
    storage.set.refreshTokenInProgress(false);
  }
}

const connectionHandler = () => {
  return navigator.onLine ? true : false;
};

const errHandler = async (res) => {
    if (res.status === 401) {
    window.dispatchEvent(new CustomEvent(REFRESH_SESSION_EVENT, { detail: OTP_LOGIN }));
  } else if (res.status === 400 || res.status === 403) {
    const contentType = res.headers.get("content-type");
    // Change later - check for positionStats/dnaPositionStats -> Not to show error
    if (res.url.includes("/stats")) return;
    if (contentType && contentType.indexOf("application/json") !== -1) {
      res.json().then(async (res) => {
        const isStringMessage = (typeof res.message === "string" || res.message instanceof String);
        if (
          isStringMessage &&
          res.message.toLowerCase().includes(USER_EXISTS)
        ) { return res.message.toLowerCase(); }
        if (
          isStringMessage &&
          res.message.toLowerCase().includes(EXPIRED_TOKEN)
        ) {
          window.dispatchEvent(new CustomEvent(REFRESH_SESSION_EVENT, { detail: OTP_LOGIN }));
        } else if (
          res.error &&
          res.message &&
          isStringMessage
        ) {
          Toast("error", `Opps! Error..`, `${res.message}`, 'toster priority');
        } else {
          Toast("error", `Opps! Error..`, `${res.message}`, 'toster priority');
        }
      });
    } else {
      res.text().then((res) => {
        Toast("error", `Opps! Error.`, `${res.error ? res.message : ""}`, 'toster priority');
      });
    }
  } else if (res.status === 404 || res.status === 500) {
    res.json().then((res) => {
      if (
        res.message.toLowerCase().trim().includes("profile proctoring not present") ||
        res.message.toLowerCase().trim().includes("no invite schedule found")
      ) return;
        
      Toast("error", "Opps! Error.", res.message, 'toster priority');
    });
  }
};

export const get = async (url, headers) => {
  if (!!headers) {
    const data = await _AuthHeaders();
    headers = data;
  }

  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, { headers })
        .then((res) => {
          if (res.status !== 200) {
            throw res;
          }
          const contentType = res.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return res.json();
          } else {
            return res.text();
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          errHandler(err);
          reject(err);
        });
    });
  }
};

export const post = async (url, data = {}, headers) => {
  if (!headers) {
    const data = await _AuthHeaders();
    headers = data;
  }
  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status !== 200) {
            throw res;
          }
          const contentType = res.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return res?.json();
          } else {
            return res?.text();
          }
        })
        .then((res) => {
          if (res.error) {
            reject(
              res?.errorData
                ? res.errorData.message
                : res.message
                  ? res.message
                  : res.error
            );
          } else {
            resolve(res.result ? res.result : res);
          }
        })
        .catch((err) => {
          errHandler(err);
          reject(err);
        });
    });
  }
};

export const put = async (url, data = {}, headers) => {
  if (!!headers) {
    const data = await _AuthHeaders();
    headers = data;
  }
  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, {
        method: "PUT",
        headers,
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status !== 200) {
            throw res;
          }
          const contentType = res.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return res.json();
          } else {
            return res.text();
          }
        })
        .then((res) => {
          if (res.error) {
            reject(res.message ? res.message : res.error);
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          window.err = err;
          reject(err);
        });
    });
  }
};

export const externalPut = async (url, data = {}, headers) => {
  if (!!headers) {
    const data = await _AuthHeaders();
    headers = data;
  }
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "PUT",
        headers,
        body: data,
      })
        .then((res) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          window.err = err;
          reject(err);
        });
    });
  }
};

export const patch = async (url, data = {}, headers) => {
  if (!!headers) {
    const data = await _AuthHeaders();
    headers = data;
  }
  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status !== 200) {
            throw res;
          }
          const contentType = res.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return res.json();
          } else {
            return res.text();
          }
        })
        .then((res) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res.result);
          }
        })
        .catch((err) => {
          window.err = err;
          reject(err);
        });
    });
  }
};

export const deleteCall = async (url, headers) => {
  if (!!headers) {
    const data = await _AuthHeaders();
    headers = data;
  }
  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, {
        method: "DELETE",
        headers,
      })
        .then((res) => (res && res.json()) || {})
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
};

export const formData = async (url, data = {}, method, headers) => {
  // converting data to formData
  const form_data = new FormData();

  for (var key in data) {
    form_data.append(key, data[key]);
  }

  data = form_data;

  if (!!headers) {
    const headersData = await _AuthHeaders();
    delete headersData["Content-Type"];
    headers = headersData;
  }
  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, {
        method: method || "POST",
        headers,
        body: data,
      })
        .then((res) => {
          if (res.status !== 200) {
            throw res;
          }
          const contentType = res.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return res?.json();
          } else {
            return res?.text();
          }
        })
        .then((res) => {
          if (res.error) {
            reject(res);
          } else {
            resolve(res.result ? res.result : res);
          }
        })
        .catch((err) => {
          errHandler(err);
          reject(err);
        });
    });
  }
};

export const Api = {
  get: (url, headers = _AuthHeaders(), noBaseUrl) =>
    get(`${noBaseUrl ? url : Config.API_URL + url}`, headers),

  post: (url, data, headers = _AuthHeaders(), noBaseUrl) =>
    post(`${noBaseUrl ? url : Config.API_URL + url}`, data, headers),

  put: (url, data, headers = _AuthHeaders()) =>
    put(`${Config.API_URL}${url}`, data, headers),

  formData: (url, data, method = "POST", headers = _AuthHeaders()) =>
    formData(`${Config.API_URL}${url}`, data, method, headers),

  patch: (url, data, headers = _AuthHeaders()) =>
    patch(`${Config.API_URL}${url}`, data, headers),

  deleteCall: (url, headers = _AuthHeaders()) =>
    deleteCall(`${Config.API_URL}${url}`, headers),

  externalPutCall: (url, data, headers = _AuthHeaders()) =>
    externalPut(url, data, headers),

  url: () => Config.API_URL,
};

export class Request {
  constructor(BASE_URL, getToken = null, HEADERS = {}) {
    this.BASE_URL = BASE_URL;
    this.getToken = getToken;
    this.DEFAULT_HEADERS = { "Content-type": "application/json" };
    this.headers = { ...this.DEFAULT_HEADERS, ...HEADERS };
    this.keywordsMap = {
      ":orgId": () => storage.get.organizationInfo().organizationId,
      ":orgType": () => AUTH_CONSTANTS.ORGANIZATION_TYPE,
    };
  }

  dict_to_params(path, params) {
    const queryString = new URLSearchParams(params).toString();
    const url = `${this.BASE_URL}${path}`;
    if (queryString) {
      return this.processPath(`${url}?${queryString}`);
    }
    return this.processPath(url);
  }

  processPath(path) {
    path = decodeURIComponent(path);
    Object.keys(this.keywordsMap).forEach((key) => {
      path = path.includes(key)
        ? path.replace(key, this.keywordsMap[key]())
        : path;
    });
    return path;
  }

  objectToFormData(object) {
    const formData = new FormData();
    for (var key in object) {
      formData.append(key, object[key]);
    }
    return formData;
  }

  call(path, method, options = {}) {
    const { data = null, params = {} } = options;
    this.path = this.dict_to_params(path, params);
    this.method = method;
    this.data = data;
    return this.request();
  }

  // If !tokenResponse -> Not make request call => token invalid or session expired.
  async safeFormData(path, method, options = {}) {
    const { data = null, params = {} } = options;
    const tokenResponse = await tokenValidityHandler(Config.API_URL);
    if (!tokenResponse) return;
    this.path = this.dict_to_params(path, params);
    this.method = method;

    this.data = this.objectToFormData(data);
    this.headers = { Authorization: tokenResponse };
    return this.request();
  }

  // If !tokenResponse -> Not make request call => token invalid or session expired.
  async safeCall(path, method, options = {}) {
    const { data = null, params = {} } = options;
    const tokenResponse = await tokenValidityHandler(Config.API_URL);
    if (!tokenResponse) return;
    this.path = this.dict_to_params(path, params);
    this.method = method;
    this.data = data;
    this.headers = { ...this.headers, Authorization: tokenResponse };
    if (!this.headers["Content-type"])
      this.headers = { ...this.DEFAULT_HEADERS, ...this.headers }
    return this.request();
  }

  request() {
    const requestOptions = {
      method: this.method,
      headers: this.headers,
    };
    if (this.data) {
      if (this.data instanceof FormData) {
        requestOptions.body = this.data;
      } else {
        requestOptions.body = JSON.stringify(this.data);
      }
    }
    return fetch(this.path, requestOptions)
      .then((res) => {
        if (res.status !== 200) {
          throw res;
        }
        const contentType = res.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return res?.json();
          /** Handle for excel responses */
        } else if (contentType && contentType.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") !== -1) {
          return res?.arrayBuffer();
        } else {
          return res?.text();
        }
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        /** 
         * Create clone for bodyStream  
         * Any method use on stream object (.json() or .text())
         * Renders it locked to be used again
         * 
         * Occurs since errHandler uses err object -> Calls some methods on err object
         * 
         * Resolution:
         * Pass cloned err object to errorHandler
         * Use original err object for returning error to function call
         * */
        const errorClone = err.clone();
        errHandler(errorClone);
        if (err.headers.get("content-type")?.includes("application/json")) {
          return err.json().then(err => { return err });
        } else {
          return err.text().then(err => { return err });
        }
      });
  }
}

export const UnberryApi = new Request(Config.API_URL, storage.get.authToken);
