import React from "react";
import { EmptyFile } from "src/assets/icons";

import "./NotFound.scss";

export const NotFound = ({ heading, className, styles }) => {
  return (
    <div className={`notFound-component ${className}`} style={styles}>
      <div className="icon-wrapper">
        <span className="icon">
          <EmptyFile />
        </span>
      </div>
      <div className="content">
        <h4 className="heading">{heading}...</h4>
        <div className="card"></div>
        <div className="card"></div>
      </div>
    </div>
  );
};
