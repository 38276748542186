import React, { useState, useEffect } from "react";
import Modal from "src/components/Common/Modal";
import { RightOutlined } from "@ant-design/icons";
import BotAvatar from "../../../../assets/img/grogu.png";

const MessageBubble = ({ data = {}, className, wrapperClassName }) => {
  const { type, content } = data;
  const isUserMsg = type === "user";

  return (
    <div
      className={
        `flex items-center weight-500
        ${wrapperClassName} 
        ${isUserMsg ? 'justify-end' : ''}`
      }
    >
      {!isUserMsg && <img src={BotAvatar} alt="chat-bot-avatar" />}
      <p
        className={
          `message-content ml-8 weight-600
          ${isUserMsg ? "user-message" : "bot-message"}
          ${className}
          `
        }
      >
        {content}
      </p>
    </div>
  );
};

const ChatAnswer = ({
  answer,
  ...props
}) => {

  const [showModal, setShowModal] = useState(false);
  const chatMessages = answer.answerText &&
    JSON.parse(answer.answerText);

  return (
    <>
      <p
        className="view-chat-history cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        <span>See Chat History</span>
        <span className="right-outlined relative ml-4 bottom-[3]">
          {<RightOutlined />}
        </span>
      </p>
      <Modal
        modalVisible={showModal}
        cancelModal={() => setShowModal(false)}
        centered
      >
        <p className="text-24 weight-700 pb-20">Chat History</p>
        <div className="chat-messages-container flex flex-col px-8 pb-24">
          {
            chatMessages.length > 0 &&
            chatMessages.map((message, index) => {
              return (
                <MessageBubble
                  key={index}
                  data={message}
                  wrapperClassName={index === 0 ? "mt-20" : "mt-10"}
                />
              )
            })
          }
        </div>
      </Modal>
    </>
  );
};

export default ChatAnswer;