import { useEffect, useState } from "react"
import { Skeleton } from "antd"
import { UserDeleteOutlined } from '@ant-design/icons'
import Modal from "src/components/Common/Modal"
import Toast from "src/components/Common/Toast"
import CandidateApi from "src/services/apis/candidate"
import { capitalizeFirstLetter, getDefaultFormattedDate } from "src/utils/helperFunctions"
import { formCategoryWiseInputs } from "./constants"
import { ReadCV } from "src/assets/icons"
import "./CandidateFormData.scss"

const CandidateFormData = ({ linkId, setCandidateFormDataLink }) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(null)

    useEffect(() => {
        if (linkId) {
            getCandidateFormData(linkId)
        }
    }, [linkId])

    const getCandidateFormData = async (linkId) => {
        try {
            setLoading(true)
            const response = await CandidateApi.getCandidateFormData(linkId)
            if (response.success) {
                if(response.userData.locationPreference) {
                    response.userData.locationPreference = response.userData.locationPreference?.split(",")?.join(", ") 
                }
                setFormData(response.userData)
            }
        } catch (err) {
            console.error(err)
            Toast("error", err)
        } finally {
            setLoading(false)
        }
    }

    const isCategoryEmpty = (category) => {
        let categoryIsEmpty = true
        for (const input in formCategoryWiseInputs[category]) {
            if (typeof(formData[input]) === "string" || typeof(formData[input]) === "number") {
                categoryIsEmpty = false
                break
            }
        }
        return categoryIsEmpty
    }

    return (
        <Modal
            modalVisible={linkId}
            cancelModal={() => {
                setFormData(null)
                setCandidateFormDataLink("")
            }}
            width="1008px"
            centered
            className="candidate-formdata-modal"
        >
            <p className="text-50 semibold mb-30 align-left">Candidate Details</p>
            <div className="candidate-details-content">
                {
                    loading
                        ? <div className="loading-container grid grid-cols-2 grid-gap-8">
                            {
                                Array.from({ length: 4 }, () => null).map((cell, index) => <Skeleton key={index} active />)
                            }
                        </div>
                        : formData === null
                            ? <div className="empty-data-container flex flex-col items-center justify-center">
                                <UserDeleteOutlined />
                                <div className="oval-shadow mt-1" />
                                <p className="text-20 weight-400 letter-spacing-8 mt-10">No details found</p>
                            </div>
                            : Object.keys(formCategoryWiseInputs).map((category, index) => isCategoryEmpty(category)
                                ? <></>
                                : <div
                                    key={category}
                                    className={`flex flex-col 
                                    ${index < (Object.keys(formCategoryWiseInputs).length - 1) ? "mb-30" : ""} 
                                    ${formData ? "slide-up" : ""} `}
                                >
                                    <p
                                        className={`category text-14 weight-600 letter-spacing-10 color-primary mb-16 text-uppercase`}
                                    >
                                        {category}
                                    </p>
                                    <div className="grid grid-cols-3 grid-gap-25">
                                        {
                                            Object.keys(formCategoryWiseInputs[category]).map(input => {
                                                if (formData[input] === undefined) return <></>

                                                const type = formCategoryWiseInputs[category][input].type
                                                const label = type === "file" ? "" : formCategoryWiseInputs[category][input].label
                                                const newRow = label === "12th Percentage" || label === "10th Percentage"
                                                const value = type === "file"
                                                    ? typeof (formData?.[input]) === "string" && formData?.[input]?.length > 0
                                                        ? <div className="flex items-center">
                                                            <span className="flex items-center justify-center download-icon mr-12">
                                                                <ReadCV />
                                                            </span>
                                                            <a
                                                                href={formData?.[input]}
                                                                target="_blank"
                                                                className="text-14 weight-600 cv-link cursor-pointer"
                                                            >
                                                                View Resume
                                                            </a>
                                                        </div>
                                                        : ""
                                                    : type === "date"
                                                        ? getDefaultFormattedDate(formData?.[input], "MMM D, YYYY")
                                                        : type === "year"
                                                            ? getDefaultFormattedDate(formData?.[input], "YYYY")
                                                            : label === "Gender"
                                                            ? capitalizeFirstLetter(formData?.[input] ?? "")
                                                            : label === "Notice Period (in days)"
                                                                ? `${formData?.[input]}`
                                                                : formData?.[input] ?? ""

                                                return (
                                                    <div
                                                        key={input}
                                                        className={`flex flex-col items-start ${newRow ? "grid-new-col" : ""}`}
                                                    >
                                                        <p className="text-14 weight-400 opacity-50 input-label">{label}</p>
                                                        <p className={`weight-600 text-16 w-full`}>
                                                            {!!value ? value : "Not available"}
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>)
                }
            </div>
        </Modal>
    )
}

export default CandidateFormData