import React from "react";
import PropTypes from "prop-types";
import TitleWithIcon from "../TitleWithIcon/TitleWithIcon";
import "./StatsWithIcon.scss";

export default function StatsWithIcon(props) {
  const { name, stat, className, titleNameStyle } = props;
  return (
    <div className={`stats-with-icon ${className}`}>
      <h1 className={`text-18 semibold opacity-9 mt-10 ${titleNameStyle} `}>
        {stat}
      </h1>
      <p className={`text-14 opacity-6 ${titleNameStyle}`}>{name}</p>
    </div>
  );
}

StatsWithIcon.propTypes = {
  headerLayout: PropTypes.string,
  dataLayout: PropTypes.string,
  dataSource: PropTypes.array,
  headers: PropTypes.array,
  dataKeys: PropTypes.array,
  uniqueKey: PropTypes.string,
};

StatsWithIcon.defaultProps = {
  headerLayout: "",
  dataLayout: "",
  dataSource: [],
  headers: [],
  dataKeys: [],
  uniqueKey: "",
};
