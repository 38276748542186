import { useEffect, useState } from "react"
import { CloseCircleOutlined } from '@ant-design/icons'
import Modal from "src/components/Common/Modal"
import { MessageBubble } from "../ChatSection/ChatDetailModal/ChatDetailModal"
import { capitalizeTitle } from "src/utils/helperFunctions"
import { isObject } from "src/utils/helperFunctions"
import { roundOff } from "src/pages/PdfReport/operations"
import { CEFR_MAP } from "../constant"
import { AIPoweredInsights, CaretRightBlue, Info } from "src/assets/icons"
import "./styles.scss"

const excludedMarkers = [
	"Average Score",
	"cefrBreakdown",
	"maxScore",
	"totalMessagesSentbyExecutive",
	"userScore",
	"summary",
	"cefrScore",
	"ticketResolved",
	"customerSatisfaction",
]

const MultiChatSection = ({ data }) => {
	const [avgMetrics, setAvgMetrics] = useState(null)
	const [showModal, setShowModal] = useState("")
	const [showInstructions, setShowInstructions] = useState("")
	const [cefrScore, setCefrScore] = useState("")
	const [cefrMarkers, setCefrMarkers] = useState([])
	const [analysisMarkers, setAnalysisMarkers] = useState([])
	const [summary, setSummary] = useState("")
	const [chat, setChat] = useState([])

	const isNumber = (num) => typeof num === 'number' && num !== null
	const convertStringToNumber = (string) => string?.includes("N/A") ? "N/A" : typeof string === 'string' ? parseFloat(string) : 'N/A'

	useEffect(() => {
		if (data) {
			const updatedAvgMetrics = {}
			const totalCefr = data.reduce((acc, current) => acc + Number(current?.answer?.customText?.cefrBreakdown?.["Average Score"] ?? 0), 0)
			updatedAvgMetrics.cefrScore = totalCefr / data?.length
			const totalTimeToRespond = data.reduce((acc, current) => acc + Number(current?.answer?.timeSpent ?? 0), 0)
			updatedAvgMetrics.timeToRespond = `${Math.round(totalTimeToRespond / data?.length)} sec`
			const totalTypingSpeed = data.reduce((acc, current) => acc + Number(current?.answer?.typingSpeed ?? 0), 0)
			updatedAvgMetrics.typingSpeed = `${Math.round(totalTypingSpeed / data?.length)} w/m`
			const numberOfMessages = data.reduce((acc, current) => acc + JSON.parse(current?.answer?.answerText ?? "[]")?.length, 0)
			updatedAvgMetrics.numberOfMessages = Math.round(numberOfMessages/data?.length)
			const totalResolvedTickets = data.reduce((acc, current) => acc + Number(current?.answer?.customText?.ticketResolved ?? false), 0)
			updatedAvgMetrics.resolvedTickets = `${totalResolvedTickets}/${data?.length}`
			const totalCustomerSatisfaction = data.reduce((acc, current) => acc + Number(current?.answer?.customText?.customerSatisfaction ?? 0) * 10, 0)
			updatedAvgMetrics.customerSatisfaction = isNaN(totalCustomerSatisfaction) ? "NA" : `${Math.round(totalCustomerSatisfaction / data?.length)}/10`
			setAvgMetrics({ ...updatedAvgMetrics })
		}
	}, [data])

	const extractMarkers = (obj, capTitle = false, roundOffToFirstDecimal = false) => Object.keys(obj)
		.filter(key => !excludedMarkers.includes(key))
		.map(elem => {
			const [keyScore, keyDesc] = isObject(obj[elem]) ?
				[
					`${isNumber(obj[elem]?.score) ? `${obj[elem].score}` : convertStringToNumber(obj[elem].score)}`,
					`${obj[elem]?.insights?.length > 0 ? obj[elem].insights : "Insufficient Data"}`
				] :
				obj[elem].split("-")
			return {
				name: capTitle ? capitalizeTitle(elem) : elem,
				description: keyDesc,
				score: keyScore?.includes("N/A") ? "NA" : `${roundOffToFirstDecimal ? roundOff(keyScore * 10) : (keyScore * 10)}/10`
			}
		})

	const onViewDetails = (answer) => {
		const { score = {}, answerText = "" } = answer ?? {}

		let scoreMarkers = [], analysisMarkers = [], analysisSummary = "", userCefrScore = ""

		userCefrScore = score?.cefrBreakdown?.["Average Score"] ? CEFR_MAP.get(Number(score?.cefrBreakdown?.["Average Score"])) : (score?.cefrScore ?? "NA")
		if (score && score?.cefrBreakdown) {
			scoreMarkers = extractMarkers(typeof score.cefrBreakdown === "string" ? {} : score.cefrBreakdown, true)
			analysisMarkers = extractMarkers(score, true, true)
			analysisSummary = score?.summary ?? ""
		}
		setCefrMarkers(scoreMarkers)
		setAnalysisMarkers(analysisMarkers)
		setSummary(analysisSummary)
		setCefrScore(userCefrScore)
		setChat(JSON.parse(answerText))
		setShowModal(answer.id)
	}

	return (
		<>
			<div className="multi-chat-section mt-10 mb-30">
				<div className="chat-card p-20 mb-20">
					<div className="chat-header flex items-center justify-between mb-10">
						<div className="chat-logo b-4 p-6 flex items-center bg-white">
							<AIPoweredInsights />
							<span className="text-12 weight-700 uppercase ml-8">AI POWERED INSIGHTS</span>
						</div>
						<div className="cefr-score flex items-center">
							<p className="text-16 weight-700">Overall CEFR Score:</p>
							<div className="b-40 text-20 weight-700 score-pill flex items-center justify-center ml-10">{CEFR_MAP.get(avgMetrics?.cefrScore)}</div>
						</div>
					</div>

					<div className="chat-title">
						<p className="heading text-20 weight-600 mb-8">Instant Messaging Analysis</p>
					</div>

					<div className="cefr-markers-container flex flex-wrap items-center justify-start mt-40">
						<div className="cefr-marker flex flex-col">
							<span className="text-25 weight-700 marker-score">{avgMetrics?.timeToRespond ?? ""}</span>
							<span className="text-16 weight-700 opacity-6 marker-name">Avg Time to Respond</span>
						</div>
						<div className="cefr-marker flex flex-col">
							<span className="text-25 weight-700 marker-score">{avgMetrics?.typingSpeed ?? ""}</span>
							<span className="text-16 weight-700 opacity-6 marker-name">Typing Speed</span>
						</div>
						<div className="cefr-marker flex flex-col">
							<span className="text-25 weight-700 marker-score">{avgMetrics?.numberOfMessages ?? ""}</span>
							<span className="text-16 weight-700 opacity-6 marker-name">Avg Number of Messages</span>
						</div>
						<div className="cefr-marker flex flex-col">
							<span className="text-25 weight-700 marker-score">{avgMetrics?.resolvedTickets ?? ""}</span>
							<span className="text-16 weight-700 opacity-6 marker-name">Tickets Resolved</span>
						</div>
						<div className="cefr-marker flex flex-col">
							<span className="text-25 weight-700 marker-score">{avgMetrics?.customerSatisfaction ?? ""}</span>
							<span className="text-16 weight-700 opacity-6 marker-name">Customer Satisfaction</span>
						</div>
					</div>

					<div className="chats-list-container flex flex-col items-stretch mt-40">
						<p className="text-16 weight-700 heading">Total {data?.length ?? ""} conversations</p>
						{
							data?.map((chat, idx) => (<div
								key={chat.question.id}
								className="chat-info-container b-10 p-24 flex items-center justify-between cursor-pointer"
								onClick={() => onViewDetails(chat.answer)}
							>
								<div className="flex items-center">
									<div className="metric-container flex flex-col items-start">
										<p className="title text-16 weight-700 mb-6">Ticket {idx + 1}</p>
										<p className="subtitle uppercase text-12 weight-700 opacity-50">{JSON.parse(chat?.answer?.answerText)?.length ?? 0} messages exchanged</p>
									</div>
									<div className="metric-container flex flex-col items-start">
										<p className="metric-title text-12 weight-700 mb-6 uppercase opacity-50">Avg time to respond</p>
										<p className="metric-subtitle text-18 weight-700">{Math.round(chat?.answer?.timeSpent ?? 0)} sec</p>
									</div>
									<div className="metric-container flex flex-col items-start">
										<p className="metric-title text-12 weight-700 mb-6 uppercase opacity-50">ticket resolved</p>
										<p className="metric-subtitle text-18 weight-700">{chat?.answer?.customText?.ticketResolved ? "Yes" : "No"}</p>
									</div>
									<div className="metric-container flex flex-col items-start">
										<p className="metric-title text-12 weight-700 mb-6 uppercase opacity-50">customer satisfaction</p>
										<p className="metric-subtitle text-18 weight-700">{isNaN(Math.round((chat?.answer?.customText?.customerSatisfaction ?? 0)*10)) ? "NA" : `${Math.round((chat?.answer?.customText?.customerSatisfaction ?? 0)*10)/10}`}</p>
									</div>
								</div>
								<div className="flex items-center chat-score-container">
									<p className="text-14 weight-700">Overall CEFR Score:</p>
									<p className="text-14 weight-700 b-40 flex items-center justify-center score-pill ml-8 mr-8">
										{chat?.answer?.customText?.cefrBreakdown?.["Average Score"] ? CEFR_MAP.get(Number(chat?.answer?.customText?.cefrBreakdown?.["Average Score"])) : (chat?.answer?.customText?.cefrScore ?? "NA")}
									</p>
									<CaretRightBlue />
								</div>
							</div>
							))
						}
					</div>
				</div>
			</div>

			<Modal
				width={'90vw'}
				open={showModal}
				onCancel={() => setShowModal("")}
				className="multichat-detail-modal"
				centered
			>
				<div className="outer-container">
					<div className="close-icon cursor-pointer" onClick={() => setShowModal("")}>
						<CloseCircleOutlined />
					</div>
					<div className="main-data-container flex">
						<div className="left-portion">
							<div className="title">
								<p className="heading text-20 weight-700 mb-8">Ticket {data?.findIndex(chat => chat.answer.id === showModal) + 1 ?? ""}</p>
								<p className="text-12 weight-700 opacity-50 uppercase">{chat?.length ?? ""} messages exchanged</p>
							</div>
							<div className="left-scroll-section mt-8">
								<p className="summary-text text-14 weight-600 mt-14">{summary ?? ""}</p>
								<div className="cefr-score flex items-center py-20">
									<p className="text-16 weight-700">CEFR SCORE:</p>
									{
										cefrScore === "NA"
										? <p className="text-16 insufficient-cefr capitalize weight-400 ml-10">Insufficient Data</p>
										: <div className="score-pill b-40 text-20 weight-700 flex items-center justify-center ml-10">{cefrScore}</div>
									}
								</div>

								<div className="flex flex-col">
									<div className="ltop-portion">
										{
											cefrMarkers.map((marker) => {
												const { name, score, description } = marker
												const scoreNumber = Number(score.split("/")[0])
												return (
													<div className="cefr-marker flex items-center">
														<span
															className="marker-score text-19 weight-700"
															style={{
																color: scoreNumber > 7.5
																	? "#61C489"
																	: scoreNumber > 6
																		? "#ABE99B"
																		: scoreNumber > 4
																			? "#FFDB8F"
																			: "#FE9EAB"

															}}
														>
															{score}
														</span>
														<div className="marker-info flex flex-col items-start">
															<span className="marker-name text-16 weight-700">{name}</span>
															<span className="marker-desc text-14 weight-600">{description}</span>
														</div>
													</div>
												)
											})
										}
									</div>
									<div className="lbottom-portion">
										{
											analysisMarkers.map((marker) => {
												const { name, score, description } = marker
												const scoreNumber = Number(score.split("/")[0])
												return (
													<div className="cefr-marker flex items-center">
														<span
															className="marker-score text-19 weight-700"
															style={{
																color: scoreNumber > 7.5
																	? "#61C489"
																	: scoreNumber > 6
																		? "#ABE99B"
																		: scoreNumber > 4
																			? "#FFDB8F"
																			: "#FE9EAB"

															}}
														>
															{score}
														</span>
														<div className="marker-info flex flex-col">
															<span className="marker-name text-16 weight-700">{name}</span>
															<span className="marker-desc text-14 weight-600">{description}</span>
														</div>
													</div>
												)
											})
										}
									</div>
								</div>							
							</div>
						</div>

						<div className="right-portion">
							<div className="title">
								<div className="flex items-center">
									<p className="heading text-20 weight-700">Chat History</p>
									<span 
										className="cursor-pointer ml-8"
										onClick={() => setShowInstructions(data?.find(question => question?.answer?.id === showModal)?.question?.question ?? "")}
									>
										<Info />
									</span>
								</div>
								<p className="summary-text text-14 weight-600 mt-8">
									The overall summary of the chat thats shown in the results page.
									This is basically the overall insight we got from the chat analysis
									of the customer purely on communication skills.
								</p>
							</div>

							<div
								className="right-scroll-section mt-30"
							>
								{chat?.map((msg, index) => (
									<MessageBubble
										key={index}
										data={msg}
										wrapperClassName={index === 0 ? "mt-10" : null}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<Modal
				width={'55vw'}
				open={showInstructions}
				onCancel={() => setShowInstructions("")}
				className="chat-instructions-modal"
				centered
			>
				<div className="close-icon cursor-pointer" onClick={() => setShowInstructions("")}>
					<CloseCircleOutlined />
				</div>
				<h2 className="text-30 capitalize">ticket instructions</h2>
				<div className="mt-20 ml-10 instructions" dangerouslySetInnerHTML={{ __html: showInstructions }}></div>
			</Modal>

		</>
	)
}

export default MultiChatSection