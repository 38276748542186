import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PdfReportApi from "src/services/apis/pdfReport";
import CandidateApi from "src/services/apis/candidate.js";
import { setWalletCredits } from "src/store/wallet/asyncActions";
import { DISQUALIFY_REASONS, hiringStatusLabel, LOGIN_TYPE, MIXPANEL_EVENTS, PLAYER_STATUS, REPORT_STATUS, TEMP_NO_FITSCORE_POSITIONS } from "src/constants/globalConstants";
import DropdownMenu from "src/components/Common/DropdownMenu";
import Toast from "../Toast";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { roundOff } from "src/pages/PdfReport/operations";
import "./ReportGenerator.scss";
import { convertToStyle, getTraitBand } from "src/pages/OnePagerReport/oprations";
import { Menu } from "antd";
import { LinkOutlined, InfoCircleFilled, FormOutlined, UserOutlined, CheckOutlined, CloseOutlined, ArrowUpOutlined, DownCircleOutlined, StopOutlined, ExclamationOutlined } from "@ant-design/icons";
import { copyTextToClipboard, getGameplayLink, openFileInNewTab } from "src/utils/helperFunctions";
import WithToolTip from "../WithToolTip";
import { CameraIcon, GamePad, Invite } from "src/assets/icons";
import UpdateCandidateEmail from "src/forms/UpdateCandidateEmail";

export default function ReportGenerator(props) {
  const {
    linkId,
    userId,
    reportStatus,
    userStatus,
    hiringStatus,
    orgId,
    orgType,
    disqualified,
    disqualifyReason,
    removeUser,
    fitScore,
    constraints = {},
    proctoredImages = {},
    logMixpanelEvent,
    candidateInTeam = false, // don't show shortlist/hire/reject options for DNA
    onChangeOfStatus,
    positionActiveStatus,
    editInvite = null,
    loginType = "",
    candidateData = {},
    activeApiKey = "",
    showCandidateFormData,
    showCandidateReport = false,
  } = props;
  const { upperBound, lowerBound, median } = constraints;
  const [status, setStatus] = useState({
    message: "",
    userStatus,
    reportStatus,
    hiringStatus: hiringStatus || "",
  });
  const thirdPartyLogin = loginType === LOGIN_TYPE.THIRD_PARTY;
  const [menuVisibility, setMenuVisiblity] = useState(false);
  const [showUpdateInviteModal, setShowUpdateInviteModal] = useState(false);
  const [disqualification, setDisqualification] = useState({
    disqualified,
    disqualifyReason
  })
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const { positionId } = params;

  // Check if positionId is present in TEMP_NO_FITSCORE_POSITIONS and replace score pills with user status
  const hideFitScorePosition = TEMP_NO_FITSCORE_POSITIONS.includes(positionId);

  useEffect(() => {
    setStatus({
      ...status,
      hiringStatus,
      userStatus,
      reportStatus,
    });
  }, [reportStatus, hiringStatus, userStatus]);

  const dispatch = useDispatch();

  const toggleMenuView = () => setMenuVisiblity((prevState) => !prevState);

  // Re-use for checking credit while updating hiringStatus - shortlist/reject
  const generatePdfReport = async (useAsCreditCheck = false) => {
    if (userStatus === PLAYER_STATUS.COMPLETED) {
      setStatus({
        ...status,
        message: "Generating",
      });
      try {
        const response = await PdfReportApi.checkCredit(orgType, orgId);
        if (response.success) {
          if (response?.hasCredit) {
            setStatus({
              ...status,
              reportStatus: REPORT_STATUS.GENERATED,
            });
            // fetching wallet credit after the report is generated using viewGeneratedReport function
            setTimeout(() => {
              dispatch(setWalletCredits());
            }, 1000);
            if (!useAsCreditCheck) viewGeneratedReport(linkId, userId);
            return true;
          } else {
            Toast(
              "info",
              "Please add credits to generate report",
              "Insufficient Credits!"
            );
            setStatus({
              reportStatus: REPORT_STATUS.PENDING,
              message: "Re-generate PDF",
            });
            return false;
          }
        }
      } catch (err) {
        setStatus({
          reportStatus: REPORT_STATUS.PENDING,
          message: "Re-generate PDF",
        });
        Toast("error", err.statusText);
      }
    }
  };

  const updateHiringStatus = async (candidateStatus) => {
    let payload = {
      ...candidateStatus,
      linkId,
    };
    // For shortlist/reject status  
    // Re-use generatePdfReport -> Checks credits remaining in an org and proceed accordingly
    if (candidateStatus?.status === PLAYER_STATUS.SHORTLISTED ||
      candidateStatus?.status === PLAYER_STATUS.REJECTED
    ) {
      const hasCredit = await generatePdfReport(true);
      if (!hasCredit) return;
    }
    try {
      let response = await CandidateApi.updateCandidateHiringStatus(payload);
      if (response?.success) {
        setStatus({ ...status, hiringStatus: candidateStatus.status });
        const event =
          candidateStatus.status === PLAYER_STATUS.SHORTLISTED
            ? MIXPANEL_EVENTS.ShortlistCandidate
            : MIXPANEL_EVENTS.RejectedCandidate;
        logMixpanelEvent(event);
      }
    } catch (err) {
      Toast("error", err?.statusText);
    }
  };

  const resendInviteToCandidate = async () => {
    try {
      // Positions/Dna listing have diff keys - positionId/dnaId -> params
      let response = await CandidateApi.resendInvite({
        userId,
        positionId: params?.positionId || params?.dnaId,
        organizationId: orgId,
        organizationType: orgType,
      });
      if (response?.success) {
        onChangeOfStatus(userId, PLAYER_STATUS.INVITED);
        Toast(
          "success",
          "Invitation link sent to registed email Id",
          "Link sent Successfully"
        );
      }
      logMixpanelEvent(MIXPANEL_EVENTS.ResendInvite);
    } catch (err) {
      Toast("error", err?.statusText);
    }
  };

  /**
   * Get scroll position in sessionStorage -> Post navigating back from report link.
   * 
   * Implementation -> useEffect -> PositionDetail.js
   *  */
  const viewGeneratedReport = () => {
    const rightPortionScroll = document.querySelector(".right-portion").scrollTop;
    const positionContainerScroll = (document.querySelector(".position-detail-conatiner") ?? document.querySelector(".mydna-detail-container")).scrollTop;
    sessionStorage.setItem('trackUserListingScroll', rightPortionScroll || positionContainerScroll);

    history.push(`/report/v2/link/${linkId}/users/${userId}`);
    logMixpanelEvent(MIXPANEL_EVENTS.ViewedCandidateReport, {
      Source: "Three-dot menu",
    });
  };

  const viewCandidateSideReport = () => {
    openFileInNewTab(`${process.env.REACT_APP_ASSESSMENT_CENTRE_URL}/report/${linkId}/${userId}`)
  }

  const getFitScoreLabel = (median, lowerBound, upperBound, score) => {
    const band = getTraitBand({
      median,
      lb: lowerBound,
      ub: upperBound,
      score: roundOff(score),
    });
    const label = location.pathname.includes("myteam")
      ? band.dnaLabel
      : band.label;

    return label.split(" ").map((text, index, arr) =>
      arr.length === 1 ? (
        <>
          <p>{`${roundOff(fitScore)} •`}</p>
          <p>{text}</p>
        </>
      ) : (
        <p>{index === 0 ? `${roundOff(fitScore)} • ${text}` : text}</p>
      )
    );
  };

  const onRemoveDisqualification = async () => {
    try {
      const response = await CandidateApi.updateDisqualificationStatus(orgType, orgId, {linkId})
      if(response.success) {
        onChangeOfStatus(userId, PLAYER_STATUS.PLAYING)
        setDisqualification({
          disqualified: false,
          disqualifyReason: ""
        })
        Toast("success", "Disqualification removed successfully")
      }
    }catch(err) {
      console.error(err)
      Toast("error", err)
    }
  }

  const menuItems = [
    status.userStatus === PLAYER_STATUS.CREATED && {
      key: "invite",
      label: "Invite",
      icon: <Invite />
    },
    status.userStatus === PLAYER_STATUS.COMPLETED && {
      key: "report",
      label: status.reportStatus === REPORT_STATUS.GENERATED
        ? "View Report"
        : "Generate Report",
      icon: <ArrowUpOutlined className="view-report-icon" />
    },
    showCandidateReport && status.userStatus === PLAYER_STATUS.COMPLETED && status.reportStatus === REPORT_STATUS.GENERATED && {
      key: "candidate-report",
      label: "Candidate Side Report",
      icon: <ArrowUpOutlined className="view-report-icon" />
    },
    disqualification?.disqualified && {
      key: "reset-disqualification",
      label: "Remove Disqualification",
      icon: <span className="relative reset-disqualification-icon">
        <StopOutlined className="absolute" />
        <ExclamationOutlined className="absolute" />
      </span>
    },
    status.reportStatus === REPORT_STATUS.GENERATED && showCandidateFormData && {
      key: "candidate-formdata",
      label: "View Details",
      icon: <UserOutlined />
    },
    !candidateInTeam &&
    status.userStatus === PLAYER_STATUS.COMPLETED &&
    status.hiringStatus === PLAYER_STATUS.UNKNOWN &&
    status.reportStatus === REPORT_STATUS.GENERATED &&
    {
      key: "shortlist",
      icon: <CheckOutlined />,
      label: "Shortlist",
    },
    !candidateInTeam &&
    status.userStatus === PLAYER_STATUS.COMPLETED &&
    status.hiringStatus === PLAYER_STATUS.UNKNOWN &&
    status.reportStatus === REPORT_STATUS.GENERATED &&
    {
      key: "reject",
      icon: <CloseOutlined />,
      label: "Reject",
    },
    !candidateInTeam &&
    status.userStatus === PLAYER_STATUS.COMPLETED &&
    status.hiringStatus === PLAYER_STATUS.OFFER_ROLLED_OUT &&
    {
      key: "hire",
      icon: <CheckOutlined />,
      label: "Hire"
    },
    !candidateInTeam &&
    status.userStatus === PLAYER_STATUS.COMPLETED &&
    status.hiringStatus === PLAYER_STATUS.SHORTLISTED &&
    {
      key: "offered",
      icon: <CheckOutlined />,
      label: "Offer Roll Out"
    },
    !candidateInTeam &&
    status.userStatus === PLAYER_STATUS.COMPLETED &&
    status.hiringStatus === PLAYER_STATUS.OFFER_ROLLED_OUT &&
    {
      key: "post-offer",
      label: "Post Offer",
      icon: <span className="line-block width-12 empty-icon" />,
      children: [
        {
          key: "reject",
          icon: <CloseOutlined className="mt-2" />,
          label: "Reject"
        },
        {
          key: "dropout",
          icon: <DownCircleOutlined className="mt-2" />,
          label: "Dropout"
        }
      ],
    },
    !candidateInTeam &&
    status.userStatus === PLAYER_STATUS.COMPLETED &&
    status.hiringStatus === PLAYER_STATUS.SHORTLISTED &&
    {
      key: "post-shortlist",
      label: "Post Shortlist",
      icon: <span className="line-block width-12 empty-icon" />,
      children: [
        {
          key: "reject",
          icon: <CloseOutlined className="mt-2" />,
          label: "Reject"
        },
        {
          key: "dropout",
          icon: <DownCircleOutlined className="mt-2" />,
          label: "Dropout"
        }
      ],
    },
    positionActiveStatus &&
    ((status.userStatus !== PLAYER_STATUS.COMPLETED &&
      status.userStatus !== PLAYER_STATUS.CREATED &&
      status.userStatus !== PLAYER_STATUS.CANCELLED) || (
        status.userStatus === PLAYER_STATUS.PLAYING
      )) &&
    {
      key: "copyinvite",
      icon: <LinkOutlined className="m-0 weight-900" />,
      label: "Copy Invite Link"
    },
    positionActiveStatus &&
    status.userStatus === PLAYER_STATUS.INVITED &&
    {
      key: "resendInvite",
      icon: <Invite />,
      label: "Resend Invite"
    },
    // editInvite &&
    // {
    //   key: "editInvite",
    //   icon: <EditOutlined />,
    //   label: "Edit Invite"
    // },
    thirdPartyLogin &&
    {
      key: "updateCanidateEmail",
      icon: <FormOutlined />,
      label: "Update Email"
    },
  ];

  const menu = (
    <div className={`optionmenu ${menuVisibility ? "open" : ""}`}>
      <Menu
        onClick={({ key, keyPath }) => {
          const keyPathStr = keyPath.join("|");
          switch (keyPathStr) {
            case "invite":
              resendInviteToCandidate();
              break;
            case "report":
              if (status.reportStatus === REPORT_STATUS.GENERATED) {
                viewGeneratedReport();
              } else {
                generatePdfReport();
              }
              break;
            case "candidate-report": viewCandidateSideReport()
              break;
            case "candidate-formdata":
              showCandidateFormData(linkId);
              break;
            case "hire":
              updateHiringStatus({ status: PLAYER_STATUS.HIRED });
              break;
            case "offered":
              updateHiringStatus({ status: PLAYER_STATUS.OFFER_ROLLED_OUT });
              break;
            case "copyinvite":
              copyTextToClipboard(getGameplayLink(linkId));
              Toast("success", "Link Copied Successfully.", "Link copied");
              break;
            case "resendInvite":
              resendInviteToCandidate();
              break;
            case "editInvite":
              editInvite();
              break;
            case "updateCanidateEmail":
              setShowUpdateInviteModal(true);
              break;
            case "shortlist":
              updateHiringStatus({ status: PLAYER_STATUS.SHORTLISTED });
              break;
            case "reject":
              updateHiringStatus({
                status: status.hiringStatus === PLAYER_STATUS.HIRED
                  ? PLAYER_STATUS.HIRED_REJECT
                  : PLAYER_STATUS.REJECTED,
              });
              break;
            case "reject|post-shortlist":
              updateHiringStatus({
                status: PLAYER_STATUS.SHORTLISTED_REJECT
              });
              break;
            case "reject|post-offer":
              updateHiringStatus({
                status: PLAYER_STATUS.OFFER_ROLLED_OUT_REJECT
              });
              break;
            case "dropout|post-shortlist":
              updateHiringStatus({
                status: PLAYER_STATUS.SHORTLISTED_DROPOUT
              });
              break;
            case "dropout|post-offer":
              updateHiringStatus({
                status: PLAYER_STATUS.OFFER_ROLLED_OUT_DROPOUT
              });
              break;
            case "reset-disqualification":
              onRemoveDisqualification()
            default: return;
          }
        }}
        items={menuItems}
      />
    </div>
  );

  const ReportOnClick = ({ children }) => {
    return (
      <div className="cursor-pointer" onClick={(e) => {
        e.stopPropagation();
        viewGeneratedReport();
      }}>
        {children}
      </div>
    );
  };

  return (
    <div className="report-generator grid mobile-order-1">
      <div
        className={`background-overlay ${menuVisibility ? "" : "hide"}`}
        onClick={toggleMenuView}
      ></div>
      {
        disqualification?.disqualified ? (
          <ReportOnClick>
            <div className="flex uppercase">
              <div
                className="status-pill disqualified flex items-center space-between"
                style={convertToStyle(
                  getTraitBand({
                    median,
                    lb: lowerBound,
                    ub: upperBound,
                    score: roundOff(fitScore),
                    disqualified: disqualification?.disqualified
                  })
                )}
              >
                {(!!fitScore || fitScore === 0) && !hideFitScorePosition ? (
                  <div className="flex items-center space-between">
                    <span className="mx-2 weight-600">{`${roundOff(fitScore)}`}</span>
                    <span className="mx-2 weight-600">•</span>
                    <span className="mx-2 text-red text-14 weight-600">Disqualified</span>
                  </div>
                ) : (
                  <span className="text-red text-14 weight-600">Disqualified</span>
                )}
                {
                  disqualification?.disqualifyReason &&
                  <WithToolTip
                    title={DISQUALIFY_REASONS[disqualification?.disqualifyReason] ?? disqualification?.disqualifyReason}
                    showToolTip={true}
                  >
                    <InfoCircleFilled className="ml-4 text-red text-16" />
                  </WithToolTip>
                }
              </div>
            </div>
          </ReportOnClick>) :
          !candidateInTeam &&
            status.hiringStatus &&
            status.hiringStatus !== PLAYER_STATUS.UNKNOWN ? (
            <>
              {[
                PLAYER_STATUS.REJECTED,
                PLAYER_STATUS.SHORTLISTED_REJECT,
                PLAYER_STATUS.OFFER_ROLLED_OUT_REJECT,
                PLAYER_STATUS.HIRED_REJECT,
              ].includes(status.hiringStatus) && (
                  <ReportOnClick>
                    <div className="flex">
                      {
                        !hideFitScorePosition &&
                        <div
                          className="score-pill flex text-12 mr-5"
                          style={convertToStyle(
                            getTraitBand({
                              median,
                              lb: lowerBound,
                              ub: upperBound,
                              score: roundOff(fitScore),
                            })
                          )}
                        >
                          <p>{roundOff(fitScore)}</p>
                        </div>
                      }
                      <div className={`status rejected uppercase semibold text-11 status-mt`}>
                        {hiringStatusLabel[status?.hiringStatus] ?? ""}
                      </div>
                    </div>
                  </ReportOnClick>
                )}
              {status.hiringStatus === PLAYER_STATUS.SHORTLISTED && (
                <ReportOnClick>
                  <div className="flex">
                    {
                      !hideFitScorePosition &&
                      <div
                        className="score-pill flex text-12 mr-5"
                        style={convertToStyle(
                          getTraitBand({
                            median,
                            lb: lowerBound,
                            ub: upperBound,
                            score: roundOff(fitScore),
                          })
                        )}
                      >
                        <p>{roundOff(fitScore)}</p>
                      </div>
                    }
                    <div className={`status shortlisted uppercase semibold text-11 status-mt`}>
                      Shortlisted
                    </div>
                  </div>
                </ReportOnClick>
              )}
              {status.hiringStatus === PLAYER_STATUS.OFFER_ROLLED_OUT && (
                <ReportOnClick>
                  <div className="flex">
                    {
                      !hideFitScorePosition &&
                      <div
                        className="score-pill flex text-12 mr-5"
                        style={convertToStyle(
                          getTraitBand({
                            median,
                            lb: lowerBound,
                            ub: upperBound,
                            score: roundOff(fitScore),
                          })
                        )}
                      >
                        <p>{roundOff(fitScore)}</p>
                      </div>
                    }
                    <div className={`status shortlisted uppercase semibold text-11 status-mt`}>
                      Offer Rolled Out
                    </div>
                  </div>
                </ReportOnClick>
              )}
              {status.hiringStatus === PLAYER_STATUS.HIRED && (
                <ReportOnClick>
                  <div className="flex">
                    {
                      !hideFitScorePosition &&
                      <div
                        className="score-pill flex text-12 mr-5"
                        style={convertToStyle(
                          getTraitBand({
                            median,
                            lb: lowerBound,
                            ub: upperBound,
                            score: roundOff(fitScore),
                          })
                        )}
                      >
                        <p>{roundOff(fitScore)}</p>
                      </div>
                    }
                    <div className={`status shortlisted uppercase semibold text-11 status-mt`}>
                      Hired
                    </div>
                  </div>
                </ReportOnClick>
              )}
              {[
                PLAYER_STATUS.SHORTLISTED_DROPOUT,
                PLAYER_STATUS.HIRED_DROPOUT,
                PLAYER_STATUS.OFFER_ROLLED_OUT_DROPOUT,
              ].includes(status.hiringStatus) && (
                  <ReportOnClick>
                    <div className="flex">
                      {
                        !hideFitScorePosition &&
                        <div
                          className="score-pill flex text-12 mr-5"
                          style={convertToStyle(
                            getTraitBand({
                              median,
                              lb: lowerBound,
                              ub: upperBound,
                              score: roundOff(fitScore),
                            })
                          )}
                        >
                          <p>{roundOff(fitScore)}</p>
                        </div>
                      }
                      <div className={`status shortlisted uppercase semibold text-11 status-mt`}>
                        {hiringStatusLabel[status?.hiringStatus] ?? ""}
                      </div>
                    </div>
                  </ReportOnClick>
                )}
            </>
          ) : status.userStatus !== PLAYER_STATUS.COMPLETED ? (
            <div
              className={`status uppercase text-12 semibold mr-10 
            ${status.userStatus === PLAYER_STATUS.INVITED ? "invited" :
                  status.userStatus === PLAYER_STATUS.CANCELLED ? "cancelled" : "pending"}
          `}
            >
              {status.userStatus === PLAYER_STATUS.INVITED && "invited"}
              {status.userStatus === PLAYER_STATUS.CREATED && "created"}
              {status.userStatus === PLAYER_STATUS.CANCELLED && "cancelled"}
              {status.userStatus === PLAYER_STATUS.PLAYING && (
                <>
                  {/* game started */}
                  In Progress
                  {/* <span className="ml-4 mb-1">
                    <GamePad />{" "}
                  </span> */}
                </>
              )}
            </div>
          ) : (
            <div className={`status completed uppercase semibold text-13`}>
              {(!!fitScore || fitScore === 0) && !hideFitScorePosition ? (
                <ReportOnClick>
                  <div
                    className="status-pill flex flex-col text-12"
                    style={convertToStyle(
                      getTraitBand({
                        median,
                        lb: lowerBound,
                        ub: upperBound,
                        score: roundOff(fitScore),
                      })
                    )}
                  >
                    {getFitScoreLabel(median, lowerBound, upperBound, fitScore)}
                  </div>
                </ReportOnClick>
              ) : (
                "Completed"
              )}
            </div>
          )}
      {
        proctoredImages?.critical > 5 ?
          <div className="flex relative right-[12] bottom-[12] ml-4">
            <span className="absolute">
              <CameraIcon />
            </span>
            <span className="absolute left-[13] bottom-[-10]">
              <InfoCircleFilled style={{ color: 'red', fontSize: '12px' }} />
            </span>
            <span
              className="absolute left-[30] top-[3] text-darkblue"
            >
              {proctoredImages?.critical}
            </span>
          </div> :
          <div className="px-1"></div>
      }
      {(status.userStatus ||
        status.hiringStatus === PLAYER_STATUS.REJECTED) &&
        !(!positionActiveStatus && (status.userStatus === PLAYER_STATUS.INVITED || status.userStatus === PLAYER_STATUS.PLAYING))
        && (
          <div className="dropdownmenu cursor-pointer ml-12" onClick={(e) => {
            e.stopPropagation();
            toggleMenuView();
          }}>
            <DropdownMenu menu={menu} />
          </div>
        )}
      {
        showUpdateInviteModal &&
        <UpdateCandidateEmail
          showModal={showUpdateInviteModal}
          setShowModal={setShowUpdateInviteModal}
          candidateData={candidateData}
          activeApiKey={activeApiKey}
          userId={userId}
        />
      }
    </div>
  );
}
