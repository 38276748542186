import React from "react";
import { Route, Redirect } from "react-router-dom";
import { storage } from "src/services/config/storage";
import Layout from "src/components/Layout";

// Exclude checkOrgInfo on these paths
const excludeCheckOrgInfoPaths = ["/select-organization"];

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // checkOrgInfo -> orgInfo not present -> Redirect to login instead of error screen
        const { path } = rest;
        const checkOrgInfo = excludeCheckOrgInfoPaths.includes(path) ? 
          true: storage.checkOrgInfo();
        if (storage.checkAuthentication() && checkOrgInfo) {
          return (
            <Layout>
              <Component {...rest} {...props} />
            </Layout>
          );
        } else {
          storage.destroy.authToken();
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
