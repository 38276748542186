export const traitsData = [
  {
    name: 'Sustained Attention',
    lowerBound: {
      text: 'May have trouble focusing on tasks for long',
      value: 6,
    },
    upperBound: {
      text: 'Able to maintain persistent focus over long durations',
      value: 8,
    },
    score: 6.2,
    median: 7,
  },
  {
    name: 'Working Memory',
    lowerBound: {
      text: 'Tends to process data only in shorter bytes',
      value: 6,
    },
    upperBound: {
      text: 'Able to process data in significantly larger chunks',
      value: 8,
    },
    score: 6.2,
    median: 7,
  },
  {
    name: 'Processing Speed',
    lowerBound: {
      text: 'Expends time & effort to perform mental tasks',
      value: 6,
    },
    upperBound: {
      text: 'Performs mental operations with high agility',
      value: 8,
    },
    score: 6.2,
    median: 7,
  },
];

export const recommendations = [
  { color: '#F7635E', label: 'Not Recommended', scale: 1 },
  { color: '#FFCC6A', label: 'Cautiously Recommended', scale: 1 },
  { color: '#9DCF4B', label: 'Recommended', scale: 1 },
  { color: '#55B255', label: 'Highly Recommended', scale: 1 },
];

export const spiderData = {
  userScore: [
    {
      id: 'cb27ca7b-a8df-433f-be32-5a90b84a86e4',
      name: 'Fluid Intelligence',
      score: 5.6,
      contribution: 25,
    },
    {
      id: '253aef87-1633-42a7-8dcf-b99aa2a695c6',
      name: 'Concept Grasping',
      score: 2,
      contribution: 25,
    },
    {
      id: 'e7e6303b-726a-468b-b800-57b67b4b80ad',
      name: 'Ambiguity Tolerance',
      score: 7.2,
      contribution: 20,
    },
    {
      id: 'fedf6742-9eae-4b06-aefa-3ffc65d95b6c',
      name: 'Sustained Attention',
      score: 6.9,
      contribution: 20,
    },
    {
      id: '010e9835-2769-40e1-8098-72d2001b64f0',
      name: 'Patience',
      score: 8,
      contribution: 10,
    },
  ],

  globalScore: [
    {
      id: 'cb27ca7b-a8df-433f-be32-5a90b84a86e4',
      name: 'Fluid Intelligence',
      score: 5.6,
      contribution: 25,
    },
    {
      id: '253aef87-1633-42a7-8dcf-b99aa2a695c6',
      name: 'Concept Grasping',
      score: 5.6,
      contribution: 25,
    },
    {
      id: 'e7e6303b-726a-468b-b800-57b67b4b80ad',
      name: 'Ambiguity Tolerance',
      score: 5.6,
      contribution: 25,
    },
    {
      id: 'fedf6742-9eae-4b06-aefa-3ffc65d95b6c',
      name: 'Sustained Attention',
      score: 5.6,
      contribution: 25,
    },
    {
      id: '010e9835-2769-40e1-8098-72d2001b64f0',
      name: 'Patience',
      score: 5.6,
      contribution: 25,
    },
  ],
};

export const traitCards = [
  {
    traitId: 'e7e6303b-726a-468b-b800-57b67b4b80ad',
    traitName: 'Ambiguity Tolerance',
    diff: 1.7000000000000002,
    type: 1,
    tag: 'TOP IMPACT SKILL',
    statement:
      'Nitesh Rawat perceives ambiguity as desirable and challenging, and strives to resolve problems or situations that appear to be complex or unfamiliar.',
  },
  {
    traitId: 'fedf6742-9eae-4b06-aefa-3ffc65d95b6c',
    traitName: 'Sustained Attention',
    diff: 1.4000000000000004,
    type: 1,
    tag: 'TOP IMPACT SKILL',
    statement:
      'Nitesh Rawat demonstrated an inherent ability to focus on an activity or stimulus over a long period of time, even if there are other distracting stimuli present.',
  },
  {
    traitId: 'cb27ca7b-a8df-433f-be32-5a90b84a86e4',
    traitName: 'Fluid Intelligence',
    diff: 0.09999999999999964,
    type: 1,
    tag: 'TOP IMPACT SKILL',
    statement:
      'Nitesh Rawat demonstrated an inherent ability to focus on an activity or stimulus over a long period of time, even if there are other distracting stimuli present.',
  },
];

export const drivers = [
  {
    title: 'Pressure situations',
    statement:
      'Sanchit is highly analytical and good at handling stress and might be the type that “thrives” under pressure.',
  },
  {
    title: 'Unfamiliar situations',
    statement:
      'Giving him a chance to explore new, innovative ways to getting a task done, might highly motivate him.',
  },
  {
    title: 'Adaptive responsibilities',
    statement:
      'He would be able to easily transition from one task to another and know how to carry himself properly.',
  },
];

export const derailers = [
  {
    title: 'Volatile environments',
    statement:
      'He may take time to adapt to or learn new concepts, making him less productive when the environment is highly agile',
  },
  {
    title: 'Aggressive deadlines',
    statement:
      'He might easily get anxious or bogged down under tight timelines, or highly complex, yet resource-crunched projects',
  },
];

export const attentionTraits = [
  {
    traitId: '253aef87-1633-42a7-8dcf-b99aa2a695c6',
    traitName: 'Concept Grasping',
    diff: 1,
    type: 1,
    score: 2,
    tag: 'SHOULD PROBE',
    statement:
      'Nitesh Rawat might feel uncomfortable and might seek out external guidance when faced with complex and unfamiliar scenarios. She might face a great deal of anxiety about not knowing what will happen next.',
    howToProbe: {
      title: 'How can you probe?',
      statement:
        'Ask them about a time when they faced an unplanned contingency and how they got out of it. Ask them about a situation where they had to make a decision without enough data. How did it turn out?',
    },
  },
  {
    traitId: 'cde33aff-c820-44a8-badf-b2adcfa929e4',
    traitName: 'Processing Speed',
    diff: 1.2000000000000002,
    type: -1,
    score: 4.3,
    tag: '',
    statement:
      'Nitesh Rawat might feel uncomfortable and might seek out external guidance when faced with complex and unfamiliar scenarios. She might face a great deal of anxiety about not knowing what will happen next.',
    howToProbe: null,
  },
  {
    traitId: '6fcbd48f-6e0e-49a3-a0ce-f536c876a071',
    traitName: 'Working Memory',
    diff: 2.5,
    type: -1,
    score: 3,
    tag: '',
    statement:
      'Nitesh Rawat might feel uncomfortable and might seek out external guidance when faced with complex and unfamiliar scenarios. She might face a great deal of anxiety about not knowing what will happen next.',
    howToProbe: null,
  },
];

// --------------------------------------------------
// TODO: Remove this data populate through api
// --------------------------------------------------
// export const RES = {
//   success: true,
//   data: {
//     user: {
//       id: '0f5f8980-36bb-41c7-8ce5-fb7a3985ad50',
//       name: 'Sanchit Agrawal',
//       email: 'niteshrawat99@gmail.com',
//       yearsOfExperience: 'individual-contributor',
//       designation: null,
//       dob: null,
//       phone: null,
//     },
//     position: {
//       id: 'f69317a8-0b4f-493d-a78d-c6dd2348e360',
//       name: 'Engineering Manager',
//       description: null,
//       organizationId: 'cd91ba65-e729-44c6-b9f8-2498f55fe727',
//       organizationType: 'company',
//       organizationName: 'Unibuddy',
//     },
//     buckets: [
//       {
//         id: '4e8f6eff-8148-4d69-a7e9-a633a81a22a0',
//         createdAt: '2021-12-15T13:37:22.689Z',
//         updatedAt: '2021-12-15T13:37:22.695Z',
//         status: 0,
//         isActive: true,
//         customText: {},
//         name: 'Cognitive Profile',
//         description: 'How they process information & knowledge mentally',
//         positionId: 'd5eaf001-62d4-434a-887d-45139e182c48',
//         traitsMeta: {
//           'cb27ca7b-a8df-433f-be32-5a90b84a86e4': {
//             active: true,
//             id: 'cb27ca7b-a8df-433f-be32-5a90b84a86e4',
//             name: 'Fluid Intelligence',
//             description:
//               'Their ability to solve new problems without prior knowledge',
//             score: 5.6,
//             lowerBound: {
//               text: 'Solves new problems using past learnings',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Able to solve new problems using logic & reason',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//           '253aef87-1633-42a7-8dcf-b99aa2a695c6': {
//             active: true,
//             id: '253aef87-1633-42a7-8dcf-b99aa2a695c6',
//             name: 'Concept Grasping',
//             description: 'How efficiently they grasp new concepts & techniques',
//             score: 2,
//             lowerBound: {
//               text: 'Prefers learning in a structured environment',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Able to grasp new and dynamic concepts flexibly',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//           'e7e6303b-726a-468b-b800-57b67b4b80ad': {
//             active: true,
//             id: 'e7e6303b-726a-468b-b800-57b67b4b80ad',
//             name: 'Ambiguity Tolerance',
//             description:
//               'The degree to which they are comfortable with uncertainty',
//             score: 7.2,
//             lowerBound: {
//               text: 'Tends to steer clear of ill-defined situations',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Displays high tolerance for unfamiliar situations',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//         },
//         media: null,
//       },
//       {
//         id: '1cee73e6-9f4f-4a78-8e18-0180af6b8cb9',
//         createdAt: '2021-12-15T13:37:22.758Z',
//         updatedAt: '2021-12-15T13:37:22.759Z',
//         status: 0,
//         isActive: true,
//         customText: {},
//         name: 'Intelligence Profile',
//         description: 'How they isolate and navigate complex problems',
//         positionId: 'd5eaf001-62d4-434a-887d-45139e182c48',
//         traitsMeta: {
//           'fedf6742-9eae-4b06-aefa-3ffc65d95b6c': {
//             active: true,
//             id: 'fedf6742-9eae-4b06-aefa-3ffc65d95b6c',
//             name: 'Sustained Attention',
//             description: 'How they maintain focus over long durations',
//             score: 6.9,
//             lowerBound: {
//               text: 'May have trouble focusing on tasks for long',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Able to maintain persistent focus over long durations',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//           '010e9835-2769-40e1-8098-72d2001b64f0': {
//             active: true,
//             id: '010e9835-2769-40e1-8098-72d2001b64f0',
//             name: 'Patience',
//             description: 'Their ability to endure difficult circumstances',
//             score: 8,
//             lowerBound: {
//               text: 'Unwilling to wait patiently or tolerate delays',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Even tempered and calm under pressure',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//           '30ca6ccf-3b5d-4de0-867e-53bbc143c5da': {
//             active: true,
//             id: '30ca6ccf-3b5d-4de0-867e-53bbc143c5da',
//             name: 'Cooperation',
//             description: 'Their ability to work with others for a common goal',
//             score: 9,
//             lowerBound: {
//               text: 'Prioritises actions that elicit personal benefit',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Willing to work with others to maximise collective gains',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//         },
//         media: null,
//       },
//       {
//         id: '4b64cb1e-2679-4de0-9892-86727c993c58',
//         createdAt: '2021-12-15T13:37:22.825Z',
//         updatedAt: '2021-12-15T13:37:22.826Z',
//         status: 0,
//         isActive: true,
//         customText: {},
//         name: 'Thinking Profile',
//         description: 'How they learn concepts & organise their thoughts',
//         positionId: 'd5eaf001-62d4-434a-887d-45139e182c48',
//         traitsMeta: {
//           '253aef87-1633-42a7-8dcf-b99aa2a695c6': {
//             active: true,
//             id: '253aef87-1633-42a7-8dcf-b99aa2a695c6',
//             name: 'Concept Grasping',
//             description: 'How efficiently they grasp new concepts & techniques',
//             score: 2,
//             lowerBound: {
//               text: 'Prefers learning in a structured environment',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Able to grasp new and dynamic concepts flexibly',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//           'c5158e70-6b62-40f1-b8ee-6b36ee587729': {
//             active: true,
//             id: 'c5158e70-6b62-40f1-b8ee-6b36ee587729',
//             name: 'Planning',
//             description: 'How methodically they approach & break down problems',
//             score: 'inconclusive',
//             lowerBound: {
//               text: 'Tends to act spontaneously on their feet',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Plans ahead methodically to minimise mistakes',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//           '66eed2b3-7961-4a79-99b7-ff25c4a2b845': {
//             active: true,
//             id: '66eed2b3-7961-4a79-99b7-ff25c4a2b845',
//             name: 'Generosity',
//             description:
//               'Their willingness & spirit of being kind and generous',
//             score: null,
//             lowerBound: {
//               text: 'Tends to be careful while sharing resources',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Tends to freely and frequently share with others',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//         },
//         media: null,
//       },
//       {
//         id: 'e33f3cb5-5036-43c4-a3d4-a71716440ab0',
//         createdAt: '2021-12-15T13:37:22.852Z',
//         updatedAt: '2021-12-15T13:37:22.852Z',
//         status: 0,
//         isActive: true,
//         customText: {},
//         name: 'Behavioural Profile',
//         description: 'How they control and conduct themselves',
//         positionId: 'd5eaf001-62d4-434a-887d-45139e182c48',
//         traitsMeta: {
//           '2c839fb6-c3a5-43df-8c2c-095298bb21be': {
//             active: true,
//             id: '2c839fb6-c3a5-43df-8c2c-095298bb21be',
//             name: 'Competitiveness',
//             description: '',
//             score: null,
//             lowerBound: {
//               text: '',
//               score: 3,
//             },
//             upperBound: {
//               text: '',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//           'fedf6742-9eae-4b06-aefa-3ffc65d95b6c': {
//             active: true,
//             id: 'fedf6742-9eae-4b06-aefa-3ffc65d95b6c',
//             name: 'Sustained Attention',
//             description: 'How they maintain focus over long durations',
//             score: 6.9,
//             lowerBound: {
//               text: 'May have trouble focusing on tasks for long',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Able to maintain persistent focus over long durations',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//           '6fcbd48f-6e0e-49a3-a0ce-f536c876a071': {
//             active: true,
//             id: '6fcbd48f-6e0e-49a3-a0ce-f536c876a071',
//             name: 'Working Memory',
//             description: 'Their mental capacity to process information',
//             score: 3,
//             lowerBound: {
//               text: 'Tends to process data only in shorter bytes',
//               score: 3,
//             },
//             upperBound: {
//               text: 'Able to process data in significantly larger chunks',
//               score: 7.5,
//             },
//             median: 5.5,
//             stdDev: 1,
//           },
//         },
//         media: null,
//       },
//     ],
//     standOutTraits: [
//       {
//         traitId: 'e7e6303b-726a-468b-b800-57b67b4b80ad',
//         traitName: 'Ambiguity Tolerance',
//         diff: 1.7000000000000002,
//         type: 1,
//         tag: 'TOP IMPACT SKILL',
//         statement:
//           'Nitesh Rawat perceives ambiguity as desirable and challenging, and strives to resolve problems or situations that appear to be complex or unfamiliar.',
//       },
//       {
//         traitId: 'fedf6742-9eae-4b06-aefa-3ffc65d95b6c',
//         traitName: 'Sustained Attention',
//         diff: 1.4000000000000004,
//         type: 1,
//         tag: 'TOP IMPACT SKILL',
//         statement:
//           'Nitesh Rawat demonstrated an inherent ability to focus on an activity or stimulus over a long period of time, even if there are other distracting stimuli present.',
//       },
//       {
//         traitId: 'cb27ca7b-a8df-433f-be32-5a90b84a86e4',
//         traitName: 'Fluid Intelligence',
//         diff: 0.09999999999999964,
//         type: 1,
//         tag: 'TOP IMPACT SKILL',
//         statement: '',
//       },
//     ],
//     probeTraits: [
//       {
//         traitId: '253aef87-1633-42a7-8dcf-b99aa2a695c6',
//         traitName: 'Concept Grasping',
//         diff: 1,
//         type: 1,
//         score: 2,
//         tag: 'SHOULD PROBE',
//         statement:
//           'Nitesh Rawat might feel uncomfortable and might seek out external guidance when faced with complex and unfamiliar scenarios. She might face a great deal of anxiety about not knowing what will happen next.',
//         howToProbe: {
//           title: 'How can you probe?',
//           statement:
//             'Ask them about a time when they faced an unplanned contingency and how they got out of it. Ask them about a situation where they had to make a decision without enough data. How did it turn out?',
//         },
//       },
//       {
//         traitId: 'cde33aff-c820-44a8-badf-b2adcfa929e4',
//         traitName: 'Processing Speed',
//         diff: 1.2000000000000002,
//         type: -1,
//         score: 4.3,
//         tag: '',
//         statement: '',
//         howToProbe: null,
//       },
//       {
//         traitId: '6fcbd48f-6e0e-49a3-a0ce-f536c876a071',
//         traitName: 'Working Memory',
//         diff: 2.5,
//         type: -1,
//         score: 3,
//         tag: '',
//         statement: '',
//         howToProbe: null,
//       },
//     ],
//     drivers: {
//       'e7e6303b-726a-468b-b800-57b67b4b80ad': [
//         {
//           heading: 'heading 1',
//           text:
//             'Unfamiliar situations → Giving him a chance to explore new, innovative ways to getting a task done, might highly motivate him.',
//         },
//         {
//           heading: 'heading 1',
//           text:
//             'Nitesh Rawat might feel uncomfortable and might seek out external guidance when faced with complex and unfamiliar scenarios. She might face a great deal of anxiety about not knowing what will happen next.',
//         },
//       ],
//       'fedf6742-9eae-4b06-aefa-3ffc65d95b6c': [],
//       'cb27ca7b-a8df-433f-be32-5a90b84a86e4': [],
//     },
//     derailers: {
//       '253aef87-1633-42a7-8dcf-b99aa2a695c6': [
//         {
//           heading: 'heading 1',
//           text:
//             'Unclear expectations → Dealing with too many uncertain responsibilities may evoke feelings of ambivalence or, in the extreme, helplessness',
//         },
//       ],
//       'cde33aff-c820-44a8-badf-b2adcfa929e4': [],
//       '6fcbd48f-6e0e-49a3-a0ce-f536c876a071': [],
//     },
//     fitScore: 5.52,
//     spiderChart: {
//       userTraits: [
//         {
//           id: 'cb27ca7b-a8df-433f-be32-5a90b84a86e4',
//           name: 'Fluid Intelligence',
//           score: 5.6,
//           contribution: 25,
//         },
//         {
//           id: '253aef87-1633-42a7-8dcf-b99aa2a695c6',
//           name: 'Concept Grasping',
//           score: 2,
//           contribution: 25,
//         },
//         {
//           id: 'e7e6303b-726a-468b-b800-57b67b4b80ad',
//           name: 'Ambiguity Tolerance',
//           score: 7.2,
//           contribution: 20,
//         },
//         {
//           id: 'fedf6742-9eae-4b06-aefa-3ffc65d95b6c',
//           name: 'Sustained Attention',
//           score: 6.9,
//           contribution: 20,
//         },
//         {
//           id: '010e9835-2769-40e1-8098-72d2001b64f0',
//           name: 'Patience',
//           score: 8,
//           contribution: 10,
//         },
//       ],
//       globalTraits: [
//         {
//           id: 'cb27ca7b-a8df-433f-be32-5a90b84a86e4',
//           name: 'Fluid Intelligence',
//         },
//         {
//           id: '253aef87-1633-42a7-8dcf-b99aa2a695c6',
//           name: 'Concept Grasping',
//         },
//         {
//           id: 'e7e6303b-726a-468b-b800-57b67b4b80ad',
//           name: 'Ambiguity Tolerance',
//         },
//         {
//           id: 'fedf6742-9eae-4b06-aefa-3ffc65d95b6c',
//           name: 'Sustained Attention',
//         },
//         {
//           id: '010e9835-2769-40e1-8098-72d2001b64f0',
//           name: 'Patience',
//         },
//       ],
//     },
//   },
// };
