import React, { useEffect } from "react";
import { storage } from "src/services/config/storage";
import PageHelmet from "src/components/Common/PageHelmet";
import { useHistory } from "react-router-dom";
import OrganizationApi from "src/services/apis/organization";
import { Mixpanel } from "src/services/mixpanel";
import { Avatar } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setOrganizations } from "src/store/Organizations/asyncActions";
import "./SelectOrganization.scss";
import SearchInput from "src/components/Common/SearchInput";
import { useUrlFilter } from "src/utils/hooks";
import { UnberryLogo } from "src/assets/icons";
import { getCompanyName } from "src/utils/helperFunctions";
import { DEFAULT_COMPANY_NAME } from "src/constants/globalConstants";

export const setOrganizationInStorage = (org) => {
  storage.set.organizationInfo(org);
};

function OrgCard(props) {
  const {
    organizationId,
    organizationName,
    logo,
    country,
    state,
    city,
    onClick,
  } = props;
  const location = [
    ...(city?.length ? [city] : []),
    ...(state?.length ? [state] : []),
    ...(country?.length ? [country] : []),
  ];
  return (
    <div
      className="organization-card grid cursor-pointer py-12 px-14 gap-3 hoverEffect items-center justify-evenly"
      key={organizationId}
      onClick={() => onClick(organizationId)}
    >
      {logo ? (
        <img src={logo} alt={`${organizationName}'s logo`} className="p-1" />
      ) : (
        <Avatar className={`flex items-center mr-10 text-35 weight-600`}>
          {organizationName[0]}
        </Avatar>
      )}

      <div className="flex flex-col justify-center">
        <p className="text-18 semibold">{organizationName}</p>
        {(state || city || country) && (
          <p className="text-16">{location.join(", ")}</p>
        )}
      </div>
      <RightOutlined />
    </div>
  );
}

export default function SelectOrganization() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [companyName] = getCompanyName();
  const defaultCompany = companyName === DEFAULT_COMPANY_NAME;
  const organizations = useSelector((state) => state.organizations.list.data);
  // const [organizations, setOrganizations] = useState([]);
  
  const [filters, setFilters] = useUrlFilter();

  const fetchOrganizationMetrics = async () => {
    const { name, email, designation } = storage.get.userInfo();
    // Setting super properties for mixpanel
    const { metrics: organization, success } =
      await OrganizationApi.getMetrics();
    if (success) {
      Mixpanel.register({ name, email, designation, organization });
    }
  };

  useEffect(() => {
    if (!filters.searchKey && organizations.length === 1) {
      setOrganizationInStorage(organizations[0]);
      fetchOrganizationMetrics();
      history.push("/hiring");
    }
    // call once if no orgs are present in redux
    // Handling if filter search is present, since search can give 0 results
    if (!filters.searchKey && organizations.length === 0) {
      dispatch(setOrganizations());
    }
  }, [organizations]);

  useEffect(() => {
    if (filters) {
      dispatch(setOrganizations({ search: filters.searchKey }));
    }
  }, [filters]);

  // Clear search on page refresh
  useEffect(() => {
    setFilters({});
  }, []);

  const getOrgFromId = (orgId) => {
    return organizations.filter((org) => org.organizationId === orgId);
  };

  const handleOrgSelect = (orgId) => {
    const org = getOrgFromId(orgId);
    setOrganizationInStorage(org[0]);
    fetchOrganizationMetrics();
    history.push("/hiring");
  };

  return (
    <>
      <PageHelmet title="Select Organization" />
      <div className={`select-organization container pt-30`}>
        {
          defaultCompany &&
          <h1>
            <span className="unberry-logo">
              <UnberryLogo />
            </span>
          </h1>
        } 
        <div className="flex items-center justify-between">
          <h3>Select organization</h3>
          <SearchInput placeholder="Search organization" />
        </div>
        <div className={`organizations grid pt-20`}>
          {
          /* Length check -> Null error */
          organizations.length>0 &&
          organizations.map((organization) => {
            const {
              organizationId,
              organizationName,
              logo,
              country,
              state,
              city,
            } = organization;
            return (
              <OrgCard
                organizationId={organizationId}
                organizationName={organizationName}
                logo={logo}
                onClick={handleOrgSelect}
                country={country}
                state={state}
                city={city}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
