import React from "react";
import Header from "./Header";
import "./Layout.scss";

export default function Layout(props) {
  return (
    <div className="layout-container">
      {(window.innerWidth > 768
        ? props?.children?.props?.showHeader
        : props?.children?.props?.showMobileHeader) && <Header />}
      <div className="main-container">{props.children}</div>
    </div>
  );
}
