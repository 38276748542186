import { UnberryApi } from "../config/request";

const SettingsApi = {
  listAdmins() {
    return UnberryApi.safeCall("/api/pointOfContact/v1/:orgId", "GET");
  },

  createAdmin(payload) {
    return UnberryApi.safeCall(
      "/api/pointOfContact/v1/:orgType/:orgId/create",
      "POST",
      { data: payload }
    );
  },

  inviteAdmin(adminId) {
    return UnberryApi.safeCall(
      `/api/pointOfContact/v1/invite/${adminId}`,
      "POST",
      { params: { organizationType: ":orgType", organizationId: ":orgId" } }
    );
  },

  deleteAdmin(adminId) {
    return UnberryApi.safeCall(
      `/api/pointOfContact/v1/:orgType/:orgId/delete/${adminId}`,
      "DELETE"
    );
  },

  setSendEmailToAdmin(payload) {
    return UnberryApi.safeCall(`/api/company/v1/:orgId/setSendEmailToAdmin`, "POST",
      { data: payload }
    );
  },

  getApisList(organizationId) {
    return UnberryApi.safeCall(`/api/integration/v1/company/${organizationId}`, "GET")
  }
};

export default SettingsApi;
