import React, { useState } from "react";
import { Select, Form, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {countries} from "countries-list";
import Flag from 'react-flagkit';

import { COUNTRY_DATA } from "src/constants/globalConstants";
import { get } from "src/utils/helperFunctions";

import "./PhoneField.scss"

const PhoneCountryAddon = ({ country, setCountry }) => {
  const { countryAbrev, phone } = country;
  const [countryList, setCountryList] = useState(COUNTRY_DATA);
  
  return (
    <div className="country-add-on-container">
      <Select
        className="country-code-select"
        style={{ width: 82 }}
        dropdownMatchSelectWidth={false}
        popupClassName="country-code-dropdown"
        dropdownRender={(menu) => (
          <>
            <div className="flag-search flex items-center relative">
              <SearchOutlined className="flag-search-icon"/>
              <input
                className="flag-search-input"
                onChange={({ target: { value }}) => setCountryList(
                  COUNTRY_DATA.filter(country => 
                    country.name.toLowerCase().includes(value.toLowerCase()))
                )} 
                placeholder="Search here..."
              />
            </div>
            {menu}
          </>
        )}
        onChange={(value, _option) => 
          setCountry({ ...countries[value], countryAbrev: value })
        }
        value={countryAbrev}
      >
        {
          countryList.map((country) => {
            const { countryAbrev: countryKey, name, phone: countryExt } = country;
            return (
              <Select.Option
                key={countryKey}
                value={countryKey}
                label={name}
                className=""
              >
                <div class="flag-container flex items-center">
                  <Flag country={countryKey} />
                </div>
                <span>{`(+${countryExt})`}</span>
                <span>{name}</span>
              </Select.Option>
            )
          })
        }
      </Select>
      <div className="flag-phone-ext flex items-center">
        <div class="flag-container">
          <Flag country={countryAbrev} />
        </div>
        <span className="ml-10 color-primary">{`+${phone}`}</span>
      </div>
    </div>
  );
};

const PhoneField = ({ 
  field,
  form,
  label,
  value,
  country, 
  setCountry,
  submitCount,
  hasFeedback,
  ...props 
}) => {
  const touched = get(form?.touched, field?.name);
  const submitted = submitCount > 0;
  let hasError = get(form.errors, field.name);
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;

  const onInputChange = ({ target: { value } }) =>
    form?.setFieldValue(field?.name, value);
  const onBlur = () => form?.setFieldTouched(field?.name, true);

  return (
    <Form.Item
      hasFeedback={
        (hasFeedback && submitted) || (hasFeedback && touched)
      }
      help={submittedError || touchedError ? hasError : false}
      validateStatus={
        submittedError || touchedError ? "error" : "success"
      }
    >
      <Input
        addonBefore={<PhoneCountryAddon country={country} setCountry={setCountry} />}
        onBlur={onBlur}
        onChange={onInputChange}
        type="text"
        value={value}
        name={"phone"}
        label={"Phone Number"}
        precision={1}
        placeholder="Phone Number"
        autoComplete={"off"}
        hasFeedback
        {...props}
      />
    </Form.Item>
  );
};

export default PhoneField;