import { COLOR_CODES, BUCKETS_ORDER } from "./constants";
import { FIT_BANDS } from "src/pages/OnePagerReport/constant";

/**
 *
 * @param median
 * @param low
 * @param high
 * @param score
 * @returns {string}
 */
export const getColorCode = (median, low, high, score) => {
  const { poor, average, aboveAverage, excellent } = COLOR_CODES;

  if (score < low) {
    return poor;
  } else if (score >= low && score < median) {
    return average;
  } else if (score >= median && score < high) {
    return aboveAverage;
  } else return excellent;
};

/**
 * rounding of a given number with nth fraction digits
 * @param num number to round off
 * @param fractionDigits nth decimal place to round of, defaults to 1
 * @returns {string} number with nth round of decimal place
 */
export const roundOff = (num = 0, fractionDigits = 1) => {
  if (num == 0) return 0;
  return Number(num).toFixed(fractionDigits);
};

/**
 * Get fit status text based on scores
 * @param median
 * @param low
 * @param high
 * @param score
 * @returns {string}
 */
export const getFitStatusText = (median, low, high, score) => {
  const { NOT, CAUTIOUSLY, RECOMMENDED, HIGHLY } = FIT_BANDS;

  if (score < low) {
    return NOT.label;
  } else if (score >= low && score < median) {
    return CAUTIOUSLY.label;
  } else if (score >= median && score < high) {
    return RECOMMENDED.label;
  } else return HIGHLY.label;
};

/**
 *
 * @param buckets
 * @returns {*[]}
 */
export const orderBuckets = (buckets) => {
  if (!buckets || buckets.length === 0) return [];

  const orderedBuckets = BUCKETS_ORDER.map((bucketName) => {
    return buckets.filter((bucket) =>
      bucket.name.toLowerCase().includes(bucketName)
    )[0];
  });
  return orderedBuckets;
};
