import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { DownOutlined, SyncOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, Checkbox } from "antd";
import { AntSelect } from "../CreateAntFields/CreateAntFields";
import {
  GAMEPLAY_STATUSES,
  HIRING_STATUSES,
  SORT_OPTIONS,
  GAMEPLAY_KEY,
  HIRING_KEY,
  CLEAR_KEY,
  USER_STATUS_KEY,
  USER_STATUSES,
  FIT_SCORE_KEY,
  INITIAL_VALUES
} from "./constants";
import { useUrlFilter } from "src/utils/hooks";
import { useIsMobileDevice } from "src/utils/hooks";
import { areObjectEqual, disableBodyScroll } from "src/utils/helperFunctions";
import { getFilterLabel, getFiltersCount, getFitScoreFilter, momentConverter } from "./helper";
import FilterIcon from "../FilterIcon";
import { AntdSelectIcon, CloseIcon } from "src/assets/icons";
import "./Filters.scss";

export default function Filters({ isHiringFilterVisible = true, constraints }) {
  const [form, setForm] = useState(INITIAL_VALUES);
  const [formVisibility, setFormVisiblity] = useState(false);
  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false)
  const [statusDropdownPlacement, setStatusDropdownPlacement] = useState('bottom')
  const [filters, setFilters] = useUrlFilter();
  const isMobile = useIsMobileDevice();
  const fitBandsFilter = getFitScoreFilter(constraints)

  /**
   * Split the filters[status] string value. Find the array item that matches the value.
   * @param {status} - type of filter
   * @param {value} - value of this type
   */
  const isItemInFilter = (status, value) => {
    const matchedValuesArr = filters[status]?.split(",")?.filter(filterVal => filterVal == value)

    if (matchedValuesArr?.length > 0) return true
    return false
  }

  const removeKeyFromStatus = (newFilters, status, key) => newFilters[status]
    .split(',')
    .filter(val => val !== key)
    .join(',')

  const updateStatusFilter = (newFilters, status, key) => {
    if (isItemInFilter(status, key)) {
      newFilters[status] = removeKeyFromStatus(newFilters, status, key)
    } else {
      if (newFilters[status] && status !== "linkStatus") {
        newFilters[status] = `${newFilters[status]},${key}`
      } else {
        newFilters[status] = key
      }
    }
    // Delete a filter from path if no value is present
    if (!newFilters[status]) delete newFilters[status];
    return { ...newFilters }
  }

  const StatusMenu = ({ setFieldValue, handleSubmit }) => {
    const menuItems = [
      {
        key: GAMEPLAY_KEY,
        label: `${getFilterLabel('gameplayStatus', filters)}`,
        children: GAMEPLAY_STATUSES.map((elem) => ({
          label: <Checkbox
            key={elem.value}
            checked={isItemInFilter('gameplayStatus', elem.value)}>
            {elem.name}
          </Checkbox>,
          key: elem.value
        })),
      },
      {
        key: HIRING_KEY,
        label: `${getFilterLabel('hiringStatus', filters)}`,
        children: HIRING_STATUSES.map((elem) => ({
          key: elem.value,
          label: <span>{elem.name}</span>,
          children: elem.children.map(subElem => ({
            label: <Checkbox
              key={subElem.value}
              checked={isItemInFilter('hiringStatus', subElem.value)}>
              {subElem.name}
            </Checkbox>,
            key: subElem.value
          }))
        })
        ),
      },
      {
        key: USER_STATUS_KEY,
        label: `${getFilterLabel('linkStatus', filters)}`,
        children: USER_STATUSES.map((elem) => ({
          label: <Checkbox
            key={elem.value}
            checked={isItemInFilter('linkStatus', elem.value)}>
            {elem.name}
          </Checkbox>,
          key: elem.value
        })),
      },
      {
        key: FIT_SCORE_KEY,
        label: getFilterLabel(FIT_SCORE_KEY, filters),
        children: Object.keys(fitBandsFilter)?.map(band => ({
          label: <Checkbox
            key={fitBandsFilter[band]?.range}
            checked={isItemInFilter(FIT_SCORE_KEY, fitBandsFilter[band]?.range)}
          >
            <span style={{
              color: fitBandsFilter[band]?.textColor,
              background: fitBandsFilter[band]?.color
            }} className="py-2 px-6 b-12">{fitBandsFilter[band]?.label}</span>
          </Checkbox>,
          key: fitBandsFilter[band]?.range
        }))
      },
      {
        key: CLEAR_KEY,
        label: <div className="flex items-center">
          <SyncOutlined />
          <span className="ml-4 mb--2">Clear All</span>
        </div>,
      },
    ]
    return (
      <Menu
        onClick={({ key, keyPath }) => {
          let newFilters = { ...filters }
          if (keyPath[keyPath.length - 1] === HIRING_KEY) {
            newFilters = updateStatusFilter(newFilters, 'hiringStatus', key)
          } else if (keyPath[keyPath.length - 1] === GAMEPLAY_KEY) {
            newFilters = updateStatusFilter(newFilters, 'gameplayStatus', key)
          } else if (keyPath[keyPath.length - 1] === USER_STATUS_KEY) {
            newFilters = updateStatusFilter(newFilters, 'linkStatus', key)
          } else if (keyPath[keyPath.length - 1] === FIT_SCORE_KEY) {
            newFilters = updateStatusFilter(newFilters, FIT_SCORE_KEY, key)
          }
          else if (keyPath[keyPath.length - 1] === CLEAR_KEY) {
            delete newFilters.gameplayStatus
            delete newFilters.hiringStatus
            delete newFilters.linkStatus
            delete newFilters.fitScoreRange
          }
          setFilters(newFilters);
        }}
        items={!isHiringFilterVisible ? [...menuItems.filter(item => item.key !== HIRING_KEY)] : [...menuItems]}
      />
    )
  };

  const filterHandler = (filters) => {
    let values = { ...filters };
    values = momentConverter(values);
    Object.keys(values).forEach((filterKey) => {
      if (!values[filterKey]) {
        delete values[filterKey];
      }
    });
    if (values["sort"] === "clear") {
      delete values["sort"]
    }
    setFilters(values);
    toggleFormView(false);
  };

  const toggleFormView = (flag) => {
    setFormVisiblity((prevState) =>
      typeof flag === "boolean" ? flag : !prevState
    );
  };

  useEffect(() => {
    disableBodyScroll(formVisibility);
  }, [formVisibility])

  useEffect(() => {
    if (filters && !areObjectEqual(form, filters)) {
      let value = { ...filters };
      const customHackFilter = { ...filters };
      if (customHackFilter.reportStatus === "shortlist") {
        customHackFilter.hiringStatus = customHackFilter.reportStatus;
        delete customHackFilter.reportStatus;
      } else if (customHackFilter.reportStatus === "all") {
        delete customHackFilter.reportStatus;
      }
      value = momentConverter(value, false);
      setForm(value);
    }
  }, [filters]);

  useEffect(() => {
    if (isMobile) {
      setStatusDropdownPlacement('top')
    } else {
      setStatusDropdownPlacement('bottom')
    }
  }, [isMobile])


  return (
    <div className="filter-component">
      <FilterIcon onClick={toggleFormView} className="mobile-filter-icon" />
      {
        getFiltersCount(filters)?.totalStatusFilterCount > 0 &&
        <p className="mobile-filter-count">
          {getFiltersCount(filters)?.totalStatusFilterCount}
        </p>
      }
      <div
        className={`background-overlay ${formVisibility ? "" : "hide"}`}
        onClick={toggleFormView}
      ></div>
      <Formik initialValues={form} enableReinitialize onSubmit={filterHandler}>
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <Form>
              <div className={`filters ${formVisibility ? "open" : ""}`}>
                <div className="mobile-filter-header">
                  <div className="flex items-center">
                    <FilterIcon />
                    <h4>Filters</h4>
                  </div>
                  <CloseIcon
                    className="close-button"
                    onClick={toggleFormView}
                  />
                </div>
                <div className="item-box grid">
                  <div className="flex item items-center">
                    <Field
                      component={AntSelect}
                      type="text"
                      selectOptions={SORT_OPTIONS}
                      optionValueKey={"value"}
                      optionNameKey={"name"}
                      value={values.sort}
                      name={"sort"}
                      suffixIcon={<AntdSelectIcon />}
                      className="filter-select"
                      placeholder="Sort by"
                      getPopupContainer={() =>
                        document.querySelector(".filter-select[name=sort]")
                      }
                      onChange={(value) => {
                        setFieldValue("sort", value);
                        handleSubmit();
                      }}
                    />
                  </div>
                  <div className="flex item items-center">
                    <Dropdown
                      placement={statusDropdownPlacement}
                      onVisibleChange={() => {
                        setIsStatusFilterOpen(isStatusFilterOpen => !isStatusFilterOpen)
                      }}
                      visible={isStatusFilterOpen}
                      trigger={"click"}
                      overlay={() => (
                        <StatusMenu
                          setFieldValue={setFieldValue}
                          handleSubmit={handleSubmit}
                        />
                      )}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          {getFilterLabel("", filters)}
                          {
                            isMobile
                              ? <DownOutlined />
                              : <span className="ml-20">
                                <AntdSelectIcon />
                              </span>
                          }
                        </Space>
                      </a>
                    </Dropdown>
                  </div>
                  {/* <p className="item-label mb-0 mr-10">GamePlay Status</p>
                    <Field
                      component={AntSelect}
                      type="text"
                      selectOptions={GAMEPLAY_STATUSES}
                      optionValueKey={"value"}
                      optionNameKey={"name"}
                      value={values.gameplayStatus}
                      name={"gameplayStatus"}
                      suffixIcon={<AntdSelectIcon />}
                      className="filter-select"
                      placeholder="Status"
                      getPopupContainer={() =>
                        document.querySelector(
                          ".filter-select[name=gameplayStatus]"
                        )
                      }
                      onChange={(value) => {
                        setFieldValue("gameplayStatus", value);
                        handleSubmit();
                      }}
                    />
                  </div> */}
                  {/* <div className="flex item items-center">
                    <Field
                      component={AntSelect}
                      type="text"
                      selectOptions={REPORT_OPTIONS}
                      optionValueKey={"value"}
                      optionNameKey={"name"}
                      // value={values.reportStatus}
                      className="filter-select"
                      suffixIcon={<AntdSelectIcon />}
                      name={"reportStatus"}
                      placeholder={"Report"}
                      getPopupContainer={() =>
                        document.querySelector(
                          ".filter-select[name=reportStatus]"
                        )
                      }
                      onChange={(value) => {
                        setFieldValue("reportStatus", value);
                        handleSubmit();
                      }}
                    />
                  </div> */}
                  {/* <div className="item flex items-center">
                    <Field
                      component={AntDatePicker}
                      type="text"
                      value={values.created}
                      name={"created"}
                      label={"Created At"}
                      placeholder={""}
                      allowClear
                      onChange={(value) => {
                        if (!value) {
                          const newValue = { ...values };
                          delete newValue.created;
                          setFilters(newValue);
                        }
                        setFieldValue("created", value);
                        handleSubmit();
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
