import Toast from "src/components/Common/Toast";
import { Button } from "antd";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import BulkOperationsApi from "src/services/apis/bulkOperations";
import Modal from "src/components/Common/Modal";
import { storage } from "src/services/config/storage";
import queryString from "query-string";
import "./BulkUploader.scss";
import { RenderResult } from "./RenderResult";
import { LOGIN_TYPE, ORGANIZATION_TYPES } from "src/constants/globalConstants";
import { CarretDown, CloudUpload } from "src/assets/icons";

const BulkUploader = ({ onSuccess, config, textForm = false }) => {
  const { positionId } = useParams();
  
  const hasThirdPartyLogin = config?.loginType === LOGIN_TYPE.THIRD_PARTY;

  const [showModal, setShowModal] = useState(false);
  const [templateLink, setTemplateLink] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const organizationId = storage.get.organizationInfo().organizationId;
  const organizationType = ORGANIZATION_TYPES.COMPANY;

  const runBulkHandler = async (values) => {
    if (!values?.csv_file?.name) {
      Toast("error", "Please attach CSV file");
      return;
    }
    setLoading(true);
    const payload = {
      ...values,
      positionId
    };
    // ThirdParty login -> Need only candidateKey and phone in csv, others optional
    const uploadThirdPartyUser = (hasThirdPartyLogin&&LOGIN_TYPE.THIRD_PARTY);
    const response = await BulkOperationsApi.uploadUsers(
      payload, 
      uploadThirdPartyUser
    );
    setLoading(false);
    if (response?.success) {
      // const { processCount, successCount } = response;
      // if (successCount > 0) {
      //   Toast("success", `${successCount} of ${processCount} Users created successfully`, "Created successfully");
      //   if (successCount===processCount) {
      //     setShowModal(false);
      //   }
      //   onSuccess();
      // } else {
      //   Toast("error",`${successCount} of ${processCount} Users created successfully`, "Bad Request");
      //   if (!processCount) {
      //     setShowModal(false);
      //   }
      // }
      Toast("info", "The task has started and we'll send an update on your email once we are done", "Bulk upload started");
      setShowModal(false);
      setStatus(response);
      // setShowResult(true);
    } else {
      // Toast("error", response?.message, "Bad Request");
      Toast("error", "There was an error uploading the file, please try again later.", "Bad Request");

    }
  };

  useEffect(async () => {
     // ThirdParty login -> Add hasThirdPartyLogin due to async nature of call
    const query = hasThirdPartyLogin ? `?thirdParty=true` : '';
    const result = await BulkOperationsApi.getSampleCsv(query);
    if (result.success) setTemplateLink(result?.link);
  }, [hasThirdPartyLogin]);

  return (
    <div className="bulk-uploader">
      {textForm ? (
        <p
          className="underline-on-hover text-30 semibold text-center"
          onClick={() => setShowModal(true)}
        >
          Bulk upload
        </p>
      ) : (
        <Button className="" type="primary" onClick={() => setShowModal(true)}>
          Bulk upload
        </Button>
      )}

      <Modal
        width="500px"
        centered
        title={
          <>
            <h2>Bulk Upload</h2>
            <p>Upload the user's data from CSV</p>
            <p className="text-14 mt-10">(Please upload and send invites in batches of 100 for the most optimal performance.)</p>
          </>
        }
        destroyOnClose
        cancelModal={() => {
          if (loading) {
            Toast("info", `Still processing the request`, "Processing");
          } else {
            setShowModal(false);
          }
        }}
        modalVisible={showModal}
        onModalClose={() => {
          setShowResult(false);
          setStatus(null);
        }}
      >
        {showResult ? (
          <RenderResult result={status} />
        ) : (
          // <RenderResult
          //   result={{
          //     processCount: 50,
          //     successCount: 10,
          //     invalidData: { "avikvvv@gmail.com": "asdasda" },
          //   }}
          // />
          <Formik
            initialValues={{ csv_file: [], positionId: "" }}
            onSubmit={runBulkHandler}
            enableReinitialize
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className="bulk-uploader-popup">
                    <a
                      className="cursor-pointer flex items-center mb-26 semibold"
                      href={templateLink}
                      download
                    >
                      <CarretDown />
                      <p className="ml-4">Download a sample CSV file.</p>
                    </a>
                    <label
                      htmlFor="upload-csv"
                      className="button-dashed cursor-pointer semibold mb-10 flex justify-center items-center"
                    >
                      <CloudUpload />
                      <span className="ml-4">
                        {values?.csv_file?.name ? "Change" : "Upload"} CSV
                      </span>
                    </label>
                    {values?.csv_file?.name && (
                      <p>
                        <b>Ready to upload:</b> {values?.csv_file?.name}
                      </p>
                    )}

                    <input
                      type="file"
                      id="upload-csv"
                      name="csv_file"
                      onChange={(e) => {
                        setFieldValue(`csv_file`, e.currentTarget.files[0]);
                      }}
                      accept=".csv"
                    />
                    <Button
                      type="primary"
                      className="bold px-60 mt-30"
                      htmlType={"submit"}
                      loading={loading}
                    >
                      Upload
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>
    </div>
  );
};

export default BulkUploader;

BulkUploader.defaultProps = {
  type: "college",
};
