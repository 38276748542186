import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import "antd/dist/antd.css";
import "./scss/main.scss";
import Router from "./router";
import { storage } from "./services/config/storage";
import { FC_LS_KEY, MIXPANEL_LS_KEY, REFRESH_SESSION_EVENT } from "./constants/globalConstants";
import OrganizationApi from "./services/apis/organization";
import { Mixpanel } from "./services/mixpanel";
import { setOrganizations } from "src/store/Organizations/asyncActions";
import { useDispatch } from "react-redux";
import { getCompanyName } from "./utils/helperFunctions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {

    // refreshProgress -> Set value to false if loaded with true
    // Hinders refreshToken flow if set to true -> Api does not trigger -> Stalled on please wait screen
    // On load, we can check for refreshToken again
    // Set to true again on App refresh (each API call with token check for its validity as is) -> Instead of persisting state in LS through multiple refreshes
    const refreshInProgress = storage.get.refreshTokenInProgress();
    if (refreshInProgress) {
      new CustomEvent(REFRESH_SESSION_EVENT, { detail: null });
      storage.set.refreshTokenInProgress(false);
    }

    // Check url for companyName
    // Pass true to allow dom updates
    getCompanyName(true);

    // Check to not call setOrganizations API on routes
    // Which include "report" or "login" in pathname
    // No need to call auth API on public routes.
    const hasReportOrLoginLocation =
      window.location.pathname.includes("report") ||
      window.location.pathname.includes("login");
    // get organizations
    if (!hasReportOrLoginLocation && storage.checkAuthentication()) {
      dispatch(setOrganizations({ search: "" }));
    }

    // Fetch organization metrics for Mixpanel
    const mixpanelData = storage.get.value(MIXPANEL_LS_KEY);
    if (mixpanelData) {
      const {
        Name: name,
        Email: email,
        Designation: designation,
      } = mixpanelData;
      const fetchMetrics = async () => {
        const { metrics: organization, success } =
          (await OrganizationApi.getMetrics()) ?? {};
        if (success) {
          Mixpanel.register({ name, email, designation, organization });
        }
      };
      if (!hasReportOrLoginLocation && storage.checkAuthentication()) fetchMetrics();
    }
  }, []);

  return (
    <div className="App">
      <ErrorBoundary
        key="home"
        FallbackComponent={ErrorFallback}
        onReset={() => {
          console.log("info", "");
        }}
      >
        <Router />
      </ErrorBoundary>
    </div>
  );
}

export default App;
