import { UnberryApi } from "../config/request";

const AuthApi = {
  sendOtp(payload) {
    return UnberryApi.call(`/api/user/v1/otp`, "POST", { data: payload });
  },
  verifyOtp(payload) {
    return UnberryApi.call(`/api/user/v1/login`, "POST", { data: payload });
  },
  getAccessibleOrganizations(search=null) {
    return UnberryApi.safeCall(`/api/company/v1/public${search ? `?search=${search}`:''}`, "GET");
  },
};

export default AuthApi;
