import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "src/services/config/storage";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import { setWalletCredits } from "src/store/wallet/asyncActions";
import "./Wallet.scss";

export default function Companywallet(props) {
  const credits = useSelector((state) => state?.wallet?.credits);

  const orgId =
    useSelector((state) => state.auth?.organizations?.[0]?.organizationId) ||
    storage.get.value(AUTH_CONSTANTS.ORGANIZATION_ID);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWalletCredits());
  }, []);

  return (
    <div className="company-wallet-wrapper text-17 line-28 weight-400">
      <span> {credits} </span> Credits
    </div>
  );
}
