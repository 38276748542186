import React from 'react';
import Text from '../Text';
import Tag from '../Tag';
import NoteBox from '../NoteBox';
import { roundOff } from '../operations';
import './AttentionTrait.scss';

export default function AttentionTrait(props) {
  const { title, statement, tag, tagColor, note, score, color } = props;

  return (
    <div className="attention-trait flex">
      <div
        className="ring"
        style={{
          borderTopColor: color ?? 'red',
          borderRightColor: color ?? 'red',
        }}
      >
        <div className="score" style={{ backgroundColor: color ?? '#f7635e' }}>
          {roundOff(score)}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center">
          <h3 className="mr-10 size-14 weight-400">{title}</h3>
          {tag && (
            <Tag
              text={tag}
              className="size-10 weight-700 br-3 py-3 px-6"
              color={tagColor || '#55B255'}
            />
          )}
        </div>
        <Text text={statement} className="size-11 weight-400 mt-6 mb-10" />
        {note && <NoteBox importantText={note?.title} text={note?.statement} />}
      </div>
    </div>
  );
}
