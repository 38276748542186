import React, { useState } from "react";
import "./Login.scss";
import LoginForm from "../../forms/LoginForm";
import VerifyOtpForm from "../../forms/VerifyOtpForm";
import AuthApi from "src/services/apis/auth";
import { storage } from "src/services/config/storage";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateFCData } from "src/services/freshchat";
import { Mixpanel } from "src/services/mixpanel";
import { DEFAULT_COMPANY_NAME, MIXPANEL_EVENTS } from "src/constants/globalConstants";
import { UnberryLogoWhite, Mask1 } from "src/assets/icons";
import { getCompanyName } from "src/utils/helperFunctions";

export default function Landing() {
  /**
   * ===================
   * props from parent
   * ===================
   */

  /**
   * ===================
   * props from redux
   * ===================
   */

  /**
   * ===================
   * constant variables
   * ===================
   */
  const dispatch = useDispatch();
  const history = useHistory();

  const [companyName] = getCompanyName();
  const defaultCompany = companyName === DEFAULT_COMPANY_NAME;

  /**
   * ===================
   * useStates
   * ===================
   */
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  /**
   * ===================
   * useRefs
   * ===================
   */

  /**
   * ===================
   * computed variables
   * ===================
   */

  /**
   * ===================
   * custom hooks
   * ===================
   */

  /**
   * ===================
   * functions
   * ===================
   */

  /**
   * validates the OTP
   * @param {*} OTP
   */
  const handleOTPVerification = async ({ otp }) => {
    try {
      const response = await AuthApi.verifyOtp({
        email,
        otp,
        route: AUTH_CONSTANTS.USER_TYPE_ADMIN,
      });

      // Mixpanel Event
      Mixpanel.track(MIXPANEL_EVENTS.EnteredOTP, {
        OTP: otp,
        Status: !!response?.success,
      });

      if (response?.success) {
        const { token, refreshToken, user } = response;
        // set token in localstorage
        storage.set.authToken(token);
        storage.set.decodedToken(token);
        storage.set.refreshToken(refreshToken);
        storage.set.userInfo(user);

        // update Freshchat user details
        // updateFCData(user);

        // Set Mixpanel Identity
        Mixpanel.alias(user.id);
        Mixpanel.identify(user.id);
        Mixpanel.people.set({
          $email: user.email,
          $name: user.name,
          Phone: user.phone,
          Designation: user.designation,
        });
        history.push("/select-organization");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleLogin = async (values, resend = false) => {
    values.route = AUTH_CONSTANTS.USER_TYPE_ADMIN;
    try {
      const response = await AuthApi.sendOtp(values);
      // Toast(
      //   "success",
      //   "An email with OTP has been sent successfully!",
      //   "Sent Successfully!"
      // );

      // Mixpanel Event
      if (resend)
        Mixpanel.track(MIXPANEL_EVENTS.ResendOTP, { Status: response.success });
      else {
        Mixpanel.track(MIXPANEL_EVENTS.EnteredEmail, {
          "Email ID": values.email,
          Status: response.success,
        });
      }

      if (response.success) {
        setOtp(response.otp);
        setEmail(values.email);
        setShowOtpForm(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * ===================
   * useCallback
   * ===================
   */

  /**
   * ===================
   * useEffects
   * ===================
   */

  return (
    <div className="login-container flex items-center">
      <div className="first-half relative">
        {
          defaultCompany && 
          <div className="logo-container">
            <UnberryLogoWhite />
          </div>
        }
        <div className="image-container">
          <Mask1 />
        </div>
        {
          defaultCompany ? (
            <div className="hero-text">
              <h1 className="heading text-white">
                {`Enter unberry`}
              </h1>
              <p className="description">
                Game-based assessments for high-performance teams
              </p>
            </div>
          ) : (
            <div className="hero-default-text">
              <p className="description">
                The most holistic & engaging talent assessments
              </p>
            </div>
          ) 
        }
      </div>
      <div className="second-half flex flex-col">
        <div className="login-form flex flex-col relative">
          {showOtpForm ? (
            <>
              <VerifyOtpForm
                email={email}
                onSubmit={handleOTPVerification}
                otp={process.env.REACT_APP_ENV === "development" && otp}
                resendOTP={(values) => handleLogin(values, true)}
                showOtpForm={() => setShowOtpForm(false)}
              />
            </>
          ) : (
            <LoginForm onSubmit={handleLogin} />
          )}
        </div>
        {/* <div className="create-login-account flex items-center">
          <p className="text-14">
            Do not have an account? <span>Register</span>{" "}
          </p>
        </div> */}
      </div>
      {/* </div> */}
    </div>
  );
}
