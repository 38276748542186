import "./DNATraits";
import { getTraitBand } from "src/pages/OnePagerReport/oprations";
import "./DNATraits.scss";
import { roundOff } from "src/pages/PdfReport/operations";
import TraitFillBlock from "src/components/Common/TraitFillBlock";
import { EmptyTraitsIcon } from "src/assets/icons";

export default function DNATraits({ relevant, other, success = false }) {
  return (
    <div className="my-dna-traits-tab">
      <div>
        {success && relevant && (
          <TraitsSection
            emptyState={!success}
            list={relevant}
            heading="Role Relevant Traits"
          />
        )}
        {success && other && (
          <TraitsSection
            emptyState={!success}
            list={other}
            heading="Other Traits"
          />
        )}
        {!success && (
          <section className="wrapper">
            <div className="header" style={{border:'none'}}>
              {/* <h1 className="heading">{heading}</h1> */}
              <div className="justify-center">
                <EmptyTraitsIcon />
                <p>DNA scores not available yet...</p>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

const TraitsSection = ({ list, heading, emptyState = false }) => {
  return (
    <section className="wrapper">
      <div className="header">
        <h1 className="heading">{heading}</h1>
        {emptyState ? (
          <div>
            <EmptyTraitsIcon />
            <p>DNA scores not available yet...</p>
          </div>
        ) : null}
      </div>
      <div className="traits-wrapper">
        {/* hotfix (Prod) -> [UB-1388] Filter traits with orgData === null */}
        {list.length > 0
          ? list.filter(({orgData}) => orgData !== null).map(
              (
                { id, displayName, description, orgData, industryData },
                index
              ) => {
                const { median = 0 } = orgData ?? {};
                const fillPercentage = emptyState ? 50 : median * 10;
                const { textColor, color } = emptyState
                  ? { color: "#F3EDE3", textColor: "#EBE4DC" }
                  : getTraitBand({
                      ub: industryData?.upperBound,
                      lb: industryData?.lowerBound,
                      median: industryData?.median,
                      score: median,
                    });
                return (
                  <div className="trait" key={id}>
                    <div className="info">
                      <h4 className="name">{displayName}</h4>
                      <p className="description">{description}</p>
                    </div>
                    <h2 className="score" style={{ color: textColor }}>
                      {emptyState ? "" : roundOff(median)}
                    </h2>
                    <TraitFillBlock
                      fillPercentage={fillPercentage}
                      color={color}
                      blankColor="#F9F3EC"
                      average={
                        emptyState ? null : (industryData?.median ?? 0) * 10
                      }
                      showAverageOnHover
                    />
                  </div>
                );
              }
            )
          : null}
      </div>
    </section>
  );
};
