import { Tooltip } from "antd";

export const WithToolTip = ({ title, showToolTip, children, ...props }) => {
  return showToolTip ? (
    <Tooltip title={title} className="cursor-pointer" placement="top" {...props}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};
