export const FIT_BANDS = {
  HIGHLY: {
    label: "Highly Recommended",
    dnaLabel: "Star",
    color: "#61C489",
    textColor: "#2C6740",
    description: "This pool represents the top performers for this role",
  },
  RECOMMENDED: {
    label: "Recommended",
    dnaLabel: "High",
    color: "#ABE99B",
    textColor: "#25581B",
    description: "This pool represents people above industry average",
  },
  CAUTIOUSLY: {
    label: "Cautiously recommended",
    dnaLabel: "Medium",
    color: "#FFDB8F",
    textColor: "#775100",
    description:
      "This pool represents people below industry average (but in the acceptable band)",
  },
  NOT: {
    label: "Not recommended",
    dnaLabel: "Low",
    color: "#FE9EAB",
    textColor: "#71000F",
    description: "This pool represents the bottom performers for this role",
  }
};

// Not included in FIT_BANDS -> Since being mapped in many comps
export const DISQUALIFIED_BAND = {
  label: "Disqualified",
  dnaLabel: "",
  color: "#F3F3F3",
  textColor: "##13434",
  description: "",
};

export const QUIZ_DISPLAY_SECTIONS = {
  MCQ: "mcq",
  PROGRAMMING: "programming",
  CHAT: "chat"
};

export const TEST_CASE_STATUS = {
  PASS: "pass",
  FAIL: "fail"
};

export const TEST_CASE_TABLE_HEADER = [
  "Input",
  "Result",
  "Time (in sec)",
  "Memory (in KB)",
  "Score",
];

export const TEST_CASE_HEADER_PLACEHOLDER = [
  { heading: 'Time', value: null },
  { heading: 'Memory', value: null },
  { heading: 'Language', value: null },
  { heading: 'Test Cases', value: null }
];

export const QUESTIONS = {
  CHAT: "chat",
  SPEECH: "speech",
  SENTENCE_COMPLETION: "sentence_completion",
  TYPING_TEXT: "typing_text",
  FREE_TEXT: "free_text",
  SPEECH_SCRIPT: "speech_scripted",
  SPEECH_SPONTANEOUS: "speech_spontaneous"
};

export const VIDEO_SKIP = {
  ACTIONS: {
    FORWARD: "forward",
    BACKWARD: "backward"
  },
  SKIP_VAL: 10,
  CLIP_LENGTH: 5,
}

export const CEFR_MAP = {
  get: (score) => {
    score = Number(score)
    if (score > 0.92) return "C2"
    if (score > 0.84) return "C1"
    if (score > 0.80) return "B2+"
    if (score > 0.75) return "B2"
    if (score > 0.68) return "B1+"
    if (score > 0.60) return "B1"
    if (score > 0.40) return "A2"
    if (score > 0.0) return "A1"
    return "NA"
  }
}