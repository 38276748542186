import { LinkOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Popover } from "antd";
import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Toast from "src/components/Common/Toast";
import { DISQUALIFY_REASONS, MIXPANEL_EVENTS } from "src/constants/globalConstants";
import { storage } from "src/services/config/storage";
import { convertToStyle, getTraitBand } from "../oprations";
import WithToolTip from "src/components/Common/WithToolTip";
import { BrowserTabs, CameraIcon, DownloadWhite } from "src/assets/icons";
import { calculateTimeTaken } from "src/utils/helperFunctions";

export const ReportHeader = ({
  reportData,
  linkConfig,
  positionConfig,
  isNonDnaReport,
  logMixpanelEvent,
  hideCta = false,
  setShowSnapshots,
  setShowVideoModal,
}) => {
  const history = useHistory();
  const { userId, linkId } = useParams();
  const { search } = useLocation()
  const params = useMemo(() => new URLSearchParams(search), [search])
  const isCandidateReport = useMemo(() => history?.location?.pathname?.includes("candidate-report"), [history])

  const handleBackClick = () => {
    // Send back to previous route regardless
    // Edge case in previous scenario breaks filter state on listing page when refreshed on report
    history.goBack();
  };

  const onUrlCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    Toast("success", "URL Successfully Copied", "Copied!", "bottomLeft");
    logMixpanelEvent(MIXPANEL_EVENTS.CopiedLink);
  };

  const openTutorial = () => {
    const tutorialLink = process.env.REACT_APP_TUTORIAL_LINK;
    if (tutorialLink) {
      logMixpanelEvent(MIXPANEL_EVENTS.ViewedHowToReadReport);
      window.open(process.env.REACT_APP_TUTORIAL_LINK, "_blank");
    } else {
      Toast("error", "Something went wrong");
    }
  };

  const onViewFullReport = () => {
    logMixpanelEvent(MIXPANEL_EVENTS.ViewedFullReport);
    window.open(`/pdf-report/link/${linkId}/users/${userId}`, "_blank");
  };

  const reportDuration = calculateTimeTaken(reportData?.startedAt, reportData?.completedAt);

  return (
    <div className="header">
      <div className="flex items-center justify-between max-width-left">
        <div className="flex items-center">
          {
            (history.action !== "POP" || storage.checkAuthentication()) && params?.get("parent")?.toLowerCase() !== "cc" && !isCandidateReport && (
              <>
                <div className="header--goBack" onClick={handleBackClick}>
                  <img className="icon" src="/img/right-arrow.png" alt="" />
                  <span className="text">Back</span>
                </div>
                <div className="divider" />
              </>
            )
          }
          <div className="candidate-bio">
            <div className="user-status flex items-center">
              <div className="flex flex-col items-start">
                <h4 className="name">
                  {reportData?.user?.name || "--"}
                </h4>
                {
                  reportData?.user?.email &&
                  !reportData?.user?.email.includes("example.com") &&
                  <h4 className="email mt-2">
                    {reportData?.user?.email}
                  </h4>
                }
                {
                  reportData?.user?.phone &&
                  <h4 className="email mt-8">
                    {reportData?.user?.phone}
                  </h4>
                }
              </div>
              {
                reportData?.disqualified &&
                <div className="ml-10 uppercase">
                  <div
                    className="status-pill disqualified flex items-center space-between"
                    style={convertToStyle(
                      getTraitBand({
                        disqualified: reportData.disqualified
                      })
                    )}
                  >
                    <span className="text-14 text-red weight-600">Disqualified</span>
                    {
                      true &&
                      <WithToolTip
                        title={DISQUALIFY_REASONS[reportData.disqualifyReason] ?? reportData?.disqualifyReason}
                        showToolTip={true}
                      >
                        <InfoCircleFilled className="ml-4 text-red text-16" />
                      </WithToolTip>
                    }
                  </div>
                </div>
              }
            </div>
            <p className="flex items-center meta capitalize">{`${reportData?.position?.name || "--"
              } • ${reportData?.user?.expertiseLevel?.split("-").join(" ") || "--"
              }`}
              {
                !isCandidateReport
                  ? <span className="flex items-center ml-6">
                    | <span className="ml-4 mr-4"><BrowserTabs /></span>
                    Switched tabs &#40;{reportData?.tabSwitched ?? "0"}&#41;
                  </span>
                  : <></>
              }
            </p>
            <p className="meta capitalize">
              {
                (reportDuration) &&
                <>
                  {`Total time taken: ${reportDuration} `}
                </>
              }
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between">
          {
            !isCandidateReport ? <LinkOutlined
              onClick={onUrlCopy}
              className="copy-url"
              title="Copy link to clipboard"
            /> : <></>
          }
          {
            (positionConfig?.proctoring || positionConfig?.saveProctoredVideo)
              ? <button
                className="default-btn mr-10 flex align-center items-center"
                onClick={
                  linkConfig?.saveProctoredVideo
                    ? setShowVideoModal
                    : linkConfig?.proctoring
                      ? setShowSnapshots
                      : positionConfig?.saveProctoredVideo
                        ? setShowVideoModal
                        : positionConfig?.proctoring
                          ? setShowSnapshots
                          : () => { }
                }
              >
                <span className="mr-8">
                  <CameraIcon />
                </span>
                {
                  linkConfig?.proctoring
                    ? "Snapshots"
                    : linkConfig?.saveProctoredVideo
                      ? "Proctoring"
                      : positionConfig?.proctoring
                        ? "Snapshots"
                        : positionConfig?.saveProctoredVideo
                          ? "Proctoring"
                          : ""
                }
                {
                  (linkConfig?.proctoring || positionConfig?.proctoring) && reportData?.proctoredImages?.total
                    ? <>
                      <span className={`${reportData.proctoredImages.critical > 5 ? 'text-darkred' : ''} ml-4`}>
                        {reportData.proctoredImages.critical}
                      </span>
                      <span className="ml-2">
                        {`/${reportData.proctoredImages.total} critical`}
                      </span>
                    </>
                    : <></>
                }
                {
                  reportData?.cameraSupported === false
                    ? <Popover
                      color={"#9e9e9e"}
                      overlayInnerStyle={{ borderRadius: '20px' }}
                      content={(
                        <span className="text-white">
                          Camera not working
                        </span>
                      )}
                    >
                      <InfoCircleFilled style={{ color: 'red' }} className="ml-6" />
                    </Popover> : <></>
                }
              </button>
              : <></>
          }
          {!hideCta && <button className="default-btn mr-10" onClick={openTutorial}>
            How to read this report ?
          </button>}
          {!hideCta && isNonDnaReport ? (
            <button className="view-long-report" onClick={onViewFullReport}>
              View Full Report <DownloadWhite />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
