import React from "react";
import "./TitleWithIcon.scss";

export default function TitleWithIcon(props) {
  const { icon, stats, title, className, onClick, titleNameStyle } = props;

  return (
    <div
      className={`flex items-center icon-title ${className}`}
      onClick={() => {
        return onClick ? onClick() : null;
      }}
    >
      {typeof icon === "string" ?  <img src={icon} alt="icon" /> : icon}
      <span
        className={`text-14 line-17 ml-6 text-black opacity-7 weight-400 ${titleNameStyle}`}
      >
        {title}
      </span>
    </div>
  );
}
