import ChatCard from "./ChatCard";

import "./ChatSection.scss";

export const ChatSection = ({ data }) => {
	return (
		<div className="chat-section mt-10 mb-30">
			{data.map((elem) => {
				return (
					<ChatCard cardData={elem}/>
				)
			})}
		</div>
	);
};