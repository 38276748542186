import React from 'react';
import { Derail, Drive } from 'src/assets/icons';
import Text from '../Text';
import './DriverDerailerTrait.scss';

export default function DriverDerailerTrait(props) {
  const { type, title, statement } = props;
  return (
    <div className="driver-derailer-trait flex flex-col">
      <div className="flex title">
        <span className='derailer-icon'>
          {
            type === 'derailer'
            ? <Derail />
            : <Drive />
          }
        </span>
        <h4 className="size-13 weight-400 capitalize">{title}</h4>
      </div>
      <Text text={statement} className="size-11 weight-400" />
    </div>
  );
}
