import "./AddPosition.scss";
import AddDriveForm from "src/forms/AddDriveForm";

export default function AddPositionForm({ onCancel, onSuccess }) {
  return (
    <div className="position-container-form">
      <div className="first-half">
        <p className="heading">Add a new drive</p>
      </div>
      <div className="second-half">
        <AddDriveForm onCancel={onCancel} onSuccess={onSuccess} />
      </div>
    </div>
  );
}
