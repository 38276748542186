import { AUTH_CONSTANTS } from "src/constants/authConstants";
import jwt_decode from "jwt-decode";

const checkAuthentication = () => {
  if (localStorage.getItem(AUTH_CONSTANTS.LS_KEY)) {
    return true;
  }
  return false;
};

const checkOrgInfo = () => {
  const orgInfo = get.organizationInfo();
  if (orgInfo) {
    return true;
  }
  return false;
};

const set = {
  authToken: (data) => {
    localStorage.setItem(AUTH_CONSTANTS.LS_KEY, JSON.stringify(data));
  },
  decodedToken: (data) => {
    const decodedData = jwt_decode(data);
    decodedData.exp -= AUTH_CONSTANTS.SUBTRACT_TOKEN_EXPIRY_TIME;
    localStorage.setItem(
      AUTH_CONSTANTS.DECODED_TOKEN,
      JSON.stringify(decodedData)
    );
  },
  refreshToken: (data) => {
    localStorage.setItem(AUTH_CONSTANTS.REFRESH_TOKEN, JSON.stringify(data));
  },
  refreshTokenInProgress: (data) => {
    localStorage.setItem(AUTH_CONSTANTS.REFRESH_TOKEN_IN_PROGRESS, JSON.stringify(data));
  },
  userInfo: (info) => {
    localStorage.setItem(AUTH_CONSTANTS.USER_INFO, JSON.stringify(info));
  },
  organizationInfo: (info) => {
    localStorage.setItem(
      AUTH_CONSTANTS.ORGANIZATION_INFO,
      JSON.stringify(info)
    );
  },
  value: (key, value) => {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },
};

const get = {
  authToken: () => {
    return JSON.parse(localStorage.getItem(AUTH_CONSTANTS.LS_KEY));
  },
  decodedToken: () => {
    return JSON.parse(localStorage.getItem(AUTH_CONSTANTS.DECODED_TOKEN));
  },
  refreshToken: () => {
    return JSON.parse(localStorage.getItem(AUTH_CONSTANTS.REFRESH_TOKEN));
  },
  refreshTokenInProgress: () => {
    return JSON.parse(localStorage.getItem(AUTH_CONSTANTS.REFRESH_TOKEN_IN_PROGRESS));
  },
  userInfo: () => {
    return JSON.parse(localStorage.getItem(AUTH_CONSTANTS.USER_INFO));
  },
  organizationInfo: () => {
    return JSON.parse(localStorage.getItem(AUTH_CONSTANTS.ORGANIZATION_INFO));
  },
  value: (key) => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch {
      return localStorage.getItem(key);
    }
  },
};

const fetch = {
  authToken: () => {
    const data = localStorage.getItem(AUTH_CONSTANTS.LS_KEY);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.auth_token;
      } catch (err) {}
    }
  },
};

const destroy = {
  authToken: () => {
    localStorage.clear();
  },
};

export const storage = {
  set,
  get,
  fetch,
  destroy,
  checkAuthentication,
  checkOrgInfo
};
