import React, { useEffect, useState } from "react";
import { Button } from "antd";
import Modal from "src/components/Common/Modal";
import {
  AntInput,
  AntSelect,
} from "src/components/Common/CreateAntFields/CreateAntFields";
import Switch from "../../components/Common/Switch";
import { Formik, Field, Form } from "formik";
import { dnaInviteValidations, inviteValidations } from "./validate";
import { BASE_SCHEMA, DNA_SCHEMA } from "./constants";
import BulkUploader from "src/forms/BulkUploader";

import "./InviteUser.scss";
import Toast from "src/components/Common/Toast";
import { DEFAULT_COUNTRY_DATA, LOGIN_TYPE, experienceOptions, COUNTRY_DATA } from "src/constants/globalConstants";
import PhoneField from "./PhoneField/PhoneField";
import { AddPositionIcon, AntdSelectIcon } from "src/assets/icons";

export default function InviteUser(props) {
  const { onSubmit, archived, onBulkInviteSuccess,
    config = {}, error = null, setError = () => { },
    isModalActiveFromParent = false, setModalActiveFromParent = () => { },
    editInviteData = {}, setEditInviteData = () => {}, submitEditInvite = () => {} } = props;
  const [isModalActive, setIsModalActive] = useState(false);
  const [initialValues, setInitialValues] = useState(BASE_SCHEMA);
  const [country, setCountry] = useState(DEFAULT_COUNTRY_DATA);
  const [isSubmitting, setIsSubmitting] = useState(false)

  const hasPhoneLogin = config.loginType === LOGIN_TYPE.PHONE;
  const hasThirdPartyLogin = config.loginType === LOGIN_TYPE.THIRD_PARTY;
  const isEditForm = Object.keys(editInviteData)?.length > 0

  /**Dynamic country code from position orgconfig as per Neha. */
  useEffect(() => {
    const countryCode = config?.organization?.organizationConfig?.countryCode ?? "91"
    const country = COUNTRY_DATA?.find(country => country?.phone == countryCode)
    setCountry(country)
  }, [])

  useEffect(() => {
    if (isModalActiveFromParent) {
      setIsModalActive(true)
    }
  }, [isModalActiveFromParent])

  /**
   * For non-dna positions, if it's a edit form then set the edit values as initial form values.
   * Else, the default empty values are set.
   */
  useEffect(() => {
    if (props.dna) {
      setInitialValues(DNA_SCHEMA);
    } else {
      if(isEditForm) {
        setInitialValues({
          name: editInviteData?.name ?? "",
          email: editInviteData?.email ?? "",
          ...(!hasThirdPartyLogin && {
            yearsOfExperience: editInviteData?.yearsOfExperience ?? "",
          }),
          candidateKey: editInviteData?.candidateKey ?? "",
          phone: editInviteData?.phone ?? "",
          hasPhoneLogin,
          hasThirdPartyLogin
        })
        setIsModalActive(true)
      } else {
        setInitialValues({
          ...BASE_SCHEMA,
          hasPhoneLogin,
          hasThirdPartyLogin
        });
      }
    }
  }, [props.dna, config, isEditForm]);

  useEffect(() => {
    if (isModalActive)
      document.body.style.overflow = "hidden";
    else {
      setError(null);
      document.body.style.overflow = "";
    }
  }, [isModalActive]);

  return (
    <>
      {props?.emptyState ? (
        props?.showEmptyState ? (
          <h2 className="mt-160 text-34 semibold">
            No results found for this search
          </h2>
        ) : (
          <div className="round-container flex flex-column items-center justify-center text-30 semibold text-center">
            <AddPositionIcon />
            <p
              className=" my-10 underline-on-hover"
              onClick={() => {
                if (archived) {
                  Toast(
                    "info",
                    "This is an archived position",
                    "Can not add user"
                  );
                } else {
                  setIsModalActive(true);
                }
              }}
            >
              No candidates yet. <br />
              Start adding here
            </p>
            <p className="">OR</p>
            <p>
              <BulkUploader config={config} onSuccess={onBulkInviteSuccess} textForm={true} />
            </p>
          </div>
        )
      ) : (
        <Button
          className="invite-btn ant-btn-primary text-16 weight-400 px-20 py-8"
          icon={props.icon}
          onClick={() => setIsModalActive(true)}
        >
          Invite a Candidate
        </Button>
      )}

      <Modal
        modalVisible={isModalActive}
        cancelModal={() => {
          setEditInviteData({})
          setIsModalActive(false);
          setModalActiveFromParent(false)
        }}
        width="350px"
        centered
        className="invite-candidate-modal"
      >
        <div className="invite-modal-content">
          <div className="text-22 semibold w-75 line-1 pb-30 modal-heading">
          {`${isEditForm ? "Edit the Invite" : "Invite a Candidate"}`}
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={
              props.dna ? dnaInviteValidations : inviteValidations
            }
            onSubmit={(values) => {
              const newvalues = { ...values }
              delete newvalues.hasPhoneLogin;
              delete newvalues.hasThirdPartyLogin;
              // If phone is present -> After passing validation, add countryCode
              if (newvalues.phone) {
                newvalues.countryCode = country.phone;
              } else if(!isEditForm) {  // to avoid deleting empty phone value in case of editing hasPhoneLogin
                delete newvalues.phone;
                delete newvalues.countryCode;
              }
              if (!hasThirdPartyLogin) delete newvalues.candidateKey;
              if (hasPhoneLogin || hasThirdPartyLogin) {
                if (newvalues?.email?.length === 0) {
                  delete newvalues.email
                }
              }
              /**
               * If it's a edit form then remove uneditable values,
               * email and candidateKey
               */
              if(isEditForm) {
                delete newvalues.email 
                if(hasThirdPartyLogin) {
                  delete newvalues.candidateKey
                }
                submitEditInvite(newvalues, setIsModalActive, setIsSubmitting)
              } else {
                onSubmit(newvalues, setIsModalActive, setIsSubmitting);
              }
            }}
            enableReinitialize
          >
            {({ values, handleSubmit }) => {
              return (
                <Form>
                  <div>
                    <div className="item-box grid mt-14 mb-30">
                      <div className="item mb-20">
                        <Field
                          component={AntInput}
                          type="text"
                          value={values.name}
                          name={"name"}
                          label={"Name"}
                          hasFeedback
                          onPressEnter={handleSubmit}
                        />
                      </div>
                      <div className={`item mb-20 ${isEditForm ? "disabled opacity-6": ""}`}>
                        <Field
                          component={AntInput}
                          type="email"
                          value={values.email}
                          name={"email"}
                          label={"Email"}
                          hasFeedback
                          onPressEnter={handleSubmit}
                        />
                      </div>
                      {
                        hasThirdPartyLogin &&
                        <div className={`item mb-20 ${isEditForm ? "disabled opacity-6": ""}`}>
                          <Field
                            component={AntInput}
                            type="text"
                            value={values.email}
                            name={"candidateKey"}
                            label={"Candidate ID"}
                            hasFeedback
                            onPressEnter={handleSubmit}
                          />
                        </div>
                      }
                      {
                        !hasThirdPartyLogin &&
                        <div className="item mb-20">
                          <Field
                            component={AntInput}
                            type="number"
                            value={values.yearsOfExperience}
                            name={"yearsOfExperience"}
                            label={"Years of experience"}
                            precision={1}
                            hasFeedback
                            onPressEnter={handleSubmit}
                          />
                        </div>
                      }
                      <div className="item mb-20 phone-field">
                        <Field
                          component={PhoneField}
                          country={country}
                          setCountry={setCountry}
                          type="text"
                          value={values.phone}
                          name={"phone"}
                          label={"Phone Number"}
                          precision={1}
                          hasFeedback
                          onPressEnter={handleSubmit}
                        />
                      </div>
                      {props.dna && (
                        <>
                          <div className="item mb-20">
                            <Field
                              name="expertiseLevel"
                              component={AntSelect}
                              optionValueKey="value"
                              optionNameKey="name"
                              value={values?.expertiseLevel}
                              hasFeedback
                              selectOptions={experienceOptions}
                              label="Expertise"
                              suffixIcon={<AntdSelectIcon />}
                              onPressEnter={handleSubmit}
                            />
                          </div>
                          <div className="item mb-20">
                            <Field
                              value={values.designation}
                              name="designation"
                              type="text"
                              hasFeedback
                              component={AntInput}
                              label="Designation"
                              onPressEnter={handleSubmit}
                            />
                          </div>
                          <div className="item mb-20 flex justify-between align-center">
                            <p className="mb-10 text-grey weight-500 size-16">
                              Star Performer
                            </p>
                            <Field
                              component={Switch}
                              value={values.starPerformer}
                              name={"starPerformer"}
                              label={"Star Performer"}
                              hasFeedback
                              onPressEnter={handleSubmit}
                            />
                          </div>
                        </>
                      )}
                      {
                        error &&
                        <p className="text-red size-16 mt-14">{error}</p>
                      }
                    </div>

                    <div className="btn-box">
                      <Button
                        disabled={isSubmitting}
                        className="ant-btn-primary text-16 weight-400 px-40"
                        onClick={handleSubmit}
                      >
                        {`${isEditForm ? "Save" : "Send"} Invitation`}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </>
  );
}
