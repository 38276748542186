import React from "react";
// import { Card } from "antd";
import BlankState from "src/components/Common/BlankState";
import PropTypes from "prop-types";
import Headers from "./Headers";
import "./Listing.scss";
import { Collapse } from "antd";

export default function Listing(props) {
  const {
    showAccordian,
    dataSource,
    headers,
    dataKeys,
    uniqueKey,
    headerLayout,
    dataLayout,
    EmptyComponent,
    onRowClick,
    getRowClassName,
    headerClassName,
    rowClassName,
    positionActiveStatus,
    AccordianHeader,
    AccordianChildren,
    accordianCollapsible,
    positionLoginType
  } = props;

  if (!positionActiveStatus && !dataSource.length) {
    // if position is expired (false) but candidate list is not empty
    return null;
  }
  if (dataSource.length === 0) {
    if (EmptyComponent) return EmptyComponent;
    return (
      <BlankState
        message={"No candidates yet.\n Start adding here"}
        onClick={() => alert("working")}
      />
    );
  }

  return (
    <div className="listing">
      <Headers
        headerLayout={headerLayout}
        headers={headers}
        className={headerClassName}
        positionLoginType={positionLoginType}
      />
      {
        showAccordian ? (
          dataSource.map((item) => (
            <ListingAccordian
              key={item?.id}
              item={item}
              AccordianHeader={AccordianHeader}
              AccordianChildren={AccordianChildren}
              dataLayout={dataLayout}
              accordianCollapsible={accordianCollapsible}
            />
          ))
        ) : dataSource.map((item) =>
          <ListingRow
            key={item?.[uniqueKey]}
            getRowClassName={getRowClassName}
            item={item}
            dataLayout={dataLayout}
            onRowClick={onRowClick}
            dataKeys={dataKeys}
            rowClassName={rowClassName}
          />
        )}
    </div>
  );
}

const ListingAccordian = ({ item, AccordianChildren, AccordianHeader, dataLayout, accordianCollapsible }) => {

  return !accordianCollapsible(item) ? (
    <div className="accordian-table-rows">
      <AccordianHeader data={item} dataLayout={dataLayout} />
    </div>
  ) : (
    <Collapse
      expandIconPosition={"right"}
    >
      <Collapse.Panel
        key={item?.id}
        header={<AccordianHeader data={item} dataLayout={dataLayout} />}
      >
        <AccordianChildren data={item} />
      </Collapse.Panel>
    </Collapse>
  )
}

const ListingRow = ({ getRowClassName, item, rowClassName, dataLayout, onRowClick, dataKeys }) => {
  return (
    <div
      className={`table-rows grid items-center ${getRowClassName(
        item
      )} ${rowClassName}`}
      style={{ gridTemplateColumns: dataLayout }}
      onClick={() => onRowClick(item)}
    >
      {dataKeys.map((renderBlock) => {
        if (typeof renderBlock === "object") {
          if ("render" in renderBlock) {
            // custom render function
            return renderBlock?.render(item);
          } else {
            // default render statement
            return <div>{item?.[renderBlock?.name]}</div>;
          }
        } else {
          return <div>{item?.[renderBlock]}</div>;
        }
      })}
    </div>
  );
}

Listing.propTypes = {
  headerLayout: PropTypes.string,
  dataLayout: PropTypes.string,
  dataSource: PropTypes.array,
  headers: PropTypes.array,
  dataKeys: PropTypes.array,
  uniqueKey: PropTypes.string,
  onRowClick: PropTypes.func,
  getRowClassName: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
};

Listing.defaultProps = {
  headerLayout: "",
  dataLayout: "",
  dataSource: [],
  headers: [],
  dataKeys: [],
  uniqueKey: "",
  onRowClick: () => { },
  getRowClassName: () => { },
  headerClassName: "",
  rowClassName: "",
};
