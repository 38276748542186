export const experienceOptions = [
  { name: "Individual Contributor", value: "individual-contributor" },
  { name: "Team Lead", value: "team-lead" },
  { name: "Manager", value: "manager" },
];

export const BASE_SCHEMA = {
  name: undefined, // required
  email: undefined, // required
  yearsOfExperience: undefined, // required
  phone: undefined, // required 
  candidateKey: undefined,
  hasPhoneLogin: undefined,
  hasThirdPartyLogin: undefined,
};

export const DNA_SCHEMA = {
  ...BASE_SCHEMA,
  expertiseLevel: undefined, // required
  designation: '',
  starPerformer: false,
}
