import React, { useEffect, useState } from "react";

import { Empty } from "antd";
import { convertToStyle, getTraitBand } from "../oprations";
import { roundOff } from "src/pages/PdfReport/operations";
import { sort } from "src/utils/helperFunctions";
import { AIPoweredInsights, Average, DoubleDownArrow, Info, MagicWand, Star } from "src/assets/icons";

export const TraitsGrid = ({
  title,
  showRelevent = false,
  showBucketWise = false,
  description,
  traitsMap,
  activeTrait,
  onTraitHover,
  hideLegends = false
}) => {
  const [traits, setTraits] = useState([]);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [activeTraitDescription, setActiveTraitDescription] = useState("");
  const [activeTraitAiAnalysis, setActiveTraitAiAnalysis] = useState("");
  const [activeTraitName, setActiveTraitName] = useState("");

  useEffect(() => {
    if (!showBucketWise) {
      setTraits(
        sort(
          [...traitsMap.values()].filter((trait) =>
            showRelevent ? trait.type === "relevant" : trait.type !== "relevant"
          ),
          "order"
        )
      );
    }
  }, [traitsMap]);

  useEffect(() => {
    if (showBucketWise) {
      setTraits([...traitsMap.values()])
    }
  }, [])

  useEffect(() => {
    const isActiveTraitPresent = traits.some(({ id }) => id === activeTrait);
    if (isActiveTraitPresent) {
      const { description, aiAnalysis, name } = traitsMap.get(activeTrait) ?? {};
      setActiveTraitDescription(description);
      if (aiAnalysis) setActiveTraitAiAnalysis(aiAnalysis);
      else setActiveTraitAiAnalysis("");
      setActiveTraitAiAnalysis(aiAnalysis);
      setActiveTraitName(name);
    }
    setShowInfoBox(isActiveTraitPresent);
  }, [activeTrait]);

  return (
    <section className={`traits-section ${showBucketWise && 'traits-section-mba'}`}>
      <div className={`info ${activeTrait ? "dim" : ""}`}>
        <h4 className="title">{title}</h4>
        <p className={`summary ${showBucketWise && 'summary-mt'}`}>{description}</p>
        <div className={`description-box ${showInfoBox ? "" : "opacity-0"}`}>
          {window.innerWidth < 500 ? (
            <div className="drawer-header">
              <DoubleDownArrow fill="#fff" />
            </div>
          ) : null}
          <span className="icon">
            <Info />
          </span>
          <p>{activeTraitDescription}</p>
        </div>
        <div className={`ai-analysis-box flex flex-col items-start ${showInfoBox && activeTraitAiAnalysis ? "" : "opacity-0"}`}>
          <div className="ai-analysis-logo flex items-center bg-white">
						<AIPoweredInsights />
						<span className="ml-8">AI POWERED INSIGHTS</span>
					</div>
          <p className="ai-analysis-score">
            {
              activeTraitAiAnalysis && 
              <div className="flex items-center justify-start">
                <span className="score">{`${activeTraitAiAnalysis.split("-")[0]}`}</span>
                <span className="trait-name ml-4">{activeTraitName}</span>
              </div>
            }
          </p>
          <p className="trait-desc">{activeTraitAiAnalysis && activeTraitAiAnalysis.split("-")[1]}</p>
        </div>
      </div>
      {traits.length > 0 ? (
        <div className="traits-grid">
          {[...traits.values()].map(
            ({ id, name, score, median, dnaMedian, ...rest }) => {
              // hide the trait when score is 0
              if (score === null) return null;
              const { aiAnalysis } = rest;
              const traitColors = getTraitBand(traitsMap.get(id));
              const fillPercentage = score * 10;
              const average = median * 10;
              const orgAverage = dnaMedian * 10;
              const isActive = id === activeTrait;

              const styles = { ...convertToStyle(traitColors) };

              return (
                <div
                  key={id}
                  className={`trait-box ${activeTrait ? (!isActive ? "dim" : "active") : ""
                    }`}
                  style={styles}
                  onMouseEnter={() => onTraitHover(id)}
                  onMouseLeave={() => onTraitHover()}
                >
                  {aiAnalysis && <div className="magic-wand-icon"><MagicWand /></div>}
                  <div
                    className={`fill ${!isActive ? "not-active" : ""}`}
                    style={{
                      background: traitColors.color,
                      width: `${fillPercentage}%`,
                    }}
                  ></div>
                  <h4>{name}</h4>

                  <div className={`score ${isActive ? "" : "opacity-0"}`}>
                    {roundOff(score)}
                  </div>
                  {!hideLegends && <Threshold
                    className={isActive ? "" : "opacity-0"}
                    Icon={Average}
                    value={average}
                  />}
                  {!hideLegends && dnaMedian !== undefined && dnaMedian !== null && (
                    <Threshold
                      className={isActive ? "" : "opacity-0"}
                      Icon={Star}
                      value={orgAverage}
                    />
                  )}
                </div>
              );
            }
          )}
        </div>
      ) : (
        <div className="empty-state-wrapper">
          <Empty description={false} />
        </div>
      )}
    </section>
  );
};

const Threshold = ({ className, Icon, value }) => {
  return (
    <>
      <div
        className={`median ${className}`}
        style={{ left: value + "%" }}
      ></div>
      <span className={`median-icon ${className}`} style={{ left: value + "%" }}>
        <Icon />
      </span>
    </>
  );
};
