import React, { useState, useEffect } from "react";
import "../../pages/Login/Login.scss";
import { Button } from "antd";
import { Formik, Field, Form } from "formik";
import { otpValidation } from "src/pages/Login/validate.js";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import OtpComponent from "../../components/Common/OtpComponent";
import { initialValues } from "./constants";
import { storage } from "src/services/config/storage";
import { GoBack } from "src/assets/icons";

export default function VerifyOtpForm(props) {
  const { email, onSubmit, otp, resendOTP, showOtpForm, pushToLoginRoute } = props;
  const [counter, setCounter] = useState(AUTH_CONSTANTS.OTP_VERIFICATION_TIMER);
  useEffect(() => {
    if (counter === 0) {
      clearTimeout(otpTimer);
    }
  }, [counter]);

  const otpTimer = setTimeout(() => {
    setCounter(counter - 1);
  }, 1000);

  // Handled for specifically refresh session flow
  // Clears LS -> User lands on login route.
  const handlePushToLoginRoute = () => {
    storage.destroy.authToken();
    window.location.reload();
  };

  return (
    <>
      <div className="text-content-block">
          <div
            onClick={pushToLoginRoute ? handlePushToLoginRoute: showOtpForm}
            className="back-btn pb-20 text-18 text-dark cursor-pointer"
          >
            <span>
              <GoBack />
            </span>
            BACK
          </div>
        <h2 className="text-30 weight-600 mb-10">Verify with OTP</h2>
        <p className="otp-para text-18 line-28 semibold mb-60">
          Enter the 4-digit code sent to{" "}
          <span className="text-darkorange">{email}</span>
        </p>
        {process.env.REACT_APP_ENV === "development" && (
          <p>Testing:- OTP is {otp}</p>
        )}
      </div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values) => {
          onSubmit(values);
        }}
      >
        {({ values, handleSubmit }) => {
          return (
            <Form>
              <div className="text-content-block">
                <div className="flex items-center justify-start otp-form">
                  <Field
                    component={OtpComponent}
                    value={values.otp}
                    name={"otp"}
                    validate={(value) => otpValidation(value)}
                  />
                  <Button
                    className="ant-btn-primary"
                    onSubmit={handleSubmit}
                    htmlType="submit"
                  >
                    Verify and Continue
                  </Button>
                </div>
                {counter > 0 ? (
                  <p className="text-16 timer semibold text-mediumgrey weight-400 otp-text">
                    Resend OTP in{" "}
                    <span className="text-darkorange">{counter}s</span>
                  </p>
                ) : (
                  <p className="text-16 timer semibold text-mediumgrey weight-400 otp-text">
                    Didn't receive the OTP?{" "}
                    <span
                      onClick={() => {
                        resendOTP({ email });
                        clearTimeout(otpTimer);
                        setCounter(AUTH_CONSTANTS.OTP_VERIFICATION_TIMER);
                      }}
                      className="text-darkorange cursor-pointer"
                    >
                      Resend OTP
                    </span>
                  </p>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
