import { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Toast from "src/components/Common/Toast";
import { PoolPerformance, ScoreDistribution } from "./Stats";
import ComparativeAnalysis from "./ComparativeAnalysis";
import DetailedScoreDistribution from "./DetailedScoreDistribution";
import PositionApi from "src/services/apis/positions";
import { defaultValues, zones } from "./constants";
import { GitDiff, GoBack } from "src/assets/icons"
import "./PositionAnalytics.scss"

const PositionAnalytics = () => {
    const [analyticsData, setAnalyticsData] = useState(null)
    const [currentZone, setCurrentZone] = useState("North")
    const [isComparativeAnalysisVisible, setIsComparativeAnalysisVisible] = useState(false)
    const [comparativeAnalysisStep, setComparativeAnalysisStep] = useState(1)
    const [selectedZones, setSelectedZones] = useState([])
    const [isQuestionsAnalysisVisible, setIsQuestionsAnalysisVisible] = useState(false)
    const [zoneForQuestionAnalysis, setZoneForQuestionAnalysis] = useState(defaultValues.FILTERS.ZONE)
    const [siteForQuestionAnalysis, setSiteForQuestionAnalysis] = useState(defaultValues.FILTERS.SITE)
    const [rollTypeForQuestionAnalysis, setRollTypeForQuestionAnalysis] = useState(defaultValues.FILTERS.ROLL.ALL.value)
    const [levelForQuestionAnalysis, setLevelTypeForQuestionAnalysis] = useState(defaultValues.FILTERS.LEVEL.ALL.value)
    const [skillForQuestionAnalysis, setSkillForQuestionAnalysis] = useState(defaultValues.FILTERS.SKILLS.ALL.value)
    const positionStats = useSelector((state) => state.positions.positionStats)
    const { positionId } = useParams()
    const history = useHistory()

    const handleOpenComparativeAnalysis = () => {
        setIsComparativeAnalysisVisible(true)
    }

    const handleCloseComparativeAnalysis = () => {
        setIsComparativeAnalysisVisible(false)
        setComparativeAnalysisStep(1)
        setSelectedZones([])
    }

    const handleZoneChange = (tab) => {
        setCurrentZone(tab)
    }

    const handleCompareZones = () => {
        if (selectedZones?.length > 0) {
            setComparativeAnalysisStep(2)
        }
    }

    const handleGoBack = () => {
        setComparativeAnalysisStep(1)
    }

    const handleOpenQuestionsAnalysis = ({ zone = defaultValues.FILTERS.ZONE, site = defaultValues.FILTERS.SITE, roll = defaultValues.FILTERS.ROLL.ALL.value, level = defaultValues.FILTERS.LEVEL.ALL.value }) => {
        setZoneForQuestionAnalysis(zone)
        setSiteForQuestionAnalysis(site)
        setRollTypeForQuestionAnalysis(roll)
        setLevelTypeForQuestionAnalysis(level)
        setIsQuestionsAnalysisVisible(true)
    }

    const handleCloseQuestionsAnalysis = () => {
        setIsQuestionsAnalysisVisible(false)
        setZoneForQuestionAnalysis(defaultValues.FILTERS.ZONE)
        setSiteForQuestionAnalysis(defaultValues.FILTERS.SITE)
        setRollTypeForQuestionAnalysis(defaultValues.FILTERS.ROLL.ALL.value)
        setLevelTypeForQuestionAnalysis(defaultValues.FILTERS.LEVEL.ALL.value)
    }

    const handleDetailedScoreDistributionFilterChange = (key, val) => {
        switch (key) {
            case "zone": setZoneForQuestionAnalysis(val)
                setSiteForQuestionAnalysis(defaultValues.FILTERS.SITE)
                break
            case "site": setSiteForQuestionAnalysis(val)
                break
            case "roll": setRollTypeForQuestionAnalysis(val)
                break
            case "level": setLevelTypeForQuestionAnalysis(val)
                break
            case "skill": setSkillForQuestionAnalysis(val)
                break
            default: setZoneForQuestionAnalysis(defaultValues.FILTERS.ZONE)
                setSiteForQuestionAnalysis(defaultValues.FILTERS.SITE)
                setRollTypeForQuestionAnalysis(defaultValues.FILTERS.ROLL.ALL.value)
                setLevelTypeForQuestionAnalysis(defaultValues.FILTERS.LEVEL.ALL.value)
                setSkillForQuestionAnalysis(defaultValues.FILTERS.SKILLS.ALL.value)
                return
        }
    }

    useEffect(() => {
        (async () => {
            if (positionId) {
                try {
                    const response = await PositionApi.getPositionAnalytics(positionId)
                    if (response.success) {
                        setAnalyticsData(response.data)
                    }
                } catch (err) {
                    console.error(err)
                    Toast("error", "Something went wrong.")
                }
            }
        })()
    }, [positionId])

    const TabCorner = ({ position = "" }) => {
        return (
            <>
                <svg width="25" height="30" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M-2,-2 L7.5,-10 Q0,25 60,38 L-2,30 Z"
                        fill="white"
                        stroke="#f3ddc2"
                        stroke-width="1"
                        {...(position === "left" && { transform: "scale(-1, 1) translate(-25, 0)" })}
                    />
                </svg>
            </>
        )
    }

    return (
        <div className="position-analytics px-70 py-20">
            <span onClick={() => {
                history.push(`/hiring/${positionId}`)
            }} className="mr-10 cursor-pointer goback-icon flex items-center justify-center">
                <GoBack />
            </span>
            <div className="flex items-center justify-between mb-16">
                <p className="text-24 weight-600 color-black">
                    Showing analytics across {positionStats?.data?.name ?? ""}
                </p>
                <button
                    className="comparative-btn cursor-pointer py-12 px-20 flex items-center capitalize text-18 weight-600"
                    onClick={handleOpenComparativeAnalysis}
                >
                    <span className="mr-6">
                        <GitDiff />
                    </span>
                    comparative analysis
                </button>
            </div>
            <div className="flex stats-cards-container ml-20">
                <PoolPerformance
                    data={{
                        zone: "overall",
                        performance: analyticsData?.overall?.performance,
                    }}
                />
                <ScoreDistribution
                    data={[{
                        scoreDistribution: analyticsData?.overall?.scoreDistribution ?? {},
                        multiplier: analyticsData?.fitScoreMultiplier ?? 1,
                    }]}
                    isDistributionComparative={false}
                    handleViewDetailedDistribution={handleOpenQuestionsAnalysis}
                />
            </div>
            <p className="capitalize text-18 weight-600 color-black mt-22 mb-16">zone wise analytics</p>
            <div className="flex items-center tabs-container relative">
                {
                    zones.map((tab, index) => (<div
                        key={tab}
                        className={`cursor-pointer relative ${tab === currentZone ? "tab-container active-border topleft-radius-30 topright-radius-30" : ""}`}
                        onClick={() => handleZoneChange(tab)}
                    >
                        <p className={`tab-label text-18 weight-600 ${tab === currentZone ? "active-tab-label" : "inactive-tab-label inactive-border"}`}>
                            {tab}
                        </p>
                        {
                            tab === currentZone && index >= 0
                                ? <div className={`absolute bottom--4 ${index === 0 ? "right--19" : "right--18"}`}>
                                    <TabCorner position={"right"} />
                                </div>
                                : <></>
                        }
                        {
                            tab === currentZone && index <= 3 && index > 0
                                ? <div className={`absolute bottom--4 ${index > 0 ? "left--18" : "left--19"}`}>
                                    <TabCorner position={"left"} />
                                </div>
                                : <></>
                        }
                        {
                            tab === currentZone
                                ? <div className="absolute bottom-border-white" />
                                : <></>
                        }
                    </div>
                    ))
                }
            </div>
            <div className={`tab-panel-container flex flex-col relative p-20 ${currentZone !== "North" ? "topleft-radius-20" : ""} active-border topright-radius-20 bottomright-radius-20 bottomleft-radius-20`}>
                {
                    !analyticsData?.zonal?.[currentZone]
                        ? <p className="text-20 color-black weight-600">No participants from {currentZone} zone</p>
                        : <>
                            <div className="flex stats-cards-container">
                                <PoolPerformance
                                    data={{
                                        zone: `${currentZone} zone`,
                                        performance: analyticsData?.zonal?.[currentZone]?.overall?.performance,
                                    }}
                                />
                                <ScoreDistribution
                                    data={[{
                                        scoreDistribution: analyticsData?.zonal?.[currentZone]?.overall?.scoreDistribution ?? {},
                                        multiplier: analyticsData?.fitScoreMultiplier ?? 1,
                                    }]}
                                    isDistributionComparative={false}
                                    handleViewDetailedDistribution={() => handleOpenQuestionsAnalysis({ zone: currentZone })}
                                />
                            </div>
                            {
                                Object.keys(analyticsData?.zonal?.[currentZone]?.sites ?? {})
                                    ?.map(site => (
                                        <div key={site}>
                                            <p className="capitalize text-18 weight-600 color-black mt-22">{site}</p>
                                            <div className="flex stats-cards-container">
                                                <PoolPerformance data={{
                                                    zone: site,
                                                    performance: analyticsData?.zonal?.[currentZone]?.sites?.[site]?.performance,
                                                }} />
                                                <ScoreDistribution
                                                    data={[{
                                                        scoreDistribution: analyticsData?.zonal?.[currentZone]?.sites?.[site]?.scoreDistribution ?? {},
                                                        multiplier: analyticsData?.fitScoreMultiplier ?? 1,
                                                    }]}
                                                    isDistributionComparative={false}
                                                    handleViewDetailedDistribution={() => handleOpenQuestionsAnalysis({ zone: currentZone, site })}
                                                />
                                            </div>
                                        </div>))
                            }
                        </>
                }
            </div>
            <ComparativeAnalysis
                analyticsData={analyticsData}
                isComparativeAnalysisVisible={isComparativeAnalysisVisible}
                comparativeAnalysisStep={comparativeAnalysisStep}
                selectedZones={selectedZones}
                setSelectedZones={setSelectedZones}
                handleCloseComparativeAnalysis={handleCloseComparativeAnalysis}
                handleCompareZones={handleCompareZones}
                handleGoBack={handleGoBack}
            />
            <DetailedScoreDistribution
                analyticsData={analyticsData}
                isQuestionsAnalysisVisible={isQuestionsAnalysisVisible}
                zones={zones}
                zoneForQuestionAnalysis={zoneForQuestionAnalysis}
                siteForQuestionAnalysis={siteForQuestionAnalysis}
                rollTypeForQuestionAnalysis={rollTypeForQuestionAnalysis}
                levelForQuestionAnalysis={levelForQuestionAnalysis}
                skillForQuestionAnalysis={skillForQuestionAnalysis}
                handleCloseQuestionsAnalysis={handleCloseQuestionsAnalysis}
                handleFilterChange={handleDetailedScoreDistributionFilterChange}
            />
        </div>
    )
}

export default PositionAnalytics
