import {
    LoadingOutlined
} from '@ant-design/icons';
import { Tooltip } from 'antd';

const ProfileContainer = ({
    loading,
    sessionProfile
}) => {
    return (
        <>
            {
                loading
                    ? <div className="relative loader-snapshots-container">
                        <div className="absolute top-0 left-0 loader-container flex items-center justify-center b-12">
                            <LoadingOutlined />
                        </div>
                    </div>
                    : sessionProfile?.profileLink && sessionProfile?.sessionLink ? (
                        <div className={`grid grid-cols-2 grid-gap-12 mt-3 mobile-grid-cols`}>
                            <div className='relative height-fit'>
                                <div className="absolute top-0 left-0 snap-width snap-overlay"></div>
                                <img src={sessionProfile?.profileLink} alt='' className="snap-width" loading="lazy" />
                                <p className="absolute bottom-0 left-0 p-10 mb-1 text-12 text-white">
                                    Profile
                                </p>
                            </div>
                            <div className='relative height-fit'>
                                <div className="absolute top-0 left-0 snap-width snap-overlay"></div>
                                <img src={sessionProfile?.sessionLink} alt='' className="snap-width" loading="lazy" />
                                <p className="absolute bottom-0 left-0 p-10 mb-1 text-12 text-white">
                                    Session
                                </p>
                                {
                                    sessionProfile?.isCritical
                                        ? <Tooltip placement="topLeft" title={sessionProfile?.criticalReason}>
                                            <p className="cursor-pointer absolute txt-12 text-white critical-icon">!</p>
                                        </Tooltip> : <></>
                                }
                            </div>
                        </div>
                    ) : (
                        <div className={`snap-critical-width-4 flex items-center justify-center text-18 weight-600 clr-gray mt-5`}>
                            No snapshots found
                        </div>
                    )
            }
        </>
    )
}

export default ProfileContainer