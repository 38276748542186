import React, { useMemo } from 'react';
import './PersonalityComponent.scss';
import { 
  BUCKET_NAMES, 
  PERSONALITY_TRAITS, 
  PERSONALITY_TRAITS_MAP, 
  PERSONALITY_TRAITS_MAP_IPIP 
} from 'src/constants/globalConstants';
import WithToolTip from 'src/components/Common/WithToolTip';
import { roundOff } from 'src/pages/PdfReport/operations';

// **** REMOVE -> PENDING CONFIRMATION **** 

// const StepComponent = () => {
//   const steps = Array.from({ length: 11 }, (_, index) => index);

//   return (
//     <div className="step-container">
//       {steps.map((step, index) => (
//         <div key={step} className="step">
//           <div className="circle">{step}</div>
//           {(index > 0 && index < 11) && 
//             <div className="line"></div>
//           }
//         </div>
//       ))}
//     </div>
//   );
// };

/**
 * @component
 * Show scoring bar -> median, score, lb and ub markers.
 * TODO: Move to own component under ./Common
 */
const TraitBar = ({ median, lb, ub, score }) => {
  const barRef = React.useRef(null);
  const offsetPercent = 1;
  const calculateOffset = (value) => (((value - 1) / 9) * 100) - offsetPercent;
  const lbPercent = calculateOffset(lb);
  const ubPercent = calculateOffset(ub);
  const progressPercent = ubPercent - lbPercent;
  const medianPercent = calculateOffset(median);
  const scorePercent = calculateOffset(score);

  return (
    <div className="trait-bar-container flex items-center justify-between">
      <div className="trait-bar-marker" style={{ left: `${0.4}%` }}></div>
      <span className="bar-marker lb">1</span>
      <div className="trait-bar" ref={barRef}>
        <div className="bar-progress" style={{ width: `${progressPercent}%`, left: `${lbPercent}%` }}></div>
        <WithToolTip
          title={`Industry Average: ${roundOff(median)}`}
          showToolTip={true}
        >
          <div className="median-marker" style={{ left: `${medianPercent}%` }}></div>
        </WithToolTip>
        <WithToolTip
          title={`User Score: ${roundOff(score)}`}
          showToolTip={true}
        >
          {
            (scorePercent >= 0 || scorePercent) &&
            <div className="score-marker-container" style={{ left: `${scorePercent}%` }}>
              <div className="score-marker"></div>
            </div>
          }
        </WithToolTip>
      </div>
      <span className="bar-marker ub">10</span>
      <div className="trait-bar-marker" style={{ left: `${-0.4}%` }}></div>
    </div>
  );
};


/**
 * @component
 * Row -> Showing trait info -> description and 
 */
const TraitInfoComponent = ({ trait, subTraits = [], isParentTrait, bucketName }) => {
  const { description, displayName='', name, color } = trait;
  
  // Data for IPIP Short and IPIP Long buckets merged into one
  // For IPIP buckets, get short or long suffix for ub, lb and median
  // SEE global constants, PERSONALITY_TRAITS_MAP_IPIP
  const markerSuffix = bucketName === BUCKET_NAMES.IPIP_LONG ? "_long" : 
    bucketName === BUCKET_NAMES.IPIP_SHORT ? "_short" : null;
    
  const { ub, lb, median, score } = useMemo(() => {

    const ub = !markerSuffix ? trait.ub : trait[`ub${markerSuffix}`];
    const lb = !markerSuffix ? trait.lb : trait[`lb${markerSuffix}`];
    const median = !markerSuffix ? trait.median : trait[`median${markerSuffix}`];

    if (isParentTrait) {
      const avgScore = subTraits.length ?
        (subTraits.reduce((acc, { score }) => acc + score, 0) / subTraits.length) :
        trait?.score || 0;
      return { 
        ub, 
        lb,
        median,
        score: avgScore 
      };
    }
    return { 
      ub, 
      lb, 
      median, 
      score: trait.score 
    };
  }, [trait]);

  return (
    <div
      className={`p-traits-container 
        flex items-center justify-between
        ${isParentTrait ? 'parent-trait' : ''}
      `}
    >
      <div className="trait-definition flex flex-col">
        <h4 
          className={`${isParentTrait ? 'parent-trait' : ''}`}
          style={{ background: isParentTrait ? `${color}`: '' }}
        >
          {isParentTrait ? name: displayName}
        </h4>
        <p>{description}</p>
      </div>
      <TraitBar
        ub={ub}
        lb={lb}
        median={median}
        score={score}
      />
    </div>
  );
};

const RenderTraitsAndSubTraits = ({ traits, bucketName }) => {
    return (
    <div className='p-traits'>
      {
        Object.keys(PERSONALITY_TRAITS)
        // Filter traits group Headers based on bucket names
        // Some traits are common to all Buckets
        .filter(trait => {
          // Find value in traits obj
          const value = PERSONALITY_TRAITS[trait];
          // Check if value has bucketFlags -> Is to be rendered only optionally in any bucket
          // Else if !bucketFlags.length for value _> Is common to all buckets
          return value?.bucketFlags?.includes(bucketName) || !value?.bucketFlags?.length
        })
        .map((key) => {
          // If group header trait belongs to IPIP buckets
          // Extract data from separate mapping
          const headerTrait = bucketName.includes("IPIP") ? 
            PERSONALITY_TRAITS_MAP_IPIP[key] : PERSONALITY_TRAITS_MAP[key];
          headerTrait.name = key;
          // Common color mapping for all buckets hence PERSONALITY_TRAITS
          headerTrait.color = PERSONALITY_TRAITS[key].color;
          const subTraits = traits.filter(({ parentTrait }) => parentTrait === key);
          if (!subTraits.length) {
            headerTrait.score = traits.find((trait) => trait.name === headerTrait.name)?.score;
          }

          return (
            !subTraits.length ? 
            <></> :
            <div className="p-trait-group">
              <TraitInfoComponent
                trait={headerTrait}
                subTraits={subTraits}
                bucketName={bucketName}
                isParentTrait
              />
              {
                subTraits.map((trait) => {
                  return (
                    <TraitInfoComponent
                      trait={trait}
                    />
                  );
                })
              }
            </div>
          )
        })
      }
    </div>
  )
};

const PersonalityComponent = ({ bucket }) => {
  const { traits, name: bucketName } = bucket;

  return (
    <div className="p-component">
      {/* <div className="p-header flex items-center justify-between px-2">
        <h3 className="title">{bucketName}</h3>
        <StepComponent />
      </div> */}
      <RenderTraitsAndSubTraits 
        traits={traits}
        bucketName={bucketName}
      />
    </div>
  );
};

export default PersonalityComponent;