import React from 'react';
import Text from '../Text';
import Scale from '../Scale';

import './TraitScore.scss';

export default function TraitScore(props) {
  const {
    className,
    name,
    colorCode,
    lowerBound,
    upperBound,
    score,
    median,
  } = props;

  return (
    <div className={`trait-score ${className}`}>
      <div className="size-17 weight-400">{name}</div>
      <div className="trait grid col-gap-20 mt-10 flex-center">
        <Text className="size-11 weight-400" text={lowerBound.text} />
        <Scale
          colorCode={colorCode || '#F7635E'}
          score={score}
          median={median}
          lowerBound={lowerBound.score}
          upperBound={upperBound.score}
        />
        <Text className="size-11 weight-400 align-right" text={upperBound.text} />
      </div>
    </div>
  );
}
