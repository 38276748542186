import { useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { ZoomInOutlined } from '@ant-design/icons';

export const BarGraph = ({
    data = [],
    height = "490px",
    width = "720px",
    barCategories = [],
    yTickFormatter = (value) => value,
    xTickFormatter = (value) => value,
}) => {

    return (
        <div style={{
            width,
            height,
        }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={data}
                >
                    <CartesianGrid
                        stroke={"#F7F8FB"}
                        vertical={false}
                    />
                    <XAxis
                        tickLine={false}
                        dataKey="name"
                        tickFormatter={xTickFormatter}
                        tick={{ fontSize: 12, fontWeight: '400', fill: '#202020' }}
                        axisLine={{ stroke: "#E5E7F1" }}
                    />
                    <YAxis
                        tickLine={false}
                        tickFormatter={yTickFormatter}
                        tick={{ fontSize: 14, fontWeight: '400', fill: '#202020', opacity: 0.6 }}
                        ticks={Array.from({ length: 11 }, (_, index) => null).map((_, index) => index * 10)}
                        axisLine={{ stroke: "#E5E7F1" }}
                    />
                    {
                        barCategories?.map(bar => (
                            <Bar
                                key={bar.dataKey}
                                dataKey={bar.dataKey}
                                fill={bar.fill}
                                barSize={bar.barSize}
                            />))
                    }
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export const ScoreDistribution = ({
    isDistributionComparative = false,
    data = [],
    handleViewDetailedDistribution = () => { }
}) => {

    return (
        <div className={`flex flex-col items-start justify-between stats-card stats-card-scoredist ${!isDistributionComparative ? "" : "mx-8 my-8"}`}>
            <div className="w-full flex items-center justify-between px-20 pt-20">
                <p className={`text-14 ${!isDistributionComparative ? "weight-400 opacity-50" : "weight-700"} color-black uppercase stats-card-heading`}>
                    score distribution
                </p>
                {
                    !isDistributionComparative
                        ? <span
                            onClick={handleViewDetailedDistribution}
                            className="opacity-50 cursor-pointer"
                            title="More"
                        >
                            <ZoomInOutlined />
                        </span>
                        : <></>
                }
            </div>
            <div className={`${!isDistributionComparative ? "flex items-center" : "w-full grid grid-cols-2"}`}>
                {
                    data?.map(scoreObj => {
                        const { type = "", multiplier = 1, scoreDistribution: {
                            highest = 0, percent75 = 0, median = 0, percent25 = 0, lowest = 0,
                        } } = scoreObj
                        return (
                            <div key={scoreObj.type} className={`flex flex-col items-start pl-20 pr-20 pb-10 ${!isDistributionComparative ? "mt-34" : ""}`}>
                                {
                                    isDistributionComparative
                                        ? <p className="text-12 weight-700 uppercase scoredist-type-tag my-16">{type}</p>
                                        : <></>
                                }
                                <div className={`${!isDistributionComparative ? "flex items-center justify-between" : "grid grid-cols-3 rg-14"}`}>
                                    <div className="flex flex-col mr-40">
                                        <p className="text-20 weight-700 color-black mb-8">
                                            {roundOff(!!highest ? highest * multiplier : 0)}
                                            <span className="text-12 weight-700 color-black">/{multiplier * 10}</span>
                                        </p>
                                        <p className="text-14 weight-400 color-black opacity-50 capitalize">highest</p>
                                    </div>
                                    <div className="flex flex-col mr-40">
                                        <p className="text-20 weight-700 color-black mb-8">
                                            {roundOff(!!percent25 ? percent25 * multiplier : 0)}
                                            <span className="text-12 weight-700 color-black">/{multiplier * 10}</span>
                                        </p>
                                        <p className="text-14 weight-400 color-black opacity-50 capitalize">75th percentile</p>
                                    </div>
                                    <div className="flex flex-col mr-40">
                                        <p className="text-20 weight-700 color-black mb-8">
                                            {roundOff(!!median ? median * multiplier : 0)}
                                            <span className="text-12 weight-700 color-black">/{multiplier * 10}</span>
                                        </p>
                                        <p className="text-14 weight-400 color-black opacity-50 capitalize">median</p>
                                    </div>
                                    <div className="flex flex-col mr-40">
                                        <p className="text-20 weight-700 color-black mb-8">
                                            {roundOff(!!percent75 ? percent75 * multiplier : 0)}
                                            <span className="text-12 weight-700 color-black">/{multiplier * 10}</span>
                                        </p>
                                        <p className="text-14 weight-400 color-black opacity-50 capitalize">25th percentile</p>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-20 weight-700 color-black mb-8">
                                            {roundOff(!!lowest ? lowest * multiplier : 0)}
                                            <span className="text-12 weight-700 color-black">/{multiplier * 10}</span>
                                        </p>
                                        <p className="text-14 weight-400 color-black opacity-50 capitalize">lowest</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const PoolPerformance = ({ data = null }) => {
    const { highlyRecommended = 0, recommended = 0, average = 0, notRecommended = 0, totalUsers = 0 } = data?.performance ?? {}

    const getPercentage = useCallback((count = "") => {
        const percentage = (count ?? 0) / (totalUsers ?? 0) * 100
        return isNaN(percentage) ? roundOff(0, 1) : roundOff(percentage, 1)
    }, [data])

    return (
        <div className="flex flex-col items-start justify-between stats-card stats-card-poolperf">
            <p className="text-14 weight-400 color-black opacity-50 uppercase stats-card-heading pl-20 pt-20">
                overall pool performance in {data?.zone === "overall" ? "all zones" : `${data?.zone}`}
            </p>
            <div className="flex items-center mt-34 pl-20 pr-20">
                <PoolPerformanceCategory
                    text={`${getPercentage(highlyRecommended)}%`}
                    subtext={`${highlyRecommended ?? 0} candidates`}
                    color={"#13C925"}
                />
                <PoolPerformanceCategory
                    text={`${getPercentage(recommended)}%`}
                    subtext={`${recommended ?? 0} candidates`}
                    color={"#61EB30"}
                />
                <PoolPerformanceCategory
                    text={`${getPercentage(average)}%`}
                    subtext={`${average ?? 0} candidates`}
                    color={"#FFD028"}
                />
                <PoolPerformanceCategory
                    text={`${getPercentage(notRecommended)}%`}
                    subtext={`${notRecommended ?? 0} candidates`}
                    color={"#FE5858"}
                />
            </div>
        </div>
    )
}

const PoolPerformanceCategory = ({ text = "", subtext = "", color = "#fff", className = "" }) => {
    return (
        <div className={`flex items-start width-150 ${className}`}>
            <svg width={8} height={60} className="ml-2">
                <line x1="2" y1="0" x2="2" y2="60" stroke={color} stroke-width="6" />
            </svg>
            <div className="flex flex-col items-start ml-8">
                <p className="text-20 weight-700 color-black mb-8">{text}</p>
                <div className="flex items-start">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8.5" r="7.5" stroke={color} />
                        <circle cx="8" cy="8.5" r="4" fill={color} />
                    </svg>
                    <p style={{ marginTop: "-2px" }} className="text-14 weight-400 color-black opacity-50 ml-4">
                        {subtext}
                    </p>
                </div>
            </div>
        </div>
    )
}

export const roundOff = (value, decimal = 1) => isNaN(value) || value === Infinity ? 0 : Math.floor(value * Math.pow(10, decimal)) / Math.pow(10, decimal) 