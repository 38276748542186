import React from "react";
import { notification } from "antd";

import { CloseOutlined } from "@ant-design/icons";

import ToastTemplate from "./ToastTemplate";

import "./Toast.scss";
import { CloseIcon } from "src/assets/icons";

const Toast = (
  type = "success",
  description = "",
  message = "",
  className = 'toster'
) => {
   // To show Toaster with only priority
   // Doing this to hide all error toasts
   // on 400, 401 and 500 statusCodes

   // -> These are given higher priority than antModal for refresh login
  if (className.includes("priority")) {
    const antNotifications = document.querySelectorAll('.ant-notification');
    antNotifications.forEach(notification => {
      notification.style.zIndex = '2000';
    });
  }
  notification.open({
    className,
    placement: "topRight",
    duration: type === "success" ? 5 : 15,
    closeIcon: <CloseIcon />,
    description: (
      <ToastTemplate type={type} description={description} message={message} />
    ),
  });
};

export default Toast;
