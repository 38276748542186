import { useEffect, useState } from "react"

const UserProfile = ({ positionLoginType = false, profileImg = "", name = "" }) => {
    const [isProfileImgValid, setIsProfileImgValid] = useState(false)

    useEffect(() => {
        if (profileImg) {
            const profileImage = new Image()
            profileImage.src = profileImg
            profileImage.onload = async () => {
                setIsProfileImgValid(true)
            }
        }
    }, [profileImg])

    return (
        <>
            {
                positionLoginType ?
                    profileImg && isProfileImgValid ?
                        <img src={profileImg} alt={name} className="user-dp mr-8" />
                        : <span className="user-initials flex items-center justify-center mr-8">{name?.[0] ?? ""}</span>
                    : <></>
            }
        </>
    )
}
export default UserProfile
