import './QuestionsSection.scss';
import ChatSection from "../ChatSection";
import MultiChatSection from '../MultiChatSection';
import DisplaySection from "./DisplaySection";
import { QUIZ_DISPLAY_SECTIONS } from "../constant";

export const QuestionsSection = (props) => {
  const { 
    chatData,
    codingData, 
    mcqData
  } = props;

  return (
    <div id="one-page-report-component" className="v2">
      <div className="info v2">
        <div className="content-section">
          <div className="display-section">
            {
              chatData.length>0 ?
                chatData.length === 1
                ? <ChatSection 
                  data={chatData}
                /> 
                : <MultiChatSection
                  data={chatData}
                /> 
              : <></>
            }
            {
              codingData.length>0 &&
              <DisplaySection 
                activeSection={QUIZ_DISPLAY_SECTIONS.PROGRAMMING} 
                data={codingData}
              />
            }
            {
              mcqData.length>0 &&
              <DisplaySection 
                activeSection={QUIZ_DISPLAY_SECTIONS.MCQ} 
                data={mcqData} 
                showMcq
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
