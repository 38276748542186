import { Average } from "src/assets/icons";
import { roundOff } from "src/pages/PdfReport/operations";
import WithToolTip from "../WithToolTip";
import { Trophy } from "src/assets/icons";
import "./TraitFillBlock.scss";

export const TraitFillBlock = ({
  fillPercentage,
  whiteSpacing = 0.3,
  color,
  blankColor,
  average,
  className = "",
  style = {},
  children,
  hideIcon = false,
  showAverageOnHover = false,
  onClick,
  ...props
}) => {
  return (
    <div
      className={`trait-fill-block-component ${className}`}
      style={{
        ...style,
        background: `linear-gradient(90deg, ${color} ${fillPercentage}%, #fff ${fillPercentage}% ${
          fillPercentage + whiteSpacing
        }%, ${blankColor ? blankColor : "#F2F2FB"} ${
          fillPercentage + whiteSpacing
        }% )`,
      }}
      onClick={() => onClick && onClick()}
      {...props}
    >
      {children}
      {props?.scoreString && (
        <div className="user-scores flex items-center ml-10">
          <Trophy />
          <span className="score-string ml-6">Scored {props.scoreString}</span>
        </div>
      )}
      {average ? (
        <>
          <div className="median" style={{ left: average + "%" }}>
            {
              <WithToolTip
                title={roundOff(average / 10)}
                showToolTip={showAverageOnHover}
              >
                <div className="line"></div>
                {!hideIcon && (
                  <span className="icon">
                    <Average />
                  </span>
                )}
              </WithToolTip>
            }
          </div>
        </>
      ) : null}
    </div>
  );
};


