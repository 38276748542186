export const listInitialState = {
  data: [],
  archived: 0,
  open: 0,
  loading: false,
};
export const positionToEditInitialState = {
  data: {},
  loading: false,
};
export const usersInitialState = {
  data: [],
  count: 0,
  totalPages: 0,
  loading: false,
};
export const positionStatsInitialState = {
  data: {},
  hasData: false,
  loading: false,
};

export const initialState = {
  list: listInitialState,
  users: usersInitialState,
  positionToEdit: positionToEditInitialState,
  positionStats: positionStatsInitialState,
};
