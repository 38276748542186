import React, { useRef } from "react";
import { Avatar, Menu } from "antd";
import "./AdminCard.scss";
import { getInitialLetters } from "src/utils/helperFunctions";
import DropdownMenu from "../DropdownMenu";
import { InviteRed, NotificationSettings, RemoveIcon } from "src/assets/icons";

export default function AdminCard(props) {
  const {
    id,
    name,
    email,
    backgroundColor,
    onDelete,
    enableDelete,
    designation,
    onResendInvite,
    adminPositions,
    setAdminData,
    sendEmailToAdmin,
    loadingToggleId,
    onEmailToggle
  } = props;
  const dropdownMenuRef = useRef();

  const usermenu = (
    <Menu>
      <Menu.Item
        className={`semibold text-delete`}
        key="invite"
        icon={<InviteRed />}
        onClick={() => {
          onResendInvite(id);
        }}
      >
        Re-send Invite
      </Menu.Item>
      {
        enableDelete && 
          <Menu.Item
          className={`semibold text-delete`}
          key="remove"
          icon={<RemoveIcon />}
          onClick={() => {
            onDelete(id);
          }}
          >
            Remove
          </Menu.Item>
      }
    </Menu>
  );

  return (
    <div className="admin-card">
      <div className="flex justify-between">
        <div className="member-details flex items-center justify-start">
          <Avatar
            className={`flex items-center mr-10 text-20 uppercase weight-600 ${
              "background-" + backgroundColor || "background-blue"
            }`}
          >
            {getInitialLetters(name)}
          </Avatar>
          <div>
            <div className="name">{name || "--"}</div>
            <div className="designation">{designation || "--"}</div>
          </div>
        </div>
        <div 
          onClick={(e) => {
            e.stopPropagation();
            dropdownMenuRef.current.toggleDropdownMenu(false)
          }} 
          className="action cursor-pointer"
        >
          <DropdownMenu ref={dropdownMenuRef} menu={usermenu} />
        </div>
      </div>
      <div className="email mb-4">{email}</div>
      <div 
        className="customize-notifications flex items-center cursor-pointer"
        onClick={() => setAdminData({ adminData: { id, adminPositions, name } })}
      >
        <NotificationSettings />
        <span className="text-16 semibold ml-4 my-6">Customise Notifications</span>
      </div>
    </div>
  );
}
