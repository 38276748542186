/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import { Dropdown, Menu, Slider } from "antd"
import { SyncOutlined } from '@ant-design/icons';
import FilterIcon from "src/components/Common/FilterIcon"
import PositionApi from "src/services/apis/positions"
import { feedbackFeelingsArray, POSITION_TYPES, ratingMarks } from "src/constants/globalConstants"
import { AntdSelectIcon, CloseIcon } from "src/assets/icons";
import { useIsMobileDevice } from "src/utils/hooks"

const { SubMenu } = Menu

const FeedbackFilters = ({ filters, updateLocation }) => {
    const [positions, setPositions] = useState([])
    const [mobileFilterVisible, setMobileFilterVisible] = useState(false)
    const [statusDropdownPlacement, setStatusDropdownPlacement] = useState('bottom')
    const [visible, setVisible] = useState(false)
    const isMobile = useIsMobileDevice()

    useEffect(() => {
        if (isMobile) {
            setStatusDropdownPlacement('top')
        } else {
            setStatusDropdownPlacement('bottom')
        }
    }, [isMobile])

    useEffect(() => {
        fetchPositionsListing()
    }, [])

    const fetchPositionsListing = async () => {
        const { HIRING, COLLEGE, DNA } = POSITION_TYPES
        const types = [HIRING, COLLEGE, DNA]

        try {
            const positions = await Promise.all([...types.map((type) => PositionApi.listPositions(null, type))])
            const allPositions = positions.reduce((acc, curr, index) => {
                if (curr.success) {
                    const mappedPositions = curr.result.map((elem) => ({
                        name: elem.name,
                        value: elem.id,
                    }))
                    acc.push(...mappedPositions)
                }
                return acc
            }, [])
            setPositions(allPositions)
        } catch (err) {
            console.error(err)
        }
    }

    const handleClick = e => {
        const [value, key] = e.keyPath
        const newFilters = { ...filters }
        if (key === "positionId") {
            if (newFilters[key] === value) {
                delete newFilters[key]
            } else {
                newFilters[key] = value
            }
            updateLocation(newFilters)
        } else if (key === "moodRatings") {
            if (newFilters[key]) {
                if (newFilters[key].includes(value)) {
                    const newValue = newFilters[key].split(",").filter(mood => mood != value).join(",")
                    if (newValue.length === 0) {
                        delete newFilters[key]
                    } else {
                        newFilters[key] = newValue
                    }
                } else {
                    const newValue = `${newFilters[key]},${value}`
                    newFilters[key] = newValue
                }
            } else {
                newFilters[key] = value
            }
            updateLocation(newFilters)
        } else if (value === "clear") {
            delete newFilters["positionId"]
            delete newFilters["moodRatings"]
            delete newFilters["ratingRange"]
            updateLocation(newFilters)
        }
    };

    const handleRangeChange = (value, key) => {
        const newFilters = { ...filters }
        if (value === "0-10") {
            delete newFilters[key]
        } else {
            newFilters[key] = value
        }
        updateLocation(newFilters)
    }

    const getAllFiltersCount = (filters) => {
        let appliedFiltersCount = 0
        if (filters?.positionId) ++appliedFiltersCount
        if (filters?.moodRatings) appliedFiltersCount += filters?.moodRatings.split(',').length
        if (filters?.ratingRange) ++appliedFiltersCount

        return (appliedFiltersCount === 0) ? "" : `(${appliedFiltersCount})`
    }

    return (
        <div className="filter-component">
            <FilterIcon
                onClick={() => setMobileFilterVisible(true)}
                className="mobile-filter-icon"
            />
            <div
                className={`background-overlay ${mobileFilterVisible ? "" : "hide"}`}
                onClick={() => setMobileFilterVisible(false)}
            ></div>
            <div className={`filters ${mobileFilterVisible ? "open" : ""}`}>
                <div className="mobile-filter-header">
                    <div className="flex items-center">
                        <FilterIcon />
                    </div>
                    <CloseIcon
                        className="close-button"
                        onClick={() => setMobileFilterVisible(false)}
                    />
                </div>
                <Dropdown
                    placement={statusDropdownPlacement}
                    trigger={"click"}
                    overlay={() => {
                        return (
                            <Menu
                                className="filter-menu-container feedbacks-menu-width"
                                onClick={handleClick}
                            >
                                <SubMenu
                                    key="positionId"
                                    title={
                                        <span>Positions {filters?.positionId ? "(1)" : ""}</span>
                                    }
                                >
                                    {
                                        positions?.map(position => <Menu.Item style={{
                                            fontWeight: filters?.positionId === position.value ? "700" : ""
                                        }} key={position.value}>{position.name}</Menu.Item>)
                                    }
                                </SubMenu>
                                <SubMenu
                                    key="moodRatings"
                                    title={
                                        <span>Sentiments {filters?.moodRatings ? `(${filters?.moodRatings?.split(',')?.length})` : ""}</span>
                                    }
                                >
                                    {
                                        feedbackFeelingsArray?.map(sentiment => <Menu.Item style={{
                                            fontWeight: filters?.moodRatings?.includes(sentiment.key) ? "700" : ""
                                        }} key={sentiment.key}>{sentiment.label}</Menu.Item>)
                                    }
                                </SubMenu>
                                <Menu.ItemGroup key="ratingRange" title="Ratings">
                                    <Menu.Item key="ratingRange">
                                        <Slider
                                            value={filters["ratingRange"]?.split("-") ?? [0, 10]}
                                            range
                                            min={0}
                                            max={10}
                                            marks={ratingMarks}
                                            defaultValue={[0, 10]}
                                            onChange={(value) => handleRangeChange(value.join("-"), "ratingRange")}
                                        />
                                    </Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.Item key="clear">
                                    <div className="flex items-center">
                                        <SyncOutlined />
                                        <span className="ml-4 mb--2">Clear All</span>
                                    </div>
                                </Menu.Item>
                            </Menu>
                        )
                    }}
                    onVisibleChange={(flag) => setVisible(flag)}
                    visible={visible}
                >
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        Filters {getAllFiltersCount(filters)}
                        <span className="ml-20">
                            <AntdSelectIcon />
                        </span>
                    </a>
                </Dropdown>
            </div>
        </div>
    )
}

export default FeedbackFilters