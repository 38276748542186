import React, { useEffect, useMemo, useRef, useState } from "react";

import { useLocation, useParams } from "react-router-dom";

import "./OnePagerReport.scss";
import PdfReportApi from "src/services/apis/pdfReport";
import TraitsGrid from "./TraitsGrid";
import FitScore from "./FitScore";
import InsightsTabs from "./InsightsTabs";
import { generateList } from "./oprations";
import { Mixpanel } from "src/services/mixpanel";
import { roundOff } from "../PdfReport/operations";
import ReportHeader from "./Header";
import V2 from "./V2";
import QuestionsSection from "./QuestionsSection";
import CandidateReport from "./CandidateReport";
import { getTraitsMap } from "src/utils/helperFunctions";

export const OnePagerReport = () => {
  const location = useLocation();
  const { userId, linkId } = useParams();
  const [reportData, setReportData] = useState(null);
  const [traitsMap, setTraitsMap] = useState(new Map());
  const [activeTrait, setActiveTrait] = useState(null);
  const [showBucketWise, setShowBucketWise] = useState(false);
  const hoverDelayRef = useRef();

  const getTraitsOfBucket = (bucket) => {
    const traits = new Map();
    const list = Object.values(bucket.traitsMeta);
    list.forEach((item) => {
      const {
        id,
        displayName: name,
        upperBound: { score: ub },
        lowerBound: { score: lb },
        ...rest
      } = item;
      traits.set(id, {
        ...rest,
        id,
        name,
        ub,
        lb,
      });
    });
    return traits;
  };

  const handleTraitHover = (id) => {
    if (id) {
      const refId = setTimeout(() => setActiveTrait(id), 400);
      hoverDelayRef.current = refId;
    } else {
      clearTimeout(hoverDelayRef.current);
      setActiveTrait(null);
    }
  };

  const logMixpanelEvent = (event) => {
    // Mixpanel Event
    Mixpanel.track(event, {
      "Candidate Name": reportData?.user?.name || reportData?.user?.email,
      "Position Title": reportData?.position?.name,
      Company: reportData?.position?.organizationName,
      Type: reportData?.position?.type,
      "Candidate Fit Score": roundOff(reportData.fitScore.score),
    });
  };

  const isNonDnaReport = useMemo(() => {
    return !!reportData?.position?.type && reportData?.position?.type !== "dna";
  }, [reportData]);

  useEffect(() => {
    async function fetchReportData() {
      const res = await PdfReportApi.getUserScore(userId, linkId);
      if (res.success) {
        const { data } = res;
        const drivers = generateList(data.drivers);
        const derailers = generateList(data.derailers);
        const traitsMap = getTraitsMap(data.buckets);
        setTraitsMap(traitsMap);
        setReportData({ ...data, drivers, derailers });
        if (
          data.position?.rootPosition &&
          data.position?.rootPosition.toLowerCase() === "mba"
        ) {
          setShowBucketWise(true);
        }
      } else setReportData(null);
    }
    fetchReportData();
  }, []);

  if (location.pathname.includes("/report/v2/")) {
    return (
      <V2
        reportData={reportData}
        // traitsMap={traitsMap}
        isNonDnaReport={isNonDnaReport}
        logMixpanelEvent={logMixpanelEvent}
      />
    );
  }

  if (location.pathname.includes("/report/v2-traits/")) {
    return (
      <QuestionsSection
        reportData={reportData}
        isNonDnaReport={isNonDnaReport}
        logMixpanelEvent={logMixpanelEvent}
      />
    );
  }

  if (location.pathname.includes("/candidate-report/")) {
    return (
      <CandidateReport
        reportData={reportData}
        isNonDnaReport={isNonDnaReport}
        logMixpanelEvent={logMixpanelEvent}
      />
    );
  }

  return (
    <div id="one-page-report-component">
      <div className="info">
        <ReportHeader
          reportData={reportData}
          isNonDnaReport={isNonDnaReport}
          logMixpanelEvent={logMixpanelEvent}
        />
        <div className="content-wrapper">
          <div className="max-width-left">
            <div>
              <FitScore
                {...reportData?.fitScore}
                showLegends={isNonDnaReport}
              />
              {showBucketWise &&
                reportData?.buckets?.map((bucket) => (
                  <TraitsGrid
                    key={bucket.id}
                    showBucketWise={showBucketWise}
                    title={bucket.name}
                    description={bucket.description}
                    traitsMap={getTraitsOfBucket(bucket)}
                    activeTrait={activeTrait}
                    onTraitHover={handleTraitHover}
                  />
                ))}
              {!showBucketWise && (
                <>
                  <TraitsGrid
                    showRelevent
                    title="Role Relevant Traits"
                    description="These workplace traits are essential for a person to be a good fit for this role and to understand their potential."
                    traitsMap={traitsMap}
                    activeTrait={activeTrait}
                    onTraitHover={handleTraitHover}
                  />
                  <TraitsGrid
                    title="Other traits"
                    description="These workplace traits can help you to understand other essential aspects of candidate's behaviour and potential."
                    traitsMap={traitsMap}
                    activeTrait={activeTrait}
                    onTraitHover={handleTraitHover}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="unberry-insights">
        <div className="header">
          <h2 className="title max-width-right">Unberry insights</h2>
        </div>
        <div className="content-wrapper">
          <div className="max-width-right">
            <InsightsTabs
              traitsMap={traitsMap}
              activeTrait={activeTrait}
              standOutTraits={reportData?.standOutTraits ?? []}
              probeTraits={reportData?.probeTraits ?? []}
              drivers={reportData?.drivers ?? []}
              derailers={reportData?.derailers ?? []}
              onTraitHover={handleTraitHover}
              logMixpanelEvent={logMixpanelEvent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
