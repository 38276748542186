import React from "react";
import { Switch as AntSwitch } from "antd";
import "./Switch.scss";

const Switch = (props) => {
  const { form, field, disabled } = props;
  return (
    <AntSwitch
      checked={form.value}
      disabled={disabled}
      onClick={(checked) => form.setFieldValue(field.name, checked)}
    />
  );
};

Switch.propTypes = {};

export default Switch;
