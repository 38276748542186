import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./constants";
import { setWalletCredits } from "./asyncActions";

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [setWalletCredits.fulfilled]: (state, action) => {
      state.credits = action.payload;
      state.fetchWallet = false;
    },
  },
});

export const { reset } = walletSlice.actions;
export default walletSlice.reducer;
