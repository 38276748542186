import { Collapse } from "antd";
import { storage } from "src/services/config/storage";
import { Avatar } from "antd";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import "./CollapseMenu.scss";
import { convertToStyle } from "src/pages/OnePagerReport/oprations";
import { roundOff } from "src/pages/PdfReport/operations";
import { Link } from "react-router-dom";
import {
  getDefaultFormattedDate,
  getInitialLetters,
  getRelativeTimeFormat,
} from "src/utils/helperFunctions";
import { FIT_BANDS } from "src/pages/OnePagerReport/constant";
import { useEffect } from "react";
import { Arrow } from "src/assets/icons";

const { Panel } = Collapse;

export default function CollapseMenu({ categories, defaultDrawerTab }) {
  useEffect(() => {
    const drawerBody = document.querySelector('.drawer-content-wrapper').parentElement;
    const tab = document.querySelector(`.category-${defaultDrawerTab}`);
    if (drawerBody && tab) {
      const offset = 30;
      const top = tab.getBoundingClientRect().top - offset;
      drawerBody.scrollTo({ top: top, behavior: "smooth" });
    }
  }, [defaultDrawerTab]);

  return (
    <Collapse
      defaultActiveKey={[0, 1, 2, 3]}
      className="collapse-container"
      expandIconPosition="right"
      expandIcon={({ isActive }) => {
        return (
          <div className={`collapse-arrow ${isActive ? "rotate" : ""}`}>
            <Arrow color="#000" />
          </div>
        );
      }}
    >
      {categories
        ? Object.values(categories).map((candidateList, index) => {
            const band = Object.values(FIT_BANDS)[index];
            return (
              <Panel
                className={`header category-${index}`}
                style={convertToStyle(band)}
                expandIcon
                header={
                  <div className="header-content">
                    <div className="flex items-center justify-start">
                      <h2
                        className="text-16 bold"
                        style={{ color: band.textColor }}
                      >
                        {band.dnaLabel}
                      </h2>
                      <span
                        className="ml-8 text-16 semiBold flex items-center justify-center"
                        style={{ backgroundColor: "#ffffff66" }}
                      >
                        {candidateList.length}
                      </span>
                    </div>
                    <h3
                      className="subHeading"
                      style={{ color: band.textColor }}
                    >
                      {band.description}
                    </h3>
                  </div>
                }
                key={index}
              >
                {candidateList.map((list, index) => {
                  return (
                    <div
                      key={index}
                      className="container candidate flex items-center justify-between"
                    >
                      <div className="content-wrapper flex items-center">
                        <Avatar
                          className={`avatar flex items-center mr-10 text-23 weight-600`}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#F7A261" : "#B448DB",
                            textTransform: list.name ? "capitalize" : "none",
                          }}
                        >
                          {getInitialLetters(list.name || list.email)}
                        </Avatar>
                        <Link
                          to={() =>
                            list.linkId && list.userId
                              ? `/report/v2/link/${list.linkId}/users/${list.userId}`
                              : "#"
                          }
                        >
                          <p className="text-16 semiBold">
                            {list.name || list.email}
                          </p>
                          <p className="text-11 text-primary-color semiBold">
                            completed on :{" "}
                            {list?.completedDate
                              ? `${getDefaultFormattedDate(
                                  list.completedDate,
                                  "DD MMM, YYYY"
                                )} • ${getRelativeTimeFormat(
                                  list.completedDate
                                )}`
                              : "--"}
                          </p>
                        </Link>
                      </div>
                      <p className="text-20 semiBold text-theme-color">
                        {roundOff(list.fitScore)}
                      </p>
                    </div>
                  );
                })}
              </Panel>
            );
          })
        : null}
    </Collapse>
  );
}
