import { reset as organizationReset } from "src/store/Organizations/reducer";
import { reset as dnaReset } from "src/store/DNA/reducer";
import { reset as positionReset } from "src/store/Positions/reducer";
import { reset as walletReset } from "src/store/wallet/reducer";
import { reset as feedbackReset } from "src/store/Feedbacks/reducer"
import { useDispatch } from "react-redux";

export const useResetStore = () => {
  const dispatch = useDispatch();
  const reset = () => {
    dispatch(organizationReset());
    dispatch(dnaReset());
    dispatch(positionReset());
    dispatch(walletReset());
    dispatch(feedbackReset())
  };
  return reset;
};
