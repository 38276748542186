import React from "react";
import "./BlankState.scss";

export default function BlankState(props) {
  const { message = "", className = "", onClick, showAddIcon = true } = props;
  return (
    <div
      className={`blank-state flex flex-col ${showAddIcon ? "cursor-pointer" : ""} ${className}`}
      onClick={onClick}
    >
      {
        showAddIcon ? <p className="text-49">+</p> : <></>
      }
      {message.split("\n").map((string) => (
        <p className="text-center text-24 weight-600">{string}</p>
      ))}
    </div>
  );
}
