import React from 'react';
import './NoteBox.scss';

export default function NoteBox(props) {
  const { importantText, text, className } = props;
  return (
    <div className={`note-box py-10 px-20 flex items-center ${className}`}>
      <img
        src="/img/light.png"
        alt="light-bulb"
        className="width-14 mr-10"
      />
      <p className="text-grey weight-400 size-11">
        <span className="text-black"> {importantText}</span>{' '}
        {text.split('>').map((string) => (
          <p className="size-11">{string}</p>
        ))}
      </p>
    </div>
  );
}
