import React from 'react';
import Text from '../Text';
import TraitScore from '../TraitScore/TraitScore';

import './Bucket.scss';

export default function Bucket(props) {
  const { className = '', name, description, icon, colorCode, traits } = props;
  return (
    <div className={`bucket ${className}`}>
      <div className="icon-heading">
        <img src={icon} alt="fit-band-info" className="img-icon" />
        <div>
          <h2 className="mt-0 size-31 weight-500">{name}</h2>
          <Text className="size-13 weight-400" text={description} />
        </div>
      </div>
      <div className="traits">
        {traits.map((trait) => {
          return (
            <TraitScore
              name={trait.displayName}
              colorCode={colorCode}
              lowerBound={trait.lowerBound}
              upperBound={trait.upperBound}
              score={trait.score ?? 0}
              median={trait.median}
            />
          );
        })}
      </div>
    </div>
  );
}
