import { useState } from "react";
import { useEffect } from "react";
import {
    VideoCameraOutlined,
    InfoCircleFilled
} from '@ant-design/icons';
import SnapshotsContainer from "./SnapshotsContainer";
import ProfileContainer from "./ProfileContainer";
import VideoPlayer from "./VideoPlayer";
import Modal from "src/components/Common/Modal";
import Tab from "src/components/Common/Tab";
import PdfReportApi from "src/services/apis/pdfReport";
import { useIsMobileDevice } from "src/utils/hooks";
import { MEDIA_PROCTORING_MODAL_TABS } from "src/constants/globalConstants";
import "./VideoModal.scss"

const ProctoredMediaModal = ({
    isVideoModalVisible,
    setIsVideoModalVisible,
    videoSessionsList,
    linkConfig,
    positionConfig,
}) => {
    const [sessionVideoUrls, setSessionVideoUrls] = useState([])
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [seekValue, setSeekValue] = useState(0)
    const [isSessionVideoEnded, setIsSessionVideoEnded] = useState(false)
    const [canPlayVideoLoading, setCanPlayVideoLoading] = useState(false)
    const [currentSession, setCurrentSession] = useState("")
    const [currentTab, setCurrentTab] = useState("")
    const [sessionSnapshots, setSessionSnapshots] = useState([])
    const [sessionCriticalSnapshots, setSessionCriticalSnapshots] = useState([])
    const [currentSnapshotsFilter, setCurrentSnapshotsFilter] = useState("all")
    const [sessionProfile, setSessionProfile] = useState({})
    const isMobile = useIsMobileDevice()

    useEffect(() => {
        if (videoSessionsList?.length > 0) {
            setCurrentSession(videoSessionsList[0])
            setCurrentTab(MEDIA_PROCTORING_MODAL_TABS.SNAPSHOTS)
        }
    }, [videoSessionsList])

    useEffect(() => {
        (async () => {
            if (isVideoModalVisible) {
                if (currentSession?.id) {
                    if (currentTab === MEDIA_PROCTORING_MODAL_TABS.SNAPSHOTS && currentSession?.isProctored) {
                        fetchSessionSnapshots()
                    } else if (currentTab === MEDIA_PROCTORING_MODAL_TABS.AV_RECORDING) {
                        resetVideoPlayer()
                        fetchSessionVideos()
                    } else if (currentTab === MEDIA_PROCTORING_MODAL_TABS.PROFILE) {
                        fetchSessionProfile()
                    }
                }
            }
        })()
    }, [currentTab, currentSession, isVideoModalVisible])

    const resetVideoPlayer = () => {
        setSeekValue(0)
        setCurrentVideoIndex(0)
        setIsPlaying(false)
        setIsSessionVideoEnded(false)
        setCanPlayVideoLoading(false)
    }

    const fetchSessionVideos = async () => {
        try {
            setLoading(true)
            const response = await PdfReportApi.getSessionVideos(currentSession?.id)
            if (response.success) {
                setSessionVideoUrls(response.data)
            }
        } catch (err) {

        } finally {
            setLoading(false)
        }
    }

    const fetchSessionSnapshots = async () => {
        try {
            setLoading(true)
            const response = await PdfReportApi.getSessionSnapshots(currentSession?.id)
            if (response.success) {
                setSessionSnapshots(response.data)
                setSessionCriticalSnapshots(response.data.filter(snap => snap?.criticalReason?.length > 0))
            }
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }

    const fetchSessionProfile = async () => {
        try {
            setLoading(true)
            const response = await PdfReportApi.getSessionProfile(currentSession?.id)
            if (response.success) {
                setSessionProfile(response.data)
            }
        } catch (err) {
            setSessionProfile(null)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            modalVisible={isVideoModalVisible}
            cancelModal={() => {
                setSeekValue(0)
                setCurrentVideoIndex(0)
                setIsPlaying(false)
                setLoading(true)
                setIsSessionVideoEnded(false)
                setCurrentSession(videoSessionsList[0])
                setCurrentTab(MEDIA_PROCTORING_MODAL_TABS.SNAPSHOTS)
                setIsVideoModalVisible(false)
            }}
            wrapClassName='snapshots-wrap'
            className='snapshots-modal media-proctoring-modal'
        >
            <p className="text-24 weight-700">Proctoring Details</p>
            {
                linkConfig?.proctoring === false
                    && linkConfig?.saveProctoredVideo === false
                    && positionConfig?.saveProctoredVideo
                    ? <div className="flex items-center">
                        <span className="flex items-center config-msg-icon">
                            <InfoCircleFilled style={{ color: '#d24444' }} className="text-18" />
                        </span>
                        <p className={`text-12 color-black ml-4 letter-spacing-3 config-msg `}>
                            <strong>Video Proctoring</strong> disabled by admin for this user
                        </p>
                    </div>
                    : <></>
            }
            <div className={`h-full mt-8 flex flex-col items-center`}>
                <div className="w-full flex justify-start mt-4 mb-12 scroll-x">
                    <Tab
                        currentKey={currentSession?.id}
                        data={videoSessionsList.map((session, index) => {
                            return {
                                name: `Session ${index + 1}`,
                                key: session?.id,
                            }
                        })}
                        onClick={(sessionId) => {
                            const session = videoSessionsList?.find(session => session?.id === sessionId)
                            setCurrentSession(session)
                        }}
                    />
                </div>
                <div className={`mt-4 mb-12 ${isMobile ? "flex-col" : ""} flex justify-center  w-full`}>
                    <div className={`${isMobile ? "flex flex-col" : ""}`}>
                        {
                            Object.values(MEDIA_PROCTORING_MODAL_TABS).slice(0, 2)
                                .map(mediaTab =>
                                    <button
                                        key={mediaTab}
                                        className={`filter-btn b-20 mr-5 ${currentTab === mediaTab ? 'selected-filter-btn text-white' : ''} txt-14 weight-600 ${isMobile ? "mt-4 mb-4" : ""}`}
                                        onClick={() => setCurrentTab(mediaTab)}
                                    >
                                        {mediaTab}
                                    </button>
                                )
                        }
                    </div>
                    <button
                        className={`filter-btn b-20 ${currentTab === MEDIA_PROCTORING_MODAL_TABS.AV_RECORDING ? 'selected-filter-btn text-white' : 'entire-video-btn-shadow'} txt-14 weight-600 ${isMobile ? "mt-4" : "entire-video-btn"}`}
                        onClick={() => setCurrentTab(MEDIA_PROCTORING_MODAL_TABS.AV_RECORDING)}
                    >
                        <VideoCameraOutlined className="mr-8" />
                        See Entire Video
                    </button>
                </div>
                <div className="h-full flex flex-col items-center">
                    {
                        currentTab === MEDIA_PROCTORING_MODAL_TABS.SNAPSHOTS
                            ? currentSession?.isProctored
                                ? <SnapshotsContainer
                                    loading={loading}
                                    isParentSnapshotsModal={false}
                                    allSnapshots={sessionSnapshots}
                                    criticalSnapshots={sessionCriticalSnapshots}
                                    currentFilter={currentSnapshotsFilter}
                                    setCurrentFilter={setCurrentSnapshotsFilter}
                                />
                                : <MediaNotProctored
                                    isMobile={isMobile}
                                    message={"This session is getting proctored right now, please check after a while."}
                                />
                            : currentTab === MEDIA_PROCTORING_MODAL_TABS.PROFILE
                                ? sessionProfile === null
                                    ? <MediaNotProctored
                                        isMobile={isMobile}
                                        message={"Profile photo was not available for proctoring."}
                                    />
                                    : <ProfileContainer
                                        sessionProfile={sessionProfile}
                                        loading={loading}
                                    />
                                : <VideoPlayer
                                    loading={loading}
                                    setLoading={setLoading}
                                    sessionVideoUrls={sessionVideoUrls}
                                    currentVideoIndex={currentVideoIndex}
                                    setCurrentVideoIndex={setCurrentVideoIndex}
                                    seekValue={seekValue}
                                    setSeekValue={setSeekValue}
                                    isPlaying={isPlaying}
                                    setIsPlaying={setIsPlaying}
                                    isSessionVideoEnded={isSessionVideoEnded}
                                    setIsSessionVideoEnded={setIsSessionVideoEnded}
                                    canPlayVideoLoading={canPlayVideoLoading}
                                    setCanPlayVideoLoading={setCanPlayVideoLoading}
                                />
                    }
                </div>
            </div>
        </Modal >
    )
}

export default ProctoredMediaModal

const MediaNotProctored = ({ isMobile, message = "" }) => <p className={`${!isMobile ? "mt-100 text-24 weight-600" : "text-16 weight-500 mt-50"}`}>
    {message}
</p>
