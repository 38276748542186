import { UnberryApi } from "../config/request";
import { POSITION_ARCHIVE_STATUS, POSITION_TYPES as POSITION_STATUS } from "src/pages/Positions/constants";
import { POSITION_TYPES } from "src/constants/globalConstants";
import { filterOperations } from "src/utils/helperFunctions";

const PositionApi = {
  getBasePositions() {
    return UnberryApi.safeCall(`/api/position/v1/base-positions`, "GET", {
      params: { organizationType: ":orgType", organizationId: ":orgId" },
    });
  },

  getPosition(positionId) {
    return UnberryApi.safeCall(`/api/position/v1/${positionId}`, "GET");
  },
  updatePosition({ positionId, payload, orgId, orgType }) {
    return UnberryApi.safeCall(
      `/api/position/v1/${positionId}?organizationId=${orgId}&organizationType=${orgType}`,
      "PUT",
      { data: payload }
    );
  },

  createPosition(payload, type = POSITION_TYPES.DNA) {
    return UnberryApi.safeCall(
      `/api/position/v1/:orgType/:orgId/create-child`,
      "POST",
      { data: { ...payload, positionType: type } }
    );
  },

  listPositions(status = POSITION_STATUS.OPEN, type = POSITION_TYPES.HIRING, sort = '') {
    return UnberryApi.safeCall(
      `/api/position/v1/:orgType/:orgId/positions`,
      "GET",
      { params: { type, ...(status !== null && { status }), ...(sort !== '' && { sort }) } }
    );
  },

  getAllUsersByPosition(positionId, filters = {}) {
    filters = filterOperations(filters, "searchKey");
    return UnberryApi.safeCall(
      `/api/position/v1/:orgType/:orgId/${positionId}/users`,
      "GET",
      { params: filters }
    );
  },
  inviteUser(payload) {
    return UnberryApi.safeCall(`/api/link/v1/:orgType/:orgId`, "POST", {
      data: payload,
    });
  },
  inviteThirdPartyUser(payload) {
    return UnberryApi.safeCall(`/api/link/v1/:orgType/:orgId/third-party/invite`,
      "POST",
      { data: payload });
  },

  deletePosition(positionId) {
    return UnberryApi.safeCall(`/api/position/v1/${positionId}`, "DELETE");
  },
  updatePositionStatus({ positionId, orgId, orgType, payload }) {
    return UnberryApi.safeCall(
      `/api/position/v1/status/${positionId}?organizationId=${orgId}&organizationType=${orgType}`,
      "PUT",
      { data: payload }
    );
  },
  archievePosition(positionId, status = POSITION_ARCHIVE_STATUS.CLOSE) {
    return UnberryApi.safeCall(
      `/api/position/v1/archive/${positionId}`,
      "PUT",
      {
        params: {
          organizationType: ":orgType",
          organizationId: ":orgId",
          status,
        },
      }
    );
  },

  getPositionStats(positionId) {
    return UnberryApi.safeCall(
      `/api/position/v1/:orgType/:orgId/${positionId}/stats`,
      "GET"
    );
  },

  getPositionConfig(idType = "positionId", id) { // idType -> linkId or positionId
    return UnberryApi.safeCall(
      `/api/position/v1/position-config?${idType}=${id}`,
      "GET",
      { 'Content-type': 'json' }
    );
  },

  getIndustries() {
    return UnberryApi.safeCall(`/api/industry/v1/`, "GET");
  },

  getTraits(params) {
    return UnberryApi.safeCall(`/api/reporting/v1/dna/trait-and-score`, "GET", {
      params,
    });
  },

  getContraints(positionId, forPosition) {
    const params = {};
    if (forPosition) params.forPosition = forPosition;
    return UnberryApi.safeCall(`/api/position-band/v1/${positionId}`, "GET", {
      params: {
        ...params,
        organizationType: ":orgType",
        organizationId: ":orgId"
      },
    });
  },

  getDnaPerformance(params) {
    return UnberryApi.safeCall(`/api/reporting/v1/performance`, "GET", {
      params,
    });
  },

  /**
  * Usage - Settings Tab, granting access to positions for user. 
  */
  updateAssignedPositionsForUser(userId, payload) {
    return UnberryApi.safeCall(`/api/pointOfContact/v1/update-assigned-positions/${userId}`,
      "POST",
      { data: payload, params: { organizationType: ":orgType", organizationId: ":orgId" } });
  },

  /** Invite Scheduling APIs */
  createScheduleInvite(payload) {
    return UnberryApi.safeCall(`/api/link/v1/:orgType/:orgId/schedule-invite`, "POST", {
      data: payload,
    });
  },
  deleteScheduledInvite(payload) {
    return UnberryApi.safeCall(`/api/link/v1/:orgType/:orgId/cancel-scheduled-invite`, "POST", {
      data: payload,
    });
  },
  getScheduledInvite(positionId) {
    return UnberryApi.safeCall(`/api/position/v1/:orgType/:orgId/${positionId}/scheduled-invite`, "GET");
  },
  getPositionAnalytics(positionId) {
    return UnberryApi.safeCall(`/api/position/v1/${positionId}/dashboard`, "GET");
  },
  getPositionTraitAnalytics(positionId, query = "") {
    let url = `/api/position/v1/${positionId}/dashboard/traits`
    if (query) url += `?${query}`
    return UnberryApi.safeCall(url, "GET");
  },
  getPositionQuestionAnalytics(positionId, query = "") {
    let url = `/api/position/v1/${positionId}/dashboard/questions`
    if (query) url += `?${query}`
    return UnberryApi.safeCall(url, "GET");
  }
};

export default PositionApi;
