export const basePositions = {
  data: [],
  loading: false,
};

export const dnaPositions = {
  data: [],
  loading: true,
};

export const usersByPosition = {
  users: [],
  count: 0,
  totalPages: 0,
  loading: false,
};

export const dnaStats = {
  data: {},
  hasData: false,
  loading: false,
};

export const initialState = {
  basePositions,
  dnaPositions,
  usersByPosition,
  dnaStats,
};
