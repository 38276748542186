import React, { useState } from "react";
import { Button } from "antd";
import { AntInput } from "../../components/Common/CreateAntFields/CreateAntFields";
import { Field, Formik } from "formik";
import { initialValues } from "./constants";
import { validationSchema } from "./validate";
import Modal from "src/components/Common/Modal";
import "./AdminForm.scss";
import { AddIcon } from "src/assets/icons";

export default function LoginForm(props) {
  const { disable, onSubmit, isModalActive, setIsModalActive } = props;
  const [formSchema, setFormSchema] = useState(initialValues);
  return (
    <>
      <Button
        className="ant-btn-primary action-btn pointer"
        onClick={() => setIsModalActive(true)}
        icon={<AddIcon />}
      // disabled={disable}
      >
        Invite a member
      </Button>

      <Modal
        modalVisible={isModalActive}
        cancelModal={() => {
          setFormSchema(initialValues);
          setIsModalActive(false);
        }}
        centered
        className="invite-candidate-modal"
        width={350}
      >
        <div className="admin-form text-content-block mb-30">
          <h2 className="text-22 semibold">Invite a Member</h2>
        </div>

        <Formik
          initialValues={formSchema}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(values, setIsModalActive);
          }}
          enableReinitialize
        >
          {({ values, handleSubmit }) => {
            return (
              <>
                <div className="email-field mb-40">
                  <div className="mb-20">
                    <Field
                      component={AntInput}
                      type="text"
                      value={values.name}
                      name={"name"}
                      label={"Name"}
                      hasFeedback
                      onPressEnter={handleSubmit}
                    />
                  </div>
                  <div className="mb-20">
                    <Field
                      component={AntInput}
                      type="text"
                      value={values.email}
                      name={"email"}
                      label={"Email"}
                      hasFeedback
                      onPressEnter={handleSubmit}
                    />
                  </div>
                  {/* <div className="mb-20">
                    <Field
                      component={AntInput}
                      type="text"
                      value={values.phone}
                      name={"phone"}
                      label={"Phone number"}
                      hasFeedback
                      onPressEnter={handleSubmit}
                    />
                  </div> */}
                  <div className="item mb-20">
                    <Field
                      component={AntInput}
                      type="text"
                      value={values.customText}
                      name={"customText"}
                      label={"Designation"}
                      hasFeedback
                      onPressEnter={handleSubmit}
                    />
                  </div>
                </div>
                <Button
                  className="ant-btn-primary width-full"
                  onClick={handleSubmit}
                >
                  Send invitation
                </Button>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
