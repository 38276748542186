import React, { useMemo } from "react";
import { isObject, roundOff } from "src/utils/helperFunctions";
import { QUESTIONS, QUIZ_DISPLAY_SECTIONS, TEST_CASE_STATUS } from "../../constant";

export const SectionHeaderOverview = (props) => {
  const { activeSection, data } = props;

  const getScoreAndQuestionCount = (data) => {
    // mcqQuestionCount -> Increment and change section heading accordingly
    // isSpeechQuestion || isTypingText || isFreeText || isSentenceCompletion -> Exclude on these Q's
    let maxScore = 0, obtainedScore = 0, mcqQuestionCount=0;
    const questionsCount = data?.length;
    if (activeSection === QUIZ_DISPLAY_SECTIONS.MCQ) {
      data.forEach((elem) => {
        const { question, options, answer, traits } = elem;
        let traitsArray = [];
        for (const entry of traits.values()) {
          traitsArray.push(entry);
        }
        /** TODO: REMOVE POST SANITY  */

        // const isSpeechQuestion = question.type.includes(QUESTIONS.SPEECH);
        // const isTypingText = question.type.includes(QUESTIONS.TYPING_TEXT);
        // const isFreeText = question.type.includes(QUESTIONS.FREE_TEXT);
        // const isSentenceCompletion = question.type.includes(QUESTIONS.SENTENCE_COMPLETION);
        // let chosenAnswer = isSpeechQuestion ? null
        //   : options.find((option) => option?.id === answer?.optionId && option?.correct);
        // if (chosenAnswer) {
        //   obtainedScore += chosenAnswer?.weight && chosenAnswer?.weight >= 0 ?
        //     chosenAnswer?.weight : 1;
        // }
        // // Check for score objects in the following cases
        // // isTypingText, isFreeText, isSpeechQuestion (speech spontaneous)
        // // Otherwise score remains a number
        // if (isSpeechQuestion || isTypingText || isFreeText || isSentenceCompletion) {
        //   const isScoreObject = isObject(answer?.score);
        //   if (!isScoreObject) obtainedScore += (answer?.score || 0);
        //   else obtainedScore += (answer.score?.userScore || 0);
        // }
        /** TODO: REMOVE POST SANITY  */
        obtainedScore += traitsArray.reduce((sum, trait) => sum + trait.userScore, 0)/traitsArray.length;
        obtainedScore = (Math.round(obtainedScore * 100) / 100)
        maxScore += traitsArray.reduce((sum, trait) => sum + trait.maxScore, 0)/traitsArray.length;
      });
    } else {
      data.forEach((elem) => {
        const {
          score: { userScore, maxScore: codingMaxScore },
          submission: { testcaseResults }, traits } = elem;
        let traitsArray = [];
        for (const entry of traits.values()) {
          traitsArray.push(entry);
        }
        if (codingMaxScore) {
          maxScore += codingMaxScore;
          obtainedScore += traitsArray.reduce((sum, trait) => sum + trait.userScore, 0)/traitsArray.length;
        } else {
          maxScore += testcaseResults.reduce((acc, { points }) => acc + points, 0);
          obtainedScore = testcaseResults.reduce((acc, { points, status }) =>
            acc + (status === TEST_CASE_STATUS.FAIL ? 0 : points), 0);
        }
      });
    }
    obtainedScore = roundOff(obtainedScore).toString().padStart(2, '0');
    maxScore = roundOff(maxScore).toString().padStart(2, '0');
    return [obtainedScore, maxScore, questionsCount, mcqQuestionCount];
  };

  const [obtainedScore, maxScore, questionsCount, mcqQuestionCount] = useMemo(
    () => getScoreAndQuestionCount(data), [data]);

  return (
    <div className="content">
      <h2>
        {activeSection === QUIZ_DISPLAY_SECTIONS.MCQ ? 
          'Questions' : `Programming Questions`
        }
      </h2>
      <div className="vertical-spacer"></div>
      <div className="overview">
        <p>
          {`Total Questions `}
          <span className="highlighted">{`(${questionsCount})`}</span>
          <span className="dot"></span>
          {` Total Score `}
          <span className="highlighted">{`(${obtainedScore}/${maxScore})`}</span>
        </p>
      </div>
    </div>
  );
};