import { Button } from "antd";
import { useState, useEffect } from "react";
import Modal from "src/components/Common/Modal";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import AuthApi from "src/services/apis/auth";
import VerifyOtpForm from "../VerifyOtpForm";
import LoginForm from "../LoginForm/form";
import { Mixpanel } from "src/services/mixpanel";
import { MIXPANEL_EVENTS, REFRESH_SESSION_FORM_VIEW } from "src/constants/globalConstants";
import { storage } from "src/services/config/storage";
import { updateFCData } from "src/services/freshchat";

import './style.scss';

const REFRESH_STATUS_SCREENS = {
  SHOW_INFO: "SHOW_INFO",
  EMAIL: "EMAIL",
  OTP: "OTP",
};

const RefreshSessionForm = ({ refreshSession }) => {
  const { SHOW_INFO, EMAIL, OTP } = REFRESH_STATUS_SCREENS;
  const { REFRESH_TOKEN, OTP_LOGIN } = REFRESH_SESSION_FORM_VIEW;
  const [screenStatus, setScreenStatus] = useState(SHOW_INFO);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  // Skip user manual login if email already exists in LS
  useEffect(() => {
    const userInfo = storage.get.userInfo();
    if (!userInfo) return;
    const { email } = userInfo;
    if (email) {
      setEmail(email);
    }
  }, []);

    /**
   * validates the OTP
   * @param {*} OTP
   */
  const handleOTPVerification = async ({ otp }) => {
    try {
      const response = await AuthApi.verifyOtp({
        email,
        otp,
        route: AUTH_CONSTANTS.USER_TYPE_ADMIN,
      });

      // Mixpanel Event
      Mixpanel.track(MIXPANEL_EVENTS.EnteredOTP, {
        OTP: otp,
        Status: !!response?.success,
      });

      if (response?.success) {
        const { token, refreshToken, user } = response;
        // set token in localstorage
        storage.set.authToken(token);
        storage.set.refreshToken(refreshToken);
        storage.set.decodedToken(token);
        storage.set.userInfo(user);

        // update Freshchat user details
        // updateFCData(user);

        // Set Mixpanel Identity
        Mixpanel.alias(user.id);
        Mixpanel.identify(user.id);
        Mixpanel.people.set({
          $email: user.email,
          $name: user.name,
          Phone: user.phone,
          Designation: user.designation,
        });
        window.location.reload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  // Skip user manual login if email already exists in LS
  const handleEmailCheck = () => {
    if (email) {
      handleLogin({ email })
    } else setScreenStatus(EMAIL);
  };

  const handleLogin = async (values, resend = false) => {
    values.route = AUTH_CONSTANTS.USER_TYPE_ADMIN;
    try {
      const response = await AuthApi.sendOtp(values);

      // Mixpanel Event
      if (resend)
        Mixpanel.track(MIXPANEL_EVENTS.ResendOTP, { Status: response.success });
      else {
        Mixpanel.track(MIXPANEL_EVENTS.EnteredEmail, {
          "Email ID": values.email,
          Status: response.success,
        });
      }

      if (response.success) {
        setOtp(response.otp);
        setEmail(values.email);
        setScreenStatus(OTP);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  useEffect(() => {
    if (refreshSession) {
      const modalWrapClass = document.querySelector('.ant-modal-wrap');
      modalWrapClass.style.setProperty('z-index', '1200', 'important');
    }
  }, [refreshSession]);

  return (
    <div className="screen-only refresh-form">
      <Modal
        closable={false}
        title={null}
        modalVisible={refreshSession}
        className="refresh-signup-modal"
        destroyOnClose
      >
        <div className="refresh-signup-form-container">
          <div className={`second-half ${refreshSession === REFRESH_TOKEN ? "refresh" : ""} flex flex-col`}>
            <div className="login-form flex flex-col relative">
              {
                refreshSession === REFRESH_TOKEN ?
                <div className="info-screen flex items-center">
                  <span className="loader"></span>
                  <p className={`title ${refreshSession === REFRESH_TOKEN ? "refresh" : ""} flex items-center ml-20`}>
                    Please hold on for a moment
                  </p>
                  {/* <p className="message">Don't worry, we kept all your data in place.</p> */}
                </div>  :
                refreshSession === OTP_LOGIN ? (
                  screenStatus === SHOW_INFO ? (
                    <div className="info-screen flex flex-col items-center">
                      <p className="title">Your session has expired.</p>
                      <p className="message">Don't worry, we kept all your data in place.</p>
                      <Button 
                        className="ant-btn-primary mt-20"
                        onClick={handleEmailCheck}>
                        Continue to login
                      </Button>
                    </div>
                  ) : screenStatus === OTP ? (
                    <>
                      <VerifyOtpForm
                        email={email}
                        onSubmit={handleOTPVerification}
                        otp={process.env.REACT_APP_ENV === "development" && otp}
                        resendOTP={(values) => handleLogin(values, true)}
                        showOtpForm={() => setScreenStatus(EMAIL)}
                        pushToLoginRoute
                      />
                    </>
                  ) : screenStatus === EMAIL ? (
                    <LoginForm onSubmit={handleLogin} />
                  ) : null
                ) : null
              }
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
};

export default RefreshSessionForm;