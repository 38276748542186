import React, { useCallback, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  copyTextToClipboard,
  generateCompanyDataCsv,
  getDefaultFormattedDate,
  getPositionSignupLink,
  getRelativeTimeFormat,
  getTextColor,
  isObject,
} from "src/utils/helperFunctions";
import StatsWithIcon from "../../Common/StatsWithIcon";
import "./PositionStatsCard.scss";
import { Input, Menu } from "antd";
import { CopyOutlined, DownloadOutlined, CalendarOutlined } from "@ant-design/icons";
import { STATS_ENUM } from "src/pages/Positions/constants";
import DropdownMenu from "../DropdownMenu";
import {
  EXPERTISE_TAG,
  ORGANIZATION_TYPES,
} from "src/constants/globalConstants";
import Toast from "../Toast";
import PositionApi from "src/services/apis/positions";
import { CSVLink } from "react-csv";
import { useExcelDownload } from "src/utils/hooks";
import { updatePositions } from "../../../store/Positions/asyncActions";
import { useDispatch } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import { ArchiveIcon, CheckIcon, CloseIcon, RemoveIcon, UnArchiveIcon } from "src/assets/icons";
import InviteScheduler from "../../../forms/InviteScheduler";
import WithToolTip from "../WithToolTip";

export default function PositionStatsCard(props) {
  const {
    id,
    orgId,
    name,
    type,
    created,
    archivedOn,
    onClick,
    onDelete,
    stats,
    expertise,
    activeCard,
    onArchieve,
    cardId,
    archived,
    activeStatus,
  } = props;
  const dispatch = useDispatch();
  const [positionName, setPositionName] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [menuVisibility, setMenuVisiblity] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const [companyUsersData, setCompanyUsersData] = useState(null);
  const [positionNameErr, setPositionNameErr] = useState("")
  const csvLink = useRef();
  useExcelDownload(csvLink, companyUsersData, setCompanyUsersData);
  const orgType = AUTH_CONSTANTS.ORGANIZATION_TYPE;
  const dropdownMenuRef = useRef();
  const toggleMenuView = (visible) => setMenuVisiblity(visible);

  useEffect(() => {
    setPositionName(name)
  }, [name])

  const userMenu = (
    <div className={`optionmenu ${menuVisibility ? "open" : ""}`}>
      <Menu onClick={() => toggleMenuView(false)}>
        <Menu.Item
          className="semibold text-archieve"
          key="0"
          icon={<EditOutlined />}
          onClick={() => {
            setIsEditMode(true);
          }}
        >
          {"Edit Position Title"}
        </Menu.Item>
        <Menu.Item
          className="semibold text-archieve"
          key="1"
          icon={<CalendarOutlined />}
          onClick={() => {
            setShowInviteModal(true);
          }}
        >
          Send Invites
        </Menu.Item>
        <Menu.Item
          className="semibold text-archieve"
          key="2"
          icon={<CopyOutlined />}
          onClick={() => {
            copyTextToClipboard(
              getPositionSignupLink(ORGANIZATION_TYPES.COMPANY, orgId, id)
            );
            Toast("success", "Link Copied Successfully.", "Link copied");
          }}
        >
          Get Public Link
        </Menu.Item>
        {onDelete && (
          <Menu.Item
            className={`semibold text-delete`}
            key="3"
            icon={<RemoveIcon />}
            onClick={() => {
              onDelete(id);
            }}
          >
            Remove
          </Menu.Item>
        )}
        <Menu.Item
          className="semibold text-archieve"
          key="4"
          icon={<DownloadOutlined />}
          onClick={() => {
            getCompanyData();
          }}
        >
          Download Data
        </Menu.Item>
        <Menu.Item
          className="semibold text-archieve"
          key="5"
          icon={activeStatus ? <ArchiveIcon /> : <UnArchiveIcon />}
          onClick={() => {
            dispatch(
              updatePositions({
                positionId: id,
                orgId,
                orgType,
                payload: {
                  positionOpen: !activeStatus,
                },
              })
            );
          }}
        >
          {activeStatus ? "Expire Position" : "Activate Position"}
        </Menu.Item>
      </Menu>
    </div>
  );

  async function getCompanyData() {
    const response = await generateCompanyDataCsv({ orgId, positionId: id });
    if (response) setCompanyUsersData(response);
  }

  const renderDate = () => {
    const date = archived ? archivedOn : created;
    const text = archived ? "Archived" : "Created";
    return `${text} ${getDefaultFormattedDate(
      date,
      "DD MMM, YYYY"
    )} • ${getRelativeTimeFormat(date)}`;
    return;
  };
  const updatePosition = useCallback(
    async (e) => {
      e.stopPropagation();
      if(positionNameErr) return
      const payload = {
        name: positionName,
      };
      const response = await PositionApi.updatePosition({
        positionId: id,
        payload,
        orgId,
        orgType
      });
      if (response.success) {
        Toast("success", "Position updated successfully!");
        setIsEditMode(false);
      }
    },
    [id, positionName]
  );

  const handlePositionNameChange = (e) => {
    setPositionName(e.target.value)
    if(!(/^[^–—+*/=]*$/).test(e.target.value)) {
      setPositionNameErr("The title cannot include –, —, +, *, /, or =")
    } else {
      setPositionNameErr("")
    }
  }

  return (
    <div
      className={`position-stats-card relative ${menuVisibility ? "" : "cursor-pointer"
        }
      ${cardId && cardId === id ? "active-card" : ""} 
      ${cardId && cardId !== id ? "opacity-5" : ""}`}
      onClick={() => {
        if (!menuVisibility) onClick();
      }}
    >
      <div
        className={`background-overlay ${menuVisibility ? "" : "hide"}`}
      />
      <div className="absolute flex flex-col justify-start position-header-container">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div
            className={`text-14 uppercase medium line-28 weight-700 text-${getTextColor(
              type
            )}`}
          >
            {type}
          </div>
          <div className="flex items-center ml-8 pt-8 pb-8 pl-8 pr-8 text-12 expertise-tag">
            {EXPERTISE_TAG[expertise]}
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            dropdownMenuRef.current.toggleDropdownMenu(false)
            activeCard(cardId === id ? "" : id);
          }}
        >
          {(onDelete || onArchieve) && <DropdownMenu ref={dropdownMenuRef} menu={userMenu} onVisibleChange={toggleMenuView}/>}
        </div>
      </div>
      <div className="title mt-2">
          <div className="text-black semibold text-26 mb-6 line-30 w-90 break-word">
            {isEditMode ? (
              <>
              <div className={"flex justify-between align-center mt-10"}>
                <Input
                  key={id}
                  value={positionName}
                  onChange={handlePositionNameChange}
                  autoFocus={true}
                  className={"edit-name"}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      updatePosition(e);
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
                <span className={"save-edit-options"}>
                  <CloseIcon
                    className="close-button"
                    width={32}
                    height={32}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditMode(false);
                    }}
                  />
                  <CheckIcon
                    className={"check-icon"}
                    width={32}
                    height={32}
                    onClick={updatePosition}
                  />
                </span>
              </div>
              {
                positionNameErr && <span className="text-10 text-darkred weight-500 relative letter-spacing-3 bottom-[11]">{positionNameErr}</span>
              }
              </>
            ) : (
              <WithToolTip
                title={positionName}
                showToolTip={positionName?.length > 38}
                placement={"right"}
              >
                {
                  positionName?.length > 38
                    ? `${positionName?.slice(0, 38)}...`
                    : positionName
                }
              </WithToolTip>
            )}
          </div>
        </div>
      </div>
      <div className="position-data-container">
        <div className="stats-date-container">
          <div className="stats">
            {Object.keys(STATS_ENUM).map((statKey, index) => {
              const { text } = STATS_ENUM[statKey];
              const statValue = stats[statKey];
              return (
                <StatsWithIcon
                  key={index}
                  name={text}
                  stat={statValue === -1 ? "0" : statValue}
                  className="my-0"
                />
              );
            })}
          </div>
        </div>
        <div className="creation-date text-14 line-22 weight-400 text-black opacity-4 created mt-20 pb-6">
          {renderDate()}
        </div>
      </div>
      <div
        ref={csvLink}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
      </div>
      {
        showInviteModal &&
        <InviteScheduler 
          orgId={orgId}
          positionId={id}
          showModal={showInviteModal}
          setShowModal={setShowInviteModal}
        />
      }
    </div>
  );
}

PositionStatsCard.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  created: PropTypes.string,
  stats: PropTypes.array,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onArchieve: PropTypes.func,
};

PositionStatsCard.defaultProps = {
  name: "",
  type: "",
  icon: "",
  created: "",
  stats: [],
  onClick: () => { },
  onDelete: null,
  onArchieve: null,
};
