import React, { useState } from "react";
import "./Header.scss";
import { routes } from "./routes";
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, Link } from "react-router-dom";
import { storage } from "src/services/config/storage";
import ConfirmationModal from "../../Common/ConfirmationModal";
import CompanyWallet from "src/components/Common/Wallet";
import { Mixpanel } from "src/services/mixpanel";
import { DEFAULT_COMPANY_NAME, MIXPANEL_EVENTS } from "src/constants/globalConstants";
import { useResetStore } from "src/store/useResetStore";
import { Logout, UnberryLogoWhite } from "src/assets/icons";
import { getCompanyDetailsFromStorage, getCompanyName } from "src/utils/helperFunctions";

export default function Header() {
  const [logoutModal, setLogoutModal] = useState(false);
  const resetStore = useResetStore();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.organizations.list.data);

  // Whitelabelling checks for header logo
  const { isWhiteLabelled, isFeedbackAllowed } = getCompanyDetailsFromStorage();
  const companyLogo = storage.get.organizationInfo()?.logo;

  const [show, setShow] = useState(false);

  const PROFILE_PATH = "/profile";

  function toggleShow() {
    setShow(!show);
  }

  const logoutHandler = () => {
    history.push("/login");
    setLogoutModal(false);
    storage.destroy.authToken();
    Mixpanel.track(MIXPANEL_EVENTS.LoggedOut);
    Mixpanel.reset();
    resetStore();
  };

  const getCurrentRouteName = () => {
    const [{ name = "" } = {}] = routes.filter((route) =>
      location.pathname.includes(route.link)
    );
    return name;
  };

  return (
    <div className="flex header-component items-left justify-between w-100">
      <nav className="navbar navbar-expand-lg">
        <Link to="/myteam" className="navbar-brand">
          {/* <img
            src="/img/logo.svg"
            alt="logo"
            className="logo cursor-pointer"
            onClick={() => history.push("/")}
          /> */}
          {
            !isWhiteLabelled ? 
            <UnberryLogoWhite /> : 
            <img src={companyLogo} />
          }
          
        </Link>
        <div className="mobile-menu">
          <button
            className={`navbar-toggler ${show ? "show" : ""}`}
            onClick={toggleShow}
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="current-page-name">{getCurrentRouteName()}</div>
        </div>
        <div className={`navbar-collapse ${show ? "show" : ""}`}>
          <ul className="navbar-nav ml-auto">
            {routes.map((route, index) => {
              /** 
               * Allow pageViewing for superAdmin, companyAdmins with toggled feedbacks in orgConfig
               */
              if (route.name === "Feedback" && !isFeedbackAllowed) return;
              return (
                <li
                  key={index}
                  className={`header-link flex items-center weight-600 cursor-pointer ${location.pathname.includes(route.link) ? "active" : ""
                    }`}
                >
                  <Link
                    to={route.link}
                    className="menu-link"
                    onClick={toggleShow}
                  >
                    <span className="text-18 line-28">{route.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="btns-wrapper flex items-center justify-end">
          {history.location?.pathname !== PROFILE_PATH && (
            <span className="wallet">{<CompanyWallet />}</span>
          )}
          <div
            className="logout-btn cursor-pointer text-grey weight-600 ml-30"
            onClick={() => setLogoutModal(true)}
          >
            Logout <span className="ml-2">{<Logout />} </span>
          </div>
          <div
            className="logout-mobile-btn"
            onClick={() => setLogoutModal(true)}
          >
            <Logout fill="#fff" backgroundColor="#323232" />
          </div>
        </div>
      </nav>
      <ConfirmationModal
        showModal={logoutModal}
        onClose={() => setLogoutModal(false)}
        confirmationText="Logout"
        onSubmit={logoutHandler}
      >
        <h4 className="semibold pb-20">Are you sure you want to logout ?</h4>
      </ConfirmationModal>
    </div>
  );
}
