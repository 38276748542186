import React, { useState, useEffect } from "react";
import { WhiteCircleCheck } from "src/assets/icons";
import "./TagSelect.scss";

export default function TagSelect(props) {
  const {
    field,
    form,
    selectOptions,
    optionValueKey,
    optionNameKey,
    multiple,
    value,
  } = props;

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    let defaultSelected = [];
    if (multiple) {
      defaultSelected = [...value];
    } else {
      defaultSelected = [value];
    }
    setSelected(defaultSelected);
  }, [value]);

  const selectHandler = (value) => {
    let newSelectionList = [...selected];
    if (selected.includes(value)) {
      newSelectionList = selected.filter((val) => val !== value);
    } else {
      newSelectionList = [...selected, value];
      if (!multiple) {
        // if it is single select getKeyThenIncreaseKey, remove all the existing value
        newSelectionList = newSelectionList.filter((val) => val === value);
      }
    }
    setSelected(newSelectionList);

    if (multiple) {
      form.setFieldValue(field.name, newSelectionList);
    } else {
      form.setFieldValue(field.name, newSelectionList[0]);
    }
  };

  return (
    <div className="tag-select">
      <div className="tag-select-items flex">
        {selectOptions?.map((option, index) => {
          const isSelected = selected.includes(option[optionValueKey]);
          return (
            <div
              key={index}
              className={`select-box cursor-pointer ${
                isSelected ? "active" : ""
              }`}
              onClick={() => selectHandler(option[optionValueKey])}
            >
              <div className="flex items-center">
                <span className="item-name text-16 weight-600">
                  {option[optionNameKey]}
                </span>
                {isSelected ? (
                  <span className="ml-10">
                    <WhiteCircleCheck />
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      {(form.touched?.[field.name] || form?.submitCount > 0)  && form.errors?.[field.name] && (
        <div className="error mt-10">{form.errors?.[field.name]}</div>
      )}
    </div>
  );
}

TagSelect.defaultProps = {
  multiple: true,
};
