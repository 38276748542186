export const formCategoryWiseInputs = {
    "Personal Details": {
            alternateContact: {
                label: "Alternate Contact Number",
                type: "phone"
            },
            dob: {
                label: "Date Of Birth",
                type: "date"
            },
            currentResidential: {
                label: "Current Residential Address",
                type: "textarea"
            },
            permanentResidential: {
                label: "Permanent Residential Address",
                type: "textarea"
            },
            gender: {
                label: "Gender",
                type: "radio"
            },
            language: {
                label: "Language Spoken",
                type: "text"
            },
            city: {
                label: "City",
                type: "text"
            },
            pin: {
                label: "Pin Code",
                type: "text"
            },
    },
    "University Details": {
            universityRegistrationNumber: {
                label: "University Registration Number",
                type: "text"
            },
            universitySeatNumber: {
                label: "University Seat Number",
                type: "text"
            },
    },
    "Educational Qualification": {
            highestQualification: {
                label: "Highest Qualification",
                type: "text"
            },
            stream: {
                label: "Stream/Specialization",
                type: "text"
            },
            collegeName: {
                label: "College Name",
                type: "text"
            },
            percentage: {
                label: "Percentage/CGPA",
                type: "text"
            },
            yearOfPassing: {
                label: "Year of Passing",
                type: "year"
            },
            twelfthPercentage: {
                label: "12th Percentage",
                type: "text"
            },
            twelfthYearOfPassing: {
                label: "12th Year of Passing",
                type: "year"
            },
            tenthPercentage: {
                label: "10th Percentage",
                type: "text"
            },
            tenthYearOfPassing: {
                label: "10th Year of Passing",
                type: "year"
            },
    },
    "Job Details": {
        yearsOfExp: {
            label: "Years of Experience",
            type: "text"
        },
        compensation: {
            label: "Expected Compensation (lpa)",
            type: "text"
        },
        locationPreference: {
            label: "Preferred locations",
            type: "text"
        },
        edpId: {
            label: "EDP Id",
            type: "text"
        },
        zoneName: {
            label: "Zone Name",
            type: "text"
        },
        site: {
            label: "Site",
            type: "text"
        },
        company: {
            label: "Last or Current Company",
            type: "text"
        },
        noticePeriod: {
            label: "Notice Period (in days)",
            type: "text"
        },
    },
    "Resume": {
            resumeUpload: {
                label: "Resume",
                type: "file"
            }
    }
}
