import Toast from "src/components/Common/Toast";
import { useLocation } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import {
  BUCKET_NAMES,
  COLOR,
  DEFAULT_COMPANY_NAME,
  JOB_PROFILE,
  PERSONALITY_TRAITS,
  PERSONALITY_TRAITS_MAP,
  PERSONALITY_TRAITS_MAP_IPIP,
} from "src/constants/globalConstants";
import BulkApi from "src/services/apis/bulkOperations";
import { storage } from "src/services/config/storage";
import { cloneDeep } from "lodash";

export const handleError = (response) => {
  if (Array.isArray(response)) {
    Toast("error", response?.map((er) => er).join("\n"));
  } else {
    Toast("error", response?.message, response?.error);
  }
  if (["staging", "development"].includes(process.env.REACT_APP_ENV)) {
    console.log(response);
  }
};

export const get = (object, pathString, defaultValue) => {
  // Coerce pathString to a string (even it turns into "[object Object]").
  var parts = (pathString + "").split(".");
  var length = parts.length;
  var i = 0;

  // In case object isn't a real object, set it to undefined.
  var value = object === Object(object) ? object : undefined;

  while (value != null && i < length) {
    value = value[parts[i++]];
  }
  return i && i === length && value !== undefined && value !== null
    ? value
    : defaultValue;
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const capitalizeTitle = (title) => {
  // Updated replace regex for accomodating number splitting in title string
  const replacedTitle = title.replace(/([a-z])([A-Z0-9])/g, '$1 $2');
  const firstWord = replacedTitle.split(' ')[0];
  const lower = firstWord.toLowerCase();
  const capitalizedFirstWord = lower.charAt(0).toUpperCase() + lower.slice(1);
  return `${capitalizedFirstWord} ${replacedTitle
    .split(' ')
    .slice(1)
    .join(' ')}`;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const assetGetter = (asset) => {
  const assetValue = `src/assets/${asset}`;
  return assetValue;
};

export const getRelativeTimeFormat = (date) => {
  let timeFormat = moment(date).fromNow();
  if (timeFormat.includes("second")) {
    return "Just now";
  } else {
    timeFormat = timeFormat.toString().split(" ");
    if (isNaN(timeFormat[0])) timeFormat[0] = "1";
    timeFormat[1] =
      timeFormat[0] +
      (timeFormat[1].includes("month")
        ? timeFormat[1].slice(0, 2)
        : timeFormat[1]?.[0]);
    timeFormat.shift();
    return timeFormat.join(" ");
  }
};

export const urlQueryCreator = (url, query = {}) => {
  return queryString.stringifyUrl({ url, query });
};

export const areObjectEqual = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export const getTextColor = (heading = "") => {
  if (heading.toLowerCase().includes(JOB_PROFILE.DEVELOPER)) {
    return COLOR.ORANGE;
  } else if (heading.toLowerCase().includes(JOB_PROFILE.DESIGN)) {
    return COLOR.PURPLE;
  } else {
    return COLOR.DARK_RED;
  }
};

export const getBackgroundColor = (query) => {
  let letter = query.toUpperCase();
  if (letter.match(`^[A-G]+$`)) {
    return COLOR.ORANGE;
  } else if (letter.match(`^[H-O]+$`)) return COLOR.BLUE;
  else {
    return COLOR.PURPLE;
  }
};

/**
 *
 * @param {string} value
 * @param {string} format
 * @returns {string} formatted date
 */
export const getDefaultFormattedDate = (value, format = "Do MMM YYYY") => {
  if (!value) return "";

  const dateObject = moment(value);

  if (dateObject.isValid()) return dateObject.format(format);
  else return "";
};

/**
 * Get capitalized initial letters for the given name
 * @param {string} name (`John Doe`)
 * @returns {string} (`JD`)
 */
export const getInitialLetters = (name = "") => {
  if (!name) return "";
  const words = name.split(" ");
  return words.map((word) => word[0]?.toUpperCase())?.join("");
};

/**
 * Get sorted list based on the given key
 * @param {array} arr Array needs to be sorted
 * @param {string} key Key based on which array will be sorted
 * @returns {array} Sorted array
 */
export const sort = (arr, key) => {
  return arr.sort((a, b) => a[key] - b[key]);
};

/**
 * Get plural form of word if count is greater than one else singular form
 * @function pluralizeWord
 * @param {string} singularWord singular form of word
 * @param {string} pluralWord plural form of word
 * @param {number} count
 * @returns {string} return singularWord or pluralWord based on count
 */
export const pluralizeWord = (singularWord, pluralWord, count) => {
  return count > 1 ? pluralWord : singularWord;
};

/**
 * fallback for copyTextToClipboard function
 * @param {string} text text to be copied
 */
const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

/**
 * copy text to the clipboard
 * @param {string} text string to be copied
 * @returns void
 */
export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
  } else {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }
}

export const getGameplayLink = (linkId) => {
  const { isWhiteLabelled, gcBaseUrl } = getCompanyDetailsFromStorage();
  const baseUrl = isWhiteLabelled && gcBaseUrl ? 
    gcBaseUrl : process.env.REACT_APP_ASSESSMENT_CENTRE_URL;
  return `${baseUrl}/home/${linkId}`;
}
  
export const getPositionSignupLink = (orgType, orgId, positionId) => {
  const { isWhiteLabelled, gcBaseUrl } = getCompanyDetailsFromStorage();
  const baseUrl = isWhiteLabelled && gcBaseUrl ? 
    gcBaseUrl : process.env.REACT_APP_ASSESSMENT_CENTRE_URL;
  return `${baseUrl}/signup/${orgType}/${orgId}/${positionId}`;
}

export const getPositionLoginLink = (positionId) => {
  let { isWhiteLabelled, gcBaseUrl, orgName } = getCompanyDetailsFromStorage();
  const baseUrl = isWhiteLabelled && gcBaseUrl ? 
    gcBaseUrl : process.env.REACT_APP_ASSESSMENT_CENTRE_URL;

  orgName = orgName
    .split(" ")
    .map(word => word.toLowerCase())
    .join("");

  return `${baseUrl}/login/${orgName}/${positionId}`
}

export const disableBodyScroll = (viewOpen) => {
  if (viewOpen) document.body.style.overflow = "hidden";
  else document.body.style.overflow = "unset";
};

const calculateOverallScore = (bucketData) => {
  if (bucketData) {
    const nonNullTraits = Object.values(bucketData?.traitsMeta ?? {}).filter(
      ({ score }) => score !== null
    );
    if (nonNullTraits.length > 0) {
      // Trait component use displayName instead of name
      bucketData.displayName = bucketData.name;

      // calculating average of all the traits score in the bucket
      bucketData.score =
        nonNullTraits.reduce((scoreSum, { score }) => score + scoreSum, 0) /
        nonNullTraits.length;

      // setting ub, lb, and median as it won't come on the bucket-level
      bucketData.lowerBound = bucketData?.lowerBound ?? { score: 2.5 };
      bucketData.upperBound = bucketData?.upperBound ?? { score: 7.5 };
      bucketData.median = bucketData?.median ?? 5;

      let updatedTraitsMeta = {};
      nonNullTraits.forEach((elem) => {
        updatedTraitsMeta[elem.id] = elem
      });
      bucketData.traitsMeta = updatedTraitsMeta

      return bucketData;
    }
  }

  return null;
};

export const getTechnicalBucket = (buckets) => {
  if (buckets) {
    let bucketData = buckets.find(
      ({ name }) => name === BUCKET_NAMES.TECHNICAL
    );
    bucketData = calculateOverallScore(bucketData);
    return bucketData;
  }
  return null;
};

export const getTraitsMap = (buckets) => {
  const traits = new Map();
  buckets.forEach((bucket) => {
    const list = Object.values(bucket.traitsMeta);
    list.forEach((item) => {
      const {
        id,
        displayName: name,
        upperBound: { score: ub },
        lowerBound: { score: lb },
        ...rest
      } = item;
      traits.set(id, {
        ...rest,
        id,
        name,
        ub,
        lb,
      });
    });
  });
  return traits;
};

export const getFeaturedBuckets = (buckets, bucketNames) => {
  if (!buckets || buckets.length === 0) return [];

  const featuredBuckets = buckets.filter(({ name }) =>
    bucketNames.includes(name)
  );

  return featuredBuckets;
  // return featuredBuckets
  //   .map((bucketData) => calculateOverallScore(bucketData))
  //   .filter((bucket) => bucket !== null);
};

export const getPersonalityTraits = (buckets, bucketNames) => {
  if (!buckets || buckets.length === 0) return [];
  
  let isIPIPBucket;
  // Check IPIP Bucket in filter
  const personalityBuckets = buckets.filter(({ name }) => {
      isIPIPBucket = name.includes("IPIP");
      return bucketNames.includes(name);
    }
  );

  if (!personalityBuckets || personalityBuckets.length === 0)
    return [];

  const { traitsMeta } = personalityBuckets[0];
  // Selecting mapping based on IPIP buckets
  const selectedTraitsMap = isIPIPBucket ? PERSONALITY_TRAITS_MAP_IPIP : PERSONALITY_TRAITS_MAP;
  const personalityTraits = Object.values(traitsMeta)
    .filter((trait) => selectedTraitsMap[trait.displayName])
    .map((trait) => {
      const traitData = selectedTraitsMap[trait.displayName];
      return {
        ...trait,
        ...traitData,
        isParentTrait: !traitData.parentTrait,
        color: PERSONALITY_TRAITS[traitData?.parentTrait || trait?.name]?.color
      }
    });

  return personalityTraits;
};

export const isObject = (objValue) => {
  return objValue && typeof objValue === 'object' && objValue.constructor === Object;
};

/**
 * This function fetches the data for a position of an organisation.
 * @param {orgId} - organisation id
 * @param {positionId} - position id
 * @return {response}
 */
export const generateCompanyDataCsv = async ({ orgId, positionId }) => {
  try {
    let query = queryString.stringify({ positionId });
    // const response = await BulkApi.getCompanyUserDataCsv(orgId, query);
    // New v2 API
    const payload = {
      positionId,
      "includeUserData" : true,
      "includeInvitationData" : true,
      "includeAssessmentTime" : true,
      "includeBucketScores" : true,
      "includeTraitScores" : true,
      "includeCodingScores" : true,
      "includeSessionTimes" : true,
      "includeCandidateForm" : true,
      "includeReportLink" : true
    };
    const response = await BulkApi.getCompanyUserDataCsvV2(payload);
    /**
     * Error Handling
     * Success -> Response {CSV}; Error -> Response {Object} 
     */
    if (response && !isObject(response)) {
      Toast('success', `Company user data fetched successfully`, 'Data fetch');
      return response
    }
  } catch (error) {
    handleError(error);
  }
  return []
};

/**
 * rounding of a given number with nth max fraction digits and remove trailing zeroes
 * @param num number to round off
 * @param maxFractionDigits nth decimal place to round of, defaults to 1
 * @returns {string} number with nth round of decimal place
 */
export const roundOff = (num = 0, maxFractionDigits = 2) => {
  if (num == 0) return 0;
  return parseFloat(Number(num).toFixed(maxFractionDigits));
};


/**
 * This function returns the truncated substring from the string provided suffixed with ...
 * @param {text} - original string to be truncated
 * @param {length} - length of the required truncated string 
 * @return {String} - truncated string
 */
export const getTruncatedText = (text, length) => text?.length > length
  ? <span>
    {text?.slice(0, length - 1)}<span className="text-14 weight-400 ml-4 color-grey">....More</span>
  </span> : text

/**
 * This function checks if the filter values have changed except page. 
 * @param {oldFilter} - old filter object
 * @param {newFilter} - new filter object 
 * @return {Boolean}
 */
export const hasFilterChanged = (oldFilter, newFilter) => {
  const filter_without_page = { ...newFilter }
  delete filter_without_page["page"]
  delete oldFilter["page"]

  const isChanged = JSON.stringify(oldFilter) !== JSON.stringify(filter_without_page)

  return isChanged
};

/**
 * Applies filtering operations to a filter object by replacing '+' characters with '%2B'.
 *
 * @param {Object} filter - The filter object to apply operations to.
 * @param {string} filterKey - The key within the filter object to target (optional).
 * @returns {Object} - A modified filter object with filtering operations applied.
 */
export const filterOperations = (filter, filterKey = "") => {
  const filterCopy = cloneDeep(filter);
  switch (filterKey) {
    case filterKey:
      if (filterCopy[filterKey])
        filterCopy[filterKey] = filterCopy[filterKey]?.replace(/\+/g, '%2B');
      return filterCopy;
    default:
      return filterCopy;
  }
};

export const getReportLink = (userId, linkId, queryParam = "") => {
  const { dbBaseUrl, isWhiteLabelled } = getCompanyDetailsFromStorage();
  const baseUrl = isWhiteLabelled && dbBaseUrl ? 
    dbBaseUrl: process.env.REACT_APP_COMPANY_DASHBOARD_URL;

  return `${baseUrl}/report/v2/link/${linkId}/users/${userId}?${queryParam}`;
}
  
export const getFormattedSeekValue = (value) => {
  const duration = moment.duration(value, 'seconds')
  const minutes = Math.floor(duration.asMinutes())
  const remainingSeconds = Math.floor(duration.asSeconds()) % 60;
  const finalVal = minutes >= 60
    ? moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
    : moment({ minutes, seconds: remainingSeconds }).format('mm:ss')

  return finalVal
}

/**
 * Used to change on document title, fav_icon and Login screen unberry logos text
 * When url does not contain DEFAULT_COMPANY_NAME
 */

export const getCompanyName = (allowHTMLUpdate=false) => {

  const location = window.location;
  const windowUrl = location.href;
  let isUrlWhiteLabelled = false;
  
  if (location.href.includes(DEFAULT_COMPANY_NAME) || location.href.includes("localhost")) 
    return [DEFAULT_COMPANY_NAME, windowUrl, isUrlWhiteLabelled];

  /*** Only for testing  ***/
  // const placeholderUrl = "https://dashboard.kaguya-sama.aizen.com";
  // const urlArray = placeholderUrl.split(".");
  
  const urlArray = windowUrl.split(".");
  const companyName = urlArray[urlArray.length - 2];

  if (allowHTMLUpdate) {
    // Update title
    document.title = `Dashboard | ${capitalizeFirstLetter(companyName)}`;

    // Update fav_icon
    const existingFavicon = document.querySelector('link[rel="icon"]');
    if (existingFavicon) {
      const faviconPath = `favicon_${companyName.toLowerCase().split("-").join("")}.ico`;
      existingFavicon.href = faviconPath;
    }
  }
  /**
   * Whitelabelling of an org should ideally be checked by @name isWhiteLabelled
   * @function getCompanyDetailsFromStorage -> @returns {...rest, isWhiteLabelled} 
   * 
   * For cases, where @name isWhiteLabelled is not usable due to various reasons
   * 
   * can use @name isUrlWhiteLabelled
   * */ 
  isUrlWhiteLabelled = DEFAULT_COMPANY_NAME !== companyName;
  return [companyName, windowUrl, isUrlWhiteLabelled];
};

/**
 * Function to check if selected company is whitleabelled and perform following actions
 * 
 * 1. Change Header Icon from unberry to orgName
 * 
 * 2. If whitelabel && (dbBaseUrl || gcBaseurl), changes in following functions:-
 * 
 * - getReportLink
 * - getPositionSignupLink
 * - getPositionLoginLink
 * - getGameplayLink
 * 
 */
export const getCompanyDetailsFromStorage = () => {

  const orgInfo = storage.get.organizationInfo() ?? {};
  const { organizationConfig, organizationName: orgName } = orgInfo;
  const isWhiteLabelled = organizationConfig?.moduleFlags?.whitelabel;
  const isFeedbackAllowed = organizationConfig?.moduleFlags?.feedbacks;
  const { gcBaseUrl, dashboardBaseUrl } = organizationConfig?.whitelabelConfigs ?? {};

  return {
    orgName,
    isWhiteLabelled,
    isFeedbackAllowed,
    gcBaseUrl,
    dbBaseUrl: dashboardBaseUrl
  }
};

export const calculateTimeTaken = (startedAt, completedAt) => {

  if (!startedAt || !completedAt) return null;

  const startTime = new Date(startedAt);
  const endTime = new Date(completedAt);

  // const startTime = new Date("2024-02-20T13:17:30.082Z");
  // const endTime = new Date("2024-02-21T14:31:13.274Z");

  const timeDifference = endTime - startTime;

  // Convert milliseconds to seconds, minutes, hours, and days
  let seconds = Math.floor(timeDifference / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours % 24;
  minutes = minutes % 60;
  seconds = seconds % 60;

  // Building total time string dynamically
  const totalTimeParts = [];
  if (days > 0) totalTimeParts.push(`${days}d`);
  if (hours > 0) totalTimeParts.push(`${hours}h`);
  if (minutes > 0) totalTimeParts.push(`${minutes}m`);
  if (seconds > 0 || totalTimeParts.length === 0) totalTimeParts.push(`${seconds}s`);

  const totalTimeString = totalTimeParts.join(' ');

  return totalTimeString;
}

export const openFileInNewTab = (fileLink) => {
  const a = document.createElement('a')
  a.href = fileLink
  a.target = '_blank'
  a.rel = 'noopener noreferrer'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}