import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Field, Form, Formik } from "formik";
import {
  AntInput,
  AntSelect,
} from "src/components/Common/CreateAntFields/CreateAntFields";
import { Button } from "antd";
import TagSelect from "src/components/Common/TagSelect";
import { experienceOptions } from "./constants";
import { positionValidation } from "src/pages/Positions/validate";
import "src/pages/Positions/Positions.scss";
import DnaApi from "src/services/apis/dna";
import { useDispatch } from "react-redux";
import PositionApi from "src/services/apis/positions";
import { storage } from "src/services/config/storage";
import { capitalizeFirstLetter, getCompanyName, handleError } from "src/utils/helperFunctions";
import Toast from "src/components/Common/Toast";
import { Mixpanel } from "src/services/mixpanel";
import { MIXPANEL_EVENTS } from "src/constants/globalConstants";
import { POSITION_TYPES } from "src/constants/globalConstants";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import { AntdSelectIcon, CancelBtn } from "src/assets/icons";

export default function DriveForm({ onCancel, onSuccess }) {
  const { positionId } = useParams();
  const orgType = AUTH_CONSTANTS.ORGANIZATION_TYPE;
  const orgId = storage.get.organizationInfo().organizationId;
  const [companyName] = getCompanyName()

  const FORM_SCHEMA = {
    name: "",
    experience: "",
    positionId: "",
    industryId: "",
  };

  const [initialValues, setInitialValues] = useState(FORM_SCHEMA);
  const [dnaPositions, setDnaPositions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    loadDnaPostions();
  }, []);

  useEffect(async () => {
    if (positionId) {
      try {
        const response = await PositionApi.getPosition(positionId);
        if (response.success) {
          const { description, name, experience, parentPosition } =
            response.data;
          setInitialValues({
            description,
            name,
            experience,
            positionId: parentPosition,
          });
        }
      } catch (error) {
        handleError(error);
      }
    } else {
      async function fetchIndustries() {
        const industriesResponse = await PositionApi.getIndustries();
        if (industriesResponse.success) {
          const industryId = industriesResponse.data.filter(
            (industry) => industry.name === "Tech"
          )[0]?.id;
          setInitialValues((prevState) => ({ ...prevState, industryId }));
        }
      }
      fetchIndustries();
    }
  }, [positionId]);

  const loadDnaPostions = async () => {
    const response = await DnaApi.listOpenDnaPositions();
    if (response.success) {
      setDnaPositions(response?.positions?.rows);
    }
  };

  const createPosition = async (values) => {
    if (!values.industryId) {
      return Toast("info", "", "Tech Industry is missing!");
    }
    setLoading(true);
    const payload = {
      positionId: values.positionId,
      data: {
        name: values.name,
        experience: values.experience,
        description: values.description,
        industryId: values.industryId,
      },
    };
    const res = await PositionApi.createPosition(
      payload,
      POSITION_TYPES.COLLEGE
    );

    // Mixpanel Event
    const [{ name }] = dnaPositions.filter(
      (position) => position.id === values.positionId
    );

    Mixpanel.track(MIXPANEL_EVENTS.CreatedOpenPosition, {
      "Drive Title": values.name,
      Experience: values.experience,
      Gameplay: name,
      Status: res.success,
    });

    if (res.success) {
      setInitialValues(FORM_SCHEMA);
      onSuccess();
    }
    setLoading(false);
  };

  const editPosition = async (values) => {
    setLoading(true);
    const payload = {
      positionId: values.positionId,
      name: values.name,
      experience: values.experience,
      description: values.description,
    };
    values.parentPosition = values.positionId;
    delete values.positionId;
    const response = await PositionApi.updatePosition({
      positionId,
      payload,
      orgId,
      orgType
    });
    if (response.success) {
      Toast("success", "Drive updated successfully!");
      onSuccess();
    }
    setLoading(false);
  };

  return (
    <div className="position-form">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={positionValidation}
        onSubmit={(values) => {
          if (positionId) {
            editPosition(values);
          } else {
            createPosition(values);
          }
        }}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => {
          return (
            <Form>
              <div className="content-wrapper">
                <p className="content-heading uppercase text-18 weight-900 pt-10">
                  Drive Details
                </p>
                <div className="item-box mt-30 mb-40">
                  <div className="item mb-20">
                    <Field
                      component={AntInput}
                      type="text"
                      value={values.name}
                      name={"name"}
                      label={"Drive title"}
                      hasFeedback
                      onPressEnter={handleSubmit}
                    />
                  </div>
                  <div className="item mb-20">
                    <Field
                      component={AntSelect}
                      type="text"
                      selectOptions={experienceOptions}
                      optionValueKey={"value"}
                      optionNameKey={"name"}
                      value={values.experience}
                      name={"experience"}
                      label={"Expertise"}
                      suffixIcon={<AntdSelectIcon />}
                      hasFeedback
                      onChange={(value) => {
                        setFieldValue("experience", value);
                      }}
                    />
                  </div>
                </div>
                <p className="content-heading text-18 weight-900 uppercase pt-10 mt-30 mb-20">
                  {capitalizeFirstLetter(companyName)} Gameplay
                </p>
                <p className="mb-4 text-grey text-18 weight-600">
                  Select position you want to enable the gameplay for
                </p>
                <Field
                  component={AntSelect}
                  selectOptions={dnaPositions}
                  optionValueKey={"id"}
                  optionNameKey={"name"}
                  value={values.positionId}
                  name={"positionId"}
                  multiple={false}
                  label={"Position"}
                />
              </div>
              <div className="btn-box flex justify-start">
                <Button
                  className="ant-cancel-btn text-16 semibold px-18"
                  onClick={onCancel}
                  icon={<CancelBtn />}
                >
                  Cancel
                </Button>
                <Button
                  className="ant-btn-primary text-16 bold px-20"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Save and continue
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
