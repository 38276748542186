import { Modal } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import GroguPng from "../../../../assets/img/grogu.png";

export const MessageBubble = ({ data = {}, className, wrapperClassName }) => {
  const { type, content } = data;
  const isUserMsg = type === "user";

  return (
    <div
      className={
        `chat-message-wrapper
        ${wrapperClassName}
        ${isUserMsg ? "justify-end mr-10" : ""}
      `}
    >
      {!isUserMsg && <img src={GroguPng} alt="" />}
      <p
        className={`
					chat-message-inner
					${!isUserMsg ? "bot-msg" : "user-msg"}
					${className}
			`}
      >
        {content}
      </p>
    </div>
  );
};

export const ChatDetailModal = ({ visible, setVisible, data }) => {

	const {
		cefrScore,
		cefrMarkers,
		analysisMarkers,
		summary,
		chat
	} = data;

	const onModalClose = () => {
		setVisible(false);
	};
	
	return (
		<Modal
			width={'90%'}
			closeIcon={false}
			title={null}
			footer={null}
			open={visible}
			onCancel={onModalClose}
			className="chat-detail-modal"
			centered
		>
			<div className="outer-container">
				<div className="close-icon cursor-pointer" onClick={onModalClose}>
					<CloseCircleOutlined />
				</div>
				<div className="main-data-container flex">
					<div className="left-portion">
						<div className="chat-title">
							<p className="heading mb-8">Chatberry Analysis</p>
							<p className="summary-text">{summary}</p>
						</div>

						<div className="cefr-score flex items-center py-20">
							<p>CEFR SCORE:</p>
							{
								cefrScore === "NA"
								? <p className="text-16 insufficient-cefr capitalize weight-400 ml-10">Insufficient Data</p>
								: <div className="score-pill ml-10">{cefrScore}</div>
							}
						</div>

						<div className="left-scroll-section">
							<div className="ltop-portion">
							{
								cefrMarkers.map((marker) => {
									const { name, score, description } = marker;
									const scoreNumber = Number(score.split("/")[0])
									return (
										<div className="cefr-marker">
											<span className="marker-score"
											style={{
												color: scoreNumber > 7.5
													? "#61C489"
													: scoreNumber > 6
														? "#ABE99B"
														: scoreNumber > 4
															? "#FFDB8F"
															: "#FE9EAB"

											}}
											>
												{score}
											</span>
											<div className="marker-info">
												<span className="marker-name">{name}</span>
												<span className="marker-desc">{description}</span>
											</div>
										</div>
									)
								})
							}
							</div>
							<div className="lbottom-portion">
							{
								analysisMarkers.map((marker) => {
									const { name, score, description } = marker;
									const scoreNumber = Number(score.split("/")[0])
									return (
										<div className="cefr-marker">
											<span className="marker-score"
											style={{
												color: scoreNumber > 7.5
													? "#61C489"
													: scoreNumber > 6
														? "#ABE99B"
														: scoreNumber > 4
															? "#FFDB8F"
															: "#FE9EAB"

											}}
											>
												{score}
											</span>
											<div className="marker-info">
												<span className="marker-name">{name}</span>
												<span className="marker-desc">{description}</span>
											</div>
										</div>
									)
								})
							}
							</div>
						</div>
					</div>
					
					<div className="right-portion">
						<div className="chat-title">
							<p className="heading mb-8">Chatberry History</p>
							<p className="chat-summary-text">
								The overall summary of the chat thats shown in the results page. 
								This is basically the overall insight we got from the chat analysis 
								of the customer purely on communication skills.
							</p>
						</div>

						<div
							className="right-scroll-section mt-30"
						>
							{/* {[
								{"type":"assistant","content":"Executive: Hello! Thank you for contacting TechNova customer support. How can I assist you today?\n\nCustomer: I can\'t believe the terrible experience I\'ve had with your company! I am so frustrated right now!\n\nExecutive: I\'m sorry to hear that you\'re feeling frustrated. I\'m here to help. Could you please let me know what specifically has been bothering you?"}, 
								{"type":"user","content":"Customer: I can\'t believe the terrible experience I\'ve had with your company! I am so frustrated right now!"},
								{"type":"assistant","content":"Executive: Hello! Thank you for contacting TechNova customer support. How can I assist you today?\n\nCustomer: I can\'t believe the terrible experience I\'ve had with your company! I am so frustrated right now!\n\nExecutive: I\'m sorry to hear that you\'re feeling frustrated. I\'m here to help. Could you please let me know what specifically has been bothering you?"}, 
								{"type":"user","content":"Customer: I can\'t believe the terrible experience I\'ve had with your company! I am so frustrated right now!"},
								{"type":"assistant","content":"Executive: Hello! Thank you for contacting TechNova customer support. How can I assist you today?\n\nCustomer: I can\'t believe the terrible experience I\'ve had with your company! I am so frustrated right now!\n\nExecutive: I\'m sorry to hear that you\'re feeling frustrated. I\'m here to help. Could you please let me know what specifically has been bothering you?"}, 
								{"type":"user","content":"Customer: I can\'t believe the terrible experience I\'ve had with your company! I am so frustrated right now!"},
							] */}

							{chat.map((msg, index) => (
								<MessageBubble
									key={index}
									data={msg}
									wrapperClassName={index === 0 ? "mt-10" : null}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};