import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./MyDna.scss";
import PageHelmet from "src/components/Common/PageHelmet";
import InfoCardShimmer from "src/components/Common/Skeletons/InfoCardShimmer";
import { getBasePositions, getDnaPositions } from "src/store/DNA/asyncActions";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "src/services/config/storage";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import { MENU_TAB, MIXPANEL_EVENTS, ORGANIZATION_TYPES } from "src/constants/globalConstants";
import Tab from "src/components/Common/Tab";
import DNAStatsCard from "src/pages/MyDna/DNAStatsCard";
import BlankState from "src/components/Common/BlankState";
import { Mixpanel } from "src/services/mixpanel";
import { reset as dnaReset } from "src/store/DNA/reducer";

export default function MyDna() {
  /**
   * ===================
   * props from parent
   * ===================
   */

  /**
   * ===================
   * props from redux
   * ===================
   */
  const { loading: dnaPositionsLoading, data: dnaPositions } = useSelector(
    (state) => state.dna.dnaPositions
  );

  /**
   * ===================
   * constant variables
   * ===================
   */
  const dispatch = useDispatch();
  const history = useHistory();
  const orgType = AUTH_CONSTANTS.ORGANIZATION_TYPE;
  const orgId = storage.get.organizationInfo().organizationId;

  /**
   * ===================
   * useStates
   * ===================
   */
  const [menuKey, setMenuKey] = useState(MENU_TAB.OPEN);
  const [data, setData] = useState([]);

  /**
   * ===================
   * useRefs
   * ===================
   */

  /**
   * ===================
   * computed variables
   * ===================
   */

  /**
   * ===================
   * custom hooks
   * ===================
   */

  /**
   * ===================
   * functions
   * ===================
   */

  const handleMenu = (key) => {
    if (key === MENU_TAB.OPEN) {
      setData(dnaPositions);
    } else {
      setData([]);
    }
    setMenuKey(key);
  };

  const onCardClick = ({ id, name, stats }) => {
    // Mixpanel Event
    if (menuKey === MENU_TAB.OPEN) {
      const { invited, playing, completed, reportStatus } = stats;
      Mixpanel.track(MIXPANEL_EVENTS.ClickedOnteam, {
        "Team Name": name,
        "Total Invites": invited,
        "Total Started": playing,
        "Total Completed": completed,
        "DNA Available": reportStatus === "ready" ? "Yes" : "No",
      });
    }
    history.push(`/myteam/detail/${id}`);
  };

  /**
   * ===================
   * useCallback
   * ===================
   */

  /**
   * ===================
   * useEffects
   * ===================
   */
  useEffect(() => {
    dispatch(getDnaPositions({ orgType, orgId }));
    dispatch(getBasePositions({ orgType, orgId }));
    // removing event when unmounting the component
    return () => {
      window.removeEventListener("click", () => { });
      dispatch(dnaReset())
    };
  }, []);

  useEffect(() => {
    setData(dnaPositions);
  }, [dnaPositions]);

  return (
    <>
      <PageHelmet title="My DNA" />
      <div className="mydna-container">
        {
          (
            <>
              <div className="container">
                <div className="content-wrapper">
                  <div className="tab-wrapper mt-40 mb-30">
                    <Tab
                      data={[
                        {
                          key: MENU_TAB.OPEN,
                          count: dnaPositions?.length,
                          name: "Teams",
                        },
                      ]}
                      onClick={(key) => handleMenu(key)}
                      currentKey={menuKey}
                    />
                    {/* <div className="btn-box">
                    <Button
                      className="ant-btn-primary text-16 weight-400 px-40"
                      onClick={() => toggleModal()}
                      disabled={!basePositions.length}
                      title={
                        basePositions.length === 0 &&
                        "There is no base position left to create DNA"
                      }
                    >
                      Add New Position
                    </Button>
                  </div> */}
                  </div>
                  {
                    dnaPositionsLoading
                      ? <div className="card-container flex mt-60">
                        <InfoCardShimmer elementNumber={4} />
                      </div>
                      : data?.length > 0 ?
                        <div className="card-container grid">
                          {data?.map((data) => {
                            return (
                              <DNAStatsCard
                                {...data}
                                key={data.id}
                                onClick={() => onCardClick(data)}
                                orgId={orgId}
                                orgType={ORGANIZATION_TYPES.COMPANY}
                              />
                            );
                          })}
                        </div>
                        : <BlankState
                          className="blank-message"
                          message={"No positions yet."}
                          onClick={() => { }}
                        />
                  }
                </div>
              </div>
            </>
          )
        }
        {/* add new position modal */}
        {/* <Modal
          modalVisible={isModalActive}
          cancelModal={toggleModal}
          centered
          className="invite-candidate-modal"
        >
          <div className="invite-modal-content">
            <div className="text-28 line-1 text-white pb-30 modal-heading">
              Add new Position
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={positionValidation}
              onSubmit={async (values) => {
                try {
                  const response = await DnaApi.createDnaPosition(values);
                  if (response) {
                    dispatch(getDnaPositions({ orgType, orgId }));
                    setIsModalActive(false);
                  }
                } catch (error) {
                  handleError(error);
                }
              }}
              enableReinitialize
            >
              {({ values, handleSubmit }) => {
                return (
                  <Form>
                    <div>
                      <p className="text-16 weight-500 text-white mb-20">
                        Select a position you want to enable the gameplay for
                      </p>
                      <Field
                        component={TagSelect}
                        type="text"
                        selectOptions={basePositions}
                        optionValueKey={"id"}
                        optionNameKey={"name"}
                        value={values.positionId}
                        name={"positionId"}
                        hasFeedback
                        multiple={false}
                      />
                      <Button
                        className="ant-btn-primary text-16 weight-400 px-40 mt-40"
                        onClick={handleSubmit}
                      >
                        Create
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal> */}
      </div>
    </>
  );
}
