import { useEffect, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import { useParams } from "react-router-dom";

import { roundOff } from "src/utils/helperFunctions";
import { QUESTIONS } from "../../constant";

import PdfReportApi from "src/services/apis/pdfReport";
import QuestionsSection from "../../QuestionsSection";
import Trait from "../Trait";
import ScrollIcon from "src/components/Common/ScrollIcon";

export const BucketDetails = ({
  onBack,
  displayName: name,
  description,
  score: { score, upperBound, lowerBound, median },
  traitsMeta,
  reportData,
  headerTraitColor="grey"
}) => {

  const [traitsIds, setTraitsIds] = useState(null);

  const { linkId } = useParams();
  const [mcqData, setMcqData] = useState([]);
  const [codingData, setCodingData] = useState([]);
  const [chatData, setChatData] = useState([]);

  // Use ref to avoid async update after setting mcq, code data 
  const traitsDataRef = useRef({});
    
  useEffect(() => {
    if (!traitsMeta) return;
    const newTraitsMeta = cloneDeep(traitsMeta);
    for (const key in traitsMeta) {
      newTraitsMeta[key] = {...traitsMeta[key], userData: { userScore: 0, totalScore: 0 }};
    }
    traitsDataRef.current = {...traitsDataRef.current, ...newTraitsMeta};
    setTraitsIds(Object.keys(traitsMeta));
  }, [traitsMeta]);

  useEffect(() => {
    if (traitsIds) {
      getQuizDetailsFromTraits();
      getCodingDetailsFromTraits();
    }
  }, [traitsIds]);

  const getMcqAndChatData = (data) => {
    let mcqObj = {};
    let chatArr = [];
    const newTraitsData = cloneDeep(traitsDataRef.current);
    // For below type of question -> Extract score from traitScore key
    const traitScoreQuestionTypes = [QUESTIONS.CHAT, QUESTIONS.FREE_TEXT, QUESTIONS.SPEECH_SPONTANEOUS];
    Object.keys(data ?? {}).forEach((key) => {
      const { name, questions } = data[key];
      questions.forEach((elem) => {
        const { question, answer } = elem;
        const userScore = traitScoreQuestionTypes.includes(question.type) ?
          answer?.traitScore: answer.score?.userScore;
        const maxScore = answer.score?.maxScore;
        newTraitsData[key].userData.userScore += (userScore || 0);
        newTraitsData[key].userData.totalScore += (maxScore || 0);

        if (question.type === QUESTIONS.CHAT) {
          // Same Q can be under many traits -> Check and eliminate from spawning multiple times.
          const questionAlreadyMapped = chatArr.find(chatElem => chatElem.question.id === question.id);
          if (!questionAlreadyMapped) chatArr.push(elem);
        } else {
          if (mcqObj[question.id]) {
            mcqObj[question.id].traits.add({ name, maxScore, userScore });
          } else {
            const traits = new Set([{ name, maxScore, userScore }])
            mcqObj[question.id] = { ...elem, traits };
          }
        }
      });
    });
    traitsDataRef.current = {...traitsDataRef.current, ...newTraitsData};
    return [
      Object.values(mcqObj)
        .map((elem) => { 
          elem.answer.createdAt = new Date(elem.answer.createdAt);
          return elem 
        })
        .sort((a, b) => a?.answer?.createdAt - b?.answer?.createdAt),
      chatArr
    ];
  };

  const getCodingData = (data) => {
    let dataObj = {};
    const { traits, problems } = data;
    const newTraitsData = cloneDeep(traitsDataRef.current);
    
    Object.keys(traits).forEach((trait) => {
      const { name, problemIds } = traits[trait];
      problemIds.forEach((elem) => {
        const userScore = problems[elem]?.score?.userScore,
          maxScore = problems[elem]?.score?.maxScore;
        newTraitsData[trait].userData.userScore += (userScore || 0);
        newTraitsData[trait].userData.totalScore += (maxScore || 0);

        if (dataObj[elem]) {
          dataObj[elem].traits.add({ name, userScore, maxScore });
        } else {
          const traits = new Set([{ name, userScore, maxScore }]);
          const problemDetails = problems[elem];
          dataObj[elem] = { ...problemDetails, traits };
        }
      })
    });
    traitsDataRef.current = {...traitsDataRef.current, ...newTraitsData};
    return Object.values(dataObj)
      .map((elem) => { 
        elem.submission.createdAt = new Date(elem.submission.createdAt);
        return elem;
      })
      .sort((a, b) => a?.submission?.createdAt - b?.submission?.createdAt);
  };

  const getQuizDetailsFromTraits = async () => {
    if (traitsIds.length === 0) return;
    
    try {
      const response = await PdfReportApi.getQuizDetailsFromTraits(linkId, traitsIds.join(","));
      if (response?.success) {
        const [mcq, chat] = response.data ? getMcqAndChatData(response.data): [[], []];
        setMcqData(mcq);
        setChatData(chat);
      };
    } catch (err) {
      console.error(err);
    }
  };

  const getCodingDetailsFromTraits = async () => {
    if (traitsIds.length === 0) return;
    
    try {
      const response = await PdfReportApi.getCodingDetailsFromTraits(linkId, traitsIds.join(","));
      if (response?.success) {
        const coding = response.data ? getCodingData(response.data): [];
        setCodingData(coding);
      };
    } catch (err) {
      console.error(err);
    }
  };

  const scrollHandler = () => {
    const scrolledWindow = document.querySelector(".content-wrapper");
    if (scrolledWindow) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      scrolledWindow.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  
  return (
    <div className="bucket-detail-container">
      <div className="header--goBack" onClick={onBack}>
        <img className="icon" src="/img/right-arrow.png" alt="" />
        <span className="text">Back</span>
      </div>
      <Trait
        showInSingleRow
        separateMobileRow
        hideAverageLegends
        displayName={name}
        description={description}
        score={score}
        upperBound={upperBound}
        lowerBound={lowerBound}
        median={median}
        traitsMeta={traitsMeta}
        headerTraitColor={headerTraitColor}
        fillBlockColor={"grey"}
      />
      <div className="group-container">
        <h4>Skill wise performance</h4>
        <div className="group-container--traits" key={traitsDataRef.current}>
          {traitsDataRef.current &&
            Object.values(traitsDataRef.current)
            .filter(({ score }) => score !== null)
              .map((traitData, index, arr) => {
                const { 
                  userData: { userScore, totalScore }, 
                  upperBound: traitUb, 
                  lowerBound: traitLb, 
                  score: traitScore, 
                  median: traitMedian 
                } = traitData;
                const scoreString = totalScore ? `${roundOff(userScore)}/${roundOff(totalScore)}` : "";
                return (
                <Trait
                  {...traitData}
                  score={traitScore}
                  upperBound={traitUb?.score}
                  lowerBound={traitLb?.score}
                  median={traitMedian}
                  description={null}
                  scoreString={scoreString}
                  showInSingleRow
                  hideAverageLegends
                />
              )})}
        </div>
      </div>
      <QuestionsSection 
        codingData={codingData}
        mcqData={mcqData}
        chatData={chatData}
      />
      <ScrollIcon 
        className={'scroll-to-top'}
        onClick={scrollHandler}
      />
    </div>
  );
};
