import { createAsyncThunk } from "@reduxjs/toolkit";
import Toast from "src/components/Common/Toast";
import { POSITION_ARCHIVE_STATUS } from "src/pages/Positions/constants";
import PositionApi from "src/services/apis/positions";
import { handleError } from "src/utils/helperFunctions";
import CandidateApi from "../../services/apis/candidate";
import { positionStatsInitialState } from "./constant";

export const setPositions = createAsyncThunk(
  "positions/getPositions",
  async ({ status, type, sort }) => {
    try {
      const response = await PositionApi.listPositions(status, type, sort);
      if (response.success) {
        return response;
      }
    } catch (error) {
      handleError(error);
      return error;
    }
  }
);
export const updatePositions = createAsyncThunk(
  "positions/putPositions",
  async ({ positionId, orgId, orgType, payload }) => {
    try {
      const response = await PositionApi.updatePositionStatus({
        positionId, orgId, orgType, payload
      });
      if (response.success) {
        Toast('success', 'Position status updated successfully.');
        return { positionId, payload };
      }
    } catch (error) {
      handleError(error);
      return error;
    }
  }
);
export const deletePosition = createAsyncThunk(
  "positions/deletePosition",
  async (positionId) => {
    try {
      const response = await PositionApi.deletePosition(positionId);
      if (response.success) {
        // Toast("success", "Position Deleted Successfully!");
        return positionId;
      }
    } catch (error) {
      handleError(error);
      return error;
    }
  }
);
export const archievePosition = createAsyncThunk(
  "positions/archievePosition",
  async (params) => {
    try {
      const { positionId, status, drive = false } = params;
      const response = await PositionApi.archievePosition(positionId, status);
      if (response.success) {
        Toast(
          "success",
          `${drive ? "Drive" : "Position"} ${status === POSITION_ARCHIVE_STATUS.OPEN ? "Unarchived" : "Archived"
          } Successfully!`
        );
        return [positionId, status];
      }
    } catch (error) {
      handleError(error);
      return error;
    }
  }
);
// TODO: Need to get positionId & archive flag to make this flow work
// export const addPosition = createAsyncThunk(
//   "positions/addPosition",
//   async (payload) => {
//     try {
//       const response = await PositionApi.createPosition(payload);
//       if (response) {
//         // Toast("success", "Position created successfully!");
//         return response.result;
//       }
//     } catch (error) {
//       handleError(error);
//       return error;
//     }
//   }
// );

export const getUsers = createAsyncThunk(
  "positions/getUsers",
  async ({ positionId, filters }) => {
    try {
      const response = await PositionApi.getAllUsersByPosition(
        positionId,
        filters
      );
      if (response.success) {
        return response.result;
      }
    } catch (error) {
      handleError(error);
      return error;
    }
  }
);

export const getPositionStats = createAsyncThunk(
  "positions/getPositionStats",
  async (positionId) => {
    try {
      const response = await PositionApi.getPositionStats(positionId);
      if (response.success) {
        return response.result;
      }
    } catch (error) {
      // Change later - check for positionStats/dnaPositionStats -> Not to show error
      // handleError(error);
      return error;
    }
  }
);

export const resetPositionStats = createAsyncThunk(
  "positions/getPositionStats",
  async () => {
    try {
      return positionStatsInitialState.data
    } catch (error) {
      console.error(error)
    }
  }
)

export const updateCandidateHiringStatus = createAsyncThunk(
  "positions/updateCandidateHiringStatus",
  async ({ orgType, orgId, userId, status }) => {
    try {
      const response = await PositionApi.updateCandidateHiringStatus(
        orgType,
        orgId,
        userId,
        status
      );
      if (response.success) {
        return response.result;
      }
    } catch (error) {
      handleError(error);
      return error;
    }
  }
);

export const removeInvitedUser = createAsyncThunk(
  "positions/removeInvitedUser",
  async (payload) => {
    try {
      let response = await CandidateApi.deleteInvitedCandidate(payload);
      if (response.success) {
        // Toast("success", "Candidate deleted successfully");
        return payload;
      }
    } catch (error) {
      Toast("error", error?.statusText);
      return error;
    }
  }
);
