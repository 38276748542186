import StatsWithIcon from "src/components/Common/StatsWithIcon";
import { STATS_ENUM } from "src/pages/Positions/constants";
import "./Stats.scss";

export const DnaStats = ({ stats = {}, className = "", style = {} }) => {
  return (
    <div className={`dna-stats-component ${className}`} style={style}>
      {Object.keys(STATS_ENUM).map((statKey) => {
        const { text } = STATS_ENUM[statKey];
        if (text === STATS_ENUM.shortlisted.text) return null;

        const statValue = stats[statKey];
        // if (statKey === "shortlisted") return null;
        return (
          <StatsWithIcon
            key={statKey}
            // icon={icon}
            name={text}
            stat={statValue === -1 ? "0" : statValue}
            className="my-14 stat"
            titleNameStyle="text-darkblue"
          />
        );
      })}
    </div>
  );
};
