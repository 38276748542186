import React from "react";
import { Skeleton } from "antd";
import "./InfoCardShimmer.scss";

export default function InfoCardShimmer(props) {
  const { elementNumber } = props;
  return (
    <>
      {[...Array(elementNumber)].map((element, index) => {
        return (
          <div key={index} className="info-card-shimmer">
            <div className="mt-20">
              <Skeleton.Button
                className="mr-10"
                active
                size="small"
                // shape="circle"
                style={{ width: 160, height: 20, marginBottom: 20 }}
              />
              <Skeleton.Input
                size="small"
                style={{ width: 200, height: 40 }}
                active
              />
            </div>
            <div className="flex justify-between items-center flex-col mt-30">
              {[...Array(2)].map((value, index) => (
                <div className="flex justify-between w-100 my-10" key={index}>
                  <div className="flex items-center">
                    <Skeleton.Button
                      className="mr-10"
                      active
                      size="small"
                      style={{ width: 120, height: 80 }}
                    />
                    <Skeleton.Input
                      size="small"
                      style={{ width: 120, height: 80 }}
                      active
                    />
                  </div>
                </div>
              ))}
            </div>
            <Skeleton.Button
              className="mt-20"
              active
              size="small"
              shape="square"
              style={{ width: 260, height: 20 }}
            />
          </div>
        );
      })}
    </>
  );
}
