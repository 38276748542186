import React, { useMemo } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleFilled } from "@ant-design/icons";
import { QUESTIONS, QUIZ_DISPLAY_SECTIONS, TEST_CASE_STATUS } from "../../constant";
import { calculateTimeTaken, isObject, roundOff } from "src/utils/helperFunctions";
import ScorePills from "../ScorePills";

export const SectionContentHeader = (props) => {
  const { data, activeSection, markedCorrect } = props;

  const getScoreAndTraits = (data) => {
    const { traits } = data;
    let traitsArray = [],
      obtainedScore = 0,
      maxScore = 0,
      codingQuestionTitle = "",
      questionTimeTaken="";
    for (const entry of traits.values()) {
      traitsArray.push(entry);
    }
    if (activeSection === QUIZ_DISPLAY_SECTIONS.MCQ) {
      const { question, options, answer } = data;
      const { startedAt, completedAt } = answer;

      questionTimeTaken = calculateTimeTaken(startedAt, completedAt);
      /** TODO: REMOVE POST SANITY  */
      // const isScoreObject = isObject(answer?.score);
      // taking maxScore from answer object in case the score is an object. (new logic)
      // For below type of question -> Extract score from traitScore key
      // const traitScoreQuestionTypes = [QUESTIONS.CHAT, QUESTIONS.FREE_TEXT, QUESTIONS.SPEECH_SPONTANEOUS];
      // maxScore = question.maxScore;

      // const isSpeechQuestion = question.type.includes(QUESTIONS.SPEECH);
      // const isTypingText = question.type.includes(QUESTIONS.TYPING_TEXT);
      // const isFreeText = question.type.includes(QUESTIONS.FREE_TEXT);
      // const isSentenceCompletion = question.type.includes(
      //   QUESTIONS.SENTENCE_COMPLETION
      // );
      // let chosenAnswer = isSpeechQuestion
      //   ? null
      //   : options.find(
      //     (option) => option?.id === answer?.optionId && option?.correct
      //   );
      // if (chosenAnswer) {
      //   obtainedScore +=
      //     chosenAnswer.weight && chosenAnswer.weight >= 0
      //       ? chosenAnswer.weight
      //       : maxScore;
      // }
      // Check for score objects in the following cases
      // isTypingText, isFreeText, isSpeechQuestion (speech spontaneous)
      // Otherwise score remains a number
      // let answerScore = traitScoreQuestionTypes.includes(question.type) ?
      //   answer?.traitScore: isScoreObject ? 
      //     answer?.score?.userScore : answer?.score;
      /** TODO: REMOVE POST SANITY  */
      maxScore += traitsArray.reduce((sum, trait) => sum + trait.maxScore, 0)/traitsArray.length;
      obtainedScore = traitsArray.reduce((sum, trait) => sum + trait.userScore, 0)/traitsArray.length;
      // if (isSpeechQuestion) obtainedScore = answerScore;
      // if (isTypingText || isFreeText || isSentenceCompletion)
      //   obtainedScore = Math.round(answerScore * 100) / 100 || 0;
    } else {
      const {
        score: { userScore, maxScore: codingMaxScore },
        submission: { testcaseResults },
        title,
        completedAt,
        startedAt
      } = data;
      questionTimeTaken = calculateTimeTaken(startedAt, completedAt);
      codingQuestionTitle = title;
      if (codingMaxScore) {
        obtainedScore = traitsArray.reduce((sum, trait) => sum + trait.userScore, 0)/traitsArray.length;
        maxScore = codingMaxScore;
      } else {
        obtainedScore = testcaseResults.reduce((acc, { points, status }) =>
          acc + (status === TEST_CASE_STATUS.FAIL ? 0 : points), 0);
        maxScore = testcaseResults.reduce((acc, { points }) => acc + points, 0);
      }
    }
    return [roundOff(obtainedScore), roundOff(maxScore), traitsArray, codingQuestionTitle, questionTimeTaken];
  };

  const [
    obtainedScore, 
    maxScore, 
    traitsArray, 
    codingQuestionTitle="", 
    questionTimeTaken
  ] = useMemo(
    () => getScoreAndTraits(data),
    [data]
  );

  const isSpeechQuestion = data.question?.type.includes(QUESTIONS.SPEECH);
  const isSpeechScriptQuestion = data.question?.type.includes(QUESTIONS.SPEECH_SCRIPT);
  const isSpeechSpontaneousQuestion = data.question?.type.includes(QUESTIONS.SPEECH_SPONTANEOUS);
  const isTypingText = data.question?.type.includes(QUESTIONS.TYPING_TEXT);
  const isFreeText = data.question?.type.includes(QUESTIONS.FREE_TEXT);
  const isSentenceCompletion = data.question?.type.includes(
    QUESTIONS.SENTENCE_COMPLETION
  );
  const isChatQuestion = data.question?.type.includes(QUESTIONS.CHAT);

  const isSpeechQuestionCorrect = isSpeechQuestion && obtainedScore > 0;
  const iconStyleObject = { strokeWidth: "40px", stroke: "white" };
  
  // customText - Contains detailed scoring of third-party tools apart from answer.score 
  const customTextObject = data?.answer?.customText;
  const answerScore = isChatQuestion ? customTextObject : Array.isArray(data?.testcases) ? data?.score : (data?.answer?.score || 0);
  
  return (
    <>
      {codingQuestionTitle && <h5 className="coding-question-title mb-20 weight-600">{codingQuestionTitle}</h5>}
      <section className="section-content-header mb-2 flex items-center justify-between">
        <div className="header-start flex items-center justify-start">
          <div className="traits-map flex items-center justify-start">
            {traitsArray?.length > 0 &&
              traitsArray.map(({ name }, index) => (
                <div className={`${index === 0 ? "" : "ml-20"}`}>
                  <h2 key={name} className={`trait-box small v2`}>
                    {name}
                  </h2>
                </div>
              ))}
          </div>
          {/* later implementation */}
          {/* <div className="difficulty-text flex items-center justify-center ml-20">
          Difficulty Level -
          <span className="difficulty-level ml-4">{`${difficulty}`}</span>
        </div> */}
        </div>
        <div className="header-end flex items-center justify-between">
          {/* <ScorePills
            detailedScoreObject={customTextObject}
            score={answerScore} 
            contextAnalysis={data?.answer?.customText?.contextAnalysis ?? {}} 
          /> */}
          {isTypingText || isFreeText || isSentenceCompletion ? (
            <span
              className={`hidden score-indicator ${obtainedScore > 0 ? "correct" : "wrong"
                }`}
            >
              {obtainedScore > 0 ? (
                <CheckCircleOutlined style={iconStyleObject} />
              ) : (
                <CloseCircleOutlined style={iconStyleObject} />
              )}
            </span>
          ) : (
            <span
              className={`hidden score-indicator ${isSpeechQuestionCorrect || markedCorrect ? "correct" : "wrong"
                }`}
            >
              {isSpeechQuestionCorrect || markedCorrect ? (
                <CheckCircleOutlined style={iconStyleObject} />
              ) : (
                <CloseCircleOutlined style={iconStyleObject} />
              )}
            </span>
          )}
          {isTypingText || isFreeText || isSentenceCompletion ? (
            <span
              className={`score-box ${obtainedScore > 0 ? "" : ""}`}
            >
              {`${obtainedScore}/${maxScore}`}
            </span>
          ) : (
            <span
              className={`score-box ${isSpeechQuestionCorrect || markedCorrect ? "" : ""
                }`}
            >
              {`${obtainedScore}/${maxScore}`}
            </span>
          )}
          <span>Score</span>
          {
            isSpeechScriptQuestion || isSpeechSpontaneousQuestion
              ? <a
                href="https://unberry.notion.site/Communication-Score-c50716364bf34acaaea154d42453d84a"
                target="_blank"
                rel="noreferrer"
                className="mb-4"
              >
                <InfoCircleFilled />
              </a>
              : <></>
          }
        </div>
      </section>
      {
        questionTimeTaken &&
        <div className="question-time-taken flex items-center">
          <span className="">{`Time taken: ${questionTimeTaken}`}</span>
        </div>
      }
      <div className="flex items-center justify-start mb-20">
        {/* <ScorePills
          detailedScoreObject={customTextObject}
          score={answerScore} 
          contextAnalysis={data?.answer?.customText?.contextAnalysis ?? {}} 
        /> */}
      </div>
    </>
  );
};
