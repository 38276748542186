import mixpanel from "mixpanel-browser";
import { MIXPANEL_LS_KEY } from "src/constants/globalConstants";
import { storage } from "../config/storage";

const env_check = process.env.REACT_APP_ENV === "production";

if (env_check) mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const structureToSuperProps = ({ name, email, designation, organization }) => {
  // Structuring props to desired insights data
  const { dnaPositions } = organization;
  const dnaPositionsData = Object.keys(dnaPositions).reduce(
    (acc, positionName) => ({
      ...acc,
      [`Total number of people in My DNA for ${positionName}`]:
        dnaPositions[positionName],
    }),
    {}
  );

  const props = {
    Name: name,
    Email: email,
    Designation: designation,
    Company: organization.name,
    "Total admin members": organization.adminCount,
    "Total number of open positions": organization.openPositionCount,
    ...dnaPositionsData,
  };
  return props;
};

const actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props = {}) => {
    if ("Status" in props) {
      props.Status = props.Status ? "success" : "failure";
    }
    if (env_check) mixpanel.track(name, props);
  },
  register: (props) => {
    if (env_check) {
      const superProps = structureToSuperProps(props);
      storage.set.value(MIXPANEL_LS_KEY, superProps);
      mixpanel.register(superProps);
    }
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  reset: () => {
    if (env_check) mixpanel.reset();
  },
};

export const Mixpanel = actions;
