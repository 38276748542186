import { useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Avatar, Tabs } from "antd";
import { Button } from "antd";
import Settings from '../Settings';
import APIKeys from './APIKeys';
import { storage } from 'src/services/config/storage';
import { useResetStore } from 'src/store/useResetStore';
import { useIsMobileDevice } from 'src/utils/hooks';
import './AllSettings.scss'
import PageHelmet from 'src/components/Common/PageHelmet';

const AllSettings = () => {
    const [currentMenu, setCurrentMenu] = useState("profile")
    const history = useHistory()
    const resetStore = useResetStore()
    const organizations = useSelector((state) => state.organizations.list.data)
    const isMobile = useIsMobileDevice()

    const getOrganizationName = () => {
        return storage.get.organizationInfo().organizationName
    }

    const getOrganizationLogo = () => {
        return storage.get.organizationInfo().logo
    }

    const switchOrgHandler = () => {
        history.push("/select-organization")
        resetStore()
    }

    return (
        <>
        <PageHelmet title="Settings" />
        <div className='all-settings'>
            <div className="company-block">
                <div className="flex items-center justify-between">
                    <div className="flex gap-2">
                        <div className="company-name flex items-center">
                            {/* {getOrganizationLogo() ? (
                                <img
                                    className="organization-logo mr-10"
                                    src={getOrganizationLogo()}
                                    alt={`${getOrganizationName()} logo`}
                                />
                            ) : (
                                <div className="flex items-center gap-6">
                                    <Avatar
                                        className={`flex items-center mr-10 text-23 weight-600`}
                                    >
                                        {getOrganizationName()[0]}
                                    </Avatar>
                                    <p className="text-24 bold">{getOrganizationName()}</p>
                                </div>
                            )} */}
                            <p className="text-24 bold">{getOrganizationName()}</p>
                        </div>
                        {organizations.length > 1 && (
                            <Button
                                className="ant-btn-primary text-16 weight-400 px-20 py-8 ml-5"
                                onClick={switchOrgHandler}
                            >
                                Switch Organization
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className={`content-wrapper flex justify-start ${isMobile ? "flex-col items-center" : "items-start"}`}>
                    <Tabs
                        type="card"
                        defaultActiveKey="profile"
                        tabPosition={isMobile ? "top" : "left"}
                        items={[
                            {
                                key: "profile",
                                label: "Team Members",
                            },
                            {
                                key: "api-keys",
                                label: "API Keys",
                            }
                        ]}
                        onChange={(key) => {
                            setCurrentMenu(key)
                        }}
                    />
                    <div className={`flex-1 ${isMobile ? "mobile-tab-content" : ""}`}>
                        {
                            currentMenu === "profile" ? <Settings /> : <APIKeys />
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AllSettings
