import React from 'react';
import './Page.scss';
export default function Page(props) {
  const { children, footer } = props;

  return (
    <page className="page">
      {/* <div className="py-30 px-40"> */}
        {children}
        {footer && (
          <div className="footer flex flex-col">
            <div className="footer-divider"></div>
            <div className="flex justify-between">
              <p className="text-black weight-500">
                INDIVIDUAL FEEDBACK REPORT
              </p>
              <p className="text-black weight-500">
                PAGE {} of {}
              </p>
            </div>
          </div>
        )}
      {/* </div> */}
    </page>
  );
}
