import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./PdfReport.scss";
import Page from "./Page";
import UserProfile from "./UserProfile";
import NoteBox from "./NoteBox";
import Text from "./Text";
import AboutTraits from "./AboutTraits";
import Bucket from "./Bucket";
import PdfReportApi from "../../services/apis/pdfReport";
import RecommendedScale from "./RecommendedScale";
import SpiderChart, { Legend } from "./SpiderChart";
import DriverDerailerTrait from "./DriverDerailerTrait";
import TraitsCard from "./TraitsCard";
import AttentionTrait from "./AttentionTrait";
import { orderBuckets } from "./operations";
import NotFound from "./NotFound";
import { getDefaultFormattedDate, sort } from "src/utils/helperFunctions";
import { UnberryLogo } from "src/assets/icons";

/**
 * generates the PDF report for a user
 * url to fetch report: {domain}/pdf-report/link/{linkId}/users/{userId}
 */
export default function PdfReport() {
  const { linkId, userId } = useParams();

  const [buckets, setBuckets] = useState([]);
  const [bucketChunks, setBucketChunks] = useState([]);
  const [user, setUser] = useState({ username: "Avinash Tiwari" });
  const [position, setPosition] = useState({ name: "Software Engineer" });
  const [reportDate, setReportDate] = useState("");
  const [spiderChartTraits, setSpiderChartTraits] = useState({});
  const [spiderChartScores, setSpiderChartScores] = useState([]);
  const [standOutTraits, setStandOutTraits] = useState([]);
  const [probeTraits, setProbeTraits] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [derailers, setDerailers] = useState([]);
  const [fitScore, setFitScore] = useState({});

  const getSpiderChartTraits = (data) => {
    const traits = {};
    for (const score of data) {
      const scoreKey = score.name.replace(" ", "");
      traits[scoreKey] = score.displayName;
    }
    setSpiderChartTraits(traits);
  };

  const getSpiderChartScores = (scores) => {
    const formattedScore = {};
    for (const score of scores) {
      const scoreKey = score.name.replace(" ", "");
      formattedScore[scoreKey] = score.score / 10 || 0;
    }
    return formattedScore;
  };

  const formatSpiderChartData = (data) => {
    getSpiderChartTraits(data.userTraits);
    const userScores = getSpiderChartScores(data.userTraits);
    const globalScores = getSpiderChartScores(data.globalTraits);
    setSpiderChartScores([userScores, globalScores]);
  };

  const createChunks = (arr, chunk = 2) => {
    const chunks = [];
    for (let i = 0, j = arr.length; i < j; i += chunk) {
      chunks.push(arr.slice(i, i + chunk));
    }
    return chunks;
  };

  const getDriversDerailers = (data) => {
    let traitsData = [];
    Object.values(data).forEach((traitData) => {
      traitsData = [...traitsData, ...traitData];
    });
    return traitsData;
  };

  /**
   * Get User Score By UserId And LinkId
   * @param {string} userId
   * @param {string} linkId
   * @returns {object} data object
   */
  const getUserScoreByUserIdAndLinkId = async (userId, linkId) => {
    try {
      const response = await PdfReportApi.getUserScore(userId, linkId);
      if (response.success) {
        return response.data;
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(async () => {
    try {
      if (userId && linkId) {
        const {
          buckets,
          position,
          user,
          spiderChart,
          standOutTraits,
          probeTraits,
          fitScore,
          drivers,
          derailers,
          completedDate,
        } = await getUserScoreByUserIdAndLinkId(userId, linkId);

        setReportDate(completedDate);

        const orderedBucket = orderBuckets(buckets);
        setBuckets(orderedBucket);
        setBucketChunks(createChunks(orderedBucket));
        setUser(user);
        setPosition(position);
        formatSpiderChartData(spiderChart);
        setStandOutTraits(standOutTraits);
        setProbeTraits(probeTraits);
        setDrivers(getDriversDerailers(drivers));
        setDerailers(getDriversDerailers(derailers));
        setFitScore(fitScore);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  return (
    <div className="first-report-page">
      <Page>
        <span className="pdf-unberry-logo">
          <UnberryLogo />
        </span>

        <div className="info-section">
          <p className="text-grey text-info weight-400 size-22">
            {getDefaultFormattedDate(reportDate) || "--"}
          </p>
          <h1 className="mt-15 size-45 weight-600">
            Individual Unberry Profile{" "}
          </h1>
          <h2 className="size-22 text-grey weight-400">
            For the position of{" "}
            <span className="text-blue">{position.name}</span>
          </h2>
          <UserProfile
            className="profile-style"
            username={user.name || user.email}
            position={position.name}
            organization={position.organizationName}
            imageUrl={user.media}
          />
        </div>

        <div className="mt-40 img-hero-style">
          <img src="/img/cubes.png" alt="profil-pic" className="img-big" />
        </div>
      </Page>
      <Page>
        <h2 className="size-31 weight-500 mb-16">About this report</h2>
        <Text
          className="size-13 weight-400"
          text="This report reflects the candidate’s work-relevant personality and behavioural traits, different dimensions of their thinking and learning styles, and their ability to handle stress during unforeseen situations, which might help predict their success at a particular job."
        />
        <NoteBox
          importantText="Remember:"
          className="mt-16"
          text="The information contained within this report is not intended to be definitive; it is to be seen as a starting point for discussing the candidate’s development plans in the context of their current role or future career potential."
        />
        <div className="competency-traits">
          <h2 className="size-22 weight-500 mb-16">The Competency Traits</h2>
          <Text
            className="size-13 weight-400"
            text="This report is organised into four core competency profiles that in turn, contain a unique set of psychological traits. These traits are grounded in validated personality and cognitive neuroscience frameworks, and are known to be reliable indicators of how someone is likely to behave in the workplace."
          />
        </div>
        <div className="traits-wrapper mt-33">
          {buckets.map((bucket) => {
            const {
              name = "",
              description,
              traitsMeta,
              customText,
              media,
            } = bucket;
            const traitsArray = Object.values(traitsMeta).map((trait) => {
              const { displayName: name, description } = trait;
              return { name, description };
            });
            return (
              <AboutTraits
                className={""}
                traitName={name}
                traitDescription={description}
                subTraits={traitsArray}
                icon={media}
              />
            );
          })}
        </div>
      </Page>
      <Page>
        <h3 className="size-31 weight-500 mt-0 mb-33">
          How to read this report
        </h3>
        <h4 className="size-22 weight-500 mb-16">To best use this report:</h4>
        <Text
          className="size-13 weight-400"
          text="When interpreting the results, it is important to remember that the scores are not good or bad, only more or less appropriate to certain types of work and companies. This is not a test, which can be passed or failed. From this snapshot of the candidate, we can develop some ideas about the way they are likely to behave in different workplace situations.  "
        />
        <h4 className="weight-500 size-22 mt-44 mb-16">
          How to read the competency ratings?
        </h4>
        <Text
          className="size-13 weight-400"
          text="We report scores on a scale ranging from 1 to 10, based on population norms. Each pole describes cognitive
          and behavioral tendencies. The closer a score is to the pole, the more descriptive it is of the participant."
        />
        <img
          src="/img/competency-rating-info.png"
          alt="competency-rating-info"
          className="mt-22 mb-33"
        />
        <h4 className="weight-500 size-22 mb-16">What is the fit band?</h4>
        <Text
          className="size-13 weight-400 mb-22"
          text="The fit band is calculated based on how well the candidate’s gameplay matches with the gameplay of top performers. The bands are broken down into four ranges (Highly Recommended, Recommended, Cautiously Recommended and Not Recommended)"
        />
        <img src="/img/fit-band-info.png" alt="fit-band-info" />
      </Page>
      {bucketChunks.map((buckets) => {
        return (
          <Page>
            {buckets.map((bucket) => {
              const { name, description, media, traitsMeta, customText } =
                bucket;
              const traitsData = sort(Object.values(traitsMeta), "order")
              return (
                <Bucket
                  name={name}
                  icon={media}
                  description={description}
                  colorCode={customText.colorCode}
                  traits={traitsData}
                />
              );
            })}
          </Page>
        );
      })}

      <Page>
        <h3 className="size-31 weight-500">Interpreting the report</h3>
        <RecommendedScale {...fitScore} />
        <div className="divider"></div>
        <div className="radar-wrapper flex">
          <div className="flex flex-col justify-between">
            <div>
              <h2 className="mb-10 weight-400 size-17">
                {user.name || user.email} versus People in {position.name}
              </h2>
              <Text
                text={`This chart represents how ${
                  user.name || user.email
                } performed compared to other people in the ${
                  position.name
                } domain. These 5 traits have been found to be most relevant for high-performing engineers.`}
                className="mb-20 size-11 weight-400"
              />
            </div>
            <Legend
              legends={[
                `${user.name || user.email}`,
                `People in ${position.name} Role`,
              ]}
            />
          </div>
          <SpiderChart
            paramsMap={spiderChartTraits}
            scores={spiderChartScores}
          />
        </div>
        <div className="divider"></div>
        <div>
          <h2 className="mb-21 weight-400 size-17">Traits that stand out</h2>
        </div>
        {standOutTraits.length > 0 ? (
          <>
            <div className="grid grid-cols-3 col-gap-10">
              {standOutTraits.map((card) => {
                const { traitDisplayName, tag, tagColor, statement } = card;
                return (
                  <TraitsCard
                    title={traitDisplayName}
                    tag={tag}
                    tagColor={tagColor}
                    statement={statement}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <NotFound heading="No traits found" />
        )}
      </Page>
      <Page>
        <h2 className="weight-400 size-17 mb-23">Traits that need attention</h2>
        {probeTraits.length > 0 ? (
          <>
            <div className="attention-trait-wrapper">
              {probeTraits.map((trait) => {
                const {
                  traitDisplayName,
                  statement,
                  howToProbe,
                  tag,
                  score,
                  colorCode,
                } = trait;
                return (
                  <AttentionTrait
                    title={traitDisplayName}
                    statement={statement}
                    note={howToProbe}
                    tag={tag}
                    score={score}
                    color={colorCode}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <NotFound heading="No probing traits found" />
        )}
        <div className="divider"></div>
        <div className="grid grid-cols-2 col-gap-20">
          <div className="driver">
            <h3 className="weight-400 size-17 mb-31">
              What might drive him at work
            </h3>
            {drivers.length > 0 ? (
              <div className="factors flex flex-col">
                {drivers.map((driver) => {
                  const { heading, text } = driver;
                  return (
                    <DriverDerailerTrait
                      type="driver"
                      title={heading}
                      statement={text}
                    />
                  );
                })}
              </div>
            ) : (
              <NotFound heading="No drivers found" />
            )}
          </div>
          <div className="derailer">
            <h3 className="weight-400 size-17 mb-31">
              What might derail him at work
            </h3>
            {derailers.length > 0 ? (
              <div className="factors flex flex-col">
                {derailers.map((derailer) => {
                  const { heading, text } = derailer;
                  return (
                    <DriverDerailerTrait
                      type="derailer"
                      title={heading}
                      statement={text}
                    />
                  );
                })}
              </div>
            ) : (
              <NotFound heading="No derailers found" />
            )}
          </div>
        </div>
      </Page>
      <Page>
        <div className="about-unberry-wrapper flex flex-col justify-between">
          <span className="pdf-unberry-logo">
            <UnberryLogo />
          </span>
          <div className="about-unberry">
            <h4 className="size-31 weight-500">About Unberry</h4>
            <p className="size-15 weight-400 mt-22">
              We are combining game technology, data science and neuroscience to
              surface precise signals and disrupt the way companies recruit,
              retain and develop talent.
            </p>
            <p className="size-15 weight-400 mt-22">
              Visit the website{" "}
              <a href="https://unberry.com/">https://unberry.com/</a>
            </p>
            <p className="size-15 weight-400 mt-11">
              © Unberry 2021. All rights reserved. Personal and Confidential.
            </p>
            <p className="size-11 weight-400 mt-20">
              This report was generated using Unberry’s Online Assessment
              System. The access to this report is limited to those people who
              have received special permissions for its use and interpretation.
              The report herein is generated from the results of an activity
              undertaken by the respondent(s) and substantially reflects the
              responses made by them. Due consideration must be given to the
              subjective nature of the scores and ratings in the interpretation
              of this data. This report has been generated electronically – the
              user of the software can make amendments and additions to the text
              of the report. HideUnhide Private Limited and its associated
              companies cannot guarantee that the contents of this report are
              the unchanged output of the computer system. We can accept no
              liability for the consequences of the use of this report and this
              includes liability of every kind (including negligence) for its
              contents. As such, HideUnhide Private Limited permits its client
              to reproduce, distribute, amend and store this report for its
              internal and non-commercial use only. All other rights of
              HideUnhide Private Limited are reserved.
            </p>
          </div>
        </div>
      </Page>
    </div>
  );
}
