import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Headers.scss";
import { QUERY_PARAMS_CONSTANTS } from "src/constants/queryConstants";
import { useUrlFilter } from "src/utils/hooks";
import { SortDownIcon, SortUpIcon } from "src/assets/icons";
import { LOGIN_TYPE } from "src/constants/globalConstants";

export default function Headers(props) {
  const { headerLayout, headers, className, positionLoginType } = props;
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useUrlFilter();

  useEffect(() => {
    if (filters && QUERY_PARAMS_CONSTANTS.SORT in filters) {
      const sortKeys = filters[QUERY_PARAMS_CONSTANTS.SORT].split(":");
      const sortObj = {};
      sortKeys.forEach((key) => {
        if (key.includes("-")) {
          sortObj[key.slice(1)] = "DESC";
        } else {
          sortObj[key] = "ASC";
        }
      });
      setSortOrder(sortObj);
    }
  }, [filters]);

  const showSortSign = (sortKey) => {
    switch (sortOrder[sortKey]) {
      case "ASC":
        return (
          <div title="in ascending">
            <SortUpIcon />
          </div>
        );
      case "DESC":
        return (
          <div title="in descending">
            <SortDownIcon />
          </div>
        );
      default:
        return (
          <div title="click to sort">
            <SortUpIcon />
            <SortDownIcon />
          </div>
        );
    }
  };

  const formatSortingValues = (sortingObj) => {
    const sortArr = Object.entries(sortingObj).map(([sortKey, sortType]) => {
      if (sortKey) {
        if (sortType === "ASC") {
          return sortKey;
        } else if (sortType === "DESC") {
          return `-${sortKey}`;
        }
      }
    });
    const newFilter = { ...filters };
    if (sortArr.length) {
      newFilter[QUERY_PARAMS_CONSTANTS.SORT] = sortArr.join(":");
    } else {
      delete newFilter[QUERY_PARAMS_CONSTANTS.SORT];
    }
    setFilters({
      ...newFilter,
    });
  };

  const sortHandler = (sortKey) => {
    const newSortOrder = { ...sortOrder };
    switch (sortOrder[sortKey]) {
      case "ASC":
        newSortOrder[sortKey] = "DESC";
        break;
      case "DESC":
        delete newSortOrder[sortKey];
        break;
      default:
        newSortOrder[sortKey] = "ASC";
        break;
    }
    setSortOrder(newSortOrder);
    formatSortingValues(newSortOrder);
  };

  return (
    <div className={`table-header 
      ${className === "test-cases-header" ? "test-cases-header" : ""}`
    }>
      <div
        className={`grid ${className}`}
        style={{ gridTemplateColumns: headerLayout }}
      >
        {headers.map((header, index) => {
          if (typeof header === "object") {
            return (
              <div
                key={index}
                className="color-grey size-11 weight-500 cursor-pointer flex items-center"
                onClick={() => sortHandler(header.sortKey)}
              >
                {header.title}{" "}
                <span className="ml-10 flex">
                  {showSortSign(header.sortKey)}
                </span>
              </div>
            );
          }
          return (
            <div className={`color-grey size-11 weight-500 ${positionLoginType === LOGIN_TYPE.THIRD_PARTY && index === 0
              ? "ml-44" : ""}`} key={index}>
              {header}
            </div>
          );
        })}
      </div>
    </div>
  );
}

Headers.propTypes = {
  headerLayout: PropTypes.string,
  headers: PropTypes.array,
};

Headers.defaultProps = {
  headerLayout: "",
  headers: [],
};
