import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from "./routes";
import { useIsMobileDevice } from "src/utils/hooks";
import { REFRESH_SESSION_EVENT, REFRESH_SESSION_FORM_VIEW } from "src/constants/globalConstants";
import RefreshSessionForm from "src/forms/RefreshSessionForm/form";
import { UnberryLogo } from "src/assets/icons";

export default function AppRouter() {
  const isMobile = useIsMobileDevice();
  const [refreshSession, setRefreshSession] = useState(null);
  /**
   * Using customEvent webAPI to pass data to toggle form views 
   * window.dispatchEvent(new CustomEvent(REFRESH_SESSION_EVENT, { detail: (REFRESH_TOKEN || OTP_LOGIN) }));
   * Implemented in request.js
   */
  const showRefreshSessionForm = (e) => setRefreshSession(e.detail);

  useEffect(() => {
    window.addEventListener(REFRESH_SESSION_EVENT, showRefreshSessionForm);

    return () => {
      window.removeEventListener(REFRESH_SESSION_EVENT, showRefreshSessionForm);
    };
  }, []);

  /**
   * Form flow is designed to handle requests with status codes 401, 403
   * Unauthorized, Forbidden token requests.
   * When the otp-login/refresh-session flow is successful -> Reload the window while retaining the current pathname.
   */

  if (refreshSession) {
   return (
    <RefreshSessionForm 
      refreshSession={refreshSession} 
    />
   );
  };

  if (
    false &&
    isMobile &&
    process.env.REACT_APP_ENV !== "development" &&
    !window.location.pathname.includes("report")
  ) {
    return (
      <div className="screen-only">
        <UnberryLogo />
        <div className="screen-only-message">
          <img
            className="mb-20 width-100"
            src="/img/change-device.png"
            alt="change-device"
          />
          <div className="flex flex-col justify-center">
            <p className="mb-0 text-center uppercase text-white align-center">
              We're coming soon on mobile
            </p>
            <p className="text-center uppercase text-white align-center">
              Use your laptops or desktops to go through the Unberry experience
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/hiring" />} />

        {PUBLIC_ROUTES.map((route, index) => (
          <Route
            exact
            path={route.path}
            component={route.component}
            key={index}
          />
        ))}

        {PROTECTED_ROUTES.map((route, index) => (
          <ProtectedRoute
            key={index}
            exact
            path={route.path}
            component={route.component}
            showHeader={route.showHeader}
            showMobileHeader={route.showMobileHeader}
          />
        ))}
      </Switch>
    </Router>
  );
}
