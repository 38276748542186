import React from "react";
import { Skeleton } from "antd";
import "./UsersListingShimmer.scss";

export default function UsersListingShimmer(props) {
  const { elementNumber } = props;
  return (
    <>
      {[...Array(elementNumber)].map((element, index) => {
        return (
          <div key={index} className="user-listing-shimmer">
            <div className="flex items-center">
              <Skeleton.Button
                className="mr-10"
                active
                size="small"
                shape="circle"
                style={{ width: 30, height: 30 }}
              />
              <Skeleton.Input
                size="small"
                style={{ width: 150, height: 20 }}
                active
              />
            </div>
            {[...Array(2)].map((value, index) => (
              <div className="flex flex-col justify-between" key={index}>
                <Skeleton.Input
                  size="small"
                  style={{ width: 60, height: 15 }}
                  active
                  className="mb-4"
                />
                <Skeleton.Input
                  size="small"
                  style={{ width: 100, height: 15 }}
                  active
                />
              </div>
            ))}
            <Skeleton.Input
              size="small"
              style={{ width: 100, height: 15 }}
              active
            />
          </div>
        );
      })}
    </>
  );
}
