import "./FilterIcon.scss";
export const FilterIcon = ({ className, ...props }) => {
  return (
    <div className={`filter-icon-component ${className}`} {...props}>
      <div className="filter-bars"></div>
      <div className="filter-bars"></div>
      <div className="filter-bars"></div>
    </div>
  );
};
