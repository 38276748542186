import React, { useEffect, useState } from "react"
import Modal from "src/components/Common/Modal";
import SnapshotsContainer from "./ProctoredMedia/SnapshotsContainer";

const SnapshotsModal = ({
    allSnapshots,
    criticalSnapshots,
    isSnapshotsModalVisible,
    setIsSnapshotsModalVisible,
    linkConfig,
    positionConfig,
}) => {
    const [currentFilter, setCurrentFilter] = useState('')

    useEffect(() => {
        if (isSnapshotsModalVisible) {
            setCurrentFilter('all')
        }
    }, [isSnapshotsModalVisible])


    return (
        <Modal
            modalVisible={isSnapshotsModalVisible}
            cancelModal={() => {
                setIsSnapshotsModalVisible(false);
            }}
            centered
            width={'fit-content'}
            wrapClassName='snapshots-wrap'
            className='snapshots-modal'
        >
            <SnapshotsContainer
                allSnapshots={allSnapshots}
                criticalSnapshots={criticalSnapshots}
                currentFilter={currentFilter}
                setCurrentFilter={setCurrentFilter}
                linkConfig={linkConfig}
                positionConfig={positionConfig}
            />
        </Modal>
    )
}

export default SnapshotsModal
