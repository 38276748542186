import { useState, useEffect, useRef } from "react";
import { Segmented, Slider } from 'antd';
import {
    PlayCircleFilled,
    PauseCircleFilled,
    LoadingOutlined,
    UndoOutlined,
    FastBackwardOutlined,
    FastForwardOutlined
} from '@ant-design/icons';
import { getFormattedSeekValue } from "src/utils/helperFunctions";
import { VIDEO_SKIP } from "../../constant";
import "./VideoModal.scss"

const VideoPlayer = ({ loading,
    setLoading,
    sessionVideoUrls,
    currentVideoIndex,
    setCurrentVideoIndex,
    isPlaying,
    setIsPlaying,
    seekValue,
    setSeekValue,
    isSessionVideoEnded,
    setIsSessionVideoEnded,
    canPlayVideoLoading,
    setCanPlayVideoLoading
}) => {
    const [playbackRate, setPlaybackRate] = useState(1)
    const skipVideo = useRef("")
    const videoElement = useRef()

    useEffect(() => {
        /**
         * @param {playbackRate} is updated of the video.
         * @param {skipVideo.current} is true if video is skipped. In that case the current time of the video needs to 
         * be updated accurately.
         * Firstly, video index is changed in @param {handleVideoSkip}
         * seekValue is the value when user presses the skip button. 
         * Number(seekValue) % 5 gives us the number of seconds starting from 0.
         * For instance, in a backward skip of 10 seconds at 0:33,
         * Number(33) % 5 will give us 3 which should be the currentTime of the video at updated index.
         * Reset @param {skipVideo} to false
         */
        const handleCanPlay = () => {
            videoElement.current.playbackRate = playbackRate
            if (skipVideo.current) {
                videoElement.current.currentTime = Number(seekValue) % VIDEO_SKIP.CLIP_LENGTH
                skipVideo.current = false
            }
            videoElement?.current?.play()
            setCanPlayVideoLoading(false)
            setIsPlaying(true)
        }
        videoElement?.current?.addEventListener("canplay", handleCanPlay)

        return () => videoElement?.current?.removeEventListener("canplay", handleCanPlay)
    }, [currentVideoIndex])

    useEffect(() => {
        if (videoElement?.current) {
            videoElement.current.playbackRate = playbackRate
        }
    }, [playbackRate])

    useEffect(() => {
        if (seekValue === sessionVideoUrls.length * VIDEO_SKIP.CLIP_LENGTH && seekValue !== 0) {
            setIsSessionVideoEnded(true)
            setCanPlayVideoLoading(false)
        } else {
            setIsSessionVideoEnded(false)
        }
    }, [seekValue])

    const handleVideoEnd = () => {
        if (currentVideoIndex === sessionVideoUrls.length - 1) {
            setIsPlaying(false)
        } else {
            setCanPlayVideoLoading(true)
            setCurrentVideoIndex((prevIndex) => prevIndex + 1)
        }
    }

    const handleVideoProgress = () => {
        if (videoElement?.current?.currentTime) {
            const seekVal = (currentVideoIndex * VIDEO_SKIP.CLIP_LENGTH) + Math.floor(videoElement?.current?.currentTime)
            setSeekValue(seekVal)
        }
    }

    const playAndPauseVideo = () => {
        if (!videoElement.current) return

        if (videoElement.current.paused) {
            videoElement.current.playbackRate = playbackRate
            videoElement.current.play()
            setIsPlaying(true)
        } else {
            videoElement.current.pause()
            setIsPlaying(false)
        }
    }

    const handleSeekChange = (value) => {
        const newVideoIndex = Math.floor(value / VIDEO_SKIP.CLIP_LENGTH)
        const seekVal = newVideoIndex * VIDEO_SKIP.CLIP_LENGTH
        setCurrentVideoIndex(newVideoIndex)
        setCanPlayVideoLoading(true)
        setSeekValue(seekVal)
    }

    const handleAfterSeekChange = (value) => {
        const newVideoIndex = Math.floor(value / VIDEO_SKIP.CLIP_LENGTH)
        const currentTime = Math.floor(value % VIDEO_SKIP.CLIP_LENGTH)
        setCurrentVideoIndex(newVideoIndex)
    }

    const handleReplaySessionVideo = () => {
        setCurrentVideoIndex(0)
        setIsPlaying(true)
    }

    /**
     * Set the @param {skipVideo} boolean to true.
     * Increment current video index in case of forward otherwise decrement.
     * After this the control flow goes to useEffect with currentVideoIndex dependency.
     */
    const handleVideoSkip = (skipAction) => {
        skipVideo.current = true
        switch (skipAction) {
            case VIDEO_SKIP.ACTIONS.BACKWARD:
                setCurrentVideoIndex(prevIndex => {
                    const newIndex = prevIndex - (VIDEO_SKIP.SKIP_VAL / VIDEO_SKIP.CLIP_LENGTH)
                    if (newIndex < 0) return 0
                    return newIndex
                })
                break
            case VIDEO_SKIP.ACTIONS.FORWARD:
                setCurrentVideoIndex(prevIndex => {
                    const newIndex = prevIndex + (VIDEO_SKIP.SKIP_VAL / VIDEO_SKIP.CLIP_LENGTH)
                    if (newIndex > sessionVideoUrls.length) return sessionVideoUrls.length
                    return newIndex
                })
                break
            default: return
        }
    }

    return (
        <>
            <div className="video-container relative mb-10">
                {
                    loading
                        ? <div className="absolute top-0 left-0 loader-container flex items-center justify-center b-12">
                            <LoadingOutlined />
                        </div>
                        : sessionVideoUrls.length === 0
                            ? <div className="absolute top-0 left-0 loader-container flex items-center justify-center b-12">
                                <p className="text-18 weight-600 clr-gray">No video found</p>
                            </div>
                            :
                            <div>
                                {
                                    canPlayVideoLoading
                                        ? <div className="absolute top-0 left-0 loader-container loader-overlay flex items-center justify-center b-12">
                                            <LoadingOutlined />
                                        </div> : <></>
                                }
                                <video
                                    ref={videoElement}
                                    src={sessionVideoUrls[currentVideoIndex]?.link}
                                    onEnded={handleVideoEnd}
                                    onTimeUpdate={handleVideoProgress}
                                    className="video-player top-0 left-0 b-12"
                                />
                            </div>
                }
            </div>
            {
                !loading
                    ? <>
                        <Slider
                            disabled={sessionVideoUrls?.length === 0}
                            tooltip={{
                                formatter: getFormattedSeekValue,
                            }}
                            min={0}
                            max={sessionVideoUrls?.length * VIDEO_SKIP.CLIP_LENGTH}
                            value={seekValue}
                            onChange={handleSeekChange}
                            onAfterChange={handleAfterSeekChange}
                        />
                        <div className={`controls-container w-80 flex items-center justify-between ${sessionVideoUrls.length === 0 || canPlayVideoLoading ? "disabled opacity-6" : ""}`}>
                            <div className="flex items-center">
                                <button
                                    className={`default-btn flex items-center`}
                                    onClick={isSessionVideoEnded ? handleReplaySessionVideo : playAndPauseVideo}
                                >
                                    {
                                        isSessionVideoEnded
                                            ? <UndoOutlined />
                                            : !isPlaying ? <PlayCircleFilled /> : <PauseCircleFilled />
                                    }
                                </button>
                                <span
                                    onClick={() => handleVideoSkip(VIDEO_SKIP.ACTIONS.BACKWARD)}
                                    className={`skip-btn cursor-pointer ml-14 ${seekValue < VIDEO_SKIP.SKIP_VAL ? "disabled opacity-6" : ""}`}
                                >
                                    <FastBackwardOutlined />
                                </span>
                                <p className="text-14 ml-10 mr-10">
                                    {getFormattedSeekValue(seekValue)}
                                </p>
                                <span
                                    onClick={() => handleVideoSkip(VIDEO_SKIP.ACTIONS.FORWARD)}
                                    className={`skip-btn cursor-pointer ${seekValue >= ((sessionVideoUrls?.length - (VIDEO_SKIP.SKIP_VAL / VIDEO_SKIP.CLIP_LENGTH)) * VIDEO_SKIP.CLIP_LENGTH) 
                                    ? "disabled opacity-6" : ""}`}
                                >
                                    <FastForwardOutlined />
                                </span>
                            </div>
                            <Segmented
                                options={[
                                    {
                                        label: "1x",
                                        value: 1
                                    },
                                    {
                                        label: "1.5x",
                                        value: 1.5
                                    },
                                    {
                                        label: "2x",
                                        value: 2
                                    },
                                ]}
                                value={playbackRate}
                                onChange={setPlaybackRate}
                            />
                        </div>
                    </>
                    : <></>
            }
        </>
    )
}

export default VideoPlayer

