import React from "react";
import { Modal as AntdModal } from "antd";
import "./Modal.scss";
import { CloseIcon } from "src/assets/icons";

const Modal = (props) => {
  const { className, children, modalVisible, cancelModal, onModalClose } = props;
  return (
    <AntdModal
      footer={null}
      closeIcon={<CloseIcon />}
      destroyOnClose
      {...props}
      className={`general-modal ${className}`}
      visible={modalVisible}
      onCancel={cancelModal}
      afterClose={onModalClose}
      centered
    >
      {children}
    </AntdModal>
  );
};

Modal.propTypes = {};

export default Modal;
