import EmailPermissionForm from "src/forms/EmailPermissionForm";
import "./AdminEmailPermission.scss";

export default function AdminEmailPermission(props) {
  return (
    <div className="position-container-form">
      <div className="first-half">
        <p className="heading">{`Receive notifications for selected positions`}</p>
      </div>
      <div className="second-half">
        <EmailPermissionForm 
          {...props}
        />
      </div>
    </div>
  );
}
