import * as Yup from "yup";
import { Button } from "antd";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "src/store/Positions/reducer";
import { AntInput } from "src/components/Common/CreateAntFields/CreateAntFields";
import Modal from "src/components/Common/Modal";
import Toast from "src/components/Common/Toast";
import CandidateApi from "src/services/apis/candidate";

const INITIAL_VALUES = {
	name: '',
	email: '',
	updatedEmail: '',
	candidateKey: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email(),
  updatedEmail: Yup.string()
    .required("Required")
    .email("Enter a valid email")
    .test('email-match', 'Emails must not match', function (value) {
      return value !== this.resolve(Yup.ref('email'));
    }),
});

const UpdateCandidateEmailForm = ({
	showModal,
	setShowModal,
	candidateData,
	activeApiKey,
	userId
}) => {

	const dispatch = useDispatch();
	const [formValues, setFormValues] = useState(INITIAL_VALUES);

	useEffect(() => {
		setFormValues({ ...INITIAL_VALUES, ...candidateData, updatedEmail: candidateData?.email });
	}, [candidateData]);

	const onCancelHandler = () => {
		setFormValues(INITIAL_VALUES);
		setShowModal(false);
	};

	const updateCandidateEmail = async (values) => {
		const payload = {
			email: values.updatedEmail,
			candidateKey: values.candidateKey
		};
		const customHeaders = {
			'unberry-api-key' : activeApiKey,
			'Content-Type': 'application/json',
		};
		try {
			const response = await CandidateApi.updateCandidateEmail(payload, customHeaders);
			if (response.success) {
				dispatch(updateUser({ userId, updatedEmail: payload.email }));
				Toast("success", "Email updated successfully");
			}
		} catch (err) {
			console.error(err);
			Toast("error", err.message);
		} finally {
			onCancelHandler();
		}
	}

  return (
    <Modal
			modalVisible={showModal}
			cancelModal={onCancelHandler}
			width="380px"
			centered
			className="invite-candidate-modal"
    >
			<div className="invite-modal-content">
				<div className="text-22 semibold w-75 line-1 pb-30 modal-heading">
					Update Email
				</div>
			</div>
			<Formik
				initialValues={formValues}
				validationSchema={validationSchema}
				onSubmit={updateCandidateEmail}
				enableReinitialize
			>
				{({ values, handleSubmit }) => {
					return (
						<Form>
							<div className="item-box grid mt-14 mb-30">
								<div className="item mb-20">
									<Field
										component={AntInput}
										type="text"
										value={values.candidateKey}
										name={"candidateKey"}
										label={"Candidate Key"}
										disabled={true}
									/>
								</div>
								<div className="item mb-20">
									<Field
										component={AntInput}
										type="email"
										value={values.email}
										name={"email"}
										label={"Current Email"}
										disabled={true}
									/>
								</div>
								<div className="item mb-20">
									<Field
										component={AntInput}
										type="email"
										value={values.updatedEmail}
										name={"updatedEmail"}
										label={"New Email"}
										hasFeedback
									/>
								</div>
							</div>
							<div className="btn-box">
								<Button
									className="ant-btn-primary text-16 weight-400 px-40"
									onClick={handleSubmit}
								>
									Submit
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
   </Modal>
  )
};

export default UpdateCandidateEmailForm;
