import { UnberryApi } from "../config/request"

const FeedbacksApi = {
    getFeedbacks(query = "") {
        let url = `/api/feedback/v1`

        return UnberryApi.safeCall(url, "GET", {
            params: query
        })
    },
}

export default FeedbacksApi