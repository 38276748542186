import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import AdminCard from "src/components/Common/AdminCard";
import AdminForm from "src/forms/AdminForm";
import Toast from "src/components/Common/Toast";
import Modal from "src/components/Common/Modal";
import AdminEmailPermission from "./AdminEmailPermission";
import BlankState from "src/components/Common/BlankState";
import ConfirmationModal from "src/components/Common/ConfirmationModal";
import InfoCardShimmer from "src/components/Common/Skeletons/InfoCardShimmer";
import { MIXPANEL_EVENTS, POSITION_TYPES } from "src/constants/globalConstants";
import { Mixpanel } from "src/services/mixpanel";
import SettingsApi from "src/services/apis/settings";
import PositionApi from "src/services/apis/positions";
import { getBackgroundColor, isObject } from "src/utils/helperFunctions.js";
import { storage } from "src/services/config/storage";
import { SuccessIcon } from "src/assets/icons";

export default function Settings() {
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [admins, setAdmins] = useState([]);
  const [loadingToggleId, setLoadingToggleId] = useState(null)
  const [confirmationModal, setConfirmationModal] = useState({
    active: false,
    email: "",
  });
  const [deleteAdminModal, setDeleteAdminModal] = useState({
    adminId: '',
    visible: false,
  })
  const [positionListing, setPositionListing] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isAddAdminModalActive, setIsAddAdminModalActive] = useState(false);

  useEffect(async () => {
    fetchAdminList();
    fetchPositionsListing();
  }, []);

  const fetchAdminList = async () => {
    try {
      const admins = await SettingsApi.listAdmins();
      if (admins.success) {
        setAdmins(admins.rows);
        setIsDataLoading(false)
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
  * Fetch all three position types and add each position, with type, in positionListing
  */
  const fetchPositionsListing = async () => {
    const { HIRING, COLLEGE, DNA } = POSITION_TYPES;
    const types = [HIRING, COLLEGE, DNA];

    try {
      const positions = await Promise.all(types.map((type) => PositionApi.listPositions(null, type)));
      const allPositions = positions.reduce((acc, curr, index) => {
        if (curr.success) {
          const mappedPositions = curr.result.map((elem) => ({
            name: elem.name,
            value: elem.id,
            type: types[index]
          }));
          acc.push(...mappedPositions);
        }
        return acc;
      }, []);

      setPositionListing(allPositions);
    } catch (err) {
      console.error(err);
    }
  };

  const setAdminData = (options = {}) => {
    const { adminData = {}, reset = false } = options;
    setShowDrawer(reset ? false : true);
    /** Set timeout for when closing drawer -> State update hidden from user. */
    if (reset) {
      setTimeout(() => setSelectedAdmin(adminData), 500);
    } else {
      setSelectedAdmin(adminData);
    }
  };

  const onAdminPositionUpdate = async () => {
    setAdminData({ reset: true });
    fetchAdminList();
  };

  const createAdmin = async (adminPayload, modalVisible) => {
    const newAdmin = await SettingsApi.createAdmin(adminPayload);
    modalVisible(false);
    if (newAdmin?.success) {
      setAdmins([...admins, { ...newAdmin, customText: newAdmin.designation, sendEmailToAdmin: true }]);
      setConfirmationModal({
        active: true,
        email: newAdmin?.email,
      });
      Mixpanel.track(MIXPANEL_EVENTS.InviteTeamMember, {
        Name: newAdmin.name,
        Email: newAdmin.email,
        Designation: newAdmin.designation,
      });
    }
  };

  const deleteAdmin = async (adminId) => {
    const deleteStatus = await SettingsApi.deleteAdmin(adminId);
    if (deleteStatus.success) {
      const newAdmins = admins.filter((admin) => admin.id !== adminId);
      setAdmins(newAdmins);
    } else {
      Toast("error", deleteStatus.message);
    }
    setDeleteAdminModal(adminModal => ({ ...adminModal, visible: false, adminId: '' }))
  };

  const onResendInvite = async (adminId) => {
    const inviteStatus = await SettingsApi.inviteAdmin(adminId);
    if (inviteStatus.success) {
      Toast("success", "Invitation sent");
    } else {
      Toast("error", inviteStatus.message);
    }
  };

  const onEmailToggle = async (admin) => {
    try {
      const { id, sendEmailToAdmin } = admin;
      setLoadingToggleId(id);
      const res = await SettingsApi.setSendEmailToAdmin({
        userId: id,
        sendEmailToAdmin: !sendEmailToAdmin,
      });
      if (res?.success) {
        const updatedList = admins.map((admin) =>
          admin.id === id
            ? { ...admin, sendEmailToAdmin: !admin.sendEmailToAdmin }
            : admin
        );
        setAdmins(updatedList);
      }
    } catch (error) {
      console.error('ERROR:::', error);
    }
    setLoadingToggleId(null);
  }

  return (
    <div className="profile">
      <div className="container">
        <div className="">
          <div className="heading-block">
            <p className="text-28 weight-600">
              Team {admins.length > 1 ? "Members" : "Member"}{" "}
              <span className="gameplay-count"> {admins.length} </span>
            </p>
            <AdminForm
              disable={admins.length >= 3}
              onSubmit={createAdmin}
              isModalActive={isAddAdminModalActive}
              setIsModalActive={setIsAddAdminModalActive}
            />
          </div>
          {
            isDataLoading ?
              <div className="admins grid">
                <InfoCardShimmer elementNumber={3} />
              </div>
              : admins?.length > 0 ?
                <div className="admins grid">
                  {admins.map((admin) => {
                    let { customText } = admin;
                    if (isObject(customText)) {
                      customText = "";
                    }
                    return (
                      <AdminCard
                        key={admin.id}
                        {...admin}
                        setAdminData={setAdminData}
                        backgroundColor={getBackgroundColor(admin.name ? admin.name[0] : '')}
                        enableDelete={admin.id !== storage.get.decodedToken().userId}
                        onDelete={() => setDeleteAdminModal(adminModal => ({ ...adminModal, visible: true, adminId: admin.id }))}
                        onResendInvite={onResendInvite}
                        designation={customText}
                        loadingToggleId={loadingToggleId}
                        onEmailToggle={() => onEmailToggle(admin)}
                      />
                    );
                  })}
                </div>
                : <BlankState
                  className="blank-message"
                  message={"No members yet.\nStart adding here"}
                  onClick={() => setIsAddAdminModalActive(true)}
                />
          }
        </div>
      </div>
      <ConfirmationModal
        showModal={deleteAdminModal.visible}
        onClose={() => setDeleteAdminModal(adminModal => ({ ...adminModal, visible: false, adminId: '' }))}
        confirmationText="Remove"
        onSubmit={() => deleteAdmin(deleteAdminModal.adminId)}
      >
        <h4 className="semibold pb-20">Are you sure you want to remove this admin ?</h4>
      </ConfirmationModal>
      <Modal
        modalVisible={confirmationModal.active}
        className="confirmation-modal"
        cancelModal={() => setConfirmationModal({ active: false, email: "" })}
      >
        <div className="flex flex-col items-center justify-center">
          <div>
            <SuccessIcon />
          </div>
          <h3 className="text-40 semibold">Invitation sent</h3>
          <p className="text-grey text-20 semibold">
            to {confirmationModal.email || "Vineet Gautam"}
          </p>
        </div>
      </Modal>
      <Drawer
        placement="right"
        onClose={() => setAdminData({ reset: true })}
        visible={showDrawer}
        destroyOnClose
        className="drawers"
        closable={false}
      >
        <AdminEmailPermission
          positionListing={positionListing}
          adminData={selectedAdmin}
          onCancel={() => setAdminData({ reset: true })}
          onSuccess={onAdminPositionUpdate}
        />
      </Drawer>
    </div>
  );
}
