import { configureStore } from "@reduxjs/toolkit";
import positionReducer from "./Positions/reducer";
import dnaPositionReducer from "./DNA/reducer";
import authReducer from "./Auth/reducer";
import walletReducer from "./wallet/reducer";
import organizationReducer from "./Organizations/reducer";
import feedbackReducer from "./Feedbacks/reducer";

export const store = configureStore({
  reducer: {
    positions: positionReducer,
    dna: dnaPositionReducer,
    auth: authReducer,
    wallet: walletReducer,
    organizations: organizationReducer,
    feedbacks: feedbackReducer
  },
});
