import * as Yup from "yup";

export const loginValidation = Yup.object().shape({
  email: Yup.string()
    .required("Doesn’t sound right, Please try again")
    .email("Doesn’t sound right, Please try again"),
});

export const otpValidation = (value) => {
  if (!value) return "Required";
  if (!+value) return "OTP must be a number";
  if (value.length !== 4) return "OTP must be 4 digits long";
};
