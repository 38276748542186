import WithToolTip from "src/components/Common/WithToolTip";
import { capitalizeTitle, getCompanyDetailsFromStorage, getCompanyName, isObject, roundOff } from "src/utils/helperFunctions";
import { InfoCircleFilled } from "@ant-design/icons";

let exclusionArray = [
  "total",
  "summary",
  "relevancy",
  "remarks",
  "remark",
  "totalScore",
  "userScore",
  "maxScore",
  "score",
  'statusCode',
  'statusMessage',
  'body',
  'speechScore'
];

// Check if showScore has big O notation and extract complexity and relevant text
const extractComplexityAndTextAnalsis = (score) => {
  // Regular expression to match Big O notation
  const bigONotationRegex = /O\(([^)]+)\)/;

  // Check if the value contains Big O notation
  const match = bigONotationRegex.exec(score);
  if (match) {
    // Split the value on the hyphen
    const parts = score.split('-').map(part => part.trim());
    
    const bigONotation = match[0];
    const text = parts.length > 1 ? parts.slice(1).join('-').trim() : '';
    return {
      complexityNotation: bigONotation,
      complexityNotationText: text
    };
  }
  return null;
}

export const ScorePills = (props) => {
  // detailedScoreObject - Contains detailed scoring of third-party tools apart from score 
  const { score, contextAnalysis = {}, className, detailedScoreObject = {} } = props;
  const isScoreObject = isObject(score);
  if (!isScoreObject) return <></>;

  // Extract and add CEFR Score display to displayScoreKeys only 
  const speechCefrScore = detailedScoreObject?.body?.speech_score?.cefr_score;
  const textCefrScore = detailedScoreObject?.body?.text_score?.cefr_score
  const speechOverallScore = score?.speechScore;

  // from the score, remove all the keys that are present in contextAnalysis and show them in relevancy box instead
  exclusionArray = [...exclusionArray, ...Object.keys(contextAnalysis)]

  let displayScoreKeys = score.contextAnalysis ?
    Object.keys(score.contextAnalysis).filter(
      (key) =>
        !exclusionArray.includes(key)
    ) : score ? Object.keys(score).filter(
      (key) =>
        !exclusionArray.includes(key)) : null;

  return (
    displayScoreKeys.length > 0 && (
      <div className="score-pills-container">
        <ScorePillWrapper
          score={score}
          displayScoreKeys={displayScoreKeys}
          className={className}
        />
        {
          // if true, add speechCefrScore object to displayScoreKeys
          speechCefrScore &&
          <ScorePillWrapper
            score={score}
            displayScoreKeys={[speechCefrScore]}
            className={className}
          />
        }
        {
          // speechScore - Speech overall score as separate pill
          speechOverallScore &&
          <ScorePillWrapper
            score={score}
            displayScoreKeys={["speechScore"]}
            className={className}
          />
        }
        {
          textCefrScore &&
          <ScorePillWrapper
            score={textCefrScore}
            displayScoreKeys={Object.keys(textCefrScore)}
            className={className}
            pillType={"CEFR"}
          />
        }
      </div>
    )
  );
};

/**
 * Render a set of score pills with optional CEFR score breakdown.
 *
 * @param {Array} displayScoreKeys - An array of keys to determine which scores to display.
 *
 * @returns {JSX.Element} - component rendering the score pills.
 */

const ScorePillWrapper = ({ displayScoreKeys, className, score, pillType = null }) => {

  // Mapping names for keys with names changes (left: original, right: modified)
  const keyAliasMap = {
    "speechScore": "overallScore"
  };

  return (
    <div className={`score-pill-wrapper`}>
      {
        pillType && <span className="pill-type mr-8">{pillType} - </span>
      }
      {displayScoreKeys.map((key) => {

        // Check for speechCefrScore -> An object containing CEFR score keys
        const cefrObject = isObject(key) ? key : null;
        let cefrOverallScore = "", cefrScoreKeysArr = [], renderCefr = <></>;

        if (cefrObject) {
          // Extract CEFR scores from the object
          Object.keys(cefrObject).map((cefrKey) => {
            if (cefrKey === "overall") cefrOverallScore = cefrObject[cefrKey];
            else cefrScoreKeysArr.push({ key: cefrKey, cefrScore: cefrObject[cefrKey] });
          });

          // Prepare JSX for rendering CEFR score breakdown
          renderCefr = (
            <div className="flex flex-col items-start">
              <p className="size-18 weight-600 mb-10">CEFR Score Breakdown</p>
              {cefrScoreKeysArr.map((scoreObj) => (
                <span key={scoreObj.key}>
                  {`${capitalizeTitle(scoreObj?.key)}: ${scoreObj?.cefrScore}`}
                </span>
              ))}
            </div>
          );
        }

        // Determine the score to display
        const showScore = cefrOverallScore ? null :
          (score.contextAnalysis >= 0 || score.contextAnalysis) ?
            score.contextAnalysis :
            Number.isFinite(score[key]) && score[key] >= 0 ?
              roundOff(score[key]) : score[key];
        
        // Check if showScore has big O notation and extract complexity and relevant text
        const scoreHasComplexityAnalysis = extractComplexityAndTextAnalsis(showScore);
        const { complexityNotation, complexityNotationText } = scoreHasComplexityAnalysis ?? {};

        return (
          <div className="score-pill flex items-center justify-start" key={key}>
            {cefrOverallScore ? (
              <span className="mr-6">CEFR:</span>
            ) : (
              <span className="mr-6">
                {keyAliasMap[key] ?
                  capitalizeTitle(keyAliasMap[key]) :
                  capitalizeTitle(key)}:
              </span>
            )}

            <span className="score">
              {cefrOverallScore ? (
                // Display CEFR overall score with tooltip
                <span className="flex cursor-pointer">
                  <span className="mr-6">{cefrOverallScore}</span>
                  <WithToolTip
                    title={renderCefr}
                    showToolTip={true}
                    placement="right"
                  >
                    <InfoCircleFilled />
                  </WithToolTip>
                </span>
              ) : complexityNotation ? (
                <DisplayScoreComplexity
                  complexityNotation={complexityNotation}
                  complexityNotationText={complexityNotationText}
                />
              ) : (
                // Display regular score
                <>{showScore}</>
              )}
            </span>
          </div>
        );
      })}
    </div>
  );
}

const DisplayScoreComplexity = ({
  complexityNotation,
  complexityNotationText
}) => {

  return (
    <div className="flex items-center gap-1">
      <span className="mr-6">{complexityNotation}</span>
      {
        complexityNotationText &&
        <WithToolTip
          title={complexityNotationText}
          showToolTip={true}
          placement="right"
        >
          <InfoCircleFilled />
        </WithToolTip>
      }
    </div>
  )
}
