import { createAsyncThunk } from "@reduxjs/toolkit";
import PdfReportApi from "src/services/apis/pdfReport";

export const setWalletCredits = createAsyncThunk("wallet", async () => {
  try {
    const response = await PdfReportApi.fetchCreditAmount();
    if (response.success) {
      return response.data?.credits || 0;
    }
  } catch (error) {
    return 0;
  }
});
