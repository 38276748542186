import React from "react";
import { ArrowUpOutlined } from '@ant-design/icons';
import "./ScrollIcon.scss";

export default function ScrollIcon(props) {
  const { icon, className, onClick } = props;

  return (
    <div
      className={`flex items-center ${className}`}
      onClick={() => {
        return onClick ? onClick() : null;
      }}
    >
      {icon || <ArrowUpOutlined style={{ color: '#fff', fontSize: "20px" }}/>}
    </div>
  );
}
