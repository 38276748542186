import { useEffect, useState } from "react"
import { Spin } from 'antd';
import { CopyTwoTone } from "@ant-design/icons";
import Listing from "src/components/Common/Listing";
import Toast from "src/components/Common/Toast";
import SettingsApi from "src/services/apis/settings";
import { storage } from "src/services/config/storage";
import { copyTextToClipboard } from "src/utils/helperFunctions";
import { useIsMobileDevice } from "src/utils/hooks";

const APIKeys = () => {
    const [loading, setLoading] = useState(false)
    const [keys, setKeys] = useState([])
    const isMobile = useIsMobileDevice()

    useEffect(() => {
        getApiKeys()
    }, [])

    const getApiKeys = async () => {
        try {
            setLoading(true)
            const organizationId = storage.get.organizationInfo()?.organizationId
            const res = await SettingsApi.getApisList(organizationId)
            if (res?.success) {
                setKeys(res?.result)
            }
        } catch (e) {
            console.error(e?.message)
            Toast("error", e?.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="container">
            <div className="flex flex-col">
                <div className="heading-block">
                    <p className="text-28 weight-600">
                        API {keys.length > 1 ? "Keys" : "Key"}{" "}
                        <span className="gameplay-count"> {keys.length} </span>
                    </p>
                </div>
                {
                    loading ?
                        <div className="flex items-center justify-center">
                            <Spin />
                        </div>
                        : keys?.length > 0 ?
                            <Listing
                                dataSource={keys}
                                headerLayout={"0.4fr 1fr 0.4fr"}
                                dataLayout={"0.4fr 1fr 0.4fr"}
                                headers={[
                                    "Name",
                                    "API Key",
                                    "Domain",
                                ]}
                                dataKeys={[
                                    {
                                        name: "name",
                                        render: (data) => {
                                            return (
                                                <p className={`${isMobile ? "width-80vw" : "width-100"} break-word`}>{data?.description}</p>
                                            )
                                        },
                                    },
                                    {
                                        name: "api-key",
                                        render: (data) => {
                                            return (
                                                <div className={`flex ${isMobile ? "flex-col items-start my-10" : "items-center"}`}>
                                                    <p className={`${isMobile ? "width-80vw break-word mb-10" : "mr-10"} text-14 text-dark weight-400 text-clip`}>
                                                        {data?.apiKey}
                                                    </p>
                                                    <CopyTwoTone
                                                        twoToneColor="#FF864E"
                                                        onClick={() => {
                                                            copyTextToClipboard(data?.apiKey)
                                                            Toast("success", "Key Copied Successfully", "Key copied");
                                                        }}
                                                    />
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        name: "domain",
                                        render: (data) => {
                                            return (
                                                <p>{data?.domain}</p>
                                            )
                                        },
                                    },
                                ]}
                            />
                            : <div className="flex justify-center py-10">
                                <p className="text-18 weight-700">Please contact our team for your API keys</p>
                            </div>
                }
            </div>
        </div>
    )
}

export default APIKeys
