const AUTH_CONSTANTS = {
  USER_TYPE_ADMIN: "dashboard",
  OTP_VERIFICATION_TIMER: 30,
  SUBTRACT_TOKEN_EXPIRY_TIME: 86400,
  LS_KEY: "auth_token",
  ORGANIZATION_TYPE: "company",
  ORGANIZATION_ID: "organizationId",
  ORGANIZATION_NAME: "organizationName",
  ORGANIZATION_LOGO: "organization_logo",
  DECODED_TOKEN: "decodedToken",
  REFRESH_TOKEN: "refreshToken",
  REFRESH_TOKEN_IN_PROGRESS: "refreshProgress",
  USER_INFO: "userInfo",
  ORGANIZATION_INFO: "organizationInfo",
};

export { AUTH_CONSTANTS };
