import React from 'react';
import Text from '../Text';
import Tag from '../Tag';
import './TraitsCard.scss';

export default function TraitsCard(props) {
  const { title, statement, tag, tagColor } = props;

  return (
    <div className="trait-card flex flex-col justify-between">
      <div className="mt-20">
        <h3 className="size-13 weight-600">{title}</h3>
        <Text text={statement} className="size-11 weight-400" />
      </div>
      <Tag
        text={tag}
        className="size-11 weight-700 br-3 py-3 px-6"
        color={tagColor || '#55B255'}
      />
    </div>
  );
}
