import { Dropdown } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { OptionsIcon } from "src/assets/icons";
import "./Dropdownmenu.scss";

const DropdownMenu = forwardRef(({ menu, onVisibleChange = () => {} }, ref) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleDropdownMenu = (visible) => setMenuVisible(visible) 

  useImperativeHandle(ref, () => ({
    toggleDropdownMenu,
  }));

  return (
    <Dropdown
      trigger={["click"]}
      overlay={menu}
      placement="bottomRight"
      overlayClassName="dropdown-menu-overlay"
      onVisibleChange={(visible) => {
        toggleDropdownMenu(visible)
        onVisibleChange(visible);
      }}
      align={{
        overflow: {
          adjustX: true,
          adjustY: true,
        },
      }}
    >
      <div className="dropdown-menu-component">
        <OptionsIcon />
      </div>
    </Dropdown>
  );
}) 

export default DropdownMenu