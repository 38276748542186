import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import PositionApi from "src/services/apis/positions";
import { hasFilterChanged } from "./helperFunctions";

export const useUrlFilter = () => {
  const location = useLocation();
  const history = useHistory();
  const [filters, setFilters] = useState({ page: 1 });

  /**
   * Different if else conditions to update page filter value, 
     due to conflicting logics of dna and non-dna listing filters.
   * First if condition for dna users (tab=people)
   * Else condition for non-dna users
   */
  const updatePage = (oldFilter, newFilter, pageReload = false) => {
    // remove searchKey if this is set to empty string since BE returns error
    !newFilter["searchKey"] && delete newFilter["searchKey"];
    const isChanged = hasFilterChanged(oldFilter, newFilter)
    // Update newFilter["page"] -> If not set
    if (pageReload) {
      if (!newFilter["page"]) newFilter["page"] = 1;
      return newFilter
    }
    if(newFilter["tab"]) {
      if ((isChanged && Object.keys(oldFilter).length > 0) || !newFilter["page"]) {
        newFilter["page"] = 1;
      }
    } else {
      if (isChanged|| !newFilter["page"]) {
        newFilter["page"] = 1;
      }
    }
    return newFilter;
  };

  useEffect(() => {
    const fromUrl = queryString.parse(location.search);
    // Pass pageReload = true in case of location.search update.
    const newFilters = updatePage(filters, fromUrl, true);
    setFilters(newFilters);
  }, [location.search]);

  const setFiltersFromParams = (filterObj) => {
    filterObj = updatePage(filters, filterObj);
    if (Object.keys(filterObj || {}).length) {
      if (queryString.stringify(filterObj) !== `?${location.search}`) {
        history.push({ search: queryString.stringify(filterObj) });
      }
    } else {
      history.push({ search: null });
    }
    setFilters(filterObj);
  };

  return [filters, setFiltersFromParams];
};

export const useIndustries = () => {
  const [industries, setIndustries] = useState([]);

  useEffect(async () => {
    const industriesResponse = await PositionApi.getIndustries();
    if (industriesResponse.success) {
      setIndustries(industriesResponse.data);
    }
  }, []);

  return [industries];
};

export const useIsMobileDevice = () => {
  const [isMobile, setIsMobile] = useState(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );

  useEffect(() => {
    function handleResize() {
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      );
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

/**
 * This hook initiates the download of csv/excel file.
 * @param {csvLink} - ref of csv/excel component
 * @param {companyUsersData} - data to be downloaded
 * @param {setCompanyUsersData} - function to change data
 */
export const useCSVDownload = (csvLink, companyUsersData, setCompanyUsersData) => {

  useEffect(() => {
    if (csvLink.current && companyUsersData.length) {
      csvLink.current.link.click();
      setCompanyUsersData([]);
    }
  }, [companyUsersData]);
}

export const useExcelDownload = (xlsxLink, companyUsersData, setCompanyUsersData) => {
  useEffect(() => {
    if (xlsxLink.current && companyUsersData) {
      const blob = new Blob([companyUsersData]);

      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'companyUsersData.xlsx');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setCompanyUsersData(null);
    }
  }, [companyUsersData]);
};