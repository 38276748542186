import React, { useState, useEffect } from "react";
import { Field, Form, Formik, setIn } from "formik";
import {
  AntSelect,
} from "src/components/Common/CreateAntFields/CreateAntFields";
import { Button } from "antd";
import { POSITION_TYPES } from "src/constants/globalConstants";
import { AntdSelectIcon, CancelIcon } from "src/assets/icons";
import { INTIIAL_VALUES } from "./constants";
import { validationSchema } from "./validate";
import PositionApi from "src/services/apis/positions";
import Toast from "src/components/Common/Toast";

export default function EmailPermissionForm({ 
  onCancel, 
  onSuccess, 
  adminData, 
  positionListing 
}) {

  const [initialValues, setInitialValues] = useState(INTIIAL_VALUES);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (adminData.id) {
      const { adminPositions=[] } = adminData;
      const updateFormValues = {...initialValues};
      /**
      * Find positions with types already assigned to admin
      * And populate form fields accordingly
      */
      adminPositions.forEach((pos) => {
        const position = positionListing.find(elem => elem.value === pos);
        if (position) {
          updateFormValues[position.type] = [
            ...updateFormValues[position.type],
            pos
          ]
        }
      });
      setInitialValues(updateFormValues);
    } else {
      setInitialValues(INTIIAL_VALUES);
    }
  }, [adminData.id]);

  const updateAssignedPositionForAdmin = async ({ id, payload }) => {
    try {
      setLoading(true);
      const response = await PositionApi.updateAssignedPositionsForUser(id, payload);
      if (response.success) {
        Toast('success', 'Notifications updated successfully.');
        onSuccess();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    const positionIds = [];
    for (const key in values) {
      if (values[key].length>0) {
        values[key].forEach((elem) => positionIds.push(elem));
      }
    }
    const payloadObject = {
      id: adminData.id,
      payload: { positionIds }
    };
    updateAssignedPositionForAdmin(payloadObject);
  };

  const commonAntSelectProps = {
    component: AntSelect,
    type: "multiSelect",                  
    suffixIcon: <AntdSelectIcon />,
    filterOption: (input, option) => {
      return (option?.children ?? '')
        .toLowerCase().includes(input.toLowerCase())
    },
    optionValueKey: "value",
    optionNameKey: "name",
    hasFeedback: true
  };

  return (
    <div className="position-form">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => {
          return (
            <Form>
              <div className="content-wrapper">
                <div className="item-box mt-0 mb-40">
                  <p className="text-20 weight-900 mb-10">
                    {`Admin: ${adminData?.name || ""}`}
                  </p>
                  <p className="content-heading uppercase text-16 weight-900 pt-10 mb-10">
                    Positions
                  </p>
                  <div className="item mb-20">
                    <Field
                      selectOptions={
                        positionListing.filter((elem) => 
                          elem.type === POSITION_TYPES.HIRING)
                      }
                      value={values.hiring}
                      name={"hiring"}
                      label={values.hiring.length ? '' : 'Positions'}
                      {...commonAntSelectProps}
                    />
                  </div>
                  <p className="uppercase text-16 weight-900 pt-10 mb-10">
                    Mass Hiring
                  </p>
                  <div className="item mb-20">
                    <Field
                      selectOptions={
                        positionListing.filter((elem) => 
                          elem.type === POSITION_TYPES.COLLEGE)
                      }
                      value={values.college}
                      name={"college"}
                      label={values.college.length ? '' : 'Mass Hiring'}
                      {...commonAntSelectProps}
                    />
                  </div>
                  <p className="uppercase text-16 weight-900 pt-10 mb-10">
                    My Team
                  </p>
                  <div className="item mb-20">
                    <Field
                      selectOptions={
                        positionListing.filter((elem) => 
                          elem.type === POSITION_TYPES.DNA)
                      }
                      value={values.dna}
                      name={"dna"}
                      label={values.dna.length ? '' : 'My Team'}
                      {...commonAntSelectProps}
                    />
                  </div>
                </div>
              </div>
              <div className="btn-box flex justify-start">
                <Button
                  className="ant-cancel-btn text-16 semibold px-18"
                  onClick={onCancel}
                  icon={<CancelIcon isBlack={true} />}
                >
                  Cancel
                </Button>
                <Button
                  className="ant-btn-primary text-16 bold px-20"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Save and continue
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
