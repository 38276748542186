import { createAsyncThunk } from "@reduxjs/toolkit";
import FeedbacksApi from "src/services/apis/feedbacks";
import { handleError } from "src/utils/helperFunctions";

export const setFeedbacks = createAsyncThunk(
    "feedbacks/getFeedbacks",
    async ({ query }) => {
        try {
            const response = await FeedbacksApi.getFeedbacks(query)
            if (response.success) {
                return response.data
            }
        } catch (error) {
            handleError(error)
            return error
        }
    }
);