import * as Yup from "yup";
const phoneRegExp = /^[1-9]\d{5,13}$/

// Rewrite this logic -> Cut down duplication
export const inviteValidations = Yup.object().shape({
  name: Yup.string()
  .when(["hasThirdPartyLogin"], {
    is: (hasThirdPartyLogin) => !hasThirdPartyLogin,
    then: Yup.string().required("This is required"),
    otherwise: Yup.string().nullable(),
  }),
  email: Yup.string().email().when(["hasPhoneLogin"], {
    is: (hasPhoneLogin) => !(hasPhoneLogin),
    then: Yup.string().required("This is required"),
    otherwise: Yup.string().nullable().when("email", {
      is: (value) => value && value.length > 0,
      then: Yup.string().email("Please enter a valid email"),
    }),
  }),
  yearsOfExperience: Yup.number()
    .when(["hasThirdPartyLogin"], {
      is: (hasThirdPartyLogin) => !hasThirdPartyLogin,
      then: Yup.number()
        .min(0, "This should not be less than 0")
        .typeError("You must specify a number")
        .required("This is required"),
      otherwise: Yup.number()
        .default(0)
        .nullable()
        .when("yearsOfExperience", {
          is: (value) => value !== null && value !== undefined,
          then: Yup.number()
            .min(0, "This should not be less than 0")
        }),
    }),
  phone: Yup.string()
    .when(["hasPhoneLogin"], {
      is: (hasPhoneLogin) => hasPhoneLogin,
      then: Yup.string()
        .required("This is required")
        .matches(phoneRegExp, "Phone number is not valid"),
      otherwise: Yup.string()
        .nullable()
        .when("phone", {
          is: (value) => value && value.length > 0,
          then: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
        }),
    }),
  candidateKey: Yup.string()
    .when(["hasThirdPartyLogin"], {
      is: (hasThirdPartyLogin) => hasThirdPartyLogin,
      then: Yup.string().required("This is required"),
      otherwise: Yup.string()
        .nullable()
        .when("candidateKey", {
          is: (value) => value && value.length > 0,
          then: Yup.string().required("This is required"),
        }),
    }),
  hasPhoneLogin: Yup.boolean(),
  hasThirdPartyLogin: Yup.boolean(),
});

export const dnaInviteValidations = inviteValidations.shape({
  expertiseLevel: Yup.string().required("This is required"),
  designation: Yup.string().required("This is required"),
});
