import React, { useState } from 'react';
import SectionContentItem from '../SectionContentItem';
import SectionHeaderOverview from '../SectionHeaderOverview';
import { 
  PlusOutlined, 
  MinusOutlined,
} from '@ant-design/icons';

export const DisplaySection = (props) => {

  const { activeSection, data, showMcq } = props;
  const [displaySection, setDisplaySection] = useState(true);

  return (
    data?.length>0 && 
    <section className="questions-section pt-30">
      <div className="section-header mb-20">
        <SectionHeaderOverview 
          activeSection={activeSection}
          data={data} 
        />
        <div className="display-section" onClick={() => setDisplaySection(prev => !prev)}>
          { displaySection ? <MinusOutlined /> :<PlusOutlined /> }
        </div> 
      </div>
      <div className={`section-content ${displaySection ? '': 'hidden'}`}>
        {
          data.map((elem, index) => {
            return (
              <div>
                <SectionContentItem 
                  data={elem} 
                  serial={index+1}
                  activeSection={activeSection}
                />
                {
                  !(data.length-1===index) &&
                  <div className="separator mt-16 mb-24"></div>
                }
              </div>
            );
          })
        }
      </div>
    </section>
  )
};