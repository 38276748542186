import { UnberryApi } from "../config/request";
import { LOGIN_TYPE, POSITION_TYPES } from "../../constants/globalConstants";

const BulkApi = {
  getSampleCsv(query='') {
    return UnberryApi.safeCall(`/api/link/v1/template/download${query}`, "GET", {
      params: { type: POSITION_TYPES.HIRING },
    });
  },
  uploadUsers(data,type=null) {
    return UnberryApi.safeFormData(
      `/api/link/v1/bulkupload${type === LOGIN_TYPE.THIRD_PARTY ? '-thirdparty' : ""}`, 
      "POST", 
      {
        data,
        params: {
          type: POSITION_TYPES.HIRING,
          organizationType: ":orgType",
          organizationId: ":orgId"
        },
      }
    );
  },
  bulkInviteUsers(data) {
    return UnberryApi.safeCall(
      `/api/link/v1/send-bulk-invite/:orgType/:orgId`,
      "POST",
      { data }
    );
  },
  getCompanyUserDataCsv(organizationId, query) {
    return UnberryApi.safeCall(`/api/company/v1/export-users-xlsx/${organizationId}?${query}`, "GET");
  },
  getCompanyUserDataCsvV2(data) {
    return UnberryApi.safeCall(
      `/api/company/v1/create-users-xlsx-v2`, 
      "POST",
      { data }
    );
  }
};

export default BulkApi;
