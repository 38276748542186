import "./AddPosition.scss";
import PositionForm from "src/forms/AddPositionForm";

export default function AddPositionForm({ onCancel, onSuccess }) {
  return (
    <div className="position-container-form">
      <div className="first-half">
        <p className="heading">Add a new position</p>
      </div>
      <div className="second-half">
        <PositionForm onCancel={onCancel} onSuccess={onSuccess} />
      </div>
    </div>
  );
}
