
import { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import moment from "moment";
import { Button, DatePicker, Radio, TimePicker } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PositionApi from "src/services/apis/positions";
import Toast from "../../components/Common/Toast";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import WithToolTip from "../../components/Common/WithToolTip";
import Modal from "../../components/Common/Modal";

import "./InviteScheduler.scss";

const EMAIL_INVITE_SCHEDULE = {
	SCHEDULE_NOW: "Schedule Now",
	SCHEDULE_LATER: "Schedule Later"
};

const { SCHEDULE_LATER, SCHEDULE_NOW } = EMAIL_INVITE_SCHEDULE;

const INTIIAL_FORM_VALUES = { date: '', time: '', selection: '' };

const ScheduledInviteDisplay = ({
	scheduledInvite,
	setAllowDelete,
	setAllowEdit,
	allowEdit,
	values,
	setFieldValue
}) => {

	const handleSelection = ({ target: { value } }) => {
		setFieldValue("selection", value);
	};

	return (
		<>
			<div className="flex items-center justify-between">
				<h3 className="text-22 semibold w-75 line-1 pb-30 modal-heading">
					Send Invites
				</h3>
			</div>
			{
				scheduledInvite && 
				<div className="flex items-center justify-between mb-20">
					<div className="flex flex-col items-start">
						<p className="weight-600">Scheduled For</p>
						<p className="color-blue weight-600">{scheduledInvite}</p>
					</div>
					<div className="flex items-center">
						{/* <WithToolTip
							title={"Edit Schedule"}
							showToolTip={true}
						>
							<EditOutlined className="ml-4 text-primary text-16" onClick={() => setAllowEdit(true)}/> 
						</WithToolTip> */}
						<WithToolTip
							title={"Delete Schedule"}
							showToolTip={true}
						>
							<DeleteOutlined className="ml-4 text-red text-16" onClick={() => setAllowDelete(true)}/> 
						</WithToolTip>						
					</div>
				</div>
			}
			{
				((scheduledInvite && allowEdit) || (!scheduledInvite)) &&
				<div className="flex items-center">
					<Radio.Group onChange={handleSelection} value={values.selection}>
						<Radio value={SCHEDULE_NOW}>{SCHEDULE_NOW}</Radio>
						<Radio value={SCHEDULE_LATER}>{SCHEDULE_LATER}</Radio>
					</Radio.Group>
				</div>
			}
		</>
	)
}

const InviteScheduler = ({
	positionId,
	showModal,
	setShowModal,
	// Callback for OverallStats Comp
	onSuccess=()=>{}
}) => {
	
	const [initialValues, setInitialValues] = useState(INTIIAL_FORM_VALUES);
	const [scheduledInvite, setScheduledInvite] = useState(null);
	const [allowDelete, setAllowDelete] = useState(false);
	const [allowEdit, setAllowEdit] = useState(false);

	useEffect(() => {
		async function getScheduledInvite () {
			const response = await PositionApi.getScheduledInvite(positionId);
			if (response.success) {
				const { scheduleDetails } = response;
				setDateTimeFromScheduledValue(scheduleDetails?.triggerAt);
			}
		}
		if (showModal && positionId) {
			getScheduledInvite();
		} 
	}, [showModal]);

	const disabledDate = (current) => {
    // Disable all dates before today
    return current && current < moment().startOf('day');
  };

	const setDateTimeFromScheduledValue = (utcDateTime) => {
		const momentDateTimeUTC = moment.utc(utcDateTime); // Parse UTC date-time string

		// Get the local time zone offset in minutes
    	const localTimeZoneOffset = moment().utcOffset();

		// Add the local time zone offset to the UTC date-time
		const momentDateTimeLocal = momentDateTimeUTC.add(localTimeZoneOffset, 'minutes');
		const extractedValues = {
			date: momentDateTimeLocal,
			time: momentDateTimeLocal,
		};
		// Format string to show in Modal
		const currentSchedule = momentDateTimeLocal.format('ddd, DD MMM YYYY, hh:mm A');

		setInitialValues(extractedValues);
		setScheduledInvite(currentSchedule);
	};

	const handleScheduleDelete = async () => {
		try {
			const payload = {
				positionId
			};
			const response = await PositionApi.deleteScheduledInvite(payload);
			if (response.success) {
				Toast("success", `Schedule deleted`, 'Deleted Successfully');
				setScheduledInvite(null);
				setInitialValues(INTIIAL_FORM_VALUES);
				onSuccess();
			}
		} catch (err) {
			console.error(err);
			Toast("error", err);
		} finally {
			setAllowDelete(false);
		}
	};

	const handleScheduleInvite = async (values) => {
		const { date, time } = values;

		const payload = { positionId };

		if (values.selection === SCHEDULE_NOW) {
			const dateTime = moment.utc().add(2, 'minutes');
			const localTimeZoneOffset = dateTime.utcOffset();
			payload.triggerAt = dateTime.subtract(localTimeZoneOffset, 'minutes').toISOString();
		} else {
			// Extracting date and time values
			const selectedDate = date.format("YYYY-MM-DD");
			const selectedTime = time.format("HH:mm:ss");
			const selectedDateTime = `${selectedDate}T${selectedTime}Z`;
			// Get the local time zone offset in minutes
			const localTimeZoneOffset = moment().utcOffset();
			// Apply the local time zone offset to the UTC date-time string
			const localDateTime = moment.utc(selectedDateTime).subtract(localTimeZoneOffset, 'minutes');
			payload.triggerAt = localDateTime;
		}
			
		try {
			const response = await PositionApi.createScheduleInvite(payload);
			if (response.success) {
				Toast("success", `Invites scheduled`, scheduledInvite ? 'Updated Successfully' : 'Created Successfully' );
				onCloseScheduleHandler();
				onSuccess();
			}
		} catch (err) {
			console.error(err);
			Toast("error", err);
		}
	};

	const onCloseScheduleHandler = () => {
		setShowModal(false);
		setInitialValues(INTIIAL_FORM_VALUES);
	};

	const onCloseDeleteHandler = () => {
		setAllowDelete(false);
	};

	return (
		<div onClick={(e) => e.stopPropagation()}>
			<Modal
				modalVisible={showModal}
				cancelModal={onCloseScheduleHandler}
				width="350px"
				centered
				className="schedule-invite-modal"
			>
				<div className="flex flex-col items-start">
				<Formik
					initialValues={initialValues}
					onSubmit={handleScheduleInvite}
					enableReinitialize
				>
					{({ values, handleSubmit, setFieldValue }) => {
						return (
							<Form className="schedule-invite-form">
								<ScheduledInviteDisplay 
									scheduledInvite={scheduledInvite} 
									setAllowDelete={setAllowDelete}
									setAllowEdit={setAllowEdit}
									allowEdit={allowEdit}
									values={values}
									setFieldValue={setFieldValue}
								/>
								{
									((scheduledInvite && allowEdit) || (!scheduledInvite)) && 
									<div className="mt-14 mb-30">
										<div className="ant-picker-container mb-20">
											<Field
												component={DatePicker}
												value={values.date}
												name={"date"}
												size={"large"}
												disabledDate={disabledDate}
												onChange={(value) => {
													setFieldValue('date', value)
												}}
												disabled={!values?.selection || values?.selection === SCHEDULE_NOW}
											/>
										</div>
										<div className="ant-picker-container item mb-20">
											<Field
												component={TimePicker}
												name={"time"}
												value={values.time}
												format="h:mm a"
												size={"large"}
												use12Hours 
												minuteStep={5}
												onChange={(value) => {
													setFieldValue('time', value)
												}}
												disabled={!values?.selection || values?.selection === SCHEDULE_NOW}

											/>
										</div>
									</div>
								}
								<div className="btn-box">
									<Button
										className="ant-btn-primary text-16 weight-400 px-40"
										disabled={
											!values.selection ||
											(values.selection === SCHEDULE_LATER &&
												!(values?.date && values?.time))
										}
										onClick={handleSubmit}
									>
										Submit
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
				</div>
				<ConfirmationModal
					showModal={allowDelete}
					onClose={onCloseDeleteHandler}
					cancelText={'No'}
					confirmationText={"Yes"}
					onSubmit={(action) => action ? handleScheduleDelete() : onCloseDeleteHandler()}
				>
					<>
						Delete invite scheduled for <span className="color-blue weight-600">{scheduledInvite}</span>
					</>
				</ConfirmationModal>
			</Modal>
		</div>
	);
}

export default InviteScheduler;