export const REPORT_STATUS_TYPES = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
  NOT_GENERATED: 'not generated',
};

export const PLAY_STATUS_TYPES = {
  CREATED: 'created',
  INVITED: 'invited',
  PLAYING: 'playing',
  COMPLETED: 'completed',
};

export const COLOR_CODES = {
  poor: '#F7635E',
  average: '#FFCC6A',
  aboveAverage: '#9DCF4B',
  excellent: '#55B255',
};

export const BUCKETS_ORDER = [
  'cognitive',
  'intelligence',
  'thinking',
  'behavioural',
];
