import { DnaIcon, PositionsIcon } from "src/assets/icons";

export const routes = [
  {
    name: "Positions",
    logo: PositionsIcon,
    link: "/hiring",
    alt: "positions icon",
  },
  {
    name: "Mass Hiring",
    logo: PositionsIcon,
    link: "/mass-hiring",
    alt: "mass hiring icon",
  },
  {
    name: "My Team",
    logo: DnaIcon,
    link: "/myteam",
    alt: "dna icon",
  },
  {
    name: "Feedback",
    link: "/feedbacks",
  },
  {
    name: "Settings",
    link: "/settings",
  },
];
