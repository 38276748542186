import TraitFillBlock from "src/components/Common/TraitFillBlock";
import { roundOff } from "src/pages/PdfReport/operations";
import { getTraitBand } from "../../oprations";

export const Trait = ({
  displayName: name,
  description,
  score,
  upperBound,
  lowerBound,
  median,
  showInSingleRow,
  traitsMeta,
  scoreString = "",
  hideAverageLegends = false,
  separateMobileRow = false,
  headerTraitColor,
  fillBlockColor
}) => {
  const color = getTraitBand({
    score,
    lb: lowerBound,
    ub: upperBound,
    median,
  })?.color || fillBlockColor;
  return (
    <div
      className={"traits-section v2" + (showInSingleRow ? ` singleRow ${separateMobileRow ? "description" : ""}` : "")}
    >
      <div className="info v2">
        <h4 className="title">{name}</h4>
        {!!description && <p className="summary">{description}</p>}
        {/* No traits to show */}
        {traitsMeta && (
          <div className="traits-list">
            {Object.values(traitsMeta).map(({ id, name }) => (
              <h2 key={id} className={`trait-box small v2`}>
                {name}
              </h2>
            ))}
          </div>
        )}
      </div>
      <div className="trait-score">
        <h2 className="score" style={{ color: color || "green" }}>
          {roundOff(score)}
        </h2>
        <TraitFillBlock
          fillPercentage={score * 10}
          color={color}
          blankColor="#F9F3EC"
          average={!hideAverageLegends ? median * 10 : null}
          scoreString={scoreString}
          showAverageOnHover
        />
      </div>
    </div>
  );
};
