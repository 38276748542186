import { createAsyncThunk } from "@reduxjs/toolkit";
import DnaApi from "src/services/apis/dna";
import Toast from "src/components/Common/Toast";
import CandidateApi from "../../services/apis/candidate";
import { handleError } from "../../utils/helperFunctions";

export const getDnaPositions = createAsyncThunk(
  "dna/getDnaPositions",
  async ({ orgType, orgId }) => {
    try {
      const response = await DnaApi.listDnaPositions(orgType, orgId);
      return response;
    } catch (error) {
      handleError(error);
    }
  }
);

export const getBasePositions = createAsyncThunk(
  "dna/getBasePositions",
  async ({ orgType, orgId }) => {
    try {
      const response = await DnaApi.getBasePositions(orgType, orgId);
      return response;
    } catch (error) {
      handleError(error);
    }
  }
);

export const getAllUsersByPosition = createAsyncThunk(
  "dna/getAllUsersByPosition",
  async ({ dnaId, filters }) => {
    try {
      const response = await DnaApi.getAllUsersByPosition(dnaId, filters);
      return response;
    } catch (error) {
      handleError(error);
    }
  }
);

export const updateCandidateHiringStatus = createAsyncThunk(
  "dna/updateCandidateHiringStatus",
  async ({ orgType, orgId, userId, status }) => {
    try {
      const response = await DnaApi.updateCandidateHiringStatus(
        orgType,
        orgId,
        userId,
        status
      );
      if (response.success) {
        return response.result;
      }
    } catch (error) {
      handleError(error);
      return error;
    }
  }
);

export const getDnaStats = createAsyncThunk(
  "dna/getDnaStats",
  async (dnaId) => {
    try {
      const response = await DnaApi.getDnaStats(dnaId);
      if (response.success) {
        return response.result;
      }
    } catch (error) {
      // Change later - check for positionStats/dnaPositionStats -> Not to show error
      // handleError(error);
      return error;
    }
  }
);

export const removeInvitedUser = createAsyncThunk(
  "dna/removeInvitedUser",
  async (payload) => {
    try {
      let response = await CandidateApi.deleteInvitedCandidate(payload);
      if (response.success) {
        // Toast("success", "Candidate deleted successfully");
        return payload;
      }
    } catch (error) {
      Toast("error", error?.statusText);
      return error;
    }
  }
);
