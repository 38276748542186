import React from "react";
import { DatePicker, Form, Input, TimePicker, Select } from "antd";
import { get } from "src/utils/helperFunctions";

import FloatLabel from "../FloatLabel";

const { TextArea } = Input;
const FormItem = Form.Item;
const { Option } = Select;

const CreateAntField =
  (AntComponent) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    selectOptions,
    submitCount,
    type,
    value,
    heading,
    optionNameKey,
    optionValueKey,
    suffixIcon,
    disableOptionsOn,
    className,
    allowClear = false,
    ...props
  }) => {
    const touched = get(form?.touched, field?.name);
    const submitted = submitCount > 0;
    const hasError = get(form?.errors, field?.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;

    const onInputChange = ({ target: { value } }) =>
      form?.setFieldValue(field?.name, value);
    const onChange = (value) => {
      form?.setFieldValue(field?.name, value);
      if (props.onChange) {
        props.onChange(value);
      }
    };
    const onBlur = () => form?.setFieldTouched(field?.name, true);

    return (
      <div className="field-container">
        <FloatLabel label={label} val={value}>
          <FormItem
            hasFeedback={
              (hasFeedback && submitted) || (hasFeedback && touched)
                ? true
                : false
            }
            help={submittedError || touchedError ? hasError : false}
            validateStatus={
              submittedError || touchedError ? "error" : "success"
            }
          >
            <AntComponent
              {...field}
              onBlur={onBlur}
              className={className || ""}
              onChange={type && type!=="multiSelect" ? onInputChange : onChange}
              mode={type==='multiSelect' && (AntComponent == Select) ? 'multiple' : ''}
              type={type}
              allowClear={allowClear || false}
              suffixIcon={suffixIcon}
              autoComplete={AntComponent === Select ? "new-password" : "off"}
              {...props}
            >
              {selectOptions &&
                selectOptions.map(
                  (option) =>
                    !!option && (
                      <Option
                        disabled={
                          !!disableOptionsOn ? disableOptionsOn(option) : false
                        }
                        key={option?.value || option[optionValueKey]}
                      >
                        {option?.name || option[optionNameKey]}
                      </Option>
                    )
                )}
            </AntComponent>
          </FormItem>
        </FloatLabel>
      </div>
    );
  };

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntTextArea = CreateAntField(TextArea);
