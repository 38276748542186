import React, { useEffect, useState } from "react";
import "./SearchInput.scss";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useUrlFilter } from "src/utils/hooks";
import { QUERY_PARAMS_CONSTANTS } from "src/constants/queryConstants";
import { debounce } from "src/utils/helperFunctions";
import { SearchIcon } from "src/assets/icons";

export default function SearchInput(props) {
  const [filters, setFilters] = useUrlFilter();
  // State variable for search filter on page load/reload
  const [input, setInput] = useState('');

  const { searchInputStyle, placeholder, paramKey = "", locationValue = "" } = props;

  useEffect(() => {
    setInput(paramKey ? filters.paramKey : filters.searchKey || '');
  }, [filters.searchKey, filters.paramKey]);

  useEffect(() => {
    if (locationValue) {
      setInput(locationValue)
    }
  }, [locationValue])

  const searchHandler = debounce((e) => {
    if (paramKey) {
      if (!e.target.value) {
        delete filters[paramKey];
        setFilters({ ...filters });
        return;
      }
      setFilters({ ...filters, [paramKey]: e.target.value });
    } else {
      setFilters({ ...filters, [QUERY_PARAMS_CONSTANTS.SEARCH_KEY]: e.target.value });
    }
  }, 300);

  return (
    <>
      <div className={`search-input-container ${searchInputStyle}`}>
        <Input
          value={input}
          placeholder={placeholder}
          prefix={<SearchIcon />}
          onChange={(e) => {
            setInput(e.target.value);
            searchHandler(e);
          }}
        />
      </div>
    </>
  );
}
