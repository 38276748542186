export const zones = ["North", "South", "Mumbai/MMR", "West-East"]

export const barGraphCategories = [
    {
        dataKey: "highest",
        fill: "#71DF7C",
        barSize: 6,
    },
    {
        dataKey: "high",
        fill: "#A0F383",
        barSize: 6,
    },
    {
        dataKey: "median",
        fill: "#FFE37E",
        barSize: 6,
    },
    {
        dataKey: "lowest",
        fill: "#FE9B9B",
        barSize: 6,
    }
]

export const defaultValues = {
    FILTERS: {
        ZONE: "all zones",
        SITE: "all sites",
        ROLL: {
            ALL: {
                value: "all",
                label: "All",
            },
            ON_ROLL: {
                value: "on",
                label: "On-Roll",
            },
            OFF_ROLL: {
                value: "off",
                label: "Off-Roll",
            }
        },
        LEVEL: {
            ALL: {
                value: "all",
                label: "All",
            },
            EASY: {
                value: "easy",
                label: "Easy",
            },
            MEDIUM: {
                value: "medium",
                label: "Medium"
            },
            HARD: {
                value: "hard",
                label: "Advanced"
            }
        },
        SKILLS: {
            ALL: {
                label: "All",
                value: "all"
            }
        }
    },
    TABS: {
        TRAITS: "competencies",
        QUESTIONS: "questions",
    },
    TABLE: {
        TRAITS: ["competencies", "highest", "75th percentile", "median", "25th percentile", "lowest"],
        QUESTIONS: ["questions", "skills", "level", "correct", "incorrect"],
    }
}
