import React, { useState } from "react";
import "./OtpComponent.scss";
import OtpInput from "react-otp-input";

function OTPComponent({ field, form }) {
  const [otp, setOtp] = useState("");

  const handleChange = (value) => {
    form.setFieldValue(field.name, value);
    setOtp(value);
  };
  return (
    <>
      <OtpInput
        containerStyle="otp-container-style"
        inputStyle="otp-input-style"
        value={otp}
        onChange={handleChange}
        numInputs={4}
        isInputNum={true}
        placeholder="••••"
      />
    </>
  );
}

export default OTPComponent;
