import { filterOperations } from "src/utils/helperFunctions";
import { UnberryApi } from "../config/request";

const DnaApi = {
  getBasePositions() {
    return UnberryApi.safeCall(
      `/api/position/v1/base-positions?organizationType=:orgType&organizationId=:orgId`,
      "GET"
    );
  },

  listDnaPositions() {
    return UnberryApi.safeCall(
      `/api/position/v1/:orgType/:orgId/positions`,
      "GET",
      { params: { type: "dna" } }
    );
  },

  listOpenDnaPositions() {
    return UnberryApi.safeCall(
      `/api/company/v1/:orgId/get-dna-positions`,
      "GET"
    )
  },

  getDnaStats(dnaId) {
    return UnberryApi.safeCall(
      `/api/position/v1/:orgType/:orgId/${dnaId}/stats`,
      "GET"
    );
  },

  createDnaPosition(payload) {
    return UnberryApi.safeCall(
      `/api/position/v1/:orgType/:orgId/subscribe`,
      "POST",
      { data: payload }
    );
  },

  createPositionFromDnaPosition(payload) {
    return UnberryApi.safeCall(
      `/api/position/v1/:orgType/:orgId/create-child`,
      "POST",
      { data: payload }
    );
  },

  inviteUser(payload) {
    return UnberryApi.safeCall(`/api/link/v1/:orgType/:orgId`, "POST", {
      data: payload,
    });
  },

  getAllUsersByPosition(positionId, filters = {}) {
    filters = filterOperations(filters, "searchKey");
    return UnberryApi.safeCall(
      `/api/position/v1/:orgType/:orgId/${positionId}/users`,
      "GET",
      { params: filters }
    );
  },
};

export default DnaApi;
