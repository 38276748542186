import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthApi from "src/services/apis/auth";
import { handleError } from "src/utils/helperFunctions";

export const setOrganizations = createAsyncThunk(
  "organizations/getOrganizations",
  async ({ search=null }) => {
    try {
      const permittedOrg = await AuthApi.getAccessibleOrganizations(search);
      if (permittedOrg.success) {
        return permittedOrg.organizations;
      }
    } catch (error) {
      handleError(error);
      return error;
    }
  }
);
