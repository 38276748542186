import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./constant";
import {
  getDnaPositions,
  getBasePositions,
  getAllUsersByPosition,
  updateCandidateHiringStatus,
  getDnaStats,
  removeInvitedUser,
} from "./asyncActions";

export const positionSlice = createSlice({
  name: "dna",
  initialState,
  reducers: {
    reset: () => initialState,
    updateUserStatus: (state, { payload }) => {
      const { userId, status } = payload;
      state.usersByPosition.users = state.usersByPosition.users.map((user) => {
        return user.id === userId ? { ...user, status } : user;
      });
    },
  },
  extraReducers: {
    [getDnaPositions.fulfilled]: (state, action) => {
      state.dnaPositions = {
        data: action.payload?.result,
        loading: false,
      };
    },
    [getDnaPositions.pending]: (state, action) => {
      state.dnaPositions = {
        ...state.dnaPositions,
        loading: true,
      };
    },

    [getBasePositions.fulfilled]: (state, action) => {
      state.basePositions = {
        data: action.payload?.result,
        loading: false,
      };
    },
    [getBasePositions.pending]: (state, action) => {
      state.basePositions = {
        ...state.basePositions,
        loading: false,
      };
    },

    [updateCandidateHiringStatus.fulfilled]: (state, action) => {
      state.usersByPosition.users = state.usersByPosition.users.map((user) => {
        if (user.userId === action.payload.userId) {
          return { ...user, hiringStatus: action.payload.status };
        }
        return user;
      });
    },

    [removeInvitedUser.fulfilled]: (state, action) => {
      state.usersByPosition.users = state.usersByPosition.users.filter(
        (user) => user.id !== action?.payload?.userId
      );
    },

    [getAllUsersByPosition.fulfilled]: (state, action) => {
      state.usersByPosition = {
        users: action.payload?.result.rows,
        count: action.payload?.result.count,
        countPerPage: action.payload?.result.countPerPage,
        totalPages: action.payload?.result.totalPages,
        loading: false,
      };
    },

    [getAllUsersByPosition.pending]: (state, action) => {
      state.usersByPosition = {
        ...state.usersByPosition,
        totalPages: 1,
        loading: true,
      };
    },
    [getDnaStats.fulfilled]: (state, action) => {
      state.dnaStats.data = action.payload;
      state.dnaStats.hasData = !!Object.keys(action.payload).length;
      state.dnaStats.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { reset, updateUserStatus } = positionSlice.actions;

export default positionSlice.reducer;
