import React from "react";
import "../../pages/Login/Login.scss";
import { AntInput } from "../../components/Common/CreateAntFields/CreateAntFields";
import { Button } from "antd";
import { Field, Formik } from "formik";
import { loginValidation } from "src/pages/Login/validate.js";
import { initialValues } from "./constants";

export default function LoginForm(props) {
  const { onSubmit } = props;
  return (
    <>
      <div className="text-content-block mb-60">
        <h2 className="text-36 semibold">Welcome back</h2>
        <p className="text-18 line-28 semibold">
          Enter your registered email ID to go to your dashboard.
        </p>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={loginValidation}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        enableReinitialize
      >
        {({ values, handleSubmit }) => {
          return (
            <div className="email-wrapper flex">
              <div className="email-field mr-10">
                <Field
                  className="antd-primary-input"
                  component={AntInput}
                  type="text"
                  value={values.email}
                  name={"email"}
                  placeholder={"Enter here"}
                  hasFeedback
                  allowClear={false}
                  onPressEnter={handleSubmit}
                />
              </div>
              <Button
                className="ant-btn-primary semibold"
                onClick={handleSubmit}
              >
                Get OTP
              </Button>
            </div>
          );
        }}
      </Formik>
    </>
  );
}
