import { useRef, useState } from "react"
import {
    PlayCircleFilled,
    PauseCircleFilled,
} from '@ant-design/icons'
import "./styles.scss"

export const AudioPlayer = ({ src }) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const audioRef = useRef(null)

    const durationChangeHandler = (event) => {
        setDuration(Math.floor(event.target.duration))
    }

    const timeUpdateHandler = (event) => {
        setCurrentTime(Math.floor(event.target.currentTime))
    }

    const audioEndedHandler = () => {
        audioRef.current.currentTime = 0
        setIsPlaying(false)
    }

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause()
        } else {
            audioRef.current.play()
        }
    }

    const handleSliderChange = (event) => {
        audioRef.current.currentTime = event.target.value
        setCurrentTime(event.target.value)
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        const formattedMinutes = String(minutes).padStart(2, "0")
        const formattedSeconds = String(remainingSeconds).padStart(2, "0")
        return `${formattedMinutes}:${formattedSeconds}`
    }

    return (
        <div className="flex items-center justify-between py-10 px-8 b-12 audio-player-container">
            <audio
                ref={audioRef}
                onDurationChange={durationChangeHandler}
                onTimeUpdate={timeUpdateHandler}
                onEnded={audioEndedHandler}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
            >
                <source src={src} type="audio/wav" />
            </audio>
            <button className="mr-8" onClick={togglePlay}>
                {isPlaying ? <PauseCircleFilled /> : <PlayCircleFilled />}
            </button>
            <input
                className="audio-slider"
                type="range"
                min={0}
                max={duration}
                step="1"
                value={currentTime}
                onChange={handleSliderChange}
            />
            <p className="ml-8 text-14">{formatTime(currentTime)}</p>
        </div>
    )
}
