import React, { useState, useEffect } from "react";

import { Button } from "antd";
import Modal from "../Modal";
import "./ConfirmationModal.scss";
import { CancelBtn } from "src/assets/icons";

const ConfirmationModal = (props) => {
  const {
    className,
    children,
    onSubmit,
    showModal,
    onClose,
    confirmationText,
    cancelText,
  } = props;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(showModal);
  }, [showModal]);

  const submitHandler = (action) => {
    onSubmit(action);
    setVisible(false);
  };

  return (
    <Modal
      modalVisible={visible}
      cancelModal={() => {
        setVisible(false);
        onClose();
      }}
      centered
      className={`confirmation-modal ${className}`}
    >
      {children}

      <div
        className="flex align-center justify-between mt-20 gap-10"
        style={{ columnGap: 10 }}
      >
        {cancelText && (
          <Button
            className="ant-btn ant-btn-secondary semibold flex items-center"
            onClick={() => {
              submitHandler(false);
            }}
            icon={<span className="mr-4 mb-2"><CancelBtn /></span>}
          >
            {cancelText || "No"}
          </Button>
        )}
        <Button
          className="ant-btn ant-btn-primary text-16 bold px-40"
          onClick={() => {
            submitHandler(true);
          }}
        >
          {confirmationText || "Yes"}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
