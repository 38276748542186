import React from 'react';
import './Tag.scss';

export default function Tag(props) {
  const { text, className, color } = props;
  return (
    <span
      className={`uppercase tag weight-600 ${className && className}`}
      style={{ background: color || '#55B255' }}
    >
      {text}
    </span>
  );
}
