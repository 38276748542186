import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./constant";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth(state, action) {
      state.organizations = action.payload;
    }
  },
  extraReducers: {},
});

export const { setAuth } = authSlice.actions
export default authSlice.reducer;
