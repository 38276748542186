import React from "react";

import { FIT_BANDS } from "../constant";
import { getTraitBand } from "../oprations";
import { roundOff } from "src/pages/PdfReport/operations";
import { Average, CheckIcon, Star } from "src/assets/icons";

export const FitScore = ({
  score = 0,
  data = {},
  dnaScore,
  showLegends = false,
  fitScoreMultiplier = 1
}) => {
  const { median } = data;
  const fillPercentage = (score / (10 * fitScoreMultiplier)) * 100;
  const currentBand = getTraitBand({
    ub: data.upperBound,
    lb: data.lowerBound,
    median: data.median,
    score: roundOff(score),
  });
  const average = (median / (10 * fitScoreMultiplier)) * 100;
  const topPerformer = (dnaScore / (10 * fitScoreMultiplier)) * 100;
  return (
    <section className="fit-score">
      <div
        className="band"
      >
        <div
          className="fill"
          style={{ background: currentBand.color, width: `${fillPercentage}%` }}
        ></div>
        <div className="median" style={{ left: average + "%" }}>
          <p className="icon-label">Industry Average</p>
        </div>
        <span
          className="median-icon"
          style={{ left: average + "%" }}
        >
          <Average />
        </span>
        {dnaScore !== undefined && dnaScore !== null && (
          <>
            <div className="median" style={{ left: topPerformer + "%" }}>
              <p className="icon-label">Your Team’s Performance</p>
            </div>
            <span className="median-icon" style={{ left: topPerformer + "%" }}>
              <Star />
            </span>
          </>
        )}
        <div className="content">
          <h4 className="fit-label" style={{ color: currentBand.textColor }}>
            Fit Score:
          </h4>
          <h1 className="score" style={{ color: currentBand.textColor }}>
            {roundOff(score)}
          </h1>
        </div>
      </div>
      {showLegends && (
        <div className="scales">
          {Object.values(FIT_BANDS)
            .reverse()
            .map((band, i) => (
              <div className="scale" key={band.label}>
                <div
                  className="color-circle"
                  style={{ backgroundColor: band.color }}
                >
                  {band.label === currentBand.label && (
                    <CheckIcon color={band.textColor} />
                  )}
                </div>
                <span
                  className={band.label === currentBand.label ? "active" : null}
                >
                  {band.label}
                </span>
              </div>
            ))}
        </div>
      )}
    </section>
  );
};
