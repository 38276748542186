import moment from "moment"
import { FIT_BANDS } from "src/pages/OnePagerReport/constant"
import { FIT_SCORE_KEY } from "./constants"

/**
 * This function takes the postion constraints and returns an object of bands with their score ranges.
 * @param {constraints} - position constraints with lower median and upper bound
 * @return {updatedFitBands} - bands with their label, color and fit score ranges 
 */
export const getFitScoreFilter = (constraints) => {
    let updatedFitBands = { ...FIT_BANDS }
    for (const key in FIT_BANDS) {
        delete updatedFitBands.description
        delete updatedFitBands.dnaLabel
        if (key === "HIGHLY") {
            updatedFitBands[key]["range"] = `${constraints?.upperBound}-10`
        } else if (key === "RECOMMENDED") {
            updatedFitBands[key]["range"] = `${constraints?.median}-${constraints?.upperBound-0.01}`
        } else if (key === "CAUTIOUSLY") {
            updatedFitBands[key]["range"] = `${constraints?.lowerBound}-${constraints?.median-0.01}`
        } else if (key === "NOT") {
            updatedFitBands[key]["range"] = `0-${constraints?.lowerBound-0.01}`
        }
    }
    return updatedFitBands
}

/**
 * This function returns how many values are selected for a particular filter key.
 * @param {status} - filter key name
 * @param {filters} - filters object
 * @return {Number} - Count of how many values are selected  
 */
export const getStatusFilterLength = (status = '', filters = {}) => {
    const statusFilterLength = filters[status]?.split(',')?.length
    return isNaN(statusFilterLength) || filters[status]?.length === 0 ? 0 : statusFilterLength
}

/**
 * This function returns the total and individual count of filters.
 * @param {filters} - filters object
 * @return {Object} - Object of length of total and individual filter  
 */
export const getFiltersCount = (filters) => {
    const gameplayFilterCount = getStatusFilterLength('gameplayStatus', filters)
    const hiringFilterCount = getStatusFilterLength('hiringStatus', filters)
    const userStatusFilterCount = getStatusFilterLength('linkStatus', filters)
    const fitScoreFilterCount = getStatusFilterLength(FIT_SCORE_KEY, filters)
    const totalStatusFilterCount = gameplayFilterCount + hiringFilterCount + userStatusFilterCount + fitScoreFilterCount

    return {
        totalStatusFilterCount,
        gameplayFilterCount,
        hiringFilterCount,
        userStatusFilterCount,
        fitScoreFilterCount
    }
}

/**
 * This function returns the label text for the filter keys.
 * @param {status} - filter key name
 * @param {filters} - filters object
 * @return {String} - Label text  
 */
export const getFilterLabel = (status = '', filters) => {
    const { totalStatusFilterCount, gameplayFilterCount, hiringFilterCount, userStatusFilterCount, fitScoreFilterCount } = getFiltersCount(filters)

    if (status === 'gameplayStatus') {
        return `Gameplay Status ${gameplayFilterCount === 0 ? "" : `(${gameplayFilterCount})`}`
    } else if (status === 'hiringStatus') {
        return `Hiring Status ${hiringFilterCount === 0 ? "" : `(${hiringFilterCount})`}`
    } else if (status === 'linkStatus') {
        return `User Status ${userStatusFilterCount === 0 ? "" : `(${userStatusFilterCount})`}`
    } else if (status === FIT_SCORE_KEY) {
        return `Fit Score ${fitScoreFilterCount === 0 ? "" : `(${fitScoreFilterCount})`}`
    } else {
        return totalStatusFilterCount === 0
            ? 'Status'
            : `(${totalStatusFilterCount}) Selected`
    }
}

export const momentConverter = (values, stringify = true) => {
    if ("created" in values) {
        values.created = stringify
            ? values.created.format("YYYY-MM-DD")
            : moment(values.created);
    }
    return values;
};