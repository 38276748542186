import React from "react";
import "./RecommendedScale.scss";
import { recommendations } from "../dummy";
import Text from "../Text";
import { getTraitBand } from "src/pages/OnePagerReport/oprations";

export const RecommendedScale = (props) => {
  const { score = 0, data, ranges = [] } = props;
  const percentage = score * 10;
  
  return (
    <>
      <div className="mt-38 mb-40">
        <Text className="size-13 weight-400" text="OVERALL RECOMMENDATION" />
        <h2 className="size-22 weight-500 mt-4">
          {
            getTraitBand({
              ub: data?.upperBound,
              lb: data?.lowerBound,
              median: data?.median,
              score,
            })?.label
          }
        </h2>
      </div>
      <div className="overall-recommendation">
        <div className="triangle" style={{ left: `${percentage}%` }} />
        {recommendations.map((item, index) => (
          <div
            className="scale"
            style={{ flex: ranges?.[index]?.[1] - ranges?.[index]?.[0] }}
          >
            <div
              className="color-block"
              style={{ backgroundColor: item.color }}
            />
            <h4>{item.label}</h4>
          </div>
        ))}
      </div>
    </>
  );
};
