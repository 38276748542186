import React, { useState, useEffect, useCallback } from "react";
import { Button, Drawer } from "antd";
import { useHistory } from "react-router-dom";
import PositionStatsCard from "src/components/Common/PositionStatsCard";
import "./Positions.scss";
import PageHelmet from "src/components/Common/PageHelmet";
import ConfirmationModal from "src/components/Common/ConfirmationModal";
import { useSelector, useDispatch } from "react-redux";
import {
  setPositions,
  archievePosition,
} from "src/store/Positions/asyncActions";
import InfoCardShimmer from "src/components/Common/Skeletons/InfoCardShimmer/InfoCardShimmer";
import { AUTH_CONSTANTS } from "src/constants/authConstants";
import { MENU_TAB, MIXPANEL_EVENTS } from "src/constants/globalConstants";
import { storage } from "src/services/config/storage";
import {
  POSITION_TYPES,
  POSITION_ARCHIVE_STATUS,
} from "src/pages/Positions/constants";
import Tab from "src/components/Common/Tab";
import BlankState from "src/components/Common/BlankState";
import AddPositionForm from "./AddPosition";
import { Mixpanel } from "src/services/mixpanel";
import { pluralizeWord } from "src/utils/helperFunctions";
import { AddIcon } from "src/assets/icons";

export default function Positions() {
  /**
   * ===================
   * props from parent
   * ===================
   */

  /**
   * ===================
   * props from redux
   * ===================
   */

  /**
   * ===================
   * constant variables
   * ===================
   */

  const history = useHistory();
  const positions = useSelector((state) => state.positions.list);
  const dispatch = useDispatch();
  const orgType = AUTH_CONSTANTS.ORGANIZATION_TYPE;
  const orgId = storage.get.organizationInfo().organizationId;

  /**
   * ===================
   * useStates
   * ===================
   */
  const [showArchieveModal, setShowArchieveModal] = useState(false);
  const [toBeArchieved, setToBeArchieved] = useState("");
  const [menuKey, setMenuKey] = useState(MENU_TAB.OPEN);
  const [data, setData] = useState([]);
  const [openCount, setOpenCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [activeCardId, setActiveCardId] = useState("");
  const [newPositionDrawer, setNewPositionDrawer] = useState(false);
  /**
   * ===================
   * useRefs
   * ===================
   */

  /**
   * ===================
   * computed variables
   * ===================
   */

  /**
   * ===================
   * custom hooks
   * ===================
   */

  /**
   * ===================
   * functions
   * ===================
   */

  const onPositionAdd = () => {
    setNewPositionDrawer(false);
    handleMenu(MENU_TAB.OPEN);
  };

  const handleMenu = (key) => {
    const positionType =
      key === MENU_TAB.OPEN ? POSITION_TYPES.OPEN : POSITION_TYPES.CLOSE;
    dispatch(
      setPositions({ type: POSITION_TYPES.HIRING, status: positionType })
    );
    setMenuKey(key);
  };

  const handleCardFading = () => {
    setActiveCardId("");
  };

  const onPositionCardClick = ({ id, name, stats }) => {
    // Mixpanel Event
    if (menuKey === MENU_TAB.OPEN) {
      const { invited, playing, completed, shortlisted } = stats;
      Mixpanel.track(MIXPANEL_EVENTS.ClickedOpenPosition, {
        "Position Title": name,
        "Total Invites": invited,
        "Total Started": playing,
        "Total Completed": completed,
        Shortlisted: shortlisted,
      });
    }
    history.push(`/hiring/${id}`);
  };

  /**
   * ===================
   * useCallback
   * ===================
   */

  const getCurrentTabCount = useCallback(() => menuKey === MENU_TAB.OPEN ? openCount : archivedCount
    , [menuKey, openCount, archivedCount])

  /**
   * ===================
   * useEffects
   * ===================
   */

  useEffect(() => {
    window.addEventListener("click", handleCardFading);
    return () => window.removeEventListener("click", handleCardFading);
  });

  useEffect(() => {
    if (orgType && orgId) {
      dispatch(
        setPositions({
          type: POSITION_TYPES.HIRING,
          status: POSITION_TYPES.OPEN,
        })
      );
    }
  }, [orgType, orgId]);

  useEffect(() => {
    setData(positions?.data);
    setOpenCount(positions?.open);
    setArchivedCount(positions?.archived);
    if (newPositionDrawer && !positions.loading) setNewPositionDrawer(false);
  }, [positions]);

  return (
    <>
      <PageHelmet title="Positions" />
      <div className="position-container">
        <div className="content-wrapper">
          <div className="tab-wrapper">
            <Tab
              data={[
                {
                  key: MENU_TAB.OPEN,
                  count: openCount,
                  name: pluralizeWord(
                    "Open Position",
                    "Open Positions",
                    openCount
                  ),
                },
                {
                  key: MENU_TAB.ARCHIVED,
                  count: archivedCount,
                  name: "Archived",
                  disabled: archivedCount === 0,
                },
              ]}
              onClick={(key) => handleMenu(key)}
              currentKey={menuKey}
            />
            <div className="btn-box">
              <Button
                className="ant-btn-primary text-16 weight-400 px-40"
                icon={<AddIcon />}
                onClick={() => setNewPositionDrawer(true)}
              >
                Add New Position
              </Button>
            </div>
          </div>
          {positions.loading ? (
            <div className="card-container flex flex-wrap mt-60">
              <InfoCardShimmer elementNumber={4} />
            </div>
          ) : (
            <>
              {!!(getCurrentTabCount()) ? (
                <>
                  <div className="card-container grid">
                    {data?.map((data) => {
                      return (
                        <PositionStatsCard
                          id={data.id}
                          key={data.id}
                          orgId={orgId}
                          archived={menuKey === MENU_TAB.ARCHIVED}
                          icon={data.mediaUrl || "/img/code-alt.png"}
                          name={data.name}
                          created={data.createdAt}
                          archivedOn={data.archivedOn}
                          stats={data.stats}
                          activeStatus={data.status}
                          type={data.parentPosition?.name}
                          expertise={data.experience}
                          cardId={activeCardId}
                          activeCard={(id) => setActiveCardId(id)}
                          onArchieve={(id) => {
                            setToBeArchieved(id);
                            setShowArchieveModal(true);
                          }}
                          onClick={() => onPositionCardClick(data)}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <BlankState
                  className="blank-message"
                  message={"No positions yet.\n Start adding here"}
                  onClick={() => setNewPositionDrawer(true)}
                />
              )}
            </>
          )}
        </div>
      </div>
      <ConfirmationModal
        showModal={showArchieveModal}
        onClose={() => setShowArchieveModal(false)}
        cancelText="Cancel"
        confirmationText={menuKey === MENU_TAB.OPEN ? "Archive" : "Unarchive"}
        className="archive"
        onSubmit={async (action) => {
          if (action) {
            const archiveStatus =
              menuKey === MENU_TAB.OPEN
                ? POSITION_ARCHIVE_STATUS.CLOSE
                : POSITION_ARCHIVE_STATUS.OPEN;
            dispatch(
              archievePosition({
                positionId: toBeArchieved,
                status: archiveStatus,
              })
            );

            // Mixpanel Event
            if (menuKey === MENU_TAB.OPEN) {
              const [{ name, stats }] = data.filter(
                (position) => position.id === toBeArchieved
              );
              Mixpanel.track(MIXPANEL_EVENTS.ArchivedPosition, {
                "Position Title": name,
                "Total Invites": stats.invited,
                "Total Started": stats.playing,
                "Total Completed": stats.completed,
                Shortlisted: stats.shortlisted,
              });
            }
          }
          setShowArchieveModal(false);
        }}
      >
        <h4 className="semibold pb-20">
          {menuKey === MENU_TAB.OPEN
            ? "Are your sure you want to archive this position?"
            : "Are your sure you want to unarchive this position?"}
        </h4>
      </ConfirmationModal>
      <Drawer
        placement="right"
        onClose={() => setNewPositionDrawer(false)}
        visible={newPositionDrawer}
        destroyOnClose
        className="drawers"
        closable={false}
      >
        <AddPositionForm
          onCancel={() => setNewPositionDrawer(false)}
          onSuccess={onPositionAdd}
        />
      </Drawer>
    </>
  );
}
