import { Drawer } from "antd";
import { BarGraph, ScoreDistribution } from "./Stats";
import { barGraphCategories, zones } from "./constants";
import { CloseIcon, GitDiff } from "src/assets/icons"

const ComparativeAnalysis = ({
    analyticsData,
    isComparativeAnalysisVisible,
    comparativeAnalysisStep,
    selectedZones,
    setSelectedZones,
    handleCloseComparativeAnalysis,
    handleCompareZones,
    handleGoBack,
}) => {

    return (
        <Drawer
            placement="right"
            onClose={handleCloseComparativeAnalysis}
            visible={isComparativeAnalysisVisible}
            destroyOnClose
            className="comparative-drawer"
            closable={false}
            width={"767px"}
        >
            <div className="flex justify-between items-start pt-10 px-18">
                {
                    comparativeAnalysisStep === 1
                        ? <div className="flex flex-col">
                            <p className="text-24 color-black weight-700 capitalize">
                                <span className="mr-4">
                                    <GitDiff />
                                </span>
                                comparative analysis
                            </p>
                            <p className="text-14 weight-600">Compare zones and see insights across those zone</p>
                        </div>
                        : <div className="flex flex-col items-start">
                            <p className="text-24 color-black weight-700 capitalize">showing comparison for</p>
                            <div className="flex items-center selected-zones-container mt-12 mb-18">
                                {
                                    selectedZones?.map(zone => (
                                        <div
                                            key={zone}
                                            className={`capitalize text-18 weight-600 color-black py-12 px-50 zone-pill-active`}
                                        >
                                            {zone}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                }
                <CloseIcon className="cursor-pointer " onClick={handleCloseComparativeAnalysis} />
            </div>
            {
                comparativeAnalysisStep === 1
                    ? <div className="relative analysis-step1-container flex items-center">
                        <div className="absolute zones-container flex flex-col items-center">
                            <p className="tex-18 weight-600 opacity-7">Select all zones you want to compare:</p>
                            <div className="flex justify-center zones-pills-container mt-24">
                                {
                                    ["all zones", ...zones]
                                        ?.map(zone => {
                                            const isZoneSelected = selectedZones.includes(zone)
                                            return (
                                                <button
                                                    key={zone}
                                                    className={`capitalize text-18 weight-600 color-black py-12 px-50 ${isZoneSelected ? "zone-pill-active" : "zone-pill"}`}
                                                    onClick={() => {
                                                        if (isZoneSelected) {
                                                            setSelectedZones(prev => prev.filter(z => z !== zone))
                                                        } else {
                                                            setSelectedZones(prev => ([...prev, zone]))
                                                        }
                                                    }}
                                                >
                                                    {zone}
                                                </button>
                                            )
                                        })
                                }
                            </div>
                        </div>
                        <button
                            className="compare-submit-btn cursor-pointer capitalize text-18 weight-600 absolute py-12 px-60"
                            onClick={handleCompareZones}
                        >
                            compare zones
                        </button>
                    </div>
                    : <div className="analysis-step2-container">
                        <div className="flex flex-col justify-center graph-container mx-8">
                            <div className="flex justify-between items-center p-20">
                                <p className="uppercase text-14 weight-700 color-black graph-heading">
                                    pool performance
                                </p>
                                <div className="flex items-center">
                                    {
                                        barGraphCategories?.map(barCategory => (
                                            <p key={barCategory.dataKey} className="legend-text flex items-center text-12 weight-400 color-black capitalize mr-12">
                                                <span />
                                                {barCategory.dataKey} score
                                            </p>
                                        ))
                                    }
                                </div>
                            </div>
                            <BarGraph
                                data={selectedZones?.map(zone => ({
                                    name: zone,
                                    ...(zone === "all zones" ? {
                                        highest: Math.round(((analyticsData?.overall?.performance?.highlyRecommended ?? 0) / (analyticsData?.overall?.performance?.totalUsers ?? 0)) * 100),
                                        high: Math.round(((analyticsData?.overall?.performance?.recommended ?? 0) / (analyticsData?.overall?.performance?.totalUsers ?? 0)) * 100),
                                        median: Math.round(((analyticsData?.overall?.performance?.average ?? 0) / (analyticsData?.overall?.performance?.totalUsers ?? 0)) * 100),
                                        lowest: Math.round(((analyticsData?.overall?.performance?.notRecommended ?? 0) / (analyticsData?.overall?.performance?.totalUsers ?? 0)) * 100),
                                    } : {
                                        highest: Math.round(((analyticsData?.zonal?.[zone]?.overall?.performance?.highlyRecommended ?? 0) / (analyticsData?.zonal?.[zone]?.overall?.performance?.totalUsers ?? 0)) * 100),
                                        high: Math.round(((analyticsData?.zonal?.[zone]?.overall?.performance?.recommended ?? 0) / (analyticsData?.zonal?.[zone]?.overall?.performance?.totalUsers ?? 0)) * 100),
                                        median: Math.round(((analyticsData?.zonal?.[zone]?.overall?.performance?.average ?? 0) / (analyticsData?.zonal?.[zone]?.overall?.performance?.totalUsers ?? 0)) * 100),
                                        lowest: Math.round(((analyticsData?.zonal?.[zone]?.overall?.performance?.notRecommended ?? 0) / (analyticsData?.zonal?.[zone]?.overall?.performance?.totalUsers ?? 0)) * 100),
                                    })
                                }))}
                                barCategories={barGraphCategories}
                                xTickFormatter={(value) => `${value[0]?.toUpperCase()}${value.slice(1,)}`}
                                yTickFormatter={(value) => `${value}%`}
                            />
                        </div>
                        <ScoreDistribution
                            data={selectedZones?.map(zone => ({
                                type: zone,
                                ...(zone === "all zones" ? {
                                    scoreDistribution: analyticsData?.overall?.scoreDistribution ?? {},
                                } : {
                                    scoreDistribution: analyticsData?.zonal?.[zone]?.overall?.scoreDistribution ?? {},
                                }),
                                multiplier: (analyticsData?.fitScoreMultiplier ?? 1),
                            }))}
                            isDistributionComparative={true}
                        />
                        <div className="flex items-center justify-center mt-80 mb-30">
                            <button
                                className="compare-goback-btn cursor-pointer capitalize text-18 weight-600 py-12 px-60"
                                onClick={handleGoBack}
                            >
                                go back
                            </button>
                        </div>
                    </div>
            }
        </Drawer>
    )
}

export default ComparativeAnalysis