import React from 'react';
import './UserProfile.scss';

export default function UserProfile(props) {
  const { className, username, position, imageUrl, organization } = props;
  return (
    <div className={`user-profile flex ${className}`}>
      <div className="hero-img">
        {imageUrl ? (
          <img src={imageUrl} alt="hero-img" />
        ) : (
          <span className="name-initial">{username?.[0]}</span>
        )}
      </div>
      <div className="flex flex-col">
        <h3 className='size-22 weight-500'> {username} </h3>
        <p className='size-15 weight-400 mt-2'>
          {position} @ {organization}{' '}
        </p>
      </div>
    </div>
  );
}
