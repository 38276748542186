import { Menu } from "antd";
import "./Tab.scss";

export default function Tab({ data, onClick, currentKey, titleClassName }) {
  return (
    <Menu
      className="switch-tab"
      onClick={({ key }) => onClick(key)}
      selectedKeys={currentKey}
      mode="horizontal"
    >
      {data?.map((item, index) => (
        <Menu.Item disabled={item.disabled} key={item.key}>
          <p className={`weight-600 ${titleClassName || "text-22"}`}>
            {item.name}
            <span className="gameplay-count"> {item.count} </span>
          </p>
        </Menu.Item>
      ))}
    </Menu>
  );
}
