import React, { useState, useEffect } from 'react';
import { getColorCode } from '../operations';
import './Scale.scss';
import { roundOff } from '../operations'

export default function Scale(props) {
  const { median, lowerBound, upperBound, score } = props;
  const BASE_UNIT = 9;
  const POINTER_UNIT = 40;
  // const MARGIN_UNIT = 1;
  const [colorCode, setColorCode] = useState(null);

  useEffect(() => {
    if (median) {
      const colorCode = getColorCode(median, lowerBound, upperBound, score);
      setColorCode(colorCode);
    }
  }, [median]);
  const calculatePixel = (unit) => {
    const withoutMargin = unit * BASE_UNIT;
    return withoutMargin + Math.round(withoutMargin / BASE_UNIT);
  };

  return (
    <div className="scale relative">
      <div className="scale-points flex">
        {Array.from(Array(10).keys()).map((scale) => {
          return <div className="scale-unit"></div>;
        })}
      </div>
      <div
        className="scale-bound absolute"
        style={{
          backgroundColor: '#ACB9D8',
          width: `${calculatePixel(upperBound - lowerBound)}%`,
          left: `${calculatePixel(lowerBound)}%`,
        }}
      ></div>
      <div
        className="scale-score size-18 weight-500 flex justify-center items-center absolute"
        style={{
          backgroundColor: colorCode,
          left: `calc(${calculatePixel(score)}% - ${POINTER_UNIT / 2}px)`,
          width: POINTER_UNIT,
          height: POINTER_UNIT,
        }}
      >
        {roundOff(score)}
      </div>
      <div
        className="scale-median-pointer absolute"
        style={{ left: `${calculatePixel(median)}%` }}
      ></div>
    </div>
  );
}
