/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import queryString from "query-string"
import { useHistory, useLocation } from "react-router-dom"

import Listing from "src/components/Common/Listing"
import PageHelmet from "src/components/Common/PageHelmet"
import PrimaryPagination from "src/components/Common/PrimaryPagination"
import SearchInput from "src/components/Common/SearchInput"
import UsersListingShimmer from "src/components/Common/Skeletons/UsersListingShimmer"
import FeedbackFilters from "./Feedback/Filters"
import BlankState from "src/components/Common/BlankState"

import { storage } from "src/services/config/storage"
import { setFeedbacks } from "src/store/Feedbacks/asyncActions"
import { REPORT_STATUS, feedbackFeelingsArray } from "src/constants/globalConstants"
import { getDefaultFormattedDate, getReportLink, hasFilterChanged } from "src/utils/helperFunctions"
import { convertToStyle, getTraitBand } from "../OnePagerReport/oprations"
import { reset as feedbackReset } from "src/store/Feedbacks/reducer"
import "./Feedbacks.scss"
import { roundOff } from "../PdfReport/operations";

const Feedbacks = () => {
    const dispatch = useDispatch();
    const feedbacks = useSelector((state) => state.feedbacks)
    const organizations = useSelector((state) => state.organizations.list.data)
    const [filters, setFilters] = useState({})
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        if (!location.search) return;
        const filterObj = queryString.parse(location.search)
        const company = storage.get.organizationInfo()
        filterObj.organizationId = company.organizationId
        filterObj.organizationType = "company"
        if (filterObj["userSearchQuery"]?.length === 0) {
            delete filterObj["userSearchQuery"]
        }
        setFilters(filterObj)
        dispatch(setFeedbacks({ query: { ...filterObj, page: filterObj?.page ?? 1, pageSize: 10, isActive: true } }))
    }, [location])

    useEffect(() => {
        const filterObj = queryString.parse(location.search)
        if (!filterObj?.organizationId) {
            const company = storage.get.organizationInfo()
            if (filterObj["userSearchQuery"]?.length === 0) {
                delete filterObj["userSearchQuery"]
            }
            filterObj.organizationId = company.organizationId
            filterObj.organizationType = "company"
            setFilters({ ...filterObj })
            updateLocation(filterObj)
        }
        return () => dispatch(feedbackReset())
    }, [])

    /** 
     * REWORK: AUTH_PERM_LEVEL
     * 
     * Handle through authToken -> Auth levels later 
     * organizations.length > 1 => SuperAdmin
     * 
     * Allow pageViewing for superAdmin, companyAdmins with toggled feedbacks in orgConfig
     */
    useEffect(() => {
        if (organizations.length) {
            handleComponentViewForAdmins();
        }
    }, [organizations])

    const updateLocation = (searchObj) => {
        const isChanged = hasFilterChanged(filters, searchObj)
        if (isChanged) {
            searchObj["page"] = 1
        }
        const search = queryString.stringify(searchObj);
        history.push({
            search: `${search ? `${search}` : ''}`,
        })
    }

    const handleComponentViewForAdmins = async () => {
        if (organizations.length > 1) return;
        if (organizations.length === 1) {
            const viewFeedbacks = organizations[0]?.organizationConfig?.moduleFlags?.feedbacks;
            if (viewFeedbacks) return;
            history.push("/hiring");
        }
    }

    return (
        <>
            <PageHelmet title="Feedbacks" />
            <div className="feedbacks-listing flex flex-col px-70 py-20">
                <div className="subheading-container flex justify-between items-center mb-20">
                    <p className="subheading semibold text-28">Candidate Feedbacks &#40;{feedbacks?.data?.count ?? ""}&#41;</p>
                    <div className="flex-1 search">
                        <SearchInput paramKey={"userSearchQuery"} locationValue={filters["userSearchQuery"] ?? ""} placeholder="Search for a candidate" />
                    </div>
                    <FeedbackFilters
                        filters={filters}
                        updateLocation={updateLocation}
                    />
                </div>
                {
                    feedbacks.loading
                        ? (<UsersListingShimmer elementNumber={5} />)
                        : feedbacks.data?.rows?.length > 0
                            ? (<Listing
                                dataSource={feedbacks?.data?.rows}
                                headerLayout={"0.8fr 0.5fr 0.4fr 0.3fr 1.1fr 0.4fr 0.3fr"}
                                dataLayout={"0.8fr 0.5fr 0.4fr 0.3fr 1.1fr 0.4fr 0.3fr"}
                                headers={[
                                    "Name & Email",
                                    "Position",
                                    "Sentiment",
                                    "Rating",
                                    "Feedback/Suggestion",
                                    "Report",
                                    "Completed On"
                                ]}
                                dataKeys={[
                                    {
                                        name: "name",
                                        render: (data) => {
                                            return (
                                                <div className="name-email-container flex items-center">
                                                    <div className="mr-10">
                                                        <p className="name-initials px-10 py-8">
                                                            {data?.user?.name?.[0]}
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-col items-start">
                                                        <p className="name text-14 mb-4 weight-600 text-dark break-word">
                                                            {data?.user?.name}
                                                        </p>
                                                        <p className="name text-14 weight-400 break-word">
                                                            {data?.user?.email}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "position",
                                        render: (data) => {
                                            return (
                                                <div>
                                                    <p className="position text-14 weight-600 text-dark break-word">
                                                        {data?.position?.name}
                                                    </p>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "sentiment",
                                        render: (data) => {
                                            const feedbackFeeling = feedbackFeelingsArray?.find(feelingObj => feelingObj.key === data?.moodRating)
                                            return (
                                                <div className="sentiment-container flex items-center">
                                                    <feedbackFeeling.icon />
                                                    <p className="sentiment-label text-14 weight-600 ml-4 text-dark">
                                                        {`${feedbackFeeling.label}`}
                                                    </p>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "rating",
                                        render: (data) => {
                                            return (
                                                <div>
                                                    <p className="text-14 weight-600 text-dark">
                                                        {data?.overallExperienceRating}/10
                                                    </p>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "feedback",
                                        render: (data) => {
                                            return (
                                                <div>
                                                    <p className="text-14 weight-600 text-dark suggestion break-word">
                                                        {data?.feedback}
                                                    </p>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "report",
                                        render: (data) => {
                                            const { position: { position_band }, reportStatus } = data;
                                            const showFitScore = REPORT_STATUS.GENERATED === reportStatus;
                                            return showFitScore ? (
                                                <a
                                                    href={getReportLink(data?.user?.id, data?.linkId, "parent=cc")}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="status cursor-pointer"
                                                >
                                                    <div
                                                        className="score-pill py-8 px-16 b-12 flex text-12"
                                                        style={convertToStyle(
                                                            getTraitBand({
                                                                median: position_band?.data?.median,
                                                                lb: position_band?.data?.lowerBound,
                                                                ub: position_band?.data?.upperBound,
                                                                score: data?.fitScore,
                                                            })
                                                        )}
                                                    >
                                                        <p>{roundOff(data?.fitScore)}</p>
                                                    </div>
                                                </a>
                                            ) : <p className="text-12 text-primary-color">Pending</p>
                                        },
                                    },
                                    {
                                        name: "date",
                                        render: (data) => {
                                            return (
                                                <p className="text-12 weight-500">
                                                    {getDefaultFormattedDate(data?.completedDate)}
                                                </p>
                                            )
                                        }
                                    }
                                ]}
                            />)
                            : <BlankState
                                className="blank-message"
                                message={"No feedback yet."}
                                onClick={() => { }}
                                showAddIcon={false}
                            />
                }
                {feedbacks.data?.totalPages > 1 ? (
                    <div className="pagination-box text-center">
                        <PrimaryPagination
                            totalElements={feedbacks.data?.count}
                            defaultPage={filters?.page ?? 1}
                            pageSize={feedbacks.data?.countPerPage}
                        />
                    </div>
                ) : <></>}
            </div>
        </>
    )
}

export default Feedbacks