import apiUrl from "../config/apiUrl";
import { Api, UnberryApi } from "../config/request";

const PdfReportApi = {
  checkCredit(organizationType, organizationId) {
    return UnberryApi.safeCall(
      `/api/reporting/v1/check-credit?organizationType=${organizationType}&organizationId=${organizationId}`,
      "GET"
    );
  },

  fetchCreditAmount(organizationId) {
    return UnberryApi.safeCall(`/api/credit/v1/company/:orgId`, "GET");
  },

  getUserScore(userId, linkId, bandType = "industry") {
    return UnberryApi.safeCall(
      `/api/reporting/v1/report-data/?userId=${userId}&linkId=${linkId}&bandType=${bandType}`,
      "GET"
    );
  },
  getQuizDetailsFromTraits(linkId, traits) {
    return UnberryApi.safeCall(
      `/api/reporting/v1/report-data/quiz-details?linkId=${linkId}&traits=${traits}`,
      "GET"
    );
  },
  getCodingDetailsFromTraits(linkId, traits) {
    return UnberryApi.safeCall(
      `/api/reporting/v1/report-data/coding-details?linkId=${linkId}&traits=${traits}`,
      "GET"
    );
  },
  fetchSnapshots(linkId) {
    return UnberryApi.safeCall(
      `/api/proctoring/v1?linkId=${linkId}`,
      "GET"
    );
  },
  getVideoSessions(linkId) {
    return Api.get(
      `${apiUrl.PROCTORING_API_URL}/api/session/v1/?assessmentId=${linkId}`,
      {},
      true
    )
  },
  getSessionVideos(sessionId) {
    return Api.get(
      `${apiUrl.PROCTORING_API_URL}/api/media/v1/?sessionId=${sessionId}`,
      {},
      true
    )
  },
  getSessionSnapshots(sessionId) {
    return Api.get(
      `${apiUrl.PROCTORING_API_URL}/api/snapshot/v1/?sessionId=${sessionId}`,
      {},
      true
    )
  },
  getSessionProfile(sessionId) {
    return Api.get(
      `${apiUrl.PROCTORING_API_URL}/api/session/v1/${sessionId}/profile-proctoring`,
      {},
      true
    )
  },
  getLinkPositionConfig(linkId) {
    return UnberryApi.safeCall(`/api/link/v1/get-link-position-config/${linkId}`, "GET")
  },
  getPositionConfig(linkId) {
    return UnberryApi.safeCall(`/api/position/v1/position-config?linkId=${linkId}`, "GET")
  }
};

export default PdfReportApi;
