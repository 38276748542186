import React from 'react';
import Text from '../Text';
import './AboutTraits.scss';

export default function AboutTraits(props) {
  const { className, traitName, traitDescription, subTraits, icon } = props;
  return (
    <div className={`about-traits w-100 ${className}`}>
      <div className="flex items-start" style={{ gap: 17 }}>
        <img src={icon} height="30" alt="skill-badge" />
        <h4 className="size-15 weight-500 flex">
          {/* <span
            className="skill-badge"
            style={{ backgroundColor: colorCode }}
          ></span> */}

          {traitName}
        </h4>
      </div>
      <div>
        <div className="text-grey size-13 weight-500">{traitDescription}</div>
        <div className="w-100 traits-description mt-18">
          {subTraits.map((trait, i, arr) => {
            return (
              <>
                <div className="size-11 weight-400">{trait.name}</div>
                <Text className="size-11 weight-400" text={trait.description} />
                {i !== arr.length - 1 && <div class="line"></div>}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
