import { createSlice } from "@reduxjs/toolkit";
import { setFeedbacks } from "./asyncActions";
import { initialState } from "./constant";

const feedbackSlice = createSlice({
    name: "feedbacks",
    initialState: initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: {
        [setFeedbacks.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        [setFeedbacks.pending]: (state, action) => {
            state.loading = true
        },
        [setFeedbacks.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { reset } = feedbackSlice.actions
export default feedbackSlice.reducer