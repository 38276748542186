export const CodeEditorView = (props) => {
  const { code } = props;
  const preLineNumber = code.split("\n")
  .map((line, i) => i + 1)
  .join("\n");

  return (
    <div className="editor">
      <pre className="pre-line-number">{preLineNumber}</pre>
      <pre className="pre-text">{code + '\n'}</pre>
    </div>
  );
};