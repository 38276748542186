import React from "react";
import "./PrimaryPagination.scss";
import { Pagination } from "antd";
import { useHistory } from "react-router-dom";
import { useUrlFilter } from "src/utils/hooks";

export default function PrimaryPagination(props) {
  const history = useHistory();
  const [filters, setFilters] = useUrlFilter();
  const { totalElements = 40, pageSize = 20, defaultPage } = props;

  const onChange = (page) => {
    setFilters({ ...filters, page });
  };

  return (
    <>
      <div className="pagination-style">
        <Pagination
          onChange={onChange}
          current={Number(filters?.page ?? defaultPage)}
          total={totalElements}
          pageSize={pageSize}
        />
      </div>
    </>
  );
}

PrimaryPagination.defaultProps = {
  pageSize: process.env.REACT_APP_PAGE_SIZE,
};
