import { Drawer } from "antd";
import { CloseIcon } from "src/assets/icons";
import CollapseMenu from "src/pages/MyDna/CollapseMenu";
import "./TraitsDrawer.scss";

export default function TraitsDrawer({
  onClose,
  visible,
  data,
  defaultDrawerTab = 0,
}) {
  return (
    <Drawer
      className="traits-drawer-container"
      title=""
      placement="right"
      onClose={onClose}
      visible={visible}
      header={null}
      footer={null}
      destroyOnClose
      closable={false}
    >
      <div className="drawer-content-wrapper">
        <div className="pb-30">
          <p className="text-18 bold text-theme-color opacity-6">Your people</p>
          <CloseIcon className="closeIcon" onClick={onClose} />
        </div>
        <CollapseMenu categories={data} defaultDrawerTab={defaultDrawerTab} />
      </div>
    </Drawer>
  );
}
