import { useMemo, useState } from "react";
import { AIPoweredInsights, StatBarsIcon } from "src/assets/icons";
import WithToolTip from "src/components/Common/WithToolTip";
import ChatDetailModal from "../ChatDetailModal";
import { capitalizeTitle } from "src/utils/helperFunctions";
import { isObject } from "src/utils/helperFunctions";
import { roundOff } from "src/pages/PdfReport/operations";

const tooltipStyle = {
	backgroundColor: "#7b7b7e",
	opacity: 1,
	borderRadius: '6px',
	padding: '10px',
	gap: '10px'
};

const excludedMarkers = [
	// Score Markers -> Visible in chat card
	"Average Score",
	// Anlalysis Markers -> Visible in modal
	"cefrBreakdown",
	"maxScore",
	"totalMessagesSentbyExecutive",
	"userScore",
	"summary",
	"cefrScore",
	"ticketResolved",
	"customerSatisfaction",
];

export const ChatCard = ({ cardData }) => {
	const { answer } = cardData;

	const [showModal, setShowModal] = useState(false);
	const isNumber = (num) => typeof num === 'number' && num !== null;
	const convertStringToNumber = (string) => string?.includes("N/A") ? "N/A" : typeof string === 'string' ? parseFloat(string)  : 'N/A';

	// roundOffToFirstDecimal -> Only for case of analysisMarkers (first col, second row ---> ChatDetailModal)
	const extractMarkers = (obj, capTitle=false, roundOffToFirstDecimal = false) =>  Object.keys(obj)
	.filter(key => !excludedMarkers.includes(key))
	.map(elem => {
		/**
		 * Since key can have value such
		 * empathy: "8/10 - The executive showed empathy by."
		 * OR 
		 * problemSolving: {
			 	"score": "7",
				"insights": "The executive offered a potential solution by suggesting a factory reset to troubleshoot the overheating issue."
			}
		 * Handle for both scenarios
		 */
		
		const [keyScore, keyDesc] =  isObject(obj[elem]) ? 
			[
				`${isNumber(obj[elem]?.score) ? `${obj[elem].score}`: convertStringToNumber(obj[elem].score) }`, // Handle for number as string cases.
				`${obj[elem]?.insights?.length>0 ? obj[elem].insights : "Insufficient Data"}`
			] : 
				obj[elem].split("-");
		return {
			name: capTitle ? capitalizeTitle(elem): elem,
			description: keyDesc,
			// Scaling of keyScore from BE always in 0-1 range -> Scale by 10 on FE
			score: keyScore?.includes("N/A") ? "NA" : `${roundOffToFirstDecimal ? roundOff(keyScore*10) : (keyScore*10)}/10`
		}
	});
	
	const [
		cefrScore, 
		cefrMarkers, 
		analysisMarkers, 
		summary, 
		chat 
	] = useMemo(() => {
		const { score={}, answerText="" } = answer ?? {};
		
		let scoreMarkers=[],
			analysisMarkers=[],
			parseChat=[],
			analysisSummary="",
			userCefrScore="";
		// If score obj does not have cefrBreakdown -> Return initialized values
		// ChatCard component specifically built for chat questions with cefr analysis
		if (score && score?.cefrBreakdown) {
			scoreMarkers = extractMarkers(typeof score.cefrBreakdown === "string" ? {} : score.cefrBreakdown, true);
			analysisMarkers = extractMarkers(score, true, true);
			parseChat = JSON.parse(answerText);
			userCefrScore = score?.cefrScore ? (score?.cefrScore?.split(" ").length > 1 ? "NA": score?.cefrScore) : "NA";
			analysisSummary = score?.summary ?? "";
		}
		
		return [
			userCefrScore, 
			scoreMarkers, 
			analysisMarkers, 
			analysisSummary, 
			parseChat 
		];
	}, [answer]);
	
  return (
		<>
			<ChatDetailModal 
				visible={showModal}
				setVisible={setShowModal}
				data={{
					cefrScore,
					cefrMarkers,
					analysisMarkers,
					summary,
					chat
				}}
			/>
			<div className="chat-card p-20 mb-20">
				<div className="chat-header flex items-center justify-between mb-10">
					<div className="chat-logo flex items-center bg-white">
						<AIPoweredInsights />
						<span className="ml-8">AI POWERED INSIGHTS</span>
					</div>
					<div 
						className="expand-btn cursor-pointer"
						onClick={() => setShowModal(true)}	
					>
						See Detailed Insights 
					</div>
				</div>

				<div className="chat-title">
					<p className="heading mb-8">Chatberry Analysis</p>
					<div className="summary-text">{summary}</div>
				</div>

				<div className="cefr-score flex items-center py-20">
					<p>CEFR SCORE:</p>
					{
						cefrScore === "NA"
						? <p className="text-16 insufficient-cefr capitalize weight-400 ml-10">Insufficient Data</p>
						: <div className="score-pill ml-10">{cefrScore}</div>
					}
				</div>

				<div className="cefr-markers-container flex flex-wrap items-center justify-start mt-20">
					{
						cefrMarkers.map((marker) => {
							const { name, score, description } = marker;
							return (
								<div className="cefr-marker flex flex-col mb-20">
									<span className="marker-score">{score}</span>
									<span className="marker-name">{name}</span>
									<WithToolTip
										title={description}
										showToolTip={true}
										placement={"bottom"}
										overlayInnerStyle={tooltipStyle}
										className={"cursor-pointer flex items-center"}
									>
										<StatBarsIcon />
										<span className="marker-desc ml-4">See Insight</span>
									</WithToolTip>
								</div>
							)
						})
					}
				</div>
			</div>
		</>
	);
};