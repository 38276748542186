import { DISQUALIFIED_BAND, FIT_BANDS } from "./constant";

/**
 *
 * Get band object from FIT_BANDS by companring score with trait attributes
 * upperBound: ub
 * lowerBound: lb
 * @param {object} ({ub: number, lb: number, median: number, score: number})
 * @returns {object} {label: string, color: string, textColor: string}
 */
export const getTraitBand = ({ ub = 0, lb = 0, median = 0, score = 0, disqualified=false }) => {
  if (disqualified) return DISQUALIFIED_BAND;
  if (score >= ub) return FIT_BANDS.HIGHLY;
  else if (score <= ub && score >= median) return FIT_BANDS.RECOMMENDED;
  else if (score <= median && score >= lb) return FIT_BANDS.CAUTIOUSLY;
  else return FIT_BANDS.NOT;
};

/**
 *
 * Get trait style object by FIT_BANDS band
 * @param {object} band ({label: string, color: string, textColor: string})
 * @returns {object} {background: string, color: string}
 */
export const convertToStyle = (band) => ({
  background: band.color,
  color: band.textColor,
});

/**
 *
 * Get array of objects from object of single array
 * @param {object} obj {traitId:string : [data: object], ...}
 * @returns {array} [{...data, traitId}: object, ...]
 */
export const generateList = (obj) => {
  return Object.keys(obj).reduce(
    (acc, traitId) =>
      obj[traitId].length > 0 ? [...acc, { ...obj[traitId][0], traitId }] : acc,
    []
  );
};
