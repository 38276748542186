import ReportGenerator from "src/components/Common/ReportGenerator";
import { LOGIN_TYPE, ORGANIZATION_TYPES } from "src/constants/globalConstants";
import { getDefaultFormattedDate, getRelativeTimeFormat, pluralizeWord } from "src/utils/helperFunctions";
import UserProfile from "./UserProfile";

export const POSITION_TYPES = {
  OPEN: 0,
  CLOSE: 1,
};

export const STATS_ENUM = {
  invited: {
    text: "Invitations Sent",
  },
  playing: {
    text: "Gameplays Started",
  },
  completed: {
    text: "Gameplays Completed",
  },
  shortlisted: {
    text: "Users Shortlisted",
  },
};

export const POSITION_ARCHIVE_STATUS = {
  OPEN: 0,
  CLOSE: 1,
};

export const HEADER_LAYOUT = {
  THIRD_PARTY: "1.2fr 0.9fr 0.6fr 0.9fr",
  NON_THIRD_PARTY: "0.9fr 1fr 0.5fr 0.6fr 1.1fr"
}
const COMMON_DATA_KEYS = {
  REPORT_GENERATOR: ({
    positionStats,
    orgId,
    constraints,
    dispatch,
    removeInvitedUser,
    mixpanelEventHandler,
    statusChangeHandler,
    editInvite = null,
    isThirdParty = false,
    activeApiKey,
    showCandidateFormData,
    showCandidateReport = false,
  }) => ({
    name: "status",
    render: (data) => {
      return (
        <ReportGenerator
          positionActiveStatus={positionStats?.data?.status}
          linkId={data?.linkId}
          userId={data?.id}
          reportStatus={data?.reportStatus}
          userStatus={data?.status}
          orgId={orgId}
          orgType={ORGANIZATION_TYPES.COMPANY}
          hiringStatus={data?.hiringStatus}
          disqualified={data?.disqualified}
          disqualifyReason={data?.disqualifyReason}
          constraints={constraints}
          fitScore={data?.fitScore}
          loginType={isThirdParty ? LOGIN_TYPE.THIRD_PARTY : ""}
          candidateData={{ candidateKey: data?.candidateKey, name: data?.name, email: data?.email }}
          activeApiKey={activeApiKey}
          proctoredImages={{}} // pass data.proctoredImages to show the snapshots component
          removeUser={(payload) =>
            dispatch(removeInvitedUser(payload))
          }
          logMixpanelEvent={(event) =>
            mixpanelEventHandler(event, data)
          }
          onChangeOfStatus={statusChangeHandler}
          editInvite={editInvite ? () => {
            editInvite(data)
          } : null}
          showCandidateFormData={showCandidateFormData}
          showCandidateReport={showCandidateReport}
        />
      )
    },
  }),
  DATE: {
    name: "date",
    render: (data) => {
      return (
        <div className="flex flex-col items-start mobile-order-3 mobile-flex-row">
          <div className="experience text-14 line-22 capitalize mobile-hide">
            {getDefaultFormattedDate(
              data.updatedAt,
              "MMM D, YYYY"
            )}
          </div>
          <div className="text-14 line-22 text-dark weight-400 opacity-7 mobile-hide">
            {getRelativeTimeFormat(data.updatedAt)}
          </div>
          <div className="text-16 line-22 text-black created mobile-visible">
            {getDefaultFormattedDate(
              data.updatedAt,
              "MMM D, YYYY"
            )}{" "}
            • {getRelativeTimeFormat(data.updatedAt)}
          </div>
        </div>
      );
    },
  },
}

export const DATA_KEYS = {
  THIRD_PARTY: ({
    positionLoginType,
    positionStats,
    orgId,
    constraints,
    dispatch,
    removeInvitedUser,
    statusChangeHandler,
    mixpanelEventHandler,
    editInvite,
    activeApiKey,
    isThirdParty = true,
    showCandidateFormData,
    showCandidateReport = false,
  }) => ([{
    name: "name",
    render: (data) => {
      let name = data.name;
      let profileImg = ''
      const { candidateKey="", customText } = data;
      if (candidateKey) {
        profileImg = customText?.profilePictureURL ?? ''
      }
      return (
        <div className="user-details flex items-center">
          <UserProfile
            positionLoginType={positionLoginType}
            profileImg={profileImg}
            name={name}
          />
          <div
            className={`user-name text-16 line-22 text-dark weight-600 text-clip ${candidateKey ? "" : ""}`}
            title={name}
          >
            {name}
            <p className="user-key text-dark weight-600 opacity-8 text-14 mt-6">
              {candidateKey}
            </p>
          </div>
          <div
            className="user-email text-14 line-22 text-dark weight-400 opacity-7 text-clip"
            title={data.email}
          >
            <span className="mobile-visible">
              {data.yearsOfExperience !== null
                ? `${data.yearsOfExperience} ${pluralizeWord(
                  "year",
                  "years",
                  data.yearsOfExperience
                )}`
                : null}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    name: "contact",
    render: (data) => {
      return (
        <div className="user-details flex flex-col items-center">
          <div
            className="user-email-contact text-14 line-22 text-dark weight-600 text-clip"
            title={data.phone}
          >
            {data.phone}
          </div>
          <div
            className="user-email-contact text-14 line-22 text-dark weight-600 text-clip"
            title={data?.email}
          >
            {data?.email}
          </div>
        </div>
      );
    },
  },
  COMMON_DATA_KEYS.DATE,
  COMMON_DATA_KEYS.REPORT_GENERATOR({
    positionStats,
    orgId,
    constraints,
    dispatch,
    removeInvitedUser,
    mixpanelEventHandler,
    statusChangeHandler,
    editInvite,
    isThirdParty,
    activeApiKey,
    showCandidateFormData,
    showCandidateReport,
  })
  ]
  ),
  NON_THIRD_PARTY: ({
    positionStats,
    orgId,
    constraints,
    dispatch,
    removeInvitedUser,
    statusChangeHandler,
    mixpanelEventHandler,
    editInvite,
    showCandidateFormData,
    showCandidateReport = false,
  }) => (
    [
      {
        name: "name",
        render: (data) => {
          let name = data.name;
          return (
            <div className="user-details">
              <div
                className={`user-name text-16 line-22 text-dark weight-600 text-clip`}
                title={name}
              >
                {name}
              </div>
              <div
                className="user-email text-14 line-22 text-dark weight-400 opacity-7 text-clip"
                title={data.email}
              >
                <span className="mobile-visible">
                  {data.yearsOfExperience !== null
                    ? `${data.yearsOfExperience} ${pluralizeWord(
                      "year",
                      "years",
                      data.yearsOfExperience
                    )}`
                    : null}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        name: "contact",
        render: (data) => {
          return (
            <div className="user-details">
              <div
                className="user-email-contact text-14 line-22 text-dark weight-600 text-clip"
                title={data.email}
              >
                {data.email}
                <span className="mobile-visible">
                  {data?.phone
                    ? ` • ${data?.phone}`
                    : null}
                </span>
              </div>
              <div
                className="user-phone-contact mt-8 text-14
              line-22 text-dark weight-600 opacity-8 text-clip mobile-hide"
                title={data?.phone}
              >
                {data?.phone ? `${data?.phone}` : "-"}
              </div>
            </div>
          );
        },
      },
      {
        name: "experience",
        render: (data) => {
          return (
            <div className="flex mobile-hide">
              <div className="">
                <div className="experience text-16 line-22 mb-6">
                  {data.yearsOfExperience !== null
                    ? `${data.yearsOfExperience} ${pluralizeWord(
                      "year",
                      "years",
                      data.yearsOfExperience
                    )}`
                    : null}
                </div>
              </div>
            </div>
          );
        },
      },
      COMMON_DATA_KEYS.DATE,
      COMMON_DATA_KEYS.REPORT_GENERATOR({
        positionStats,
        orgId,
        constraints,
        dispatch,
        removeInvitedUser,
        mixpanelEventHandler,
        statusChangeHandler,
        editInvite,
        showCandidateFormData,
        showCandidateReport,
      })
    ])
}

