import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./constant";
import { setOrganizations } from "./asyncActions";

export const organizationSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [setOrganizations.fulfilled]: (state, action) => {
      if (!action.payload) return;
      state.list.data = action.payload;
      state.list.loading = false;
      state.list.hasData = !!action.payload.length;
    },
    [setOrganizations.pending]: (state, action) => {
      state.list.loading = true;
    },
    [setOrganizations.rejected]: (state, action) => {
      state.list.loading = false;
    },
  },
});

export const { reset } = organizationSlice.actions;
export default organizationSlice.reducer;
